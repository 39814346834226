<template>
  <div style="text-align: center">
    <my-nav-bar :title="'提示'"/>
    <div style="padding: 16px;background-color: darkred;color: white;font-weight: bolder;font-size: 18px">
      您的IPhone手机版本过低<br/>需要通过Safari浏览器打开
    </div>
    <div style="width: 100vw;height: 90vh;">
      <img style="width: auto;height: auto;max-width: 100%;max-height: 100%"
           src="https://cdn.keihong.tech/typora/2022-09-29-%E4%BB%8E%E6%B5%8F%E8%A7%88%E5%99%A8%E6%89%93%E5%BC%80.png"/>
    </div>

  </div>
</template>

<script>
import MyNavBar from '../../components/MyNavBar'

export default {
  name: 'MdtWarning',
  components: { MyNavBar },
  data () {
    return {
      uid: '',
      channel: '',
      name: '',
      userAccount: ''
    }
  },
  mounted () {
    const query = this.$route.query
    if (query) {
      // this.$store.commit('setIsApp', query.isApp)
      this.uid = query.uid
      this.channel = query.channel
      this.name = query.name
      this.userAccount = query.userAccount
      // 检查当前是否是
      if (!this.isWeixin()) {
        const query = `uid=${this.uid}&channel=${this.channel}&name=${this.name}&userAccount=${this.userAccount}`
        this.$router.replace(`/mdt/video?from=safari&${query}`)
      }
    }
  },
  methods: {
    isWeixin () {
      const { userAgent } = navigator
      const isWeixin = !!/MicroMessenger/i.test(userAgent)
      return isWeixin
    }
  }
}
</script>

<style scoped>

</style>
