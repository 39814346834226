<template>
  <div>
    <van-nav-bar
      v-if="!hideNavBar"
      :fixed="true"
      left-arrow
      left-text="返回"
      title="文章详情"
      @click-left="handleGoBack"
    />
    <div v-if="$store.state.user.canShowMall && showMall"
         style="position:fixed;left: 50%;transform:translateX(-50%);width: 50%;padding:16px;text-align: center;color:#136b59;font-size: 16px;bottom: 16px;background-color: rgba(255,255,255,0.85);box-shadow: 0 0 10px 2px rgb(190,190,190);border-radius: 100px"
         @click="handleClickToolMall">
      点击查看文中提到的辅具>>
    </div>
    <div :class="{ 'non-nav-bar': hideNavBar }" class="page-content">
      <van-skeleton :loading="loading" :row="16" title/>
      <RichContent :richText="article.content"/>
    </div>

    <div style="height: 64px"></div>
  </div>
</template>

<!-- 文章详情 -->
<script>
import Vue from 'vue'
import RichContent from '../../common-components/RichEditor/RichContent.vue'

export default Vue.extend({
  comments: { RichContent },
  data () {
    return {
      // 数据
      article: {},
      // 状态
      loading: false,
      hideNavBar: false,
      pathFrom: null
    }
  },
  watch: {
    article (nextValue) {
      if (nextValue?.title) {
        document.title = nextValue?.title
      }
    }
  },
  mounted () {
    const params = this.$route.params
    const query = this.$route.query
    if (!params?.contentId) {
      this.goBackToPrevious('页面参数有误')
      return
    }
    if (query.hideNavBar === 'true') this.hideNavBar = true
    this.loadDetail({ ...params })
  },
  beforeRouteUpdate (to, from, next) {
    this.pathFrom = from.fullPath
    const params = to.params
    const query = to.query
    if (query.hideNavBar === 'true') this.hideNavBar = true
    if (!params?.contentId) {
      this.goBackToPrevious('页面参数有误')
      return
    }
    this.loadDetail({ ...params })
    next()
  },
  computed: {
    showMall () {
      return this.article.tool && this.article.mallGoodsId
    }
  },
  methods: {
    handleClickToolMall () {
      // 跳转
      this.$router.push('/MallDetailPage?from=kepulist&goodId=' + this.article.mallGoodsId)
    },
    async goBackToPrevious (tips) {
      try {
        await this.$alert({
          title: '提示',
          message: tips
        })
      } catch (error) {
      }
      if (this.pathFrom) {
        this.$router.replace(this.pathFrom)
      } else {
        this.$router.back()
      }
    },
    setPageViewed (params) {
      // FIXME 接口调用成功，后台管理员查看生效，非管理员查看无效
      this.$http.post('/reh_circle/add/content_browsing_history', null, {
        params: {
          ...params,
          userId: ''
        }
      })
    },
    async loadDetail (params) {
      this.setPageViewed(params)
      this.loading = true
      const { data: resp } = await this.$http.get('reh_circle/check/content', {
        params
      })
      const {
        code,
        data
      } = resp
      if (code !== 200 || !data) {
        this.goBackToPrevious('查询文章详情失败')
        this.loading = false
        return
      }
      this.article = data
      console.log('文章详情', data)
      this.loading = false
    },
    handleGoBack () {
      this.$router.back()
    }
  },
  components: { RichContent }
})
</script>
<style lang="less">
body {
  line-height: 1.6;
  font-size: 16px;
  font-family: system-ui, -apple-system, Helvetica Neue, sans-serif;

  .page-content {
    padding: 20px 20px 0;
    margin-top: 46px;

    &.non-nav-bar {
      margin-top: 0px;
    }

    .rich-content-container {
      width: unset;
      padding: 0;

      h1 {
        font-size: 22px;
        font-weight: normal;
        line-height: 1.4;
        margin-bottom: 14px;
      }
    }
  }
}
</style>
