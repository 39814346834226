<template>
  <div id="demo-app">
    <my-web-loading :visible="isLoading"/>
    <!-- 主内容 -->
    <!--    <keep-alive>-->
    <router-view/>
    <!--    </keep-alive>-->
  </div>
</template>

<script>

import MyWebLoading from '@/h5/doucan/components/MyWebLoading.vue'

export default {
  name: 'app',
  components: { MyWebLoading },
  data () {
    return {
      isLoading: false
    }
  },
  created () {
    window.addEventListener('load', this.hideLoading)
  },
  methods: {
    hideLoading () {
      this.isLoading = false
    }
  },
  beforeDestroy () {
    window.removeEventListener('load', this.hideLoading)
  },
  beforeCreate () {
  }
}
</script>
