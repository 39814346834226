<template>
  <div>
    <base-title-bar-scroll-page ref="container" title="企业登录">
      <div class="login-container">
        <van-field
          v-model="loginInfo.name"
          label="企业名称"
          placeholder="请输入企业名称"
          required
        />
        <van-field
          v-model="loginInfo.password"
          label="密码"
          placeholder="请输入密码"
          required
          type="password"
        />
        <van-button block type="info" @click="handleLogin">登录</van-button>
      </div>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import ExchangeDao from '@/h5/doucan/network/ExchangeDao'

export default {
  name: 'EnterpriseLoginPage',
  components: { BaseTitleBarScrollPage },
  data () {
    return {
      loginInfo: {
        name: '',
        password: ''
      }
    }
  },
  mounted () {
    if (this.$store.state.giftCard.enterpriseName) {
      this.loginInfo.name = this.$store.state.giftCard.enterpriseName
    }
    this.hideLoading()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    handleLogin () {
      // 这里应实现登录逻辑，例如调用API
      console.log('登录信息：', this.loginInfo)
      ExchangeDao.loginEnterprise(this.loginInfo.name, this.loginInfo.password).then(res => {
        console.log('登录结果：', res)
        if (res.code === 200) {
          console.log('登录成功')
          this.$store.commit('giftCard/setEnterpriseName', this.loginInfo.name)
          // 跳转首页
          this.$router.push('/EnterpiseMainPage?name=' + this.loginInfo.name)
          // console.log()
        } else {
          console.error('登录失败:', res.message)
          // 弹窗提示
          this.$toast('登录失败,请检查名称、密码是否正确')
        }

        return null
      }).catch(error => {
        console.error('登录失败', error)
      })
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>
.login-container {
  padding: 20px;
}
</style>
