<template>
  <div>
    <van-nav-bar v-if="!hideNavBar" :title="barTitle" fixed/>
    <div :class="{ 'non-nav-bar': hideNavBar }" class="page-content">
      <van-empty
        v-if="!loading && !!errorMessage"
        :description="errorMessage"
        image="error"
      />
      <van-pull-refresh v-model="refreshing" @refresh="handleRefresh">
        <van-list
          v-model="loading"
          :error.sync="hasError"
          :finished="finished"
          :finished-text="list === null ? '' : '没有更多内容了'"
          :immediate-check="false"
          error-text="加载失败，点击重新加载"
          @load="handleLoad"
        >

          <div v-for="item in list" :key="item.contentId"
               @click="handleToDetail(item)">
            <ListItem
              :browseNumber="item.browseNumber"
              :cover="item.cover"
              :mall-goods-id="item.mallGoodsId"
              :picList="item.picList"
              :summary="item.summary"
              :title="item.title"
              :tool="item.tool"
              :type="item.type"
              :video="item.video"

            />
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div style="height: 64px"></div>
  </div>
</template>

<!-- 文章列表 -->
<!--
TODO
1、增加只可以单例播放
 -->
<script>
import Vue from 'vue'
import ListItem from './ListItem.vue'

export default Vue.extend({
  components: { ListItem },
  props: {
    type: {
      type: Number,
      default: 1
    },
    from: {
      type: String,
      default: ''
    }

  },
  data () {
    return {
      // 数据
      nextPage: 1,
      pageCount: 10,
      totalCount: 0,
      list: null,
      // 状态
      hideNavBar: false,
      hasError: false,
      errorMessage: null,
      loading: false,
      refreshing: false,
      finished: true,
      barTitle: '康复圈'
    }
  },
  computed: {
    isEmpty () {
      return !this.loading && this.list?.length === 0
    }
  },
  mounted () {
    // const query = this.$route.query
    // if (!query?.userId) {
    //   this.errorMessage = '调用参数有误'
    //   return
    // }
    // if (query?.hideNavBar === 'true') this.hideNavBar = true
    this.hideNavBar = true
    this.loadList()

    // TODO 判断当前是否为Android APP 环境
    // 挂在函数到window，供Android端调用
    window.androidCallChangeTitle = this.androidCallChangeTitle
  },
  beforeRouteUpdate (to, _, next) {
    const { query } = to
    if (query.hideNavBar === 'true') this.hideNavBar = true
    next()
  },
  methods: {
    androidCallChangeTitle (title) {
      this.barTitle = title
      return 10
    },
    vueCallAndroidOnFeedDetail (feedItem) {
      if (window.android !== null && typeof (window.android) !== 'undefined') {
        window.android.onFeedDetail(JSON.stringify(feedItem))
      } else {
        // alert(typeof (window.android))
      }
    },
    async loadList () {
      const page = this.refreshing ? 1 : this.nextPage
      const onFailed = () => {
        if (this.refreshing) this.$toast('刷新失败')
      }
      try {
        const { data: resp } = await this.$http.get(
          'reh_circle/check/content_list',
          {
            params: {
              page,
              pageCount: this.pageCount,
              status: 1,
              type: this.type
            }
          }
        )
        if (resp?.code !== 200 || !resp?.data) {
          this.hasError = true
          onFailed()
          return
        }
        const {
          contentPreResp,
          pageResp
        } = resp.data
        console.log(resp.data)
        if (contentPreResp?.length > 0) {
          this.nextPage = pageResp?.page + 1
          this.totalCount = pageResp?.size
          this.finished = false
        } else {
          this.finished = true
        }
        console.log('科普列表', contentPreResp)
        // 下拉刷新
        if (this.refreshing) {
          this.list = contentPreResp
          return
        }
        // 上拉加载
        if (!this.list) {
          this.list = contentPreResp
        } else {
          this.list.push(...contentPreResp)
        }
      } catch (error) {
        console.log('error: ', error)
        this.hasError = true
        onFailed()
      }
    },
    async handleLoad () {
      if (this.finished) return
      if (this.list?.length === this.totalCount) {
        this.loading = false
        this.finished = true
        return
      }
      await this.loadList()
      this.loading = false
    },
    async handleRefresh () {
      this.finished = false
      this.refreshing = true
      await this.loadList()
      this.refreshing = false
    },
    handleToDetail (item) {
      console.log('item: ', item)
      // 非文章类型，不可跳转
      if (item.type !== 1) return
      const contentId = item.contentId
      // 告知Android当前进入的feed详情
      this.vueCallAndroidOnFeedDetail(item)
      this.$router.push({
        name: 'detail',
        params: {
          contentId
        }
      })
    }
  }
})
</script>

<style lang="less" scoped>
html body {
  height: 100%;

  .page-content {
    margin-top: 48px;
    padding: 0;

    &.non-nav-bar {
      margin-top: 0px;
    }
  }
}
</style>
