<template>
  <div>
    <base-title-bar-scroll-page ref="container" title="疾病科普宣教">

      <div id="srcollcontainer" class="bg" style="width: 100vw;height: 100vh;overflow-y: auto;/* 垂直滚动 */">
        <div id="top" style="padding: 16px">
          <div v-for="(cateItems,index) in cate" :key="index">
            <div style="margin-top: 8px;font-weight: bold;font-size: 18px">目录·{{ cateItems.title }}</div>
            <div class="cate-container">
              <div
                v-for="(item, index) in cateItems.items"
                :key="index"
                :class="{ placeholder: item.placeholder }"
                class="item"
                @click="handleCateClick(item.type)"
              >
                <div v-if="!item.placeholder">{{ item.text }}</div>
              </div>
            </div>
          </div>
        </div>
        <div style="padding: 16px">
          <div v-for="(content, index) in contents" :key="index"
               style="margin-bottom: 16px;">
            <div
              :id="content.type"
              style="color: white;border-top-left-radius: 16px;border-top-right-radius: 16px;height: 48px;background-color: #23b346;font-size: 20px;font-weight: bold;line-height: 48px;text-align: center">
              {{ content.type }}
            </div>
            <!--            <div style="margin-top: 8px;font-weight: bold;font-size: 18px"></div>-->
            <!--            <div style="margin-top: 8px;font-weight: bold;font-size: 18px">目录·{{ content.type }}</div>-->
            <div v-for="(article, index) in content.articles" :key="index" style="width: 100%"
                 @click="toDetail(article)">
              <div style="background-color: white;padding: 24px;display: flex;flex-direction: row">
                <div v-if="content.notShowIndex" style="font-size: 18px;margin-right: auto">{{ article.title }}</div>
                <div v-else style="font-size: 18px;margin-right: auto">{{ index + 1 }}、{{ article.title }}</div>
                <div>
                  <my-img :url="article.poster"
                          style="width: 80px;height: 55px;margin-left: 8px;border-radius: 8px; object-fit: cover"/>
                </div>
              </div>
              <div v-if="index!==content.articles.length-1"
                   style="height: 0.5px;background-color: #eaeaea;width: 100%"></div>
            </div>
            <div
              style="background-color: white;height: 16px;border-bottom-left-radius: 16px;border-bottom-right-radius: 16px"></div>
          </div>

        </div>
      </div>
      <div
        style="position: fixed;bottom: 16px;right:0;padding: 8px 16px;border-top-left-radius: 100px; border-bottom-left-radius:100px;background-color: rgba(10,75,61,0.86);color: white"
        @click="toTop">
        回到目录
      </div>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import MyImg from '@/h5/doucan/components/MyImg.vue'
import ReportFeatureAccessDao from '@/h5/doucan/network/ReportFeatureAccessDao'

const BASEURL = 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/'

export default {
  name: 'SickKepuShenwai',
  components: {
    MyImg,
    BaseTitleBarScrollPage
  },
  data () {
    return {
      savedPosition: 0,
      hospital: null,
      cateItemsPerRow: 4,
      cate: [{
        title: '常见科普',
        items: [
          {
            text: '入院须知',
            type: '入院须知'
          },
          {
            text: '出院须知',
            type: '出院须知'
          },
          {
            text: '辅具科普',
            type: '辅具科普'
          },
          {
            text: '脑卒中康复',
            type: '脑卒中康复'
          }]
      },
      {
        title: '入院早期',
        items: [
          {
            text: '用药指导',
            type: '入院早期-用药指导'
          },
          {
            text: '日常宣教',
            type: '入院早期-日常宣教'
          },
          {
            text: '疾病宣教',
            type: '入院早期-疾病宣教'
          }
        ]
      },
      {
        title: '软瘫期康复指导',
        items: [
          {
            text: '一、肩部',
            type: '软瘫期康复指导（一、肩部）'
          },
          {
            text: '二、肘关节',
            type: '软瘫期康复指导（二、肘关节）'
          },
          {
            text: '三、指和腕关节',
            type: '软瘫期康复指导（三、指和腕关节）'
          },
          {
            text: '四、下肢训练',
            type: '软瘫期康复指导（四、下肢训练）'
          },
          {
            text: '五、躯干与呼吸',
            type: '软瘫期康复指导（五、躯干与呼吸）'
          }
          // ... 这里是你要显示的实际数据
        ]
      }
      ],
      contents: [
        {
          type: '入院须知',
          articles: [
            {
              title: '神经外科患者入院用物准备清单',
              id: 378,
              poster: BASEURL + 'article/feeds/未知标题文章/Thu-Jan-18-2024/神经外科患者入院用物准备清单.png'
            },
            {
              title: '神经外科入院患者须知',
              id: 376,
              poster: BASEURL +
                'article/feeds/未知标题文章/Thu-Jan-18-2024/神经外科入院患者须知（首图）.png'
            }
          ]
        }, {
          type: '出院须知',
          articles: [
            {
              title: '神经外科出院患者须知',
              id: 377,
              poster: BASEURL + 'article/feeds/神经外科出院患者须知/神经外科出院患者须知.png'
            }
          ]
        }, {
          type: '辅具科普',
          articles: [
            {
              id: 401,
              title: '中风患者康复辅具——足托的使用指南',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/中风患者康复辅具——足托的使用指南/足下垂固定器.jpg'
            }, {
              id: 400,
              title: '中风患者康复辅具——【肩托】的使用指南',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/中风患者康复辅具——【肩托】的使用指南/肩部脱臼z1.jpg'
            }, {
              id: 393,
              title: '拍痰杯，让咳嗽变得不再痛苦',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/拍痰杯，让咳嗽变得不再痛苦/1.jpg'
            }, {
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题资讯/Fri-Apr-26-2024/医用弹力袜的穿戴步骤2.mp4',
              id: 417,
              title: '医用弹力袜如何穿戴？',
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1714489087575.jpg'
            }, {
              id: 418,
              title: '医用弹力袜|有什么作用？如何穿戴？',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/医用弹力袜|有什么作用？如何穿戴？/医用弹力袜封面1.webp'
            }
          ]
        },
        {
          type: '入院早期-用药指导',
          articles: [
            {
              title: '神经外科针剂药物宣教（精简版）',
              id: 404,
              poster: BASEURL + 'article/feeds/神经外科针剂药物宣教（精简版）/针剂图.jpg'
            },
            {
              title: '神经外科口服药药物宣教（精简版）',
              id: 403,
              poster: BASEURL + 'article/feeds/神经外科口服药药物宣教（精简版）/药图.png'
            }
          ]
        },
        {
          type: '入院早期-日常宣教',
          articles: [
            {
              title: '神经外科危重患者的护理要点',
              id: 380,
              poster: BASEURL + 'article/feeds/神经外科危重患者的护理要点/神经外科危重患者的护理要点.png'
            },
            {
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/1L/01CSE1WF047FWYB8FH87FBTVM1-1L1005-轮椅-床体位转移.mp4',
              from: 'course - 视频课程库',
              title: '轮椅-床体位转移',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/1L/01CSE1WF047FWYB8FH87FBTVM1-1L1005-轮椅-床体位转移.jpg'
            }
          ]
        },
        {
          type: '脑卒中康复',
          articles: [{
            videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/Kp1004-%E5%8D%8A%E5%8D%A7%E4%BD%8D-%E8%89%AF%E8%82%A2%E4%BD%8D%E6%91%86%E6%94%BE%E8%AE%AD%E7%BB%83-%E6%96%B0%E7%BC%96%E7%A0%81.mp4',
            from: 'article - 科普宣教库',
            id: 24,
            title: '半卧位-良肢位摆放训练',
            poster: 'https://cdn.keihong.tech/202311211855040.jpg'
          }, {
            videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/KP1007-%E6%82%A3%E4%BE%A7%E7%BF%BB%E8%BA%AB%E8%AE%AD%E7%BB%83.mp4',
            from: 'article - 科普宣教库',
            id: 27,
            title: '患侧翻身训练',
            poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/KP1007-%E6%82%A3%E4%BE%A7%E7%BF%BB%E8%BA%AB%E8%AE%AD%E7%BB%83.jpeg'
          }, {
            videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/KP1003-%E6%82%A3%E4%BE%A7%E5%8D%A7%E4%BD%8D-%E8%89%AF%E8%82%A2%E4%BD%8D%E6%91%86%E6%94%BE.mp4',
            from: 'article - 科普宣教库',
            id: 23,
            title: '患侧卧位-良肢位摆放',
            poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/KP1003-%E6%82%A3%E4%BE%A7%E5%8D%A7%E4%BD%8D-%E8%89%AF%E8%82%A2%E4%BD%8D%E6%91%86%E6%94%BE.jpeg'
          }, {
            videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/KP1002-%E5%81%A5%E4%BE%A7%E5%8D%A7%E4%BD%8D-%E8%89%AF%E8%82%A2%E4%BD%8D%E6%91%86%E6%94%BE.mp4',
            from: 'article - 科普宣教库',
            id: 22,
            title: '健侧卧位-良肢位摆放',
            poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/KP1002-%E5%81%A5%E4%BE%A7%E5%8D%A7%E4%BD%8D-%E8%89%AF%E8%82%A2%E4%BD%8D%E6%91%86%E6%94%BE.jpeg'
          }, {
            videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/KP1001-%E4%BB%B0%E5%8D%A7%E4%BD%8D-%E8%89%AF%E8%82%A2%E4%BD%8D%E6%91%86%E6%94%BE.mp4',
            from: 'article - 科普宣教库',
            id: 20,
            title: '仰卧位-良肢位摆放',
            poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/KP1001-%E4%BB%B0%E5%8D%A7%E4%BD%8D-%E8%89%AF%E8%82%A2%E4%BD%8D%E6%91%86%E6%94%BE.jpeg'
          }, {
            videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/KP1006-%E5%81%A5%E4%BE%A7%E7%BF%BB%E8%BA%AB%E8%AE%AD%E7%BB%83.mp4',
            from: 'article - 科普宣教库',
            id: 26,
            title: '健侧翻身训练',
            poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/KP1006-%E5%81%A5%E4%BE%A7%E7%BF%BB%E8%BA%AB%E8%AE%AD%E7%BB%83.jpeg'
          }, {
            videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/KP1008-%E5%81%A5%E4%BE%A7%E5%8D%A7%E4%BD%8D%E5%88%B0%E5%9D%90%E4%BD%8D%E7%BF%BB%E8%BA%AB%E8%AE%AD%E7%BB%83.mp4',
            from: 'article - 科普宣教库',
            id: 28,
            title: '健侧卧位到坐位翻身训练',
            poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/KP1008-%E5%81%A5%E4%BE%A7%E5%8D%A7%E4%BD%8D%E5%88%B0%E5%9D%90%E4%BD%8D%E7%BF%BB%E8%BA%AB%E8%AE%AD%E7%BB%83.jpeg'
          }, {
            videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/KP1010-%E5%81%8F%E7%98%AB%E5%88%9D%E6%9C%9F%E5%8C%BB%E7%96%97%E4%BD%93%E6%93%8D-%E5%8D%8A%E6%A1%A5%E8%BF%90%E5%8A%A8.mp4',
            from: 'article - 科普宣教库',
            id: 30,
            title: '偏瘫初期医疗体操-半桥运动',
            poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/KP1010-%E5%81%8F%E7%98%AB%E5%88%9D%E6%9C%9F%E5%8C%BB%E7%96%97%E4%BD%93%E6%93%8D-%E5%8D%8A%E6%A1%A5%E8%BF%90%E5%8A%A8.jpeg'
          }, {
            videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/Kp1011-%E5%81%8F%E7%98%AB%E5%88%9D%E6%9C%9F%E5%8C%BB%E7%96%97%E4%BD%93%E6%93%8D-%E6%8D%8F%E6%8C%A4%E6%82%A3%E6%89%8B.mp4',
            from: 'article - 科普宣教库',
            id: 31,
            title: '偏瘫初期医疗体操-捏挤患手',
            poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/Kp1011-%E5%81%8F%E7%98%AB%E5%88%9D%E6%9C%9F%E5%8C%BB%E7%96%97%E4%BD%93%E6%93%8D-%E6%8D%8F%E6%8C%A4%E6%82%A3%E6%89%8B.jpeg'
          }, {
            videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/Kp1012-%E5%81%8F%E7%98%AB%E5%88%9D%E6%9C%9F%E5%8C%BB%E7%96%97%E4%BD%93%E6%93%8D-%E7%9B%B4%E8%85%BF%E6%8A%AC%E9%AB%98.mp4',
            from: 'article - 科普宣教库',
            id: 32,
            title: '偏瘫初期医疗体操-直腿抬高',
            poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/Kp1012-%E5%81%8F%E7%98%AB%E5%88%9D%E6%9C%9F%E5%8C%BB%E7%96%97%E4%BD%93%E6%93%8D-%E7%9B%B4%E8%85%BF%E6%8A%AC%E9%AB%98.jpeg'
          }, {
            videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/Kp1013-%E5%81%8F%E7%98%AB%E5%88%9D%E6%9C%9F%E5%8C%BB%E7%96%97%E4%BD%93%E6%93%8D-%E7%BB%84%E6%8C%87%E4%B8%8A%E4%B8%BE.mp4',
            from: 'article - 科普宣教库',
            id: 33,
            title: '偏瘫初期医疗体操-组指上举',
            poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/Kp1013-%E5%81%8F%E7%98%AB%E5%88%9D%E6%9C%9F%E5%8C%BB%E7%96%97%E4%BD%93%E6%93%8D-%E7%BB%84%E6%8C%87%E4%B8%8A%E4%B8%BE.jpeg'
          }, {
            videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/Kp1005-%E6%AD%A3%E7%A1%AE%E7%9A%84%E5%BA%8A%E4%B8%8A%E5%9D%90%E5%A7%BF-%E6%96%B0%E7%BC%96%E7%A0%81.mp4',
            from: 'article - 科普宣教库',
            id: 25,
            title: '正确的床上坐姿',
            poster: 'https://cdn.keihong.tech/202311211858586.jpg'
          }, {
            videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/KP1009-%E5%9D%90%E4%BD%8D-%E4%BB%B0%E5%8D%A7%E4%BD%8D%E4%BD%93%E4%BD%8D%E8%BD%AC%E7%A7%BB.mp4',
            from: 'article - 科普宣教库',
            id: 29,
            title: '坐位-仰卧位体位转移',
            poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/KP1009-%E5%9D%90%E4%BD%8D-%E4%BB%B0%E5%8D%A7%E4%BD%8D%E4%BD%93%E4%BD%8D%E8%BD%AC%E7%A7%BB.jpeg'
          }]
        },
        {
          type: '入院早期-疾病宣教',
          articles: [{
            title: '伤口是怎么愈合的？',
            id: 213,
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917237200.jpg',
            videoUrl: BASEURL + 'article/feeds/未知标题资讯/Mon-Mar-27-2023/伤口是怎么愈合的？.mp4'
          },
          {
            title: '垂体瘤——康复科普宣教',
            id: 373,
            poster: BASEURL + 'article/feeds/垂体瘤康复科普宣教——神经外科/垂体瘤2.png'
          }, {
            title: '颅骨缺损——康复科普宣教',
            id: 375,
            poster: BASEURL + 'article/feeds/未知标题文章/Thu-Jan-18-2024/颅骨缺损3.jpg'
          }, {
            title: '颈动脉海绵窦篓——康复科普宣教',
            id: 374,
            poster: BASEURL + 'article/feeds/颈动脉海绵窦篓——康复科普宣教/颈动脉海绵窦瘘1.jpg'
          },
          {
            title: '脑动静脉畸形的多种方案治疗',
            id: 225,
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917244427.jpg',
            videoUrl: BASEURL + 'article/feeds/未知标题资讯/Wed-Apr-19-2023/脑动静脉畸形的多种方案治疗.mp4'
          },
          {
            title: '海绵状血管畸形是什么？',
            id: 105,
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917129665.jpg',
            videoUrl: BASEURL + 'article/feeds/未知标题资讯/Sun-Oct-23-2022/海绵状血管畸形是什么？.mp4'
          },
          {
            title: '脑干海绵状血管畸形手术展示',
            id: 206,
            poster: 'https://cdn.keihong.tech/typora/2023-03-19-%E7%BB%84%2010%20-1-.png',
            videoUrl: BASEURL + 'article/feeds/未知标题资讯/Thu-Mar-16-2023/脑干海绵状血管畸形手术展示.mp4'
          },
          {
            title: '疼痛是怎样产生的？止痛药是怎样起作用的？',
            id: 71,
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917054238.jpg',
            videoUrl: BASEURL + 'article/feeds/未知标题资讯/Thu-Sep-15-2022/疼痛是怎样产生的？止痛药是怎样起作用的？.mp4'
          },
          {
            title: '阿司匹林的抗炎、退热、抗血栓的作用机制是什么 ？',
            id: 76,
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917068542.jpg',
            videoUrl: BASEURL + 'article/feeds/阿司匹林的抗炎、退热、抗血栓的作用机制是什么 ？/阿司匹林的100多年传奇故事.mp4'
          },
          {
            title: '血流导向装置治疗颅内动脉瘤',
            id: 88,
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917097263.jpg',
            videoUrl: BASEURL + 'article/feeds/血流导向装置治疗颅内动脉瘤/血流导向装置治疗颅内动脉瘤.mp4'
          },
          {
            title: '不稳定斑块堵塞血管的过程',
            id: 83,
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917083270.jpg',
            videoUrl: BASEURL + 'article/feeds/未知标题资讯/Sat-Sep-24-2022/不稳定斑块堵塞血管的过程.mp4'
          },
          {
            title: '进行颅内动脉瘤介入栓塞后效果如何？',
            id: 216,
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917239358.jpg',
            videoUrl: BASEURL + 'article/feeds/未知标题资讯/Tue-Apr-04-2023/进行颅内动脉瘤介入栓塞后效果.mp4'
          },
          {
            title: '血小板活化聚集在止血中起重要作用？',
            id: 78,
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917070400.jpg',
            videoUrl: BASEURL + 'article/feeds/血小板活化聚集在止血中起重要作用/血小板活化聚集在止血中起重要作用02.mp4'
          },
          {
            title: 'TCAR--逆向血流保护下经颈动脉支架成形术',
            id: 111,
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917144655.jpg',
            videoUrl: BASEURL + 'article/feeds/未知标题资讯/Wed-Oct-26-2022/TCAR--逆向血流保护下经颈动脉支架成形术.mp4'
          },
          {
            title: '为什么做腰椎穿刺？怎么做腰椎穿刺？做完腰椎穿刺怎么办？',
            id: 132,
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917189852.jpg',
            videoUrl: BASEURL + 'article/feeds/为什么做腰椎穿刺？怎么做腰椎穿刺？做完腰椎穿刺怎么办？/为什么做腰椎穿刺？怎么做腰椎穿刺？做完腰椎穿刺怎么办？.mp4'
          },
          {
            title: '解密脑动脉瘤的微创治疗——弹簧圈栓塞技术',
            id: 148,
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917198221.jpg',
            videoUrl: BASEURL + 'article/feeds/未知标题资讯/Wed-Nov-30-2022/解密脑动脉瘤的微创治疗弹簧圈栓塞技术.mp4'
          },
          {
            title: '动脉瘤破裂后蛛网膜下腔出血脑血管痉挛过程',
            id: 192,
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917222532.jpg',
            videoUrl: BASEURL + 'article/feeds/未知标题资讯/Thu-Feb-16-2023/动脉瘤破裂后蛛网膜下腔出血脑血管痉挛过程.mp4'
          },
          {
            id: 160,
            title: '无声的杀手-高血压：是如何一步步破坏各种器官的',
            poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/无声的杀手-高血压：是如何一步步破坏各种器官的/48岁男子血压07.jpg'
          }, {
            id: 409,
            title: '血栓最容易堵在身体3个地方！有这4个信号一定要留意→',
            poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/血栓最容易堵在身体3个地方！有这4个信号一定要留意→/血栓封面1.jpg'
          }, {
            id: 410,
            title: '国人“最佳血压值”出炉！改变1个小习惯，死亡风险大大降低',
            poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/国人“最佳血压值”出炉！改变1个小习惯，死亡风险大大降低/高血压首图1.jpg'
          }, {
            id: 411,
            title: '超一半人不知道自己得了糖尿病！出现这些症状要警惕→',
            poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/超一半人不知道自己得了糖尿病！出现这些症状要警惕→/糖尿病首图.jpg'
          }, {
            id: 412,
            title: '注意了！有这6种症状，提醒你该查血脂了',
            poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/注意了！有这6种症状，提醒你该查血脂了/高血脂首图1.jpg'
          }, {
            id: 109,
            title: '一起了解高血压九大误区！这些错误不能犯',
            poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/一起了解高血压九大误区！这些错误不能犯/微信图片_20221024220426.jpg'
          }, {
            id: 108,
            title: '为什么中国人最容易中风？',
            poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/为什么中国人最容易中风？/微信图片_20221023201522.jpg'
          }, {
            videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题资讯/Fri-Oct-07-2022/从水锤效应看高血压脑出血.mp4',
            id: 92,
            title: '从水锤效应看高血压脑出血',
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917106868.jpg'
          }, {
            videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/中风有哪些急救方式/中风有哪些急救方式.mp4',
            id: 123,
            title: '中风有哪些急救方式？',
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917169587.jpg'
          }, {
            id: 147,
            title: '脑卒中后的大脑终生都有可塑性，千万别轻易放弃！',
            poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题文章/Mon-Nov-28-2022/微信图片_20221128112401.jpg'
          }, {
            id: 207,
            title: '中风是如何影响语言功能的？',
            poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/中风是如何影响语言功能的？/微信图片_20230117120811.png'
          }, {
            id: 265,
            title: '脑卒中康复|如何自己穿脱衣裤？',
            poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/脑卒中康复|如何自己穿脱衣裤？/R-C.png'
          }, {
            videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题资讯/Mon-Dec-12-2022/中国康复医学会标准委员会主任观点：远程康复市场分析与展望.mp4',
            id: 154,
            title: '你觉得远程康复会给卒中患者带来怎么样的获益？',
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917202374.jpg'
          }, {
            videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题资讯/Mon-May-15-2023/三分钟了解脑卒中：类型、病因、症状和治疗 .mp4',
            id: 241,
            title: '三分钟了解脑卒中：类型、病因、症状和治疗 ',
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917254537.jpg'
          }, {
            videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题资讯/Wed-May-31-2023/脑梗死恢复期患者如何饮食指导？.mp4',
            id: 249,
            title: '脑梗死恢复期患者如何饮食指导？'
          }, {
            id: 274,
            title: '脑卒中康复|如何经口进食？',
            poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/脑卒中康复|如何经口进食？/640.jpg'
          }, {
            id: 325,
            title: '关爱女性，远离卒中！',
            poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/关爱女性，远离卒中！/妊娠（2）.png'
          }, {
            id: 326,
            title: '“卒”不及防，如何预防？',
            poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/“卒”不及防，如何预防？/640.jpg'
          }, {
            id: 432,
            title: '远程康复是什么？患者该如何进行康复？',
            poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/远程康复是什么？患者该如何进行康复？/容器 5@1x.png'
          }, {
            videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/房颤如何引起中风？ 如何急救？/房颤如何引起中风？如何急救？.mp4',
            id: 80,
            title: '房颤如何引起中风？ 如何急救？',
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917075638.jpg'
          }, {
            videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/中风发病后有哪些就诊陷阱？/中风发病后有哪些就诊陷阱02.mp4',
            id: 81,
            title: '中风发病后有哪些就诊陷阱？',
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917076426.jpg'
          }, {
            videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题资讯/Fri-Sep-23-2022/手术麻醉之房颤与中风.mp4',
            id: 82,
            title: '手术麻醉之房颤与中风',
            poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917077344.jpg'
          }
          ]
        },
        {
          type: '软瘫期康复指导（一、肩部）',
          notShowIndex: true,
          articles: [
            {
              from: 'course - 视频课程库',
              title: '1、肩关节外展训练',
              // id: 29,
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21A/494526b2-6f2d-4e32-a9bd-88243b89ff0a1A1004-肩关节外展训练-软瘫期.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21A/4abbac37-1bb5-428c-b56d-1926d90c9e481A1004.png'
            }, {
              from: 'course - 视频课程库',
              title: '2、肩关节内收',
              // id: 29,
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21A/733e6cd2-300c-40ff-86b4-401f86c9eb651A1003-肩关节内收训练-软瘫期.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21A/318f6611-8528-45d0-befa-11fd1494cf8f1A1003.png'
            }, {
              from: 'course - 视频课程库',
              title: '3、肩关节前屈训练',
              // id: 29,
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21A/b6319f78-8b0d-48d9-b7c4-8431547649a31A1005-肩关节前屈训练-软瘫期.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21A/c660c688-ee31-4353-b000-582978590bd81A1005.png'
            }, {
              from: 'course - 视频课程库',
              title: '4、肩关节上举(肩关节活动度上举棍子训练)',
              // id: 29,
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21A/c02aa1c0-c1b5-4f73-bb43-c5646b5c9acc1A3001-肩关节活动度上举棍子训练-恢复期.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21A/d08e456a-7a6e-4730-b816-339f21be471b1A3001.png'
            }, {
              from: 'course - 视频课程库',
              title: '5、主动挤捏患侧训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21A/44d4bee6-5e3a-4595-bd48-4fe19d21d0c01A2009-主动挤捏患侧训练.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21A/99a388dd-b8d6-4636-912d-82e576f87ef61A2009.png'
            }, {
              from: 'course - 视频课程库',
              title: '6、上肢分离螺旋对角运动',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21A/84cf55f0-9327-41f3-93da-bffcb6dfedcd1A3002-上肢分离螺旋对角运动-恢复期.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21A/09add8a4-9a3b-4c59-8f11-30698d59d4be1A3002.png'
            }, {
              from: 'course - 视频课程库',
              title: '7、上肢摸耳外展运动',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21A/885b2474-7e2c-47dd-aacc-0fff273283b01A3003-偏瘫上肢摸耳外展训练-恢复期.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21A/4914c20b-c1dc-45e2-ba3e-b67ac467a9131A3003.png'
            }, {
              from: 'course - 视频课程库',
              title: '8、按摩法-缓解肩部肿胀（肩手综合症）',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21A/618fafd7-f1a2-4801-a4c2-5b79abfd81fc1A7011-按摩法-缓解肩部肿胀 [MP4 720p AVC].mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21A/8cfd5b70-2a5f-40e3-8721-1e1e0a0d3f611A7011.png'
            }, {
              from: 'course - 视频课程库',
              title: '9、bobath握手上肢划圈运动训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21A/604cbb83-db68-4682-a536-42510d3e2e601A2006-Bobath握手上肢划圈运动训练-软瘫期.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21A/91b5402e-117c-4e7a-ab2d-ed93d944d54a1A2006.png'
            }, {
              from: 'course - 视频课程库',
              title: '10、肩关节半脱位防治之健侧卧位良肢位摆放基础训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21A/801a1b15-e7a3-41fc-b0ec-318781fb59031A8003肩关节半脱位防治之健侧卧位良肢位摆放基础训练.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21A/2e0885da-65d0-4b83-807b-4688e2d6de583.png'
            }
          ]
        },
        {
          type: '软瘫期康复指导（二、肘关节）',
          notShowIndex: true,
          articles: [
            {
              from: 'course - 视频课程库',
              title: '11、促进上肢运动伸肘训练',
              // id: 29,
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21A/80085eef-1f4e-493a-84ae-77ea7d4a26751A2003-促进上肢主动伸肘运动.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21A/55d28626-0873-4f88-9b97-196f1d352f961A2003.png'
            }, {
              from: 'course - 视频课程库',
              title: '12、主动、被动肘关节屈曲训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/5C2003-主动、被动肘关节屈曲训练.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/5C2003-主动、被动肘关节屈曲训练.jpg'
            }, {
              from: 'course - 视频课程库',
              title: '13、肘关节伸直抗痉挛训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21D/79c8d4b5-ecf3-4d62-836c-65b26a4b0c961D1004-肘关节伸直抗痉挛训练~1.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21D/f7172b74-1575-4ba8-8a5a-03a745baf16c17.png'
            }, {
              from: 'course - 视频课程库',
              title: '14、肘腕指牵拉训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21D/97e5a2da-d879-4067-a9c2-0f2f9efa54271D1003-肘腕指牵拉训练~1.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21D/e1216344-69b8-483a-8082-6962c07fbf0e16.png'
            }

          ]
        },
        {
          type: '软瘫期康复指导（三、指和腕关节）',
          notShowIndex: true,
          articles: [
            {
              from: 'course - 视频课程库',
              title: '15、上肢屈肌痉挛整体牵拉训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21D/1aaae527-99f8-49ad-ae7a-9721d949d8de1D1007-上肢屈肌痉挛整体牵拉训练.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21D/69254fbf-0023-4027-a9d7-3aaf8c4d394c20.png'
            }, {
              from: 'course - 视频课程库',
              title: '16、辅助者协助手腕伸直训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21A/e9f1dde5-f952-48ef-831b-90cdbf3ef9401A6002-手功能训练-辅助者协助手腕伸直训练 [MP4 720p AVC].mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21A/907aabe5-83e8-43f6-ad48-f4e1f4a5fe0d1A6002.png'
            }, {
              from: 'course - 视频课程库',
              title: '17、促进伸腕运动训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21D/68d91e33-3fcf-4152-8da3-58082f9f5cec1D1001-促进伸腕运动训练-软瘫期.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21D/8ac0e287-512b-4343-8105-f2c36ea504d614.png'
            }, {
              from: 'course - 视频课程库',
              title: '18、辅助者协助手指伸展训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21A/8248dbae-bff0-4ce7-a081-0165609d35681A4013-手功能训练-辅助者协助手指伸展训练 [MP4 720p AVC].mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21A/03e7a102-04f3-4940-90d4-a88de1902a851A4013.png'
            }, {
              from: 'course - 视频课程库',
              title: '19、健侧手捏挤手指运动训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/1L/01CSE1WFM0TVWKQGT64C54KQ2J-1L4006-坐位下健手捏挤患手指运动训练.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/1L/01CSE1WFM0TVWKQGT64C54KQ2J-1L4006-坐位下健手捏挤患手指运动训练.jpg'
            }
          ]
        },
        {
          type: '软瘫期康复指导（四、下肢训练）',
          notShowIndex: true,
          articles: [
            {
              from: 'course - 视频课程库',
              title: '20、卧位下肢内收训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21B/d9147b2c-8792-4d0d-850e-35c781bdde711B3004-仰卧位下肢内收训练.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21B/81f2bf2c-ce9a-40f9-ab79-5682927fe92c3.png'
            }, {
              from: 'course - 视频课程库',
              title: '21、卧位下肢外展训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21B/12e47706-0d90-4fa3-bfdc-f39bef717ef91B3002-仰卧位下肢外展训练.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21B/1885ca9c-83a7-4f93-ac8d-c6c8ce5f6cbd1B3002.png'
            }, {
              from: 'course - 视频课程库',
              title: '22、卧位屈髋抬脚训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21B/f76c46b6-9222-46f0-9e47-f04fea28cc3e1B3007-卧位屈髋床边抬脚训练-恢复期早期.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21B/1fa18652-a169-4262-bbd4-7fa1233a86b66.png'
            }, {
              from: 'course - 视频课程库',
              title: '23、床边摆腿力量训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21B/68deb6a0-9c3c-4bbb-ad68-e957a07a79991B3008-床边摆腿力量训练.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21B/d70849c4-da48-4489-9aff-0e5c5e94fd2d1B3008.png'
            }, {
              from: 'course - 视频课程库',
              title: '24、卧位交替屈曲膝关节训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21B/4e9c31cc-5730-472d-9a17-a2a6c59ee2541B1009-卧位交替屈曲膝关节训练.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21B/5c4cf995-f181-4bed-93aa-c483ddca86701009.png'
            }, {
              from: 'course - 视频课程库',
              title: '25、膝关节稳定性训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21B/2490fa5a-e443-480f-9d13-5578ff398b461B1004-卧位膝关节稳定性2-伸直抬腿训练.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21B/73940052-02e9-46b4-bce9-d2c7f28546841B1004.png'
            }, {
              from: 'course - 视频课程库',
              title: '26、卧位下直腿抬高训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/2G1110-直腿抬高训练.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/2G1110-直腿抬高训练.jpg'
            }, {
              from: 'course - 视频课程库',
              title: '27、踝内翻跟腱痉挛牵拉训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21D/acb55551-cf7b-4bd8-8f00-c868702b1bf81D2007-踝内翻跟腱痉挛牵拉训练.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21D/7cc849a9-d277-4988-9dd1-2921550905c529.png'
            }, {
              from: 'course - 视频课程库',
              title: '28、辅助踝关节背屈训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/5D2001-辅助踝关节背曲训练.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/5D2001-辅助踝关节背曲训练.jpg'
            }, {
              from: 'course - 视频课程库',
              title: '29、踝泵运动',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/2C/01CSV8J2AVX24VCHCV4FFMWNF8-2C1026-踝泵运动.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/2C/01CSV8J2AVX24VCHCV4FFMWNF8-2C1026-踝泵运动.jpg'
            }, {
              from: 'course - 视频课程库',
              title: '30、踝关节旋转练习',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/2F1106-踝关节旋转练习.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/2F1106-踝关节旋转练习.jpg'
            }, {
              from: 'course - 视频课程库',
              title: '31、下肢屈髋屈膝持续牵拉训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21D/52b83b35-9007-484f-b068-d2d0ef0850d61D3002-下肢屈髋屈膝持续牵拉训练.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21D/efab0823-4fa6-490b-8003-5d337d81b5e932.png'
            }
          ]
        },
        {
          type: '软瘫期康复指导（五、躯干与呼吸）',
          notShowIndex: true,
          articles: [
            {
              from: 'course - 视频课程库',
              title: '32、卧位呼吸控制训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21C/b73d6130-2029-481b-843c-dc37317432e81C1008-卧位呼吸控制训练 [MP4 720p AVC].mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21C/35095ce4-8e97-49bb-a55c-f15cfb7676c71C1008.png'
            },
            {
              from: 'course - 视频课程库',
              title: '33、双桥训练',
              videoUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/21C/aa2d6b5d-96b8-42ce-97c2-1803fab88a471C1003-双桥训练.mp4',
              poster: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/image/21C/ffcb052b-318f-4c71-9b7d-db258f0ab4301C1003.png'
            }
          ]
        }
      ]
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('setScrollY', this.savedPosition)
    console.log('beforeRouteLeave', this.savedPosition)
    next()
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
    console.log('mounted this.$store.state.scrollY', this.$store.state.scrollY)
    this.$nextTick(() => {
      document.querySelector('#srcollcontainer').scrollTo(0, this.$store.state.scrollY)
    })
    // window.scrollTo(0, this.$store.state.scrollY)
    // document.body.scrollTo({
    //   top: this.$store.state.scrollY,
    //   behavior: 'smooth'
    // })
    this.hospital = this.$store.state.user.qrCodeHospital
    if (!this.hospital) {
      // this.$toast('请先扫码登录')
      // this.$router.push('/login')
    }
    console.log(this.hospital)
    this.hideLoading()
    this.reportFeatureAccess()

    var videoUrls = []
    this.contents.forEach(item => {
      var urls = []
      item.articles.forEach(article => {
        if (article.videoUrl) {
          urls.push({
            title: article.title,
            url: article.videoUrl
          })
        }
      })
      videoUrls.push({
        type: item.type,
        urls: urls
      })
    })
    console.log('videoUrls', videoUrls)
  },

  methods: {
    handleScroll (e) {
      const scrollY = e.target.scrollTop
      // var scrollY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop   //这几个我的也不生效不好使
      console.log(scrollY, '滚动条的距离')
      this.savedPosition = scrollY
    },
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    toDetail (content) {
      if (content.videoUrl) {
        console.log(content.videoUrl)
        // 带参数跳转：/BaseVideoPlayPage
        this.$router.push({
          path: '/BaseVideoPlayPage',
          query: {
            videoUrl: content.videoUrl
          }
        })
      } else {
        this.$router.push('/feeds/detail/' + content.id)
      }
    },
    reportFeatureAccess () {
      if (this.$store.state.route.from === '/KepuxuanjiaoMainPage') {
        ReportFeatureAccessDao.report(10, this.$store.state.user.qrCodeHospital, this.$store.state.user.openId).then(res => {
          console.log('上报访问成功', res)
        })
      }
    },
    handleCateClick (type) {
      // id=type的元素，滚动到顶部
      const element = document.getElementById(type)
      element.scrollIntoView({ behavior: 'smooth' })
    },
    toTop () {
      console.log('toTop')
      const element = document.getElementById('top')
      element.scrollIntoView({ behavior: 'smooth' })
    }
  },
  watch: {},
  computed: {
    cateFormattedItems () {
      const items = [...this.cateItems]
      // const remainder = items.length % 4
      // const placeholdersNeeded = remainder === 0 ? 0 : 4 - remainder
      //
      // // 添加占位元素
      // for (let i = 0; i < placeholdersNeeded; i++) {
      //   items.push({
      //     text: '',
      //     placeholder: true
      //   })
      // }

      return items
    },
    getItemStyle () {
      return {
        width: `${100 / this.cateItemsPerRow}%` // 根据每行元素数量动态设置宽度
      }
    }
  }
}
</script>
<style scoped>

.cate-container {
  margin-top: 8px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.item {
  box-sizing: border-box;
  text-align: center;
  padding-right: 16px;
  padding-bottom: 8px;
}

.item div {
  width: 100%;
  font-size: 14px;
  padding: 8px 4px;
  color: #000000;
  border-radius: 10px;
  background-color: #ffffff;
}

.item.placeholder {
  background-color: transparent;
}
</style>
