// constants.js
export const PatientUserGuideData = {
  // ID1: 第一个视频的详情
  1: {
    id: 1,
    title: '使用教程1-扫码网页版查看训练方案',
    videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/user_guide/patient/%E4%BD%BF%E7%94%A8%E6%95%99%E7%A8%8B2-%E6%82%A3%E8%80%85%E9%80%9A%E8%BF%87%E6%89%AB%E7%A0%81%E7%BD%91%E9%A1%B5%E7%89%88%E6%9F%A5%E7%9C%8B%E8%AE%AD%E7%BB%83%E6%96%B9%E6%A1%88.mp4',
    steps: []
  },
  2: {
    id: 2,
    title: '使用教程2-下载安卓手机APP进行查看训练方案',
    videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/user_guide/patient/%E4%BD%BF%E7%94%A8%E6%95%99%E7%A8%8B3-%E6%82%A3%E8%80%85%E4%B8%8B%E8%BD%BD%E5%AE%89%E5%8D%93%E6%89%8B%E6%9C%BAapp%E8%BF%9B%E8%A1%8C%E6%9F%A5%E7%9C%8B%E8%AE%AD%E7%BB%83%E6%96%B9%E6%A1%88.mp4',
    steps: []
  },
  3: {
    id: 3,
    title: '使用教程3-一对一在线指导-APP-如何在预定时间进行视频指导（一对一）',
    description: '',
    videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/user_guide/patient/%E6%8E%A5%E8%AF%8A-%E6%82%A3%E8%80%85%E7%AB%AF-%E4%B8%80%E5%AF%B9%E4%B8%80.mp4',
    steps: [{
      description: '',
      images: ['https://cdn.keihong.tech/img/202405181558331.png']
    }]
  },
  4: {
    id: 4,
    title: '使用教程4-MDT在线指导-APP-如何在预定时间进行视频指导（MDT）',
    description: '',
    videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/user_guide/patient/%E6%8E%A5%E8%AF%8A-%E6%82%A3%E8%80%85%E7%AB%AF-Mdt.mp4',
    steps: [{
      description: '',
      images: ['https://cdn.keihong.tech/img/202405181559093.png']
    }]
  },
  5: {
    id: 5,
    title: '使用教程5-MDT在线指导-网页版-如何在预定时间进行视频指导（MDT）',
    description: '',
    videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/user_guide/patient/%E6%93%8D%E4%BD%9C%E6%8C%87%E5%8D%97-%E5%9C%A8%E7%BA%BF%E6%8C%87%E5%AF%BC-%E7%BD%91%E9%A1%B5%E7%89%88mdt%281%29.mp4',
    steps: [{
      description: '',
      images: ['https://cdn.keihong.tech/img/202405182032197.png', 'https://cdn.keihong.tech/img/202405182035253.png']
    }, {
      description: '',
      images: ['https://cdn.keihong.tech/img/202405182033084.png']
    }, {
      description: '',
      images: ['https://cdn.keihong.tech/img/202405182034499.png']
    }]
  }

}
