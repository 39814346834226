import axios from 'axios'

const MdtTokenDao = {
  async getAgroaToken (appid, certificate, channelName, userAccount, uId) {
    const url = '/videoCall/token?appId=' + appid + '&appCertificate=' + certificate + '&channelName=' + channelName + '&userAccount=' + userAccount + '&uId=' + uId
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }
}
export default MdtTokenDao
