<template>
  <div class="container">
    <!--    <MyNavBar v-if="!isApp" title="创建缴费单" back @onBackClick="onBackClick" :back-text="''"/>-->
    <div class="header"
         style="text-align: center;font-size: 18px;height: 56px;line-height: 56px;background-color: #f1f1f1">
      创建缴费单
    </div>
    <div class="content" style="padding: 16px">
      <van-form @submit="onSubmit">
        <van-field v-model="form.name" class="input-field" label="姓名" name="name" placeholder="请输入姓名" required/>
        <van-field class="input-field" label="性别" required>
          <template #input>
            <van-radio-group v-model="form.gender">
              <van-radio name="1" style="margin-top: 8px">男</van-radio>
              <van-radio name="2" style="margin-top: 8px">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field v-model="form.age" class="input-field" label="年龄" name="age" placeholder="请输入年龄" required
                   type="number"/>
        <van-field v-model="form.phone" class="input-field" label="手机号" name="phone" placeholder="请输入手机号"
                   required
                   type="tel"/>
        <van-field v-model="form.disease" class="input-field" label="病因" name="disease" placeholder="请输入病因"
                   required/>
        <van-field v-model="form.department" class="input-field" label="住院科室" name="department"
                   placeholder="请输入住院科室"
        />
        <van-field v-model="form.bedNumber" class="input-field" label="病床号" name="bedNumber"
                   placeholder="请输入病床号"
        />

        <!--        <van-radio-group v-model="form.serviceType" required>-->
        <!--          <van-radio name="serviceType" label="远程康复服务" value="rehabilitation"/>-->
        <!--          <van-radio name="serviceType" label="中药服务" value="medicine"/>-->
        <!--        </van-radio-group>-->
        <!-- 单选按钮组合，放在 van-field 内的 slot 中 -->
        <div class="input-field">
          <van-field label="服务类型" required>
            <template #input>
              <van-radio-group v-model="form.serviceType" @change="serviceTypeChange">
                <van-radio name="1" style="margin-top: 8px">远程康复服务</van-radio>
                <!--                <van-radio name="2" style="margin-top: 8px">远程康复半年服务包</van-radio>-->
                <!--                <van-radio name="4" style="margin-top: 8px">中风康复远程康复季度服务包</van-radio>-->
                <van-radio name="5" style="margin-top: 8px">中风康复远程康复半年服务包</van-radio>
                <van-radio name="3" style="margin-top: 8px">远程康复季度服务包</van-radio>
                <van-radio name="6" style="margin-top: 8px">租赁服务1： 2通道TENS+EMS低频治疗仪</van-radio>
                <van-radio name="7" style="margin-top: 8px">租赁服务2： 4通道FES低频治疗仪(3个月起)</van-radio>
                <van-radio name="8" style="margin-top: 8px">租赁服务3： 4通道FES低频治疗仪（1个月起）</van-radio>
                <!--                <van-radio name="2" style="margin-top: 8px">上门评估与康复服务</van-radio>-->
                <!--                <van-radio name="3" style="margin-top: 8px">中药服务</van-radio>-->
              </van-radio-group>
            </template>
          </van-field>
          <div v-if="form.serviceType==='1'">
            <van-field label="服务周数" required>
              <template #input>
                <div style="width: 100%">
                  <van-stepper v-model="form.weeks" class="right" integer min="1"/>
                  <van-cell :value="form.weeks" title="服务周数">
                    <!--修改单位为周-->
                    <template #right-icon>
                      <span style="font-size: 12px">周</span>
                    </template>
                  </van-cell>
                  <van-cell :value="form.price" title="服务单价">
                    <!--修改单位为元-->
                    <template #right-icon>
                      <span style="font-size: 12px">元/周</span>
                    </template>
                  </van-cell>
                  <van-cell :value="form.weeks * form.price" title="小计">
                    <!--修改单位为元-->
                    <template #right-icon>
                      <span style="font-size: 12px">元</span>
                    </template>
                  </van-cell>
                  <van-cell title="自费部分" value="50%"/>
                  <van-cell :value="form.weeks * form.price * 0.5" title="实际支付">
                    <!--修改单位为元-->
                    <template #right-icon>
                      <span style="font-size: 12px">元</span>
                    </template>
                  </van-cell>
                </div>
              </template>
            </van-field>
          </div>
          <service-type-half-year v-if="form.serviceType==='2'" @update-fee="updateFee"></service-type-half-year>
          <service-type-guke-shuhou3month v-if="form.serviceType==='3'"
                                          @update-fee="updateFee"></service-type-guke-shuhou3month>
          <service-type-zhongfeng3month v-if="form.serviceType==='4'"
                                        @update-fee="updateFee"></service-type-zhongfeng3month>
          <service-type-zhongfeng6month v-if="form.serviceType==='5'"
                                        @update-fee="updateFee"></service-type-zhongfeng6month>
          <service-type-rent-tens-ems-device v-if="form.serviceType==='6'"
                                             @update-fee="updateFeeAndCount"></service-type-rent-tens-ems-device>
          <service-type-rent-fes-low-device v-if="form.serviceType==='7'"
                                            @update-fee="updateFeeAndCount"></service-type-rent-fes-low-device>
          <service-type-rent-fes-low-device-1-month v-if="form.serviceType==='8'"
                                                    @update-fee="updateFeeAndCount"></service-type-rent-fes-low-device-1-month>
          <!--          <div v-if="form.serviceType!=='-1'&&['2','3'].includes(form.serviceType)"-->
          <!--               style="text-align: center;color: #c20000">-->
          <!--            暂未上线-->
          <!--          </div>-->

        </div>
        <!--    备注，一个长文本输入框    -->
        <van-field v-model="form.mark" class="input-field" label="其他" name="doses" placeholder="请输入其他信息"
                   rows="2" type="textarea"/>
        <div class="input-field">
          <van-field>
            <template #label>
              <div style="display: block">居家康复训练时，可采用以下相关辅具进行预防/改善</div>
            </template>
            <template #input>
              <van-checkbox-group v-model="form.tools" shape="square" style="display: block">
                <div class="checkbox-group">
                  <van-checkbox v-for="(tool, index) in tools" :key="index" :name="tool.name"
                                class="checkbox-item" style="margin-top: 8px;">
                    {{
                      tool.label
                    }}
                  </van-checkbox>
                </div>
              </van-checkbox-group>
            </template>
          </van-field>
          <!--     手动输入其他辅具     -->
          <van-field v-model="form.moreTools" label="其他辅具" name="moreTools" placeholder="请输入其他辅具"
                     rows="2"
                     type="textarea"/>
        </div>
        <van-button block native-type="submit"
                    style="margin-top: 16px;background-color: #4CAF50; border-radius: 20px; border-color: transparent"
                    type="submit">
          提交
        </van-button>
      </van-form>
    </div>
  </div>
</template>
<script>

import AidsDao from '@/h5/doucan/network/AidsDao'
import ServiceTypeHalfYear from '@/h5/doucan/pages/doctor_patient_payment_bill/ServiceTypeHalfYear.vue'
import ServiceTypeGukeShuhou3month from '@/h5/doucan/pages/doctor_patient_payment_bill/ServiceTypeGukeShuhou3month.vue'
import ServiceType3month from '@/h5/doucan/pages/doctor_patient_payment_bill/ServiceTypezhongfeng3month.vue'
import ServiceTypeZhongfeng3month from '@/h5/doucan/pages/doctor_patient_payment_bill/ServiceTypezhongfeng3month.vue'
import ServiceTypeZhongfeng6month from '@/h5/doucan/pages/doctor_patient_payment_bill/ServiceTypezhongfeng6month.vue'
import ServiceTypeRentTensEmsDevice
from '@/h5/doucan/pages/doctor_patient_payment_bill/ServiceTypeRentTensEmsDevice.vue'
import ServiceTypeRentFesLowDevice from '@/h5/doucan/pages/doctor_patient_payment_bill/ServiceTypeRentFesLowDevice.vue'
import ServiceTypeRentFesLowDevice1Month
from '@/h5/doucan/pages/doctor_patient_payment_bill/ServiceTypeRentFesLowDevice1Month.vue'

export default {
  name: 'DoctorPatientPaymentBillCreate',
  components: {
    ServiceTypeRentFesLowDevice1Month,
    ServiceTypeRentFesLowDevice,
    ServiceTypeRentTensEmsDevice,
    ServiceTypeZhongfeng6month,
    ServiceTypeZhongfeng3month,
    ServiceType3month,
    ServiceTypeGukeShuhou3month,
    ServiceTypeHalfYear
  },
  data () {
    return {
      isApp: false,
      patientId: '',
      doctorId: '',
      form: {
        name: '',
        gender: '',
        age: '',
        phone: '',
        department: '',
        bedNumber: '',
        disease: '',
        serviceType: '-1',
        weeks: '',
        price: '',
        doses: '',
        mark: '',
        tools: [],
        moreTools: ''
      },
      tools: []
    }
  },
  mounted () {
    this.isApp = this.$route.query.isApp
    if (this.isApp) {
      // toast 当前环境是app
      this.$toast('当前环境是app')
      this.$nextTick(() => {
        this.bridge()
      })
    } else {
      this.patientId = this.$route.query.patientId
      this.doctorId = this.$route.query.doctorId
    }
    this.getAids()
  },
  methods: {
    onBackClick () {
      // this.$router.replace('/home/square?hospital=暨南大学附属顺德医院')
    },
    updateFee (fee) {
      this.form.price = fee
      console.log('费用：', fee)
    },
    updateFeeAndCount (fee, count) {
      this.form.price = fee
      this.form.weeks = count
      console.log('数量：', count)
      console.log('费用：', fee)
    },
    async bridge () {
      if (window.android && window.android.onBridgeSuccess) {
        var fromAndroid = await window.android.onBridgeSuccess('桥接成功 from 内蒙患者录入页')
        console.log('fromAndroid', fromAndroid)
        const params = JSON.parse(fromAndroid)
        this.doctorId = params.doctorId
        this.patientId = params.patientId
        this.form.name = params.patientName
        this.form.phone = params.patientPhone
        this.form.age = params.patientAge
        this.form.gender = params.patientGender
      } else {
        alert(typeof (window.android))
        // this.title = '桥接失败'
      }
    },
    getAids () {
      AidsDao.list().then(res => {
        console.log('getAids:', res)
        this.tools = res.map(item => ({
          label: item.name,
          name: item.id.toString()
        }))
        console.log('tools:', this.tools)
      })
    },
    onSubmit (values) {
      console.log('Submit:', values)
      console.log('form:', this.form)

      // 判空
      if (!this.form.name) {
        alert('姓名不能为空')
        return
      }
      if (!this.form.gender) {
        alert('性别不能为空')
        return
      }
      if (!this.form.age) {
        alert('年龄不能为空')
        return
      }
      if (!this.form.phone) {
        alert('手机号不能为空')
        return
      }
      // if (!this.form.department) {
      //   alert('住院科室不能为空')
      //   return
      // }
      // if (!this.form.bedNumber) {
      //   alert('病床号不能为空')
      //   return
      // }
      if (!this.form.disease) {
        alert('病因不能为空')
        return
      }
      if (!this.form.serviceType || this.form.serviceType === '-1') {
        alert('服务类型不能为空')
        return
      }
      if (this.form.serviceType === '1' && !this.form.weeks) {
        alert('服务周数不能为空')
        return
      }

      // 创建一个新的对象来存储转换后的数据

      var totalAmount = 0
      var serviceCount = 0
      var serviceDiscount = 0

      if (this.form.serviceType === '1') {
        totalAmount = this.form.weeks * this.form.price * 0.5
        serviceCount = this.form.weeks
        serviceDiscount = 0.5
      }

      if (this.form.serviceType === '2') {
        totalAmount = this.form.price
        serviceCount = 1
        serviceDiscount = 1
      }

      if (['3', '4', '5', '6'].includes(this.form.serviceType)) {
        totalAmount = this.form.price
        serviceCount = 1
        serviceDiscount = 1
      }

      if (['6', '7', '8'].includes(this.form.serviceType)) {
        totalAmount = this.form.price
        serviceCount = this.form.weeks
        serviceDiscount = 1
      }

      const req = {
        patientId: this.patientId, // 这个值需要从其他地方获取
        doctorId: this.doctorId, // 这个值需要从其他地方获取
        totalAmount: totalAmount,
        patientName: this.form.name,
        gender: this.form.gender,
        age: this.form.age,
        patientPhone: this.form.phone,
        department: this.form.department,
        bedNumber: this.form.bedNumber,
        diagnosis: this.form.disease,
        toolsIdList: this.form.tools,
        moreTools: this.form.moreTools,
        serviceTypeId: this.form.serviceType,
        serviceCount: serviceCount,
        servicePrice: this.form.price,
        serviceDiscount: serviceDiscount, // 这个值是固定的，如果有变化需要更新
        remark: this.form.mark
      }

      this.$http.post('doctorPatientPaymentBill/bill', req).then(res => {
        console.log('res:', res)
        res = res.data
        if (res.code === 200) {
          // this.$router.replace('/doctor/patient/payment/bill/list')
          // dialog  : 提交成功，请通知患者或康复助理进行后续指引
          this.$dialog.alert({
            title: '提交成功',
            message: '请通知患者或康复助理进行后续指引'
          }).then(() => {
            // on close
            this.$router.push('/DoctorPatientPaymentBillListForPatient?isApp=true&patientId=' + this.patientId)
          })
        } else {
          alert(res.message)
        }
      })

      console.log('req:', req)
    },
    serviceTypeChange () {
      console.log('serviceTypeChange:', this.form.serviceType)
      // 服务类型与价格备注：
      // 1. 远程康复服务：400元/周
      // 2. 上门评估与康复服务：暂未上线
      // 3. 中药服务：暂未上线

      if (this.form.serviceType === '1') {
        this.form.price = 200
      }
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>
.input-field {
  margin-top: 16px;
  border: 1px solid #ccc;
}

.right {
  float: right;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Use the full viewport height */
}

.header {
  height: 56px;
  /* Add your header styles here */
}

.content {
  flex-grow: 1; /* Take up the remaining space */
  overflow-y: auto; /* Enable vertical scrolling */
  /* Add your content styles here */
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-item {
  margin-right: 10px;
  font-size: 12px;
}
</style>
