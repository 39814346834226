<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/PatientList.vue -->
<template>
  <div>
    <MyNavBar :title="isPatientList?'患者列表':'第一步：选择患者'"/>
    <div>
      <van-search
        v-model="searchValue"
        placeholder="请输入姓名或手机号进行查询"
        @search="onSearch"
      />
    </div>
    <div>
      <div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-if="list.length > 0"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="loadMore"
          >
            <div v-for="(item, index) in list" :key="index" style="width: 100%" @click="handlePatientClick(item)">
              <div>
                <van-cell title="用户信息">
                  <template #default>
                    <div>
                      <span>{{ item.realname }}</span>
                      <span> - </span>
                      {{ item.phone }}

                    </div>
                  </template>
                </van-cell>

                <van-cell title="注册信息">
                  <template #default>
                    <div>
                      <div class="date">{{ DateUtils.formattedTime(item.createDate) }}</div>
                      <div>
                        <van-tag type="primary">
                          {{ item.registerHospital === '非APP渠道' ? item.hospital : item.registerHospital }}
                        </van-tag>
                      </div>
                      <div>
                        <van-tag type="primary">
                          {{ item.registerDoctorName === '非APP渠道' ? item.inviterName : item.registerDoctorName }}
                        </van-tag>
                      </div>

                    </div>
                  </template>
                </van-cell>
                <van-cell is-link title="操作"/>
                <div v-if="index !== list.length-1" style="height: 16px;background-color: #f5f5f5"></div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect"/>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import AdminDao from '@/h5/doucan/network/AdminDao'
import moment from 'moment/moment'
import DateUtils from '../../../../utils/DateUtils'
import NumberUtil from '@/utils/NumberUtil'

export default {
  name: 'PatientList',
  components: { MyNavBar },
  data () {
    return {
      isPatientList: false,
      actionPatient: null,
      actions: [
        { name: '新增预约' },
        { name: '免费下单远程康复服务' },
        { name: '删除患者' },
        { name: '更多功能请联系技术人员添加' }],
      show: false,
      searchValue: '',
      list: [], // 数据列表
      pageNo: 1, // 当前页码
      pageSize: 10,
      totalPages: 0, // 总页数
      refreshing: false, // 下拉刷新状态,
      total: 0,
      loading: false,
      finished: false,
      canLoadMore: true
    }
  },
  async mounted () {
    // 获取路由参数 patientlist=true
    const params = this.$route.query
    if (params?.patientlist) {
      this.isPatientList = true
    }

    this.loadData() // 初始化加载数据
  },
  methods: {
    onSelect (action) {
      console.log('action', action)

      if (action.name === '删除患者') {
        // 弹窗对话框确认
        this.$dialog.confirm({
          title: '注意！删除患者不可恢复',
          message: `确定要删除该患者吗？\n【${this.actionPatient.realname}-${this.actionPatient.phone}】`
        }).then(() => {
          AdminDao.deletePatient(this.actionPatient.phone).then(res => {
            if (res != null) {
              this.$toast('删除成功')
              this.onRefresh()
            }
          })
        })

        return
      }
      if (action.name === '新增预约') {
        this.handleBookingClick(this.actionPatient)
        return
      }
      if (action.name === '免费下单远程康复服务') {
        this.$router.push({
          path: '/payserve1',
          query: {
            patientId: this.actionPatient.userId,
            patientName: this.actionPatient.realname,
            doctorId: this.actionPatient.registerDoctorUserId,
            doctorName: this.actionPatient.registerDoctorName,
            hospital: this.actionPatient.registerHospital,
            hospitalId: this.actionPatient.hospitalCode,
            patientMobile: this.actionPatient.phone,
            isAdmin: true
          }
        })
      }
    },
    handlePatientClick (patient) {
      this.actionPatient = patient
      console.log('点击了患者', this.actionPatient)
      this.show = true
    },
    // 处理预约服务点击
    handleBookingClick (item) {
      console.log('handleBookingClick', item)
      // 跳转到SelectDoctorPage
      this.$router.push({
        path: '/selectdoctor',
        query: {
          patientId: item.userId,
          patientName: item.realname
        }
      })
      // this.$router.push({
      //   path: '/admin/booking',
      //   query: {
      //     patientId: item.id
      //   }
      // })
    },
    async onSearch () {
      console.log(this.searchValue)
      this.$toast(this.searchValue)
      if (!this.searchValue) {
        this.canLoadMore = true
        this.onRefresh()
        return
      }
      this.canLoadMore = false
      if (NumberUtil.isNumeric(this.searchValue)) {
        this.$toast('查询手机号')
        const patientPageByPhone = await AdminDao.patientByPhone(this.searchValue)
        this.list = patientPageByPhone.content
        this.totalPages = patientPageByPhone.totalPages
        this.total = patientPageByPhone.totalElements
        this.refreshing = false
      } else {
        this.$toast('查询姓名')
        const patientPageByPhone = await AdminDao.patientByName(this.searchValue)
        this.list = patientPageByPhone.content
        this.totalPages = patientPageByPhone.totalPages
        this.total = patientPageByPhone.totalElements
        this.refreshing = false
      }
    },
    async loadData () {
      this.finished = false
      const datas = await AdminDao.patientList(this.pageNo, this.pageSize)
      console.log('网页订单列表', datas)
      this.list = datas.content
      this.totalPages = datas.totalPages
      this.total = datas.totalElements
      this.refreshing = false
    },
    onRefresh () {
      console.log('刷新')
      this.pageNo = 1 // 重置页码
      this.loadData() // 刷新数据
    },
    loadMore () {
      if (!this.canLoadMore) {
        this.finished = true
        return
      }
      if (this.pageNo <= this.totalPages - 1) {
        this.pageNo++ // 增加页码
        AdminDao.patientList(this.pageNo, this.pageSize) // 假设接口地址为/api/data
          .then(datas => {
            console.log('loadMore', datas)
            this.totalPages = datas.totalPages
            this.total = datas.totalElements
            this.list = this.list.concat(datas.content) // 追加数据
            // 加载状态结束
            this.loading = false
            if (this.list.length >= this.total) {
              this.finished = true
            }
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    formattedTime (timestamp) {
      return moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  watch: {
    refreshing (newVal) {
      if (!newVal) {
        this.$nextTick(() => {
          this.loadMore() // 加载更多数据
        })
      }
    }
  },
  computed: {
    DateUtils () {
      return DateUtils
    }
  }
}
</script>
<style scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap;
  margin: 16px;
}

.flex-child {
  background-color: lightblue;
  text-align: center;
  padding: 16px;
  flex: 1;
  text-decoration: underline
}

.action-item {
  border-bottom: 1px solid #ebebeb;
}

.van-action-sheet__item {
  border-bottom: 1px solid #ebebeb;
  /*border-bottom: none;  最后一个 item 不需要分割线 */
}
</style>
