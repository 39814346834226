<template>
  <div style="width: 100%">
    <my-nav-bar
      :title="'家庭病床康复-服务预约'"
      back
      @onBackClick="onBackClick"
    />
    <div style="text-align: center">
      当前活动：{{ hospital ? hospital : '广州科普活动' }}
    </div>
    <div class="warm-tip">
      <p style="color: white; font-weight: bolder">温馨提示</p>
      <p class="tip-text">
        如果您不符合全额资助条件，但也想参加活动，则需要自费支付50%费用，基金会会为您补贴支付另外50%费用，
        可以点击选择资助类型和服务时长，每人仅限1次资助申请。
      </p>
    </div>
    <img
      style="max-width: 100%; height: auto"
      src="https://cdn.keihong.tech/typora/2023-06-17-%E7%BB%84%20151%20-1-.png"
      alt=""
    />
    <div style="padding-left: 16px; padding-right: 16px">
      <img
        style="max-width: 100%; height: auto"
        src="https://cdn.keihong.tech/typora/2023-06-17-%E7%BB%84%20152%20-1-.png"
        alt=""
      />
    </div>
    <div class="container">
      <div class="radio-group">
        <van-checkbox
          ref="typeCheck1"
          v-model="checked1"
          shape="circle"
          icon-size="30px"
          checked-icon="circle"
          unchecked-icon="circle"
          @click="serveTypeChange($refs.typeCheck1)"
        ></van-checkbox>
      </div>
      <div class="image-container" @click="serveTypeChange($refs.typeCheck1)">
        <img src="https://cdn.keihong.tech/img/202307051916685.png" alt="" />
      </div>
    </div>
    <div style="height: 16px"></div>
    <div class="container">
      <div class="radio-group">
        <van-checkbox
          ref="typeCheck2"
          @click="serveTypeChange($refs.typeCheck2)"
          v-model="checked2"
          shape="circle"
          icon-size="30px"
          checked-icon="circle"
          unchecked-icon="circle"
        ></van-checkbox>
      </div>
      <div class="image-container" @click="serveTypeChange($refs.typeCheck2)">
        <img src="https://cdn.keihong.tech/img/202307051918187.png" alt="" />
      </div>
    </div>
    <div style="padding-left: 16px; padding-right: 16px; margin-top: 16px">
      <img
        style="max-width: 100%; height: auto"
        src="https://cdn.keihong.tech/typora/2023-06-17-%E7%BB%84%20118%20-1-.png"
        alt=""
      />
    </div>
    <div id="forminfo">
      <van-form ref="form" label-position="top" colon>
        <van-field
          v-model="name"
          label="姓名"
          placeholder="请输入报名表的姓名"
        />
        <van-field
          v-model="phoneNumber"
          label="手机号"
          placeholder="请输入报名表的手机号"
        />
        <!--        <van-field v-model="contactName" label="医生/工作人员" placeholder="请输入名字(选填)"/>-->
      </van-form>
    </div>
    <div id="agreement" style="padding: 16px">
      <label>
        <input type="checkbox" v-model="agreed" @change="toggleAgreed" />
        我已阅读并同意
      </label>
      <span class="clickable" @click="showAgreement">《服务协议告知书》</span>
      <van-popup v-model="showDetail" position="bottom">
        <div
          style="margin-bottom: 56px; padding: 16px"
          v-html="agreement.htmlContent"
        ></div>
        <van-button
          @click="closeDetail"
          style="position: fixed; bottom: 0; width: 100%; padding-bottom: 16px"
          type="primary"
          >我已阅读并同意</van-button
        >
      </van-popup>
    </div>
    <div class="reminder">
      <p style="font-weight: bold">温馨提示</p>
      <p>{{ reminderText }}</p>
    </div>
    <div
      v-if="checked1 || checked2"
      style="
        display: flex;
        height: 48px;
        position: fixed;
        bottom: 0;
        width: 100vw;
      "
    >
      <div
        style="
          flex-grow: 1;
          background-color: #ff7845;
          font-size: 20px;
          font-weight: bold;
          line-height: 48px;
          text-align: center;
          color: white;
        "
      >
        需支付：{{ fee }}元
      </div>
      <van-button
        style="width: 40vw; height: 48px; margin: 0"
        type="primary"
        @click="submitForm"
        >提交支付</van-button
      >
    </div>
  </div>
</template>

<script>
import MyNavBar from '../../../components/MyNavBar.vue'
import axios from 'axios'
import { Dialog, Toast, Notify } from 'vant'
import WxPayDao from '../../../network/WxPayDao'
import wx from 'weixin-jsapi'

export default {
  name: 'ServeSale202301NotFree',
  components: {
    MyNavBar
  },
  data () {
    return {
      hospital: '',
      reminderText:
        '支付成功后，康复助理会联系您开展服务，请添加助理微信留意来电18520535303',
      checked1: false,
      checked2: false,
      name: '',
      phoneNumber: '',
      contactName: '',
      fee: 0,
      appsecret: '',
      appid: '',
      code: '',
      openid: '',
      paySignInfo: null,
      testMobile: ['18008000808', '13610164743', '18520535303', '18501011010'],
      outTradeNo: '', // 动优支付系统里面的订单号 ordernum
      agreed: false,
      showDetail: false,
      agreement: {
        htmlContent: '加载中...'
      },
      isWeChatBrowser: false
    }
  },
  async mounted () {
    const hospital = this.$route.query.hospital
    if (hospital) {
      this.hospital = hospital
    }
    this.checkWeChatBrowser()
    if (this.isWeChatBrowser) {
      await this.getAppid()
      const openid = this.$store.state.user.openId
      if (openid) {
        this.openid = openid
      } else {
        await this.getMpOpenID()
      }
    }
    this.loadHtmlFile()

    const profile = this.$store.state.apply20230001.profile
    this.phoneNumber = profile.patientMobile
    this.name = this.contactName = profile.patientName
  },
  methods: {
    onBackClick () {
      let backRoute = ''
      if (this.hospital) {
        if (this.hospital === '东莞康华医院') {
          backRoute = '/salepage/SalePageDongguangkanghua01'
        }
        if (this.hospital === '暨南大学附属顺德医院') {
          backRoute = '/salepage/20230001'
        }
      } else {
        backRoute = '/ServeSale202301'
      }
      this.$router.replace(backRoute)
    },
    checkWeChatBrowser () {
      const userAgent = navigator.userAgent.toLowerCase()
      this.isWeChatBrowser = /micromessenger/.test(userAgent)
    },
    async loadHtmlFile () {
      try {
        const response = await fetch(
          'https://cdn.keihong.tech/img/202306291605944.html'
        )
        this.agreement.htmlContent = await response.text()
      } catch (error) {
        console.error('Failed to load HTML file:', error)
      }
    },
    toggleAgreed () {
      if (this.agreed) {
        console.log('已同意服务协议')
      } else {
        console.log('未同意服务协议')
      }
    },
    showAgreement () {
      this.showDetail = true
    },
    closeDetail () {
      this.showDetail = false
      this.agreed = true
    },
    serveTypeChange (typeCheck) {
      if (typeCheck === this.$refs.typeCheck1) {
        this.checked1 = true
        this.checked2 = false
        this.fee = 1800
        return
      }

      if (typeCheck === this.$refs.typeCheck2) {
        this.checked1 = false
        this.checked2 = true
        this.fee = 3600
      }
    },
    async submitForm () {
      console.log(this.name)
      console.log(this.phoneNumber)
      if (!this.name || !this.phoneNumber) {
        Notify({ type: 'danger', message: '请填写姓名和手机号' })
        document.getElementById('forminfo').scrollIntoView()
        return
      }
      if (!this.agreed) {
        Notify({
          type: 'danger',
          message: '请勾选“我已阅读并同意服务协议告知书”'
        })
        document.getElementById('agreement').scrollIntoView()
        return
      }
      // this.$refs.form.validate((valid) => {
      //   if (valid) {
      //     // form is valid, submit data to server or perform other actions
      //     console.log('Form submitted successfully')
      //     console.log(this.name)
      //     console.log(this.phoneNumber)
      //   } else {
      //     // form is invalid, show error messages
      //     console.log('Form validation failed')
      //   }
      // })

      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      if (this.testMobile.includes(this.phoneNumber)) {
        this.fee = 0.01
      }
      var fromActivity = ''
      var goods = ''

      fromActivity = '广州科普活动2023001-中风家庭康复科普公益活动'
      goods = this.checked1 ? '自费50%-季度(12周)' : '自费50%-半年(24周)'

      const profile = {
        serveType: 0,
        mobile: this.phoneNumber, // 后期mobile从用户授权获得，即订单记录支付者登录的微信账号手机号及实际接受服务患者的手机号
        patientName: this.name,
        patientMobile: this.phoneNumber,
        orderType: '',
        fee: this.fee,
        fromActivity: fromActivity,
        goods: goods,
        openId: this.openid
      }
      console.log('提交订单', profile)
      const data = await WxPayDao.createOrder(profile)
      console.log('支付qrcode', data)
      if (data.code === 1) {
        this.paySignInfo = data.data
        this.outTradeNo = data.data.outTradeNo
        // 调起微信支付
        wx.config({
          // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false
          debug: false,
          // 必填，公众号的唯一标识
          appId: this.paySignInfo.sign.appId,
          // 必填，生成签名的时间戳
          timestamp: this.paySignInfo.sign.timeStamp,
          // 必填，生成签名的随机串
          nonceStr: this.paySignInfo.sign.nonceStr,
          // 必填，签名
          signature: this.paySignInfo.sign.paySign,
          // 必填，需要使用的JS接口列表
          jsApiList: ['chooseWXPay']
        })
        wx.ready(() => {
          wx.checkJsApi({
            jsApiList: ['chooseWXPay'],
            success: function (res) {
              console.log('seccess')
              console.log('hskdjskjk', res)
            },
            fail: function (res) {
              console.log('fail')
              console.log(res)
            }
          })
          const _that = this
          wx.chooseWXPay({
            // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            timestamp: this.paySignInfo.sign.timeStamp,
            // 支付签名随机串，不长于 32 位
            nonceStr: this.paySignInfo.sign.nonceStr,
            // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            package: this.paySignInfo.sign.packageValue,
            // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            signType: this.paySignInfo.sign.signType,
            // 支付签名
            paySign: this.paySignInfo.sign.paySign,
            // 支付成功后的回调函数
            success: function (res) {
              if (res.errMsg === 'chooseWXPay:ok') {
                console.log('支付成功', res)
                _that.payStatus = true
                Dialog.alert({
                  message: '支付成功'
                }).then(() => {
                  // on close
                  // todo 跳转到支付成功页面，显示支付凭证和提示
                  _that.$router.push(
                    '/salepage/20230001/result?outTradeNo=' + _that.outTradeNo
                  )
                })
              } else {
                Dialog({ message: JSON.stringify(res) })
                _that.payStatus = false
              }
            },
            fail: function (res) {
              Dialog({ message: '支付失败:' + JSON.stringify(res) })
              _that.payStatus = false
            }
          })
        })
        wx.error((err) => {
          alert(err)
        })
      } else {
        console.log('支付失败', data)
      }
    },
    async getOpenId (code) {
      // this.info = '正在获取getOpenId'
      const url = '/quick/pay/openid?code=' + code

      axios
        .get(url)
        .then((res) => {
          // this.info = JSON.stringify(res)
          this.openid = res.data.data.openid
          console.log('openid = ', res.data.data.openid)
          if (!this.openid) {
            this.getCodeApi()
            return
          }
          this.$store.commit('user/setOpenId', this.openid)
          // const profile = this.$store.state.apply20230001.profile
          // profile.openId = this.openid
          // this.$store.commit('apply20230001/update', profile)
        })
        .catch((err) => {
          console.log('err', err)
          this.getCodeApi()
        })
    },
    getCodeApi () {
      const appid = 'wxcb56667c309e369b'
      const urlNow = encodeURIComponent(window.location.href)
      const scope = 'snsapi_base' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      // const scope = 'snsapi_userinfo' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      const url =
        'https://open.weixin.qq.com' +
        '/connect/oauth2/authorize?appid=' +
        appid +
        '&redirect_uri=' +
        urlNow +
        '&response_type=code&scope=' +
        scope +
        '#wechat_redirect'
      window.location.href = url
    },
    getUrlKey (name) {
      // 获取url 参数
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [undefined, ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    },
    async getAppid () {
      const url = '/quick/pay/appid'
      const res = await axios.get(url)
      console.log('appid', res)
      this.appid = res.data.data.codeName
      this.appsecret = res.data.data.codeValue
    },
    async getMpOpenID () {
      const code = this.getUrlKey('code')
      if (code) {
        this.code = code
        await this.getOpenId(code)
      } else {
        this.getCodeApi()
      }
    }
  }
}
</script>

<style scoped>
.reminder {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  padding-bottom: 60px;
}

.container {
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 8px;
}

.radio-group {
  /*margin-right: 16px;*/
  width: 80px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.image-container {
  flex-grow: 1;
  text-align: center;
}

.image-container img {
  max-width: 100%;
  height: auto;
}

.clickable {
  color: green;
  text-decoration: underline;
  cursor: pointer;
}

.warm-tip {
  background-color: green;
  padding: 20px;
}

.tip-text {
  margin-top: 15px;
  font-size: 14px;
  color: #ffffff;
  line-height: 22px;
}
</style>
