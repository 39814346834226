<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <!--  标题栏:管理员主页  -->
    <div
      style="height: 48px;line-height: 48px;text-align: center;font-size: 14px;font-weight: bold;background-color: #0d3d3d;color: white">
      管理员主页-{{ hospitalId }}
    </div>
    <FlowButton v-show="isSysAdmin" img-src="https://cdn.keihong.tech/img/202309231208070.png" @click="navBack"/>
    <!--  loading  -->
    <div v-if="loading" style="text-align: center;margin-top: 16px">
      <van-loading/>
    </div>
    <div v-else>

      <!--  输入密码  -->
      <div v-show="!login" style="padding: 16px">
        <van-field
          v-model="passwd"
          label="密码"
          placeholder="请输入密码"
          type="password"
        />
        <van-button
          style="margin-top: 16px;width: 100%"
          type="primary"
          @click="handleLogin"
        >
          确定
        </van-button>
      </div>
      <div v-show="login" style="padding: 16px">
        <div class="button-group">
          <div
            v-for="(button, index) in buttons"
            :key="index"
            :style="{'background-color':button.name==='其他'?'#0000':'aliceblue','color':button.name==='其他'?'#0000':'#000'}"
            :type="button.type"
            style="display: flex;
          text-align: center;
          width: 25vw;
          min-height: 100px;
          border-radius: 8px;
          margin-bottom: 16px;
        "
            @click="handleClick(button.name,button.url)"
          >
            <div style="width: 100%; display: flex; justify-content: center; align-items: center;  padding: 8px;">
              {{ button.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import AdminHospitalDao from '@/h5/doucan/network/AdminHospitalDao'
import FlowButton from '@/h5/doucan/components/FlowButton.vue'

export default {
  name: 'AdminHospital',
  components: { FlowButton },
  data () {
    return {
      isSysAdmin: false,
      loading: true,
      hospitalId: '',
      passwd: '',
      login: false,
      token: '',
      adminData: null,
      buttons: [
        {
          name: '医生列表',
          url: '/AdminHospitalDoctorList',
          type: 'success'
        },
        {
          name: '已注册患者列表',
          url: '/AdminHospitalPatientList',
          type: 'primary'
        }, {
          name: '录入患者列表',
          url: '/AdminHospitalPatientInputList',
          type: 'primary'
        },
        {
          name: '远程指导记录',
          url: '/AdminHospitalRemoteGuideList',
          type: 'primary'
        },
        {
          name: '训练方案记录',
          url: '/AdminHospitalPlanList',
          type: 'success'
        },
        {
          name: '预约单',
          url: '/AdminHospitalBookingList',
          type: 'success'
        },
        {
          name: '绩效统计',
          url: this.performanceStatisticsUrl,
          type: 'success'
        }, {
          name: '打卡记录',
          url: '/AdminHospitalDakaRecordPage',
          type: 'success'
        },
        // {
        //   name: '创建预约单',
        //   url: '/BookingManager',
        //   type: 'success'
        // },
        {
          name: '其他',
          url: '/searchuser?type=患者的全部预约&title=查询患者的全部预约&userType=patient',
          type: 'success'
        }
      ]
    }
  },
  mounted () {
    this.hospitalId = this.$route.query.hospitalId
    if (!this.hospitalId) {
      this.$dialog({
        title: '提示',
        message: '网页链接有误，请联系管理员',
        confirmText: '确定'
      })
    }
    this.passwd = this.$route.query.passwd
    if (this.passwd) {
      this.isSysAdmin = true
      this.loading = false
      // 平台管理员入口进入
      this.handleLogin()
    } else {
      if (this.$store.state.loginAdmin.token) {
        this.token = this.$store.state.loginAdmin.token
        this.checkLogin()
      } else {
        this.loading = false
      }
    }
  },
  methods: {
    navBack () {
      // 返回
      this.$router.go(-1)
    },
    async checkLogin () {
      const admin = await AdminHospitalDao.loginCheck(this.token)
      console.log('admin', admin)
      this.loading = false
      if (admin) {
        if (admin.userId === this.hospitalId) {
          this.login = true
          this.$store.commit('loginAdmin/setAdminData', admin)
        } else {
          this.login = false
          // toast 提示 登录令牌过期
          this.$dialog({
            title: '提示',
            message: '登录令牌过期，请重新登录',
            confirmText: '确定'
          })
        }
      } else {
        this.login = false
        this.$dialog({
          title: '提示',
          message: '登录令牌过期，请重新登录',
          confirmText: '确定'
        })
      }
    },
    async handleLogin () {
      // console.log('登录', this.hospitalId, this.passwd)
      // post请求 /admin/hospital/login  {hospitalId: this.hospitalId, passwd: this.passwd}
      const token = await AdminHospitalDao.login(this.hospitalId, this.passwd)
      if (token) {
        // 持久化token
        await this.$store.commit('loginAdmin/setToken', token)
        this.token = this.$store.state.loginAdmin.token
        await this.checkLogin()
      } else {
        await this.$dialog({
          title: '提示',
          message: '密码错误',
          confirmText: '确定'
        })
      }
    },
    handleClick (name, url) {
      console.log('点击了' + name)
      if (name === '其他') {
        return
      }
      if (name === '绩效统计') {
        url = this.performanceStatisticsUrl
      }
      if (!url) {
        this.$dialog({
          title: '提示',
          message: '功能正在开发中',
          confirmText: '确定'
        })
        return
      }
      this.$router.push(url)
    }
  },
  watch: {},
  computed: {
    performanceStatisticsUrl () {
      return '/AdminHospitalPerformanceStatistics?hospitalId=' + this.hospitalId
    }
  }
}
</script>
<style scoped>
.button-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
