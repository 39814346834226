<template>
  <div>个人中心</div>
</template>

<script>
export default {
  name: 'Person'
}
</script>

<style scoped>

</style>
