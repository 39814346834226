<template>
  <!--  佛山中医院 扫码申请报名页-->
  <div style="margin-bottom: 48px">
    <div class="main">
      <img
        class="img_item"
        @click="toZfkf"
        src="https://cdn.keihong.tech/typora/2022-11-22-img-zfkf-normal-apply-btn.png"
      />
      <img
        class="img_item"
        @click="toJzcw"
        src="https://cdn.keihong.tech/typora/2022-11-22-img-jzcw-apply-btn.png"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeApplyFszyy',
  data () {
    return {
      name: '',
      iosVer: ''
    }
  },
  mounted () { },
  methods: {
    toZfkf () {
      window.location.href = 'https://www.wjx.cn/vm/YUtAuZg.aspx'
    },
    toJzcw () {
      window.location.href = 'https://www.wjx.top/vm/OtAa7GX.aspx'
    }
  }
}
</script>

<style scoped>
.img_item {
  display: block;
  width: 100%;
}

.main {
  padding-top: 48px;
  padding-bottom: 48px;
}
</style>
