<template>
  <div>
    <base-title-bar-scroll-page ref="container" :title="title">
      <div v-if="guideData">
        <h3 style="margin-left: 16px;margin-right: 16px">{{ role }} |
          {{ guideData.title }}</h3>
        <video :src="guideData.videoUrl" controls poster="https://cdn.keihong.tech/img/202405171254489.png"
               style="width: 100%;"></video>
        <div v-if="guideData.description" style="margin: 16px">
          {{ guideData.description }}
        </div>
        <h3 v-if="guideData.steps.length>0" style="margin-left: 16px">主要步骤如下：</h3>
        <div v-for="(step, index) in guideData.steps" :key="index" style="margin-left: 16px;margin-right: 16px">
          <h4>步骤 {{ index + 1 }}: {{ step.description }}</h4>
          <div v-for="(image, index) in step.images" :key="index">
            <img :src="image" alt="Step Image" style="width: 100%; ">
          </div>
        </div>
      </div>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import { DoctorUserGuideData } from './DoctorUserGuideData'
import { PatientUserGuideData } from './PatientUserGuideData'
import { UniverseGuideData } from '@/h5/doucan/pages/user_guide/UniverseGuideData'

export default {
  name: 'FeatureTutorial',
  components: { BaseTitleBarScrollPage },
  props: ['role', 'featureId'],
  data () {
    return {
      title: '使用教程',
      guideData: null
    }
  },
  mounted () {
    console.log('role', this.role, 'featureId', this.featureId)
    switch (this.role) {
      case '医生端':
        this.guideData = DoctorUserGuideData[this.featureId]
        break
      case '患者端':
        this.guideData = PatientUserGuideData[this.featureId]
        break
      case '常见问题':
        this.guideData = UniverseGuideData[this.featureId]
        break
    }
    console.log('guideData', this.guideData)
    this.hideLoading()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    }
  },
  watch: {},
  computed: {
    PatientUserGuideData () {
      return PatientUserGuideData
    },
    DoctorUserGuideData () {
      return DoctorUserGuideData
    },
    videoUrl () {
      return `/path/to/videos/${this.role}-${this.featureId}.mp4`
    },
    steps () {
      return [
        {
          text: '打开应用',
          imgUrl: '/path/to/images/step1.jpg'
        },
        {
          text: '点击按钮',
          imgUrl: '/path/to/images/step2.jpg'
        },
        {
          text: '输入数据',
          imgUrl: '/path/to/images/step3.jpg'
        }
      ]
    }
  }
}
</script>
<style scoped>

</style>
