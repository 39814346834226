import moment from 'moment'
import 'moment/locale/zh-cn'

moment.locale('zh-cn')

class DateUtils {
  static formate (milliscond, format) {
    const date = new Date(milliscond)
    return moment(date).format(format)
  }

  static formateDate (date, format) {
    return moment(date).format(format)
  }

  static formattedTime (timestamp) {
    return moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
  }

  static formattedDay (timestamp) {
    return moment(timestamp).format('YYYY-MM-DD')
  }

  static formatedSecond (secs) {
    // 秒数转 时分秒
    const minutes = Math.floor(secs / 60)
    const seconds = secs % 60

    return minutes + '分' + seconds + '秒'
  }
}

export default DateUtils
