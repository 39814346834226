<template>
  <div>
    <MyNavBar :title="'方案中心'"/>
    <div style="padding: 16px">
      <!--      <div class="item color1" @click="toGy">康复体操</div>-->
      <!--      <div class="item color2" style="margin-top: 32px" @click="toPlan">居家训练方案</div>-->
      <img
        class="img_item"
        src="https://cdn.keihong.tech/202311212220304.png"
        @click="toPlan"
      />
      <img
        class="img_item"
        src="https://cdn.keihong.tech/202311212219769.png"
        @click="toKepu"
      />
      <img
        class="img_item"
        src="https://cdn.keihong.tech/202311212220633.png"
        @click="toGy"
      />
      <img
        class="img_item"
        src="https://cdn.keihong.tech/img/202407112124191.png"
        @click="toSrceening"
      />
    </div>
  </div>
</template>

<script>
import MyNavBar from '../../components/MyNavBar'

export default {
  name: 'PlanGymnasticsSelPage',
  components: { MyNavBar },
  methods: {
    toGy () {
      this.$router.push('/gymnasticsListPage')
    },
    toSrceening () {
      this.$router.push('/ScreeningListPage')
    },
    toPlan () {
      this.$router.push('/planListPage')
    },
    toKepu () {
      this.$router.push('/MyKepuXuanJiaoMainPage')
    }
  }
}
</script>

<style scoped>
.item {
  text-align: center;
  height: 200px;
  width: 100%;
  line-height: 200px;
  font-size: 32px;
  border-radius: 16px;
}

.color1 {
  background-color: rgba(47, 161, 214, 0.66);
}

.color2 {
  background-color: #399d71;
}

.img_item {
  display: block;
  margin-bottom: 32px;
  width: 100%;
}
</style>
