<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/AdminHomePage.vue -->
<template>
  <div>
    <!--  标题栏  -->
    <div
      style="height: 48px;line-height: 48px;text-align: center;font-size: 14px;font-weight: bold;background-color: #0d3d3d;color: white">
      动优-康复助理管理后台
    </div>
    <div style="padding: 16px">
      <div style="font-size: 18px; margin-bottom: 16px">用户管理</div>
      <div class="button-group">
        <div
          v-for="(button, index) in btnUsers"
          :key="index"
          :type="button.type"
          class="btn-wrp"
          @click="handleClick(button.url)"
        >
          <div style="width: 100%; display: flex; justify-content: center; align-items: center;padding: 8px">
            {{ button.name }}
          </div>
        </div>
      </div>
    </div>
    <div style="height: 16px;background-color: #eeeeee"></div>
    <div style="padding: 16px">
      <!--  标题-订单管理  -->
      <div style="font-size: 18px; margin-bottom: 16px">订单管理</div>
      <div class="button-group">
        <div
          v-for="(button, index) in btnOrders"
          :key="index"
          :type="button.type"
          class="btn-wrp"
          @click="handleClick(button.url)"
        >
          <div style="width: 100%; display: flex; justify-content: center; align-items: center;padding: 8px">
            {{ button.name }}
          </div>
        </div>
      </div>
    </div>
    <div style="height: 16px;background-color: #eeeeee"></div>
    <div style="padding: 16px">
      <div style="font-size: 18px; margin-bottom: 16px">预约管理</div>
      <div class="button-group">
        <div
          v-for="(button, index) in btnBookings"
          :key="index"
          :type="button.type"
          class="btn-wrp"
          @click="handleClick(button.url)"
        >
          <div style="width: 100%; display: flex; justify-content: center; align-items: center;padding: 8px">
            {{ button.name }}
          </div>
        </div>
      </div>
    </div>
    <div style="height: 16px;background-color: #eeeeee"></div>
    <div style="padding: 16px">
      <div style="font-size: 18px; margin-bottom: 16px">MDT群管理</div>
      <div class="button-group">
        <div
          v-for="(button, index) in buttons"
          :key="index"
          :type="button.type"
          class="btn-wrp"
          @click="handleClick(button.url)"
        >
          <div style="width: 100%; display: flex; justify-content: center; align-items: center;padding: 8px">
            {{ button.name }}
          </div>
        </div>
      </div>
      <div style="height: 16px;background-color: #eeeeee"></div>
      <div style="padding: 16px">
        <div style="font-size: 18px; margin-bottom: 16px">医院管理</div>
        <div class="button-group">
          <div
            v-for="(button, index) in btnHospitals"
            :key="index"
            :type="button.type"
            class="btn-wrp"
            @click="handleClick(button.url)"
          >
            <div style="width: 100%; display: flex; justify-content: center; align-items: center;padding: 8px">
              {{ button.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AdminHomePage',
  data () {
    return {
      btnHospitals: [
        {
          name: '医院管理端',
          url: '/HospitalAdminListPage',
          type: 'success'
        }
      ],
      btnUsers: [
        {
          name: '患者列表',
          url: '/bookforpatient?patientlist=true',
          type: 'success'
        }
      ],
      btnBookings: [
        {
          name: '新增预约',
          url: '/bookforpatient',
          type: 'success'
        },
        {
          name: '待执行预约',
          url: '/bookinglist?userType=admin',
          type: 'success'
        },
        {
          name: '查询患者的全部预约',
          url: '/searchuser?type=患者的全部预约&title=查询患者的全部预约&userType=patient',
          type: 'success'
        },
        {
          name: '查询医生的全部预约',
          url: '/searchuser?type=医生的全部预约&title=医生的全部预约&userType=doctor',
          type: 'success'
        }
      ],
      btnOrders: [{
        name: '免费患者下单',
        url: '/FreePatientOrderAddPage',
        type: 'success'
      },
      {
        name: '网页版订单',
        url: '/WebOrderListPage',
        type: 'primary'
      },
      {
        name: '商城订单',
        url: '/MallOrderListPage',
        type: 'primary'
      }],
      buttons: [
        {
          name: '创建MDT群',
          url: '/mdtcreate',
          type: 'primary'
        }
        // 更多按钮...
      ]
    }
  },
  mounted () {

  },
  methods: {
    handleClick (url) {
      if (!url) {
        this.$dialog({
          title: '提示',
          message: '功能正在开发中',
          confirmText: '确定'
        })
        return
      }
      this.$router.push(url)
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>
.btn-wrp {
  display: flex;
  text-align: center;
  width: 25vw;
  background-color: aliceblue;
  margin-right: 8px;
  min-height: 100px;
  border-radius: 8px;
  margin-bottom: 16px;
}

.button-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}
</style>
