// 定义企业列表
const enterpriseList = [
  {
    id: 1,
    name: '动优'
  },
  {
    id: 2,
    name: '汐香记'
  }
]

// 定义一个对象来包装你的函数
const enterpriseFinder = {
  findNameById (id) {
    const enterprise = enterpriseList.find(enterprise => enterprise.id === id)
    return enterprise ? enterprise.name : null
  },
  findIdByName (name) {
    const enterprise = enterpriseList.find(enterprise => enterprise.name === name)
    return enterprise ? enterprise.id : null
  }
}

// 导出这个对象
module.exports = enterpriseFinder
