<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar v-if="!isApp" back style="position:fixed;top: 0;width: 100%" title="辅具科普" @onBackClick="onBackClick"/>
    <div
      v-if="$store.state.user.canShowMall&&!$store.state.isWxapp"
      style="color:white;position:fixed;bottom: 16px;right: 8px;padding-left: 16px;padding-right: 16px;padding-top: 8px;padding-bottom:8px;background-color: #00b74f;z-index: 100;border-radius: 100px"
      @click="handleMoreTool">
      查看更多辅具资讯>
    </div>
    <div :style="{marginTop: isApp ? '0px' : '56px'}" style="padding: 16px">
      <div id="videolist">
        <ListPageV2 :from="'toolkepu'" :type="2"/>
      </div>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../../components/MyNavBar'
import ListPageV2 from '@/h5/feeds/ListPageV2.vue'
import ReportFeatureAccessDao from '@/h5/doucan/network/ReportFeatureAccessDao'

export default {
  name: 'ToolKepuHomePage',
  components: {
    ListPageV2,
    MyNavBar
  },
  data () {
    return {
      isApp: false
    }
  },
  mounted () {
    if (this.$route.query && this.$route.query.from === 'doctorapp') {
      this.$store.commit('user/setCanShowMall', false)
      this.isApp = true
    }

    if (this.$route.query.from === 'wxapp') {
      this.$store.commit('setIsWxapp', true)
    }

    if (this.$store.state.isWxapp) {
      this.isApp = true
    }
    this.reportFeatureAccess()
  },
  methods: {
    onBackClick () {
      console.log('重定向到主页')
      this.$router.replace('/home/square')
    },
    handleMoreTool () {
      // this.$router.push('/MallMainPage?from=toolkepu')
      this.$router.push('/MallMainPageNeimeng')
    },
    reportFeatureAccess () {
      ReportFeatureAccessDao.report(9, this.$store.state.user.qrCodeHospital, this.$store.state.user.openId).then(res => {
        console.log('上报访问成功', res)
      })
    }
  },
  watch: {},
  computed: {
    isEmpty () {
      return !this.loading && this.list?.length === 0
    }
  }
}
</script>
<style scoped>

</style>
