<template>
  <div>
    <base-title-bar-scroll-page ref="container" title="床边结算宣教">
      <div>
        <div v-for="(content, index) in contents" :key="index" @click="toDetail(content)">
          <div style="background-color: white;padding: 24px;display: flex;flex-direction: row">
            <div style="font-size: 18px;margin-right: auto">{{ index + 1 }}、{{ content.title }}</div>
            <div>
              <my-img :url="content.poster" style="width: 80px;height: 80px;margin-left: 8px"/>
            </div>
          </div>
          <div style="height: 1px;width: 100%;background-color: #eaeaea"></div>
        </div>
      </div>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import MyImg from '@/h5/doucan/components/MyImg.vue'
import ReportFeatureAccessDao from '@/h5/doucan/network/ReportFeatureAccessDao'

const BASEURL = 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/'

export default {
  name: 'BedPayKepu',
  components: {
    MyImg,
    BaseTitleBarScrollPage
  },
  data () {
    return {
      hospital: null,
      /**
       * 421	住院患者床边结算流程
       * 422	1.患者公众号、住院管家注册信息流程
       * 423	2.结算流程文档说明
       * 424	3.查看电子发票与清单流程
       * 425	4.下载电子发票、住院清单、医保结算单流程
       * 426	5.代收住院退款操作说明
       * 427	患者入院用物准备清单
       * 428	异地医保备案1--如何快速找到小程序？
       * 429	异地医保备案2-如何通过小程序快速备案？
       * 430	异地医保备案3-如何查询备案的进度？
       */
      contents: [
        {
          title: '床边结算操作视频',
          id: 433,
          poster: 'https://cdn.keihong.tech/img/202405191747031.png',
          videoUrl: BASEURL + 'article/feeds/床边结算操作视频（暨大顺医）/床边结算操作视频.mp4'
        },
        {
          title: '住院患者床边结算流程',
          id: 421,
          poster: 'https://cdn.keihong.tech/img/202405191747031.png'
        },
        {
          title: '① 患者公众号、住院管家注册信息流程',
          id: 422,
          poster: 'https://cdn.keihong.tech/img/202405191747031.png'
        },
        {
          title: '② 结算流程文档说明',
          id: 423,
          poster: 'https://cdn.keihong.tech/img/202405191747031.png'
        },
        {
          title: '③ 查看电子发票与清单流程',
          id: 424,
          poster: 'https://cdn.keihong.tech/img/202405191747031.png'
        },
        {
          title: '④ 下载电子发票、住院清单、医保结算单流程',
          id: 425,
          poster: 'https://cdn.keihong.tech/img/202405191747031.png'
        },
        {
          title: '⑤ 代收住院退款操作说明',
          id: 426,
          poster: 'https://cdn.keihong.tech/img/202405191747031.png'
        },
        {
          title: '患者入院用物准备清单',
          id: 427,
          poster: 'https://cdn.keihong.tech/img/202405191747031.png'
        },
        {
          title: '异地医保备案1--如何快速找到小程序？',
          id: 428,
          poster: 'https://cdn.keihong.tech/img/202405191747031.png'
        },
        {
          title: '异地医保备案2-如何通过小程序快速备案？',
          id: 429,
          poster: 'https://cdn.keihong.tech/img/202405191747031.png'
        },
        {
          title: '异地医保备案3-如何查询备案的进度？',
          id: 430,
          poster: 'https://cdn.keihong.tech/img/202405191747031.png'
        },
        {
          title: '工伤医疗费如何联网结算？（佛山市）',
          id: 436,
          poster: 'https://cdn.keihong.tech/img/202405191747031.png'
        },
        {
          title: '发生工伤时未参保怎么办？这些工伤知识为您解答（（佛山市））',
          id: 434,
          poster: 'https://cdn.keihong.tech/img/202405191747031.png'
        },
        {
          title: '指尖社保 | 如何查询工伤待遇发放明细？（佛山市）',
          id: 437,
          poster: 'https://cdn.keihong.tech/img/202405191747031.png'
        }
      ]
    }
  },
  mounted () {
    this.hospital = this.$store.state.user.qrCodeHospital
    if (!this.hospital) {
      // this.$toast('请先扫码登录')
      // this.$router.push('/login')
    }
    console.log(this.hospital)
    this.reportFeatureAccess()
    this.hideLoading()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    toDetail (content) {
      if (content.videoUrl) {
        console.log(content.videoUrl)
        // 带参数跳转：/BaseVideoPlayPage
        this.$router.push({
          path: '/BaseVideoPlayPage',
          query: {
            videoUrl: content.videoUrl
          }
        })
      } else {
        this.$router.push('/feeds/detail/' + content.id)
      }
    },
    reportFeatureAccess () {
      if (this.$store.state.route.from === '/KepuxuanjiaoMainPage') {
        ReportFeatureAccessDao.report(7, this.hospital, this.$store.state.user.openId).then(res => {
          console.log('上报访问成功', res)
        })
      }
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
