<template>
  <!-- 居家康复学组专栏 -->
  <div style="width: 100%;box-sizing: border-box">
    <my-nav-bar title="居家康复学组专栏"/>
    <iframe id=“frame”
            src="https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzI3NjczMDc5Mg==&action=getalbum&album_id=2911239609479577601#wechat_redirect"
            style="width: 100%;border: 0px;margin:0;display: none"></iframe>

  </div>
</template>

<script>
import MyNavBar from '../../components/MyNavBar'

export default {
  name: 'KepuColumn',
  components: { MyNavBar }
}
</script>

<style scoped>

</style>
