<template>
  <div>
    <my-nav-bar :title="'康复体操'"/>
    <div v-if="!loadingCourse" class="player">
      <video-player
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="true"
        :x5-video-player-fullscreen="true"
        class="video-player vjs-custom-skin"
        style="object-fit: fill"
        @click="fullScreen"
        @fullscreenchange="onFullscreenChange($event)"
        @pause="onPlayerPause($event)"
        @play="onPlayerPlay($event)"
      ></video-player>
      <container-list
        :empty="empty"
        :empty-desc="'方案数据读取错误'"
        :loading="loading"
      >
        <div v-if="data" style="padding: 16px">
          <div class="container-card">
            <my-title1 :fontColor="'#00703e'" :size="20" :title="'康复体操'"/>
            <div style="margin-left: 16px; font-size: 28px">
              {{ data.title }}
            </div>
          </div>

          <!-- <div class="container-card">
          <my-title1 :title="'动作列表'" :size="20" :fontColor="'#00703e'" />
          <div style="padding: 16px">
            <div
              v-for="(item, index) in data.courseVideoList"
              :key="item.videoId"
            >
              <div style="font-size: 20px">
                {{ index + 1 }}、{{ item.title }}
              </div>
              <van-divider />
            </div>
          </div>
        </div> -->
        </div>
      </container-list>
      <div
        style="
          display: flex;
          flex-direction: row;
          margin-top: 16px;
          position: relative;
        "
      >
        <div style="margin-left: 16px; font-weight: bolder; font-size: 18px">
          课程视频列表：
        </div>
        <div
          style="
            text-align: center;
            color: #606060;
            position: absolute;
            right: 16px;
            font-size: 18px;
          "
        >
          点击下方标题可切换视频
        </div>
      </div>
      <div
        style="
          margin: 16px;
          box-shadow: 0 0 5px grey;
          padding: 8px;
          background-color: white;
          border-radius: 8px;
        "
      >
        <div v-for="item in data.courseVideoList" :key="item">
          <div
            style="background-color: white; display: flex; flex-direction: row"
            @click="courseClick(item)"
          >
            <div
              v-if="playingCourse.videoId === item.videoId"
              style="
                background-color: #0d3d3d;
                color: white;
                font-size: 18px;
                padding: 4px;
                width: 15vw;
                height: 15vw;
                text-align: center;
                border-radius: 8px;
                margin-top: 4px;
                margin-bottom: 4px;
              "
            >
              <div>当前</div>
              <div>播放</div>
            </div>
            <div style="padding: 16px; font-size: 20px; width: fit-content">
              {{ item.title }}
            </div>
          </div>
          <div style="height: 0.5px; background-color: #777777"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyNavBar from '../../components/MyNavBar'
import ContainerList from '../../components/ContainerList'
import PlanDao from '../../network/PlanDao'
import GymnasticsDao from '../../network/GymnasticsDao'
import myTitle1 from '../../components/MyTitle1'

export default {
  name: 'TicaoDetailPage',
  components: {
    MyNavBar,
    ContainerList,
    myTitle1
  },
  data () {
    return {
      courseId: null,
      playingCourse: null,
      courses: [],
      empty: true,
      loading: true,
      data: null,
      loadingCourse: true,
      playerOptions: {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          // type: 'video/mp4',
          src: ''
        }],
        poster: '你的封面地址',
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          // timeDivider: true,
          // durationDisplay: true,
          // remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      }
    }
  },
  computed: {
    player () {
      return this.$refs.videoPlayer.player
    }
  },
  async created () {
    const query = this.$route.query
    if (query?.id) {
      this.courseId = query.id
    }
    await this.loadDatas()
    this.playerOptions.sources[0].src = this.data.courseVideoList[0].videoUrl
    this.playerOptions.poster = this.data.courseVideoList[0].coverUrl
    this.playingCourse = this.data.courseVideoList[0]
    this.loadingCourse = false
    // this.courseClick(this.data.courseVideoList[0])
    // this.loadCourseFirst()
  },
  methods: {
    courseClick (item) {
      console.log('courseClick', item)
      if (this.playingCourse && (item.videoId === this.playingCourse.videoId)) {
        return
      }
      const player = this.player
      this.playingCourse = item
      player.src({
        type: 'video/mp4',
        src: item.videoUrl
      })
      player.ready(function () {
        // get
        // var howLoudIsIt = myPlayer.volume();
        // set
        // myPlayer.volume(0.5); // Set volume to half
        player.play()
      })
    },
    async loadCourseFirst () {
      for (const e of this.data.plan.planCourseBeans) {
        const course = await this.loadCourse(e.courseId)
        this.courses.push(course)
      }
      console.log('课程', this.courses)
      this.playerOptions.sources[0].src = this.courses[0].videoUrl
      this.playerOptions.poster = this.courses[0].coverUrl
      this.playingCourse = this.courses[0]
      this.loadingCourse = false
    },
    async loadCourse (courseId) {
      const course = await PlanDao.loadCourse(courseId)
      console.log('课程细节', course)
      return course
    },
    async httpDatas () {
      this.data = await GymnasticsDao.detail(this.courseId)
      console.log('体操data', this.data)
    },
    async loadDatas () {
      this.loading = true
      await this.httpDatas()
      console.log('数据请求结果', this.data)
      if (this.data) {
        this.empty = false
      } else {
        this.empty = true
      }
      this.loading = false
    },
    onPlayerPause (event) {

    },
    onPlayerPlay (event) {
      this.player.play()
    },
    onFullscreenChange (event) {

    },
    fullScreen (event) {
      const player = this.$refs.videoPlayer.player
      player.requestFullscreen()// 调用全屏api方法
      player.isFullscreen(true)
      player.play()
    }
  }
}
</script>

<style scoped>
.container-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 5px grey;
}
</style>
