<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/WebOrderListPage.vue -->
<template>
  <div>
    <MyNavBar style="position:fixed;top: 0;width: 100%" title="网页版支付订单"/>
    <div style="margin-top: 56px">

      <div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-if="list.length > 0"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="loadMore"
          >
            <div v-for="(item, index) in list" :key="index" style="width: 100%">
              <!--            <div style="padding: 16px">-->
              <!--              <div style="font-size: 16px">订单编号{{ item.orderNum }}</div>-->
              <!--              <div style="display: flex">-->
              <!--                <div style="font-size: 24px">{{ item.patientName }}</div>-->
              <!--                <div-->
              <!--                  style="font-size: 16px; margin-left: auto; margin-top: auto"-->
              <!--                >-->
              <!--                  {{ formattedTime(item.createTime) }}-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div style="font-size: 16px">下单手机号:{{ item.mobile }}</div>-->
              <!--              <div style="font-size: 16px">-->
              <!--                {{ item.fromActivity }}-->
              <!--              </div>-->
              <!--              <div style="font-size: 16px">{{ item.goods }}</div>-->

              <!--              <div style="font-size: 16px">支付金额:{{ item.payAmount }}</div>-->
              <!--            </div>-->
              <div>
                <van-cell :value="item.orderNum" title="订单编号" title-width="4em"/>
                <van-cell :value="formattedTime(item.createTime)" title="下单日期" title-width="4em"/>

                <van-cell title="用户信息">
                  <template #default>
                    <div>
                      <span class="patient-name">{{ item.patientName }}</span>
                    </div>
                  </template>
                </van-cell>

                <van-cell title="购买服务">
                  <template #default>
                    <div>
                      <van-tag plain type="primary">{{ item.fromActivity }}</van-tag>
                      <div>{{ item.goods }}</div>
                    </div>
                  </template>
                </van-cell>

                <van-cell :title="`支付金额`" :value="`${item.payAmount?item.payAmount:'0.00'}元`"/>
                <!--      支付状态        -->
                <van-cell title="支付状态">
                  <template #default>
                    <div>
                      <van-tag v-if="item.orderStatus === 1" plain style="color: #00ad61" type="primary"> 已支付
                      </van-tag>
                      <van-tag v-else plain type="danger"> 未支付</van-tag>
                    </div>
                  </template>
                </van-cell>
              </div>
              <div
                style="
                height: 8px;
                background-color: rgb(232,232,232);
                width: 100%;
              "
              ></div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>
<script>
import AdminDao from '../../network/AdminDao'
import moment from 'moment'
import MyNavBar from '@/h5/doucan/components/MyNavBar.vue'

export default {
  name: 'WebOrderListPage',
  components: { MyNavBar },
  data () {
    return {
      list: [], // 数据列表
      pageNo: 0, // 当前页码
      pageSize: 10,
      totalPages: 0, // 总页数
      refreshing: false, // 下拉刷新状态,
      total: 0,
      loading: false,
      finished: false
    }
  },
  async mounted () {
    this.loadData() // 初始化加载数据
  },
  methods: {
    async loadData () {
      this.finished = false
      const datas = await AdminDao.webOrderList(this.pageNo, this.pageSize)
      console.log('网页订单列表', datas)
      this.list = datas.content
      this.totalPages = datas.totalPages
      this.total = datas.totalElements
      this.refreshing = false
    },
    onRefresh () {
      console.log('刷新')
      this.pageNo = 0 // 重置页码
      this.loadData() // 刷新数据
    },
    loadMore () {
      if (this.pageNo < this.totalPages - 1) {
        this.pageNo++ // 增加页码
        AdminDao.webOrderList(this.pageNo, this.pageSize) // 假设接口地址为/api/data
          .then(datas => {
            console.log('loadMore', datas)
            this.totalPages = datas.totalPages
            this.total = datas.totalElements
            this.list = this.list.concat(datas.content) // 追加数据
            // 加载状态结束
            this.loading = false
            if (this.list.length >= this.total) {
              this.finished = true
            }
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    formattedTime (timestamp) {
      return moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  watch: {
    refreshing (newVal) {
      if (!newVal) {
        this.$nextTick(() => {
          this.loadMore() // 加载更多数据
        })
      }
    }
  },
  computed: {}
}
</script>
<style scoped>
.van-tag--primary.van-tag--plain {
  color: #00ad61;
}
</style>
