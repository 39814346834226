<!-- ListComponent.vue -->
<template>
  <div style="width: 100%;">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="已加载完毕"
        @load="onLoad"
      >
        <div v-for="(item, index) in list" :key="index">
          <slot :data="item" name="item">

            <div style="padding:16px;background: white;box-sizing: content-box;">
              {{ JSON.stringify(item) }}
            </div>

          </slot>
          <!--     分割线     -->
          <slot name="divider">
            <div style="height: 16px;background-color: #f5f5f5"></div>
          </slot>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
export default {
  name: 'ListView',
  data () {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      list: []
    }
  },
  methods: {
    onRefresh () {
      // 处理下拉刷新逻辑
      //   通知父组件
      this.finished = false
      this.loading = true
      this.$emit('onRefresh')
    },
    onLoad () {
      // 处理加载更多逻辑
      //   通知父组件
      this.loading = true
      this.$emit('onLoad')
    },
    addData (data) {
      // 添加数据
      if (this.refreshing) {
        this.list = []
        this.refreshing = false
      }
      this.list.push(...data)
      this.loading = false
    },
    finish () {
      // 数据全部加载完成
      this.finished = true
    }
  }
}
</script>
