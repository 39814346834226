<template>
  <div>
    <img src="https://cdn.keihong.tech/img/202403201027675.png" style="display: block;width: 100%">
  </div>
</template>

<script>
export default {
  name: 'ServiceTypeHalfYear',
  data () {
    return {
      fee: 6800
    }
  },
  created () {
    this.$emit('update-fee', this.fee)
  }
}
</script>

<style scoped>

</style>
