<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar v-if="showAppbar" title="科普宣教"/>
    <!--    <div-->
    <!--      style="font-size: 16px;text-align: center;background-color: rgba(0,161,126,0.72);color: white;font-weight: bold;padding: 8px">-->
    <!--      请点击下面的卡片进入需要的宣教板块哦-->
    <!--    </div>-->
    <div style="position: relative">
      <my-img url="https://cdn.keihong.tech/img/202405191652550.png"/>
      <div style="padding: 16px;width: 100%;position:absolute;bottom: -56px;box-sizing: border-box"
           @click="handleItemClick('/MyKepuXuanJiaoMainPage')">
        <div
          style="background-color: #f6ba36;padding: 8px;text-align: center;border-radius: 16px">
          <div style="font-size: 48px;font-weight: bold;color: white">
            我 的 宣 教
          </div>
          <div style="font-size: 14px;color: #136b59">查看医生/治疗师/护士给你发送的专属科普内容吧</div>
        </div>
      </div>
    </div>
    <div style="width: 100%;margin-top: 40px">
      <div v-show="false" @click="handleItemClick('/ticaoList')">

        <my-img style="padding: 12px;box-sizing: border-box" url="https://cdn.keihong.tech/img/202405191723919.png"
        />
      </div>
      <div style="height: 16px"></div>

      <!--  四宫格，用于存放图片，且点击后进入详情页    -->
      <div class="image-gallery">
        <div v-for="(row, index) in imageRows" :key="index" class="image-row">
          <div class="image-column">
            <div v-for="image in row" :key="image.id">
              <img :src="image.url" :style="{ width: menuItemWith + 'vw' }" alt="Image"
                   @click="handleItemClick(image.route)"/>
            </div>
          </div>
        </div>
      </div>
      <!--      <div v-if="hospital === '暨南大学附属顺德医院'">-->

      <!--   神外   -->
      <div @click="handleItemClick('/SickKepuShenwai')">
        <my-img style="padding: 12px;box-sizing: border-box" url="https://cdn.keihong.tech/img/202405310833795.png"
        />
      </div>
      <!--   神内   -->
      <div @click="handleItemClick('/SickKepuShennei')">
        <my-img style="padding: 12px;box-sizing: border-box" url="https://cdn.keihong.tech/img/202405310836503.png"
        />
      </div>
      <!--   康复医学科   -->
      <div @click="handleItemClick('/SickKepuKangfuyixueke')">
        <my-img style="padding: 12px;box-sizing: border-box" url="https://cdn.keihong.tech/img/202405310835404.png"
        />
      </div>
      <!--      &lt;!&ndash;   创伤骨科   &ndash;&gt;-->
      <!--      <div @click="handleItemClick('/ticaoList')">-->
      <!--        <my-img style="padding: 12px;box-sizing: border-box" url="https://cdn.keihong.tech/img/202405310835038.png"-->
      <!--        />-->
      <!--      </div>-->
      <!--      &lt;!&ndash;   脊柱外科   &ndash;&gt;-->
      <!--      <div @click="handleItemClick('/ticaoList')">-->
      <!--        <my-img style="padding: 12px;box-sizing: border-box" url="https://cdn.keihong.tech/img/202405310837412.png"-->
      <!--        />-->
      <!--      </div>-->
    </div>
    <!--    </div>-->
  </div>
</template>
<script>
import MyNavBar from '../../../components/MyNavBar'
import MyImg from '@/h5/doucan/components/MyImg.vue'
import ReportFeatureAccessDao from '@/h5/doucan/network/ReportFeatureAccessDao'

export default {
  name: 'KepuxuanjiaoMainPage',
  components: {
    MyImg,
    MyNavBar
  },
  data () {
    return {
      isWxapp: false,
      patientId: null,
      showAppbar: true,
      menuItemWith: 43,
      rowCount: 2,
      hospital: null,
      images: []
    }
  },
  mounted () {
    if (this.$route.query.from === 'wxapp') {
      this.isWxapp = true
      this.$store.commit('setIsWxapp', true)
      this.showAppbar = false
      this.hospital = this.$route.query.hospital
      this.patientId = this.$route.query.patientId ===
      'undefined' ? null : this.$route.query.patientId

      if (this.$store.state.notShowMallHospitals.includes(this.hospital)) {
        this.$store.commit('user/setCanShowMall', false)
      } else {
        this.$store.commit('user/setCanShowMall', true)
      }

      console.log('patientId', this.patientId)
    } else {
      this.hospital = this.$store.state.user.qrCodeHospital
    }
    this.checkHospital()
  },
  methods: {
    reportFeatureAccess () {
      console.log('上级页面url', this.$store.state.route.from)
      if (this.$store.state.route.from === '/home/square') {
        ReportFeatureAccessDao.report(5, this.hospital, this.$store.state.user.openId).then(res => {
          console.log('上报访问成功', res)
        })
      }
    },
    handleItemClick (route) {
      console.log('route', route)
      if (route) {
        if (this.isWxapp && route === '/MyKepuXuanJiaoMainPage') {
          if (this.patientId) {
            route += '?patientId=' + this.patientId
          } else {
            this.$toast('请先回到主页个人信息页面登录')
            return
          }
        }
        this.$router.push(route)
      } else {
        // 提示 暂未内容
        this.$toast('暂无内容哦，请向护士咨询出入院相关科普与常见问题')
      }
    },
    checkHospital () {
      console.log(this.hospital)
      switch (this.hospital) {
        case '暨南大学附属顺德医院':
          this.images = [
            {
              id: 1,
              url: 'https://cdn.keihong.tech/img/202405191733406.png',
              route: '/RuyuanAndChuyuanKepu'
            },
            // {
            //   id: 2,
            //   url: 'https://cdn.keihong.tech/img/202405191732890.png',
            //   route: '/SickKepu'
            // },
            {
              id: 3,
              url: 'https://cdn.keihong.tech/img/202405191747031.png',
              route: '/BedPayKepu'
            },
            {
              id: 4,
              url: 'https://cdn.keihong.tech/img/202405310828464.png',
              route: '/ticaoList'
            },
            {
              id: 5,
              url: 'https://cdn.keihong.tech/img/202405311623116.png',
              route: '/ToolKepuHomePage'
            }
            // ...more images
          ]
          this.menuItemWith = 22
          this.rowCount = 5
          break
        default:
          this.images = [
            {
              id: 1,
              url: 'https://cdn.keihong.tech/img/202405191733406.png',
              route: '/RuyuanAndChuyuanKepu'
            },
            {
              id: 2,
              url: 'https://cdn.keihong.tech/img/202405191732890.png',
              route: '/SickKepu'
            },
            {
              id: 3,
              url: 'https://cdn.keihong.tech/img/202405310828464.png',
              route: '/ticaoList'
            },
            {
              id: 4,
              url: 'https://cdn.keihong.tech/img/202405311623116.png',
              route: '/ToolKepuHomePage'
            }
          ]
          this.menuItemWith = 22
          this.rowCount = 4
      }
    }
  },
  watch: {
    hospital: {
      handler (newValue) {
        if (this.hospital) {
          this.reportFeatureAccess()
        }
      },
      immediate: true
    }
  },
  computed: {
    imageRows () {
      const rows = []
      let currentRow = []
      this.images.forEach((image, index) => {
        currentRow.push(image)
        if ((index + 1) % this.rowCount === 0) {
          rows.push(currentRow)
          currentRow = []
        }
      })
      if (currentRow.length > 0) {
        rows.push(currentRow)
      }
      console.log('rows', rows)
      return rows
    }
  }
}
</script>
<style scoped>
.image-gallery {
}

.image-row {
}

.image-column {
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
