<template>
  <div style="width: 100%">
    <div style="position: relative">
      <video ref="videoFlv" :style="{height:livePlayerHeight+'px'}" controls muted
             style="width: 100%;display: block"></video>
      <div style="position: absolute;top: 16px;left: 16px;color: white;font-size: 12px">动优康护 doUcan</div>
      <div v-if="liveLoading" :style="{height:livePlayerHeight+'px'}"
           style="width: 100vw;background-color: #333333;position:absolute;top: 0">
        <div
          style="top: 50%;left:50%;margin-left: -60px;margin-top: -40px;position: absolute;display: flex;flex-direction: column;align-items: center;background-color: #e7e7e7;width: 100px;height: 80px;border-radius: 16px;justify-content: center">
          <van-loading color="#0a4b3d" size="24px" type="spinner"/>
          <div>视频连线中</div>
        </div>
      </div>
      <div v-if="liveChannel"
           style="position: absolute;top: 16px;right: 16px;color: black;font-size: 12px;background-color: white">
        {{ liveChannel.title }}
      </div>
    </div>
    <!--  显示最新告警记录 ,水平展示 时间+图片（base64格式）  -->
    <div v-if="newestAlarm" style="background-color: #dedede;padding: 16px"
         @click="handlerClickAlarmDetail">
      <div
        style="display: flex; ">
         <span style="font-size: 12px; margin-top: auto;margin-bottom: auto">最近一次告警：{{
             Dateutils.formattedTime(new Date(newestAlarm.createTime))
           }}</span>
        <div style="width: 56px; height: 56px;margin-left: auto">
          <img :src="newestAlarm.base64"
               style="width: 48px; height: 48px"/>
        </div>
      </div>
      <div
        style="background-color: #3d3d3d;color: white;padding: 8px;width: auto;display: inline-block;border-radius: 8px;font-size: 12px">
        点击查看告警详情
      </div>
    </div>
    <div v-else style="text-align: center">正在读取告警信息</div>

    <!--  告警图片闪烁，来自assets的alarm-on.png 和 alarm-off.png  -->
    <div v-if="isAlarm" style="text-align: center">
      <!--   水平展示一个图片和一个按钮，图片在右，按钮在左   -->
      <div
        style="background-color: #0a4b3d;padding: 8px;display: flex; flex-direction: row; align-items: center; justify-content: space-between">
        <div style="width: 56px; height: 56px">
          <img v-show="isAlarmLight" src="../../assets/alarm-on.png"
               style="width: 48px; height: 48px"/>
          <img v-show="!isAlarmLight" src="../../assets/alarm-off.png"
               style="width: 48px; height: 48px"/>
        </div>
        <van-button type="primary" @click="fixAlarm()">关闭本次告警
        </van-button>
      </div>
    </div>

    <div style="display: flex; padding: 16px">
      <span>跌倒告警</span>
      <span style="margin-left: auto; font-size: 12px; margin-top: auto"
      >最近一次时间：{{ downTime }}</span
      >
    </div>
    <VideoPlayer
      poster-url="https://cdn.keihong.tech/img/202307282237225.png"
      videoUrl="https://cdn.keihong.tech/img/202307282236059.mp4"
    />
    <div style="display: flex; padding: 16px">
      <span>离床告警</span>
      <span style="margin-left: auto; font-size: 12px; margin-top: auto"
      >最近一次时间：2023年7月1日 15:33:23</span
      >
    </div>
    <VideoPlayer
      poster-url="https://cdn.keihong.tech/img/202308112304844.png"
      videoUrl="https://cdn.keihong.tech/img/202308112304066.mp4"
    />

    <div style="display: flex; padding: 16px">
      <span>火情告警</span>
      <span style="margin-left: auto; font-size: 12px; margin-top: auto"
      >最近一次时间：2023年7月16日 17:32:02</span
      >
    </div>
    <VideoPlayer
      poster-url="https://cdn.keihong.tech/img/202307282242455.png"
      videoUrl="https://cdn.keihong.tech/img/202307282243027.mp4"
    />

    <div style="display: flex; padding: 16px">
      <span>出走告警</span>
      <span style="margin-left: auto; font-size: 12px; margin-top: auto"
      >最近一次时间：2023年7月23日 8:33:03</span
      >
    </div>
    <VideoPlayer
      poster-url="https://cdn.keihong.tech/img/202307282242289.png"
      videoUrl="https://cdn.keihong.tech/img/202307282241171.mp4"
    />
    <div style="display: flex; padding: 16px">
      <span>照护行为查证</span>
      <span style="margin-left: auto; font-size: 12px; margin-top: auto"
      >最近一次时间：2023年7月17日 15:33:23</span>
    </div>
    <VideoPlayer
      poster-url="https://cdn.keihong.tech/img/202308112305454.png"
      videoUrl="https://cdn.keihong.tech/img/202308112305063.mp4"
    />
    <img class="img" src="https://cdn.keihong.tech/img/202307071554216.png"/>
    <audio ref="audioElement" loop preload src="../../assets/alarm1.mp3"></audio>
    <div
      style="position:fixed;right: 16px;bottom: 56px;height: 56px;width: 56px;background-color: white;border-radius: 28px;box-shadow: 1px 1px 20px #888888"
      @click="refresh">
      <img src="https://cdn.keihong.tech/img/202309211523073.png"
           style="height: 48px;width: 48px;margin-left: 4px;margin-top: 4px"/>
    </div>
    <!--  底部弹出选择liveChannelArray   -->
    <van-popup v-model="openChannelSelect" position="bottom">
      <van-picker
        :columns="liveChannelArray"
        :value-key="'title'"
        show-toolbar
        title="请选择设备"
        @cancel="openChannelSelect = false"
        @confirm="onChannelConfirm"
      />
    </van-popup>
    <van-popup v-model="showDetail" position="bottom">
    </van-popup>
  </div>
</template>

<script>
import VideoPlayer from '../../../../common-components/VideoPlayer/VideoPlayer.vue'
import Dateutils from '@/utils/DateUtils'
import DateUtils from '@/utils/DateUtils'
import MonitorDao from '@/h5/doucan/network/MonitorDao'
import { ImagePreview } from 'vant'
import flvjs from 'flv.js'

export default {
  computed: {
    Dateutils () {
      return Dateutils
    },
    livePlayerHeight () {
      return window.innerWidth * 3 / 4 // 获取屏幕宽度的一半
    }
  },
  components: {
    VideoPlayer
  },
  data () {
    return {
      showDetail: false,
      delayTime: 60, // 视频告警延迟时间-秒
      activeIndex: 0,
      monitoringScreenSrc: 'http://101.33.204.139:18026/Streaming/channels/2/httppreview',
      websocket: null,
      isAlarm: false,
      isAlarmLight: true,
      downTime: '2023年7月1日 15:33:23',
      timer: null,
      openAlarm: false,
      rtmpPlayer: null,
      liveLoading: true,
      liveChannel: null,
      liveChannelArray: [{
        title: 'Canada1',
        channel: 'zichuan-ca-test-1',
        deviceName: 'Canada1',
        deviceId: 4
      }, {
        title: '龙生-客厅-1',
        channel: 'zichuan-long-1',
        deviceName: '龙岳军-客厅',
        deviceId: 2
      }, {
        title: '康康家-测试机-1',
        channel: 'zichuan-kang',
        deviceName: 'chenqikang01',
        deviceId: 3
      }],
      openChannelSelect: true,
      newestAlarm: null,
      handledAlarmId: null
    }
  },
  async mounted () {
  },
  methods: {
    handlerClickAlarmDetail () {
      // this.showDetail = true
      this.$router.push('/MonitorAdminLabel?dID=' + this.liveChannel.deviceId + '&notAdmin=true')
    },
    preview () {
      // vant2 里面的图片预览组件
      const images = []
      images.push(this.newestAlarm.base64)
      ImagePreview({
        images: images,
        startPosition: 0
      })
    },
    async loadNewestAlarm () {
      const _that = this
      const result = await MonitorDao.newestAlarm(_that.liveChannel.deviceName)
      if (result) {
        result.base64 = 'data:image/jpeg;base64,' + result.base64
        this.newestAlarm = result
        console.log('newestAlarm', this.newestAlarm)
        // 已经处理过当前id的告警了
        if (this.handledAlarmId === result.id) {
          return
        }
        // todo 判断是否需要告警：若告警时间和当前时间相差1分钟内，则告警
        const now = new Date()
        const alarmTime = new Date(result.createTime)
        // if ((now.getTime() - this.delayTime * 1000) - alarmTime.getTime() < 60 * 1000) {
        if (now.getTime() - alarmTime.getTime() < 60 * 1000) {
          if (this.isAlarm) {
            return
          }
          setTimeout(() => {
            _that.$refs.audioElement.play()
          }, 200)
          // 告警信息
          this.isAlarm = true
          this.downTime = DateUtils.formattedTime(new Date())
          //   开启一个定时器，每1秒钟闪烁一次，5秒后停止闪烁
          this.timer = setInterval(function () {
            if (_that.isAlarmLight) {
              _that.isAlarmLight = false
            } else {
              _that.isAlarmLight = true
            }
          }, 200)
        }
      }
    },
    connectSocket () {
      // 随机数
      var random = Math.round(Math.random() * 100)
      this.websocket = new WebSocket(this.liveChannel.socket + random)
      this.websocket.onopen = function (event) {
        console.log('WebSocket opened: ', event)
      }
      const _that = this
      this.websocket.onmessage = function (event) {
        let data = null
        try {
          data = JSON.parse(event.data)
          console.log('WebSocket Message received: ', data)
          if (_that.isAlarm) {
            return
          }
          if (data.eventType) {
            setTimeout(() => {
              _that.$refs.audioElement.play()
            }, 200)
            // 告警信息
            _that.isAlarm = true
            _that.downTime = DateUtils.formattedTime(new Date())
            //   开启一个定时器，每1秒钟闪烁一次，5秒后停止闪烁
            _that.timer = setInterval(function () {
              if (_that.isAlarmLight) {
                _that.isAlarmLight = false
              } else {
                _that.isAlarmLight = true
              }
            }, 200)
          }
        } catch (e) {
          // console.log(e)
          console.log('WebSocket Message received: ', event)
        }
      }
    },
    onChannelConfirm (value, index) {
      this.openChannelSelect = false
      this.liveChannel = this.liveChannelArray[index]
      this.loadNewestAlarm()
      this.initFlvPlayer()
    },
    initFlvPlayer () {
      const videoElement = this.$refs.videoFlv
      console.log('videoElement:', videoElement)
      videoElement.addEventListener('loadeddata', () => {
        this.liveLoading = false
        // todo 读取告警
        this.loadNewestAlarm()
        setInterval(() => {
          this.loadNewestAlarm()
        }, 5000)
        // this.connectSocket()
      })
      // const baseurl = 'https://www.doucan1758.com:8082/live/'
      // const videoUrl = baseurl + this.liveChannel.channel + '.m3u8'
      if (flvjs.isSupported()) {
        const flvPlayer = flvjs.createPlayer({
          type: 'flv',
          // url: 'https://171.111.219.37:8081/live/' + this.liveChannel.channel + '.flv',
          url: 'https://www.doucan1758.com:8082/live/' + this.liveChannel.channel + '.flv',
          isLive: true
        })
        flvPlayer.attachMediaElement(videoElement)
        flvPlayer.load()
        flvPlayer.play()
        // var hls = new Hls()
        //
        // hls.loadSource(videoUrl)
        // hls.attachMedia(videoElement)
        // hls.on(Hls.Events.MANIFEST_PARSED, function () {
        //   videoElement.play()
        // })
      } else {
        // 弹窗提示
        this.$toast('您的浏览器不支持播放视频')
      }
    },
    refresh () {
      window.location.reload()
    },
    fixAlarm () {
      this.isAlarm = false
      clearInterval(this.timer)
      this.$refs.audioElement.pause()
      this.$refs.audioElement.currentTime = 0
      this.handledAlarmId = this.newestAlarm.id
    }
  }
}
</script>
<style scoped>
.img {
  display: block;
  width: 100vw;
}

.device-info {
  font-size: 24px;
  text-align: center;
}
</style>
