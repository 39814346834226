<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <div style="position: relative;width: 100%;">
      <MyNavBar :back-text="isArticle?'康复圈':'返回'" back style="position:fixed;top: 0;width: 100%"
                title="产品详情" @onBackClick="onBackClick"/>

      <!--      <div style="position: fixed;z-index: 100;right:0;top: 0px;padding: 14px" @click="share">分享</div>-->
    </div>

    <div style="margin-top: 46px;">
      <div v-if="error" style="width: 100%;text-align: center;margin-top: 32px">
        商品编号有误，找不到该商品(id:{{ goodId }})
      </div>
      <div v-else>
        <!--  loading  vant  -->
        <van-loading v-if="loading" color="#1989fa" size="24px" style="text-align: center;margin-top: 74px"/>
        <div v-else>
          <div style="width: 100%;">
            <div v-if="distributor" style="text-align: center;background-color: #ec6b00;color: white">{{
                distributor.name
              }}与您分享严选康复用品~
            </div>
            <div style=";padding-bottom: 16px; background-color: #88a98a">
              <!--    vant image 宽度占满，高度自适应          -->
              <van-image :src="goodDetail.bigPicUrl" style="display: block" width="100%"/>

              <div style="height: 12px"/>
              <!--      产品标题和简介模块          -->
              <div class="product-info">
                <!--       价格 模块，现价 + 原价（删除线）      -->
                <div style="display: flex;align-items:center;padding: 8px 16px;width: 100%">
                  <div
                    style="font-size: 24px;color: #c20000;font-weight: bold;">
                    ￥{{ goodDetail.price }}
                  </div>
                  <div style="margin-left: 16px;font-size: 18px;color: #8f8f8f;text-decoration: line-through">￥{{
                      goodDetail.originPrice
                    }}
                  </div>
                </div>
                <div class="product-title">
                  {{ goodDetail.name }}
                </div>
                <div class="product-intro">
                  {{ goodDetail.brief }}
                </div>
              </div>
            </div>
            <!--     图片列表       -->
            <div style="margin-bottom: 56px">
              <div v-for="(item, index) in goodDetail.detailPics" :key="index">
                <img :src="item" style="  display: block;width: 100%;"/>
              </div>
            </div>
            <van-goods-action>
              <van-goods-action-icon icon="chat-o" text="客服" @click="onClickKefu"/>
              <!--              <van-goods-action-icon icon="cart-o" text="购物车" @click="onClickIcon"/>-->
              <van-goods-action-icon v-if="showMoreButton" icon="shop-o" text="更多辅具" @click="onClickHome"/>
              <div style="padding-left: 16px;padding-right: 16px;color: #3d3d3d">库存：{{ goodDetail.stock }}</div>
              <div v-if="isDistributor"
                   style="flex: 1;display: flex;flex-direction: row;justify-content: space-around">
                <div
                  style="text-align: center;background-color: #ff7300;color: white;padding: 8px 16px;border-radius: 8px"
                  @click="onDistributeClick"
                >分享链接
                </div>
                <div
                  style="text-align: center;background-color: #ff4400;color: white;padding: 8px 16px;border-radius: 8px"
                  @click="onDistributePicClick"
                >分享图片
                </div>
              </div>
              <van-goods-action-button v-else
                                       :text="goodDetail.stock <= 0?'已售罄':'立即购买'"
                                       type="danger"
                                       @click="onClickBuy"
              />
            </van-goods-action>
          </div>
        </div>
      </div>

    </div>
    <van-popup v-model="showKefu" position="bottom">
      <img
        alt="图片"
        src="https://cdn.keihong.tech/img/202309072319723.png"
        width="100%"
      />
    </van-popup>
    <van-popup v-model="showSku" position="bottom"
               style="min-height: 50vh;border-top-left-radius: 16px;border-top-right-radius: 16px">
      <div style="padding: 16px">
        <div v-for="(optionCategory,index) in  goodDetail.options" :key="index">
          <div>
            <span>请选择 - </span><span>{{ optionCategory.optionCategoryName }}：</span>
          </div>
          <div>
            <van-tag v-for="(option, index) in optionCategory.options" :key="index" v-model="option.selected"
                     :type="option.selected ? 'success' : 'default'"
                     style="margin-left: 4px" @click="selectSku(option)">{{
                option.name
              }}
            </van-tag>
          </div>
          <van-goods-action-button
            text="立即购买"
            type="danger"
            @click="onSkuClickBuy"
          />
        </div>
      </div>

    </van-popup>
    <div v-if="isDistributor"
         style="position: fixed;z-index: 10;top: 0px;right: 0px;background-color: #ff8000;color: white">
      分销者模式
    </div>
    <van-popup v-model="showPopupGenPic" closeable position="bottom" style="height: 90%">
      <div>
        <div style="height: 48px;line-height: 48px;font-size: 18px;padding-left: 16px">
          {{ drawFinished ? '长按或右击图片保存到相册或转发' : '生成中' }}
        </div>
        <canvas v-if="!drawFinished" ref="canvas" height="500" width="500"></canvas>
        <img v-else :src="imgUrl" :style="{with:imgWith,height:imgHeight}"/>
        <!--            <button @click="downloadImage">下载图片</button>-->
      </div>
    </van-popup>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import MallDao from '@/h5/doucan/network/MallDao'
import { Toast } from 'vant'
import DistributionDao from '@/h5/doucan/pages/distribution/DistributionDao'
import QRCode from 'qrcode'

export default {
  name: 'MallDetailPage',
  components: { MyNavBar },
  data () {
    return {
      loading: true,
      isArticle: false,
      isMallList: false,
      isKepulist: false,
      goodId: '',
      error: false,
      goodDetail: null,
      showKefu: false,
      showMoreButton: true,
      showSku: false,
      currentSelectedOption: null,
      distributor: null,
      isDistributor: false,
      showPopupGenPic: false,
      imageSrc: 'https://cdn.keihong.tech/img/202406071745097.png', // 底图路径
      drawFinished: false,
      imgUrl: '',
      imgWith: '',
      imgHeight: '',
      imgName: ''
    }
  },
  mounted () {
    if (this.$store.state.user.qrCodeHospital === '中国民间中医医药研究开发协会中蒙民族医药研究发展分会') {
      this.showMoreButton = false
    }
    if (this.$route.query.article) {
      this.isArticle = true
    }

    this.goodId = this.$route.query.goodId
    if (!this.goodId) {
      this.error = true
      return
    }

    if (this.$route.query.from === 'malllist') {
      this.isMallList = true
    }
    if (this.$route.query.from === 'kepulist') {
      this.isKepulist = true
    }

    if (this.$route.query.distributor) {
      this.isDistributor = true
      this.distributor = this.$store.state.distributor.data
      console.log('分销者模式', this.distributor)
    }

    if (this.$route.query.distributeUserId) {
      // console.log('loadDistributor', this.$route.query.distributeUserId)
      const uid = this.$route.query.distributeUserId
      DistributionDao.loadUser(uid).then(res => {
        console.log('loadDistributor', res)
        this.distributor = res
      })
    }

    this.getGoodDetail()
  },
  methods: {
    onDistributeClick () {
      // https://doucan.jianerkanggz.cn/#/MallDetailPage?goodId=71&article=true
      const baseUrl = 'https://doucan.jianerkanggz.cn'
      // const baseUrl = 'http://192.168.0.119:8094/doucan'
      const shareText = `${this.distributor.name} 为您挑选了一款康复用品【${this.goodDetail.name}】，点击链接看一看：${baseUrl}/#/MallDetailPage?goodId=${this.goodId}&article=true&distributeUserId=${this.distributor.id}`
      this.$copyText(shareText).then(e => {
        console.log('剪切板', e)
        this.$dialog({
          title: '复制成功，发送给好友吧',
          message: shareText,
          confirmText: '确认',
          messageAlign: 'left'
        })
      }, function (e) {
        console.log(e)
      })
    },
    onDistributePicClick () {
      // https://doucan.jianerkanggz.cn/#/MallDetailPage?goodId=71&article=true
      const baseUrl = 'https://doucan.jianerkanggz.cn'
      // const baseUrl = 'http://192.168.0.119:8094/doucan'
      const shareText = `${this.distributor.name} 为您挑选了一款康复用品【${this.goodDetail.name}】，点击链接看一看：${baseUrl}/#/MallDetailPage?goodId=${this.goodId}&article=true&distributeUserId=${this.distributor.id}`
      let picGoodName = this.goodDetail.name
      if (picGoodName.length > 25) {
        picGoodName = picGoodName.substring(0, 20) + '...'
      }

      const shareTextPic = `${this.distributor.name} 为您挑选了一款康复用品【${picGoodName}】，扫描下方二维码可查看详情哦~`
      this.$copyText(shareText).then(e => {
        console.log('剪切板', e)
        // this.$dialog({
        //   title: '复制成功，发送给好友吧',
        //   message: shareText,
        //   confirmText: '确认',
        //   messageAlign: 'left'
        // })
        const goodUrl = `${baseUrl}/#/MallDetailPage?goodId=${this.goodId}&article=true&distributeUserId=${this.distributor.id}`
        this.genPic(shareTextPic, this.goodDetail.name, this.goodDetail.bigPicUrl, goodUrl)
      }, function (e) {
        console.log(e)
      })
    },
    genPic (code, goodName, goodPoster, goodUrl) {
      const _that = this
      this.drawFinished = false
      console.log('生成图片', code)
      this.showPopupGenPic = true
      this.$nextTick(() => {
        // loadImage
        // const ctx = this.$refs.canvas.getContext('2d')
        const img = new Image()
        img.crossOrigin = 'anonymous'
        img.onload = () => {
          const canvas = this.$refs.canvas
          if (!canvas) return
          const dpr = window.devicePixelRatio || 1 // 获取设备的像素比率

          // 设置canvas的宽度和高度为图片的宽度和高度
          console.log('img', img.width, img.height)
          const screenWidth = window.innerWidth // 获取屏幕宽度
          const scale = screenWidth / img.width // 计算缩放比例
          // 设置canvas的宽度为屏幕宽度，高度为图片高度乘以缩放比例
          canvas.width = screenWidth * dpr // 内部大小为屏幕宽度乘以设备像素比率
          canvas.height = img.height * scale * dpr // 同理
          console.log('canvas', canvas.width, canvas.height)
          canvas.style.width = screenWidth + 'px' // CSS大小为屏幕宽度
          canvas.style.height = (img.height * scale) + 'px' // CSS高度按比例
          this.imgHeight = canvas.style.height
          this.imgWith = canvas.style.width

          // 绘制图片
          const ctx = canvas.getContext('2d')
          ctx.scale(dpr, dpr) // 根据设备像素比例缩放画布
          ctx.drawImage(img, 0, 0, screenWidth, img.height * scale) // 重绘图片以适应新尺寸
          // drawText
          ctx.font = '18px Arial'
          ctx.fillStyle = 'black' // 文字颜色
          // 确定文字位置， 在UI设计稿上确定 设计稿宽度、高度，文字的坐标位置，按比例换算x，y
          // 1080 图片实际宽度px；1373 图片实际高度px
          // 220 在原图下文字左下角的x坐标 px
          // 1123 在原图下文字左下角的y坐标 px
          const x1 = Math.round(90 * screenWidth / img.width)
          const y1 = Math.round(240 * img.height * scale / img.height)
          const maxWidth1 = Math.round(900 * screenWidth / img.width)
          this.wrapText(ctx, code, x1, y1, maxWidth1, 28)
          const x2 = Math.round(90 * screenWidth / img.width)
          const y2 = Math.round(1230 * img.height * scale / img.height)
          const maxWidth2 = Math.round(900 * screenWidth / img.width)
          this.wrapText(ctx, `康复产品：“ ${goodName} ”`, x2, y2, maxWidth2, 28)

          const imgGood = new Image() // 创建一个新的Image对象
          imgGood.crossOrigin = 'anonymous'
          imgGood.onload = function () {
            const goodWitdh = Math.round(680 * screenWidth / img.width)
            const x3 = Math.round(200 * screenWidth / img.width)
            const y3 = Math.round(470 * img.height * scale / img.height)
            ctx.drawImage(imgGood, x3, y3, goodWitdh, goodWitdh) // 当图像加载完毕后，在Canvas上绘制它

            console.log('商品url', goodUrl)
            // 生成二维码并在Canvas上指定位置绘制
            const qrWitdh = Math.round(260 * screenWidth / img.width)
            QRCode.toDataURL(goodUrl, {
              margin: 1,
              width: qrWitdh,
              color: {
                dark: '#000', // 二维码颜色
                light: '#fff0' // 背景色透明
              }
            }).then((url) => {
              const qrImg = new Image()
              qrImg.onload = () => {
                // 设置绘制位置和大小
                const x4 = Math.round(411 * screenWidth / img.width)
                const y4 = Math.round(1424 * img.height * scale / img.height)
                ctx.drawImage(qrImg, x4, y4, qrWitdh, qrWitdh)
                console.log('二维码绘制结束')
                // 将canvas转换成base64，可通过img的src传入呈现图片
                _that.imgUrl = canvas.toDataURL('image/png')
                console.log('canvas转换成base64结束')
                // this.imgName = '兑换码-' + code
                _that.drawFinished = true
                console.log('绘制结束')
              }
              qrImg.src = url
            }).catch(err => {
              console.error('Error generating QR code:', err)
            })
          }
          imgGood.onerror = function () {
            console.error('无法加载图片: ' + imgGood.src)
          }
          console.log('imageSrc', goodPoster)
          imgGood.src = goodPoster // 设置图像源路径

          // this.wrapText(ctx, code, x, y, 200, 28)
          // ctx.fillText(code, x, y) // 绘制文字的位置
        }
        img.src = this.imageSrc
      })
    },
    wrapText (context, text, x, y, maxWidth, lineHeight) {
      // const words = text.split(' ') // 将文本分割成单词数组
      let line = ''

      for (let n = 0; n < text.length; n++) {
        const testLine = line + text[n] + ''
        const metrics = context.measureText(testLine)
        const testWidth = metrics.width
        if (testWidth > maxWidth && n > 0) {
          context.fillText(line, x, y)
          line = text[n]
          y += lineHeight
        } else {
          line = testLine
        }
      }
      context.fillText(line, x, y)
    },
    onSkuClickBuy () {
      if (!this.currentSelectedOption) {
        this.$toast('请选择规格')
        return
      }
      console.log('currentSelectedOption', this.currentSelectedOption)
      this.showSku = false
      this.$router.push({
        path: '/MallOrderConfirmPage',
        query: {
          good: this.goodDetail,
          sku: this.currentSelectedOption,
          distributor: this.distributor
        }
      })
    },
    selectSku (option) {
      if (this.currentSelectedOption) {
        this.currentSelectedOption.selected = false
      }
      option.selected = true
      this.currentSelectedOption = option
      console.log('option', option)
    },
    async onClickBuy () {
      if (this.goodDetail.stock <= 0) {
        return Toast.fail('已售罄')
      }
      await this.getGoodDetail()
      console.log('更新库存：' + this.goodDetail.stock)
      if (this.goodDetail.stock <= 0) {
        return Toast.fail('已售罄')
      }
      //     this.$router.push('/MallOrderConfirmPage')

      if (this.goodDetail.options && this.goodDetail.options.length > 0) {
        this.goodDetail.options.forEach(optionCategory => {
          optionCategory.options.forEach(option => {
            console.log(option)
            this.$set(option, 'selected', false)
            // option.selected = false
          })
        })
        this.showSku = true
        return
      }

      this.$router.push({
        path: '/MallOrderConfirmPage',
        query: {
          good: this.goodDetail,
          distributor: this.distributor
        }
      })
    },
    onBackClick () {
      // 返回上一页
      const isBack = this.isMallList || this.isKepulist
      if (isBack) {
        this.$router.go(-1)
        return
      }
      if (this.isArticle) {
        // this.$router.replace('/home/square')
        this.$router.push('/ToolKepuHomePage')
        return
      }
      this.$router.push('/ToolKepuHomePage')
    },
    onClickKefu () {
      this.showKefu = true
    },
    onClickHome () {
      // 跳转到MallMainPage
      this.$router.replace('/MallMainPage')
    },
    async getGoodDetail () {
      this.goodDetail = await MallDao.findById(this.goodId)
      console.log('good detail', this.goodDetail)
      this.loading = false
      if (!this.goodDetail) {
        this.error = true
      }
      if (this.goodDetail.stock <= 0) {
        Toast.fail('已售罄')
      }
    },
    share () {
      console.log('share', this.goodDetail)
    }
  },
  watch: {
    drawFinished: {
      handler (newValue, oldValue) {
        console.log('drawFinished', newValue)
      }
    }
  },
  computed: {}
}
</script>
<style scoped>
.product-info {
  margin: 8px;
  background-color: #fffbf3;
  display: flex;
  box-shadow: 1px 1px 2px rgba(63, 63, 63, 0.6);
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px 16px;
}

.product-title {
  font-size: 20px;
  width: 100%;
  text-align: left;
  font-weight: bold;
  margin-bottom: 2px;
}

.product-intro {
  font-size: 16px;
  width: 100%;
  color: #505050;
  text-align: left;
}
</style>
