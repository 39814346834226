<template>
  <div>
    <base-title-bar-scroll-page ref="container" title="视频播放">
      <video :src="videoUrl" autoplay controls poster=""
             style="width: 100%;max-height: 93vh"></video>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'

export default {
  name: 'BaseVideoPlayPage',
  components: { BaseTitleBarScrollPage },
  data () {
    return {
      videoUrl: null
    }
  },
  mounted () {
    // console.log(this.$route.query)
    this.videoUrl = this.$route.query.videoUrl
    this.hideLoading()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
