import axios from 'axios'
import store from '@/h5/doucan/store'

const DakaDao = {
  async add (uid, videoUrl) {
    const url = `/dakaPatientRecord/daka?u_id=${uid}&url=${videoUrl}`
    const httpResp = await axios.get(url)
    const { data: resp } = httpResp
    console.log(`请求:${url}`, httpResp)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async load (userId) {
    const url = `/dakaPatientRecord/load?u_id=${userId}`
    const httpResp = await axios.get(url)
    const { data: resp } = httpResp
    console.log(`请求:${url}`, httpResp)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async loadByHospital () {
    const url = '/dakaPatientRecord/load/hospital'
    const { data: resp } = await axios.get(url, {
      headers: {
        token: store.state.loginAdmin.token
      }
    })
    // const { data: resp } = httpResp
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }
}
export default DakaDao
