// 用户模块
export default {
  namespaced: true,
  state () {
    return {
      profile: {
        serveType: 0,
        status: 1, // 申请状态，目前申请到第几步，是否已经申请过等；-1 未开始申请 0-申请成功结束 1-第一步 2-第二步 3-第三步 4-第五步
        mobile: '', // 后期mobile从用户授权获得，即订单记录支付者登录的微信账号手机号及实际接受服务患者的手机号
        patientName: '',
        patientMobile: '',
        orderType: '',
        fee: '',
        fromActivity: '',
        goods: '',
        openId: ''
      }
    }
  },
  mutations: {
    // 修改用户信息，payload就是用户信息对象
    update (state, payload) {
      state.profile = payload
    }
  }
}
// ————————————————
// 版权声明：本文为CSDN博主「run-Ameng」的原创文章，遵循CC 4.0 BY-SA版权协议，转载请附上原文出处链接及本声明。
// 原文链接：https://blog.csdn.net/m0_58861443/article/details/120247013
