<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar title="科普宣教-医生推送"/>
    <div style="text-align: center;padding: 16px">当前为演示模式</div>
    <div style="padding: 16px">
      <ListPageV3 :datas="datas"/>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../../components/MyNavBar'
import ListPageV3 from '@/h5/feeds/ListPageV3.vue'

export default {
  name: 'template',
  components: {
    ListPageV3,
    MyNavBar
  },
  data () {
    return {
      datas: [
        {
          browseNumber: 449,
          content: '',
          contentId: 323,
          cover: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1698658799965.jpg',
          del: 0,
          mallGoodsId: 17,
          mallUrl: '',
          picList: [],
          status: 0,
          summary: '',
          tagIdList: null,
          title: '肩托的使用指南',
          tool: true,
          type: 3,
          video: 'article/feeds/未知标题资讯/Mon-Oct-30-2023/肩部肱骨骨折固定带  视频.mp4'
        }, {
          browseNumber: 0,
          content: '',
          contentId: 318,
          cover: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1698051024319.jpg',
          del: 0,
          mallGoodsId: 0,
          mallUrl: '',
          picList: [],
          status: 0,
          summary: '',
          tagIdList: null,
          title: '患者在家中该如何进行自我康复治疗？',
          tool: false,
          type: 3,
          video: 'article/feeds/未知标题资讯/Mon-Oct-23-2023/患者在家中该如何进行自我康复治疗？(1).mp4'
        }, {
          browseNumber: 0,
          content: '',
          contentId: 310,
          cover: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1695379462875.jpg',
          del: 0,
          mallGoodsId: 0,
          mallUrl: '',
          picList: [],
          status: 0,
          summary: '',
          tagIdList: null,
          title: '什么是肩周炎？如何治疗？',
          tool: false,
          type: 3,
          video: 'article/feeds/未知标题资讯/Fri-Sep-22-2023/16什么是肩周炎？如何治疗？.mp4'
        }

      ]
    }
  },
  mounted () {

  },
  methods: {},
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
