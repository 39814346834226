import { render, staticRenderFns } from "./MyEmptyView.vue?vue&type=template&id=181a9a34&scoped=true"
import script from "./MyEmptyView.vue?vue&type=script&lang=js"
export * from "./MyEmptyView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "181a9a34",
  null
  
)

export default component.exports