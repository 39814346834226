<template>
  <div>
    <base-title-bar-scroll-page ref="container" title="兑换记录">
      <div class="exchange-record-page">
        <!--        <van-nav-bar left-arrow title="兑换订单记录" @click-left="goBack"/>-->
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="fetchExchangeRecords"
          >
            <van-cell
              v-for="(record, index) in exchangeRecords"
              :key="index"
              :label="`兑换码: ${record.code.code} | 状态: ${record.code.status ? '已使用' : '未使用'} | 疾病：${record.user.sickKind.name}`"
              :title="`订单ID: ${record.id} | 兑换时间: ${formatDate(record.exchangeDate)}` "
              @click="showDetails(record)"
            />
          </van-list>
        </van-pull-refresh>
      </div>
    </base-title-bar-scroll-page>
    <van-popup v-model="showDetailsPopup" :style="{ height: '50%' }" position="bottom">
      <van-icon class="close-icon" name="cross" @click="showDetailsPopup=false"/>
      <div v-if="currentRecord" style="padding: 16px">
        <p>兑换码: {{ currentRecord.code.code }}</p>
        <p>兑换人手机号: {{ currentRecord.user.phone }}</p>
        <p>兑换时间: {{ currentRecord.exchangeDate | formatDate }}</p>
        <p>病种: {{ currentRecord.user.sickKind.name }}</p>
        <p>病情自报: {{ currentRecord.description }}</p>
      </div>
    </van-popup>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import axios from 'axios'

export default {
  name: 'AdminHospitalPatientInputList',
  components: { BaseTitleBarScrollPage },
  data () {
    return {
      showDetailsPopup: false,
      currentRecord: null,
      isLoading: false,
      loading: false,
      finished: false,
      exchangeRecords: [],
      enterpriseId: 1 // 假设企业ID是已知的或从其他页面传递过来
    }
  },
  filters: {
    formatDate (value) {
      const date = new Date(value)
      return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
    }
  },
  mounted () {
    this.enterpriseId = this.$route.query.enterpriseId
    console.log(this.enterpriseId)
    this.fetchExchangeRecords()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    showDetails (record) {
      this.currentRecord = record
      this.showDetailsPopup = true
    },
    formatDate (dateStr) {
      const date = new Date(dateStr)
      return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
    },
    onRefresh () {
      this.finished = false // 重置完成状态
      this.isLoading = true // 标记为下拉刷新操作
      // this.exchangeRecords = [] // 清空当前记录列表
      this.fetchExchangeRecords() // 重新获取数据
    },
    async fetchExchangeRecords () {
      if (this.finished) return
      if (this.loading && !this.isLoading) return // 如果正在加载且不是下拉刷新，则直接返回
      this.loading = true

      try {
        const response = await axios.get(`/api/enterprise/recordByEnterprise/${this.enterpriseId}`)
        console.log('兑换记录', response.data)
        this.hideLoading()
        if (this.isLoading) {
          // 如果是下拉刷新操作，重置记录列表
          this.exchangeRecords = response.data
        } else {
          // 如果是加载更多操作，追加到记录列表
          this.exchangeRecords = [...this.exchangeRecords, ...response.data]
        }
        // 假设这里一次加载所有记录，如果实现了分页，需要根据返回数据判断是否已加载所有记录
        this.finished = true
      } catch (error) {
        console.error('获取兑换订单记录失败:', error)
      } finally {
        this.loading = false
        this.isLoading = false
      }
    }

  },
  watch: {},
  computed: {}
}
</script>
<style scoped>
.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #ccc;
  z-index: 1;
}
</style>
