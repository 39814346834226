<template>
  <div>
    <base-title-bar-scroll-page ref="container" :show-title-bar="!isApp" title="录入患者基本信息">
      <!--   当前录入人：doctorName  -->
      <div style="display: flex;flex-direction: row;justify-content: space-between;padding: 16px">
        <div>当前录入人:</div>
        <div>{{ doctorName }}</div>
      </div>
      <div v-if="isApp" style="display: flex;flex-direction: row;justify-content: space-between;padding: 16px">
        <div>录入者手机号:</div>
        <div>{{ entryClerkMobile }}</div>
      </div>
      <div v-if="formData.createDate" style="text-align: center;font-size: 12px;color: #3d3d3d">录入时间：{{
          formData.createDate
        }}
      </div>
      <!--      <div>{{ doctorId }}</div>-->
      <van-form ref="form" :readonly="!canEdit" @submit="onSubmit">
        <van-field
          v-model="formData.patientName"
          :rules="formRules.patientName"
          label="患者姓名"
          placeholder="请输入患者姓名"
          required
        />
        <div v-if="phoneCheckMsg" :style="`background-color:${phoneCheckMsg.status?'#009900':'#993300'}`"
             style="padding: 8px;font-size: 12px;text-align: center;color: white">
          {{ phoneCheckMsg.msg }}
        </div>
        <van-field
          v-model="formData.phoneNumber"
          :rules="formRules.phoneNumber"
          label="联系手机(患者端登录账号)"
          maxlength="11"
          placeholder="请输入联系手机"
          required
          show-word-limit
          type="tel"
          @input="onPhoneInput"
        />
        <van-field
          v-model="formData.age"
          :rules="formRules.age"
          label="年龄"
          placeholder="请输入年龄"
          required
          type="number"
        />
        <van-field :rules="formRules.gender" label="性别" required>
          <template #input>
            <van-radio-group v-model="formData.gender" direction="horizontal">
              <van-radio name="男">男</van-radio>
              <van-radio name="女">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field :rules="formRules.sickKinds" label="病种(单选)" required>
          <template #input>
            <van-checkbox-group v-model="formData.sickKind" direction="horizontal" @change="onSickKindClick">
              <div v-for="(item,index) in sickKinds" :key="index">
                <van-checkbox :name="item.id">{{ item.name }}</van-checkbox>
              </div>
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field label="服务类型(可多选)">
          <template #input>
            <van-checkbox-group v-model="formData.serviceType" direction="horizontal">
              <van-checkbox name="中风康复">中风康复</van-checkbox>
              <van-checkbox name="疼痛康复">疼痛康复</van-checkbox>
              <van-checkbox name="脊柱康复">脊柱康复</van-checkbox>
              <van-checkbox name="骨科术后康复">骨科术后康复</van-checkbox>
              <van-checkbox name="运动损伤康复">运动损伤康复</van-checkbox>
              <van-checkbox name="脑损伤康复">脑损伤康复</van-checkbox>
              <van-checkbox name="截瘫康复">截瘫康复</van-checkbox>
              <van-checkbox name="照护服务">照护服务</van-checkbox>
              <!--           助浴服务、家政保洁服务，保姆服务服务、租赁服务、辅具指导服务   -->
              <van-checkbox name="助浴服务">助浴服务</van-checkbox>
              <van-checkbox name="家政保洁服务">家政保洁服务</van-checkbox>
              <van-checkbox name="保姆服务">保姆服务</van-checkbox>
              <van-checkbox name="租赁服务">租赁服务</van-checkbox>
              <van-checkbox name="辅具指导服务">辅具指导服务</van-checkbox>
              <van-checkbox name="其他康复服务">其他服务</van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field
          v-model="formData.address"
          label="住址"
          placeholder="请输入住址"
          type="textarea"
        />
        <van-field
          v-model="formData.idNumber"
          label="身份证号码"
          placeholder="请输入身份证号码"
        />
        <van-field
          v-model="formData.familyName"
          label="家属姓名"
          placeholder="请输入家属姓名"
        />
        <!--    单选，与患者的关系（配偶、父母、子女、孙子女、亲戚、护工、其他关系）    -->
        <van-field label="与患者的关系">
          <template #input>
            <van-radio-group v-model="formData.relationship" direction="horizontal">
              <van-radio name="配偶">配偶</van-radio>
              <van-radio name="父母">父母</van-radio>
              <van-radio name="子女">子女</van-radio>
              <van-radio name="孙子女">孙子女</van-radio>
              <van-radio name="(外)祖父母">(外)祖父母</van-radio>
              <van-radio name="亲戚">亲戚</van-radio>
              <van-radio name="护工">护工</van-radio>
              <van-radio name="其他关系">其他关系</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="formData.familyPhone"
          label="家属电话"
          placeholder="请输入家属电话"
          type="tel"
        />
        <van-field
          label="病历图片"
        >
          <template #input>
            <!--            <div style="color: #1989fa">上传病历图片</div>-->
            <van-uploader v-model="formData.medicalImages" :after-read="afterRead" label="病历图片"/>
          </template>
        </van-field>
        <van-field
          v-model="formData.medicalDescription"
          label="病历描述"
          placeholder="请输入病历描述"
          type="textarea"
        />
        <van-field
          v-model="formData.otherNotes"
          label="其他备注"
          placeholder="请输入其他备注"
          type="textarea"
        />
        <div v-if="canEdit" class="submit-button">
          <van-button native-type="submit" style="width: 100%" type="primary">提交</van-button>
        </div>
      </van-form>
    </base-title-bar-scroll-page>
    <van-popup v-model="showPopup" :close-on-click-overlay="canHideClickOverlay" :style="{ height: '50%' }"
               position="bottom">
      <div v-if="!finished" style="padding: 16px;text-align: center">正在上传资料，请勿离开本页面</div>
      <div v-else>
        <div style="padding: 16px;text-align: center">已成功录入患者</div>
        <div style="padding: 16px;text-align: center">请点击左上角返回按钮，退出本界面</div>
      </div>
      <!--   loading   -->
      <div v-if="!finished" style="text-align: center">
        <van-loading color="#1989fa" size="24px" type="spinner"/>
      </div>
      <div v-if="!finished&&formData.medicalImages.length>0" style="text-align: center;padding: 16px">正在上传第_{{
          currentUploadImageIndex + 1
        }}_张照片（{{ currentImagePercent }}%）
      </div>
    </van-popup>
  </div>
</template>

<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import DoctorInputPatientDao from '@/h5/doucan/network/DoctorInputPatientDao'
import RegisterDao from '@/h5/doucan/network/RegisterDao'
// import { Button, Checkbox, CheckboxGroup, Field, Form, Radio, RadioGroup, Uploader } from 'vant'

export default {
  name: 'NeimengPatientBaseInfoFormComponent',
  components: {
    BaseTitleBarScrollPage
  },
  data () {
    return {
      sickKinds: null,
      isWebClerk: false,
      isNewInput: false,
      patientId: '',
      phoneCheckMsg: '',
      canEdit: true,
      canHideClickOverlay: false,
      finished: false,
      showPopup: false,
      currentUploadImageIndex: 0,
      currentImagePercent: 0,
      isApp: false,
      doctorName: '',
      doctorId: '',
      entryClerkMobile: '',
      hospitalId: '',
      formData: {
        patientName: '',
        phoneNumber: '',
        age: '',
        gender: '',
        serviceType: [],
        address: '',
        idNumber: '',
        familyName: '',
        relationship: '',
        familyPhone: '',
        medicalImages: [],
        medicalDescription: '',
        otherNotes: '',
        sickKind: []
      },
      formRules: {
        patientName: [{
          required: true,
          message: '请填写患者姓名'
        }],
        phoneNumber: [{
          required: true,
          message: '请填写联系手机'
        }],
        age: [{
          required: true,
          message: '请填写年龄'
        }],
        gender: [{
          required: true,
          message: '请选择性别'
        }],
        serviceType: [{
          required: true,
          message: '请选择服务类型'
        }],
        sickKinds: [{
          required: true,
          message: '请选择病种'
        }],
        address: [{
          required: true,
          message: '请填写住址'
        }],
        idNumber: [{
          required: true,
          message: '请填写身份证号码'
        }],
        familyName: [{
          required: true,
          message: '请填写家属姓名'
        }],
        relationship: [{
          required: true,
          message: '请选择与患者的关系'
        }],
        familyPhone: [{
          required: true,
          message: '请填写家属电话'
        }],
        medicalImages: [{
          required: true,
          message: '请上传病历图片'
        }]
      }
    }
  },
  mounted () {
    this.loadSickKind()
    this.isWebClerk = this.$route.query.from === 'webEntry'
    this.isApp = this.$route.query.isApp === 'true'
    this.patientId = this.$route.query.patientId
    this.isNewInput = this.$route.query.isNewInput === 'true'
    this.$refs.container.stopLoading()
    if (this.isApp && this.isNewInput) {
      // app录入
      this.bridge()
      return
    }
    if (this.patientId) {
      // 患者端查看界面
      DoctorInputPatientDao.loadByPatientId(this.patientId).then(resp => {
        if (resp) {
          this.showData(resp)
        } else {
          this.$dialog({
            title: '提示',
            message: '当前患者没有录入的数据记录',
            confirmText: '确定'
          })
          this.doctorId = this.$route.query.doctorId
          this.doctorName = this.$route.query.doctorName
        }
      })
      return
    }

    if (this.$route.query.patient) {
      const patient = this.$route.query.patient
      this.showData(patient)
      return
    }

    if (this.isWebClerk) {
      console.log(this.$store.state.patientDataEntryClerk)
      this.doctorId = this.$store.state.patientDataEntryClerk.userId
      this.doctorName = this.$store.state.patientDataEntryClerk.name
      return
    }

    this.doctorId = this.$route.query.doctorId
    this.doctorName = this.$route.query.doctorName
  },
  methods: {
    onSickKindClick (event) {
      // console.log('onSickKindClick', event)
      console.log('onSickKindClick', this.formData.sickKind)
      // 保留最后一个
      if (this.formData.sickKind.length > 1) {
        this.formData.sickKind.shift()
      }
    },
    async loadSickKind () {
      var sickKinds = await RegisterDao.getSickKind()
      sickKinds.forEach(e => {
        e.selected = false
      })
      this.sickKinds = sickKinds
      console.log('sickKinds', sickKinds)
    },
    showData (patient) {
      this.canEdit = false
      patient.pics = JSON.parse(patient.pics)
      // 去除空字符串
      patient.pics = patient.pics.filter(item => item)
      // 转化为{ url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }格式
      patient.pics = patient.pics.map(item => {
        return {
          url: item
        }
      })
      console.log('this.$route.query.patient', patient)
      this.doctorId = patient.doctorId
      this.doctorName = patient.doctorName
      this.entryClerkMobile = patient.createDoctorPhone
      // patient就是LeaveHospitalPatientReq，转换为formData

      // patient.gmtCreate 格式化
      patient.gmtCreate = new Date(patient.gmtCreate).toLocaleString()

      this.formData = {
        patientName: patient.patientName,
        phoneNumber: patient.phone,
        age: patient.age,
        gender: patient.sex === 1 ? '男' : '女',
        serviceType: patient.serviceType.split(','),
        address: patient.address,
        idNumber: patient.idCardNo,
        familyName: patient.familyName,
        relationship: patient.relationship,
        familyPhone: patient.familyPhone,
        medicalImages: patient.pics,
        medicalDescription: patient.medicalRecord,
        otherNotes: patient.otherRemark,
        createDate: patient.gmtCreate
      }
    },
    async onPhoneInput (value) {
      // console.log('onPhoneInput', value)
      // 字数
      const len = value.length
      if (len === 11) {
        console.log('手机号码输入完成')
        this.phoneCheckMsg = {
          status: false,
          msg: '正在检查手机号码是否已被注册'
        }
        const msg = await DoctorInputPatientDao.checkPhone(value, this.doctorId)
        this.phoneCheckMsg = msg
        console.log('手机号码检查结果', msg)
      }
    },
    async afterRead (file) {
      console.log('afterRead', file)
      // const result = await this.uploadToAliyun(file.file, 0)
      // const videourl = result.res.requestUrls[0].substring(0, result.res.requestUrls[0].indexOf('?'))
      // console.log('【视频URL】', videourl)
    },
    async onSubmit () {
      if (!this.phoneCheckMsg.status) {
        // 手机号已注册或录入，不可再次添加
        await this.$dialog({
          title: '提示',
          message: this.phoneCheckMsg.msg + '，当前手机号不可再录入',
          confirmText: '确定'
        })
        return
      }
      console.log('submit', this.formData)
      this.showPopup = true
      if (this.formData.medicalImages.length > 0) {
        for (let i = 0; i < this.formData.medicalImages.length; i++) {
          const file = this.formData.medicalImages[i].file
          // const data = this.formData.medicalImages[i]
          const result = await this.uploadToAliyun(file, i)
          this.formData.medicalImages[i].url = result.res.requestUrls[0].substring(0, result.res.requestUrls[0].indexOf('?'))
        }
      }
      console.log('图片上传完成', this.formData.medicalImages)

      const LeaveHospitalPatientReq = {
        patientId: this.patientId,
        doctorId: this.doctorId,
        patientName: this.formData.patientName,
        phone: this.formData.phoneNumber,
        sex: this.formData.gender === '男' ? 1 : 2,
        age: this.formData.age,
        pics: this.formData.medicalImages.map(image => image.url),
        address: this.formData.address,
        idCardNo: this.formData.idNumber,
        serviceType: this.formData.serviceType.join(','),
        sickKind: this.formData.sickKind[0],
        familyName: this.formData.familyName,
        relationship: this.formData.relationship,
        familyPhone: this.formData.familyPhone,
        medicalRecord: this.formData.medicalDescription,
        otherRemark: this.formData.otherNotes,
        entryChannel: this.isWebClerk ? 2 : 1,
        entryClerkUserId: this.doctorId,
        hospitalId: this.isWebClerk ? this.$store.state.patientDataEntryClerk.hospitalId : null
      }

      const resp = await DoctorInputPatientDao.inputPatient(LeaveHospitalPatientReq)
      console.log('上传录入患者 resp', resp)

      if (resp) {
        this.finished = true
        this.canHideClickOverlay = true
      }

      // this.$refs.form.validate(valid => {
      //   console.log('valid', valid)
      //   if (valid) {
      //     // 提交表单
      //   } else {
      //     // 处理错误
      //     console.log('有必填项未填')
      //   }
      // })
    },
    async uploadToAliyun (file, index) {
      this.currentUploadImageIndex = index
      const _that = this
      const OSS = require('ali-oss')
      const ossConfig = {
        // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
        region: 'oss-cn-shenzhen',
        // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
        accessKeyId: 'LTAIAcClE56I1mY0',
        accessKeySecret: 'IIPoiW0o3noCHU9bRT5vyAw0EDtem8',
        bucket: 'ihealthy'
      }
      const client = new OSS(ossConfig)
      // console.log(file)
      // 分片上传文件
      const s = `mr/${this.formData.patientName}/` + file.name
      console.log('上传文件', s)
      const ret = await client.multipartUpload(s, file, {
        progress: async function (p) {
          // console.log('multipartUpload', p)
          const e = {}
          e.percent = parseInt(p * 100)
          console.log(`上传进度:  第${index + 1}张图片 - ${e.percent} - `)

          _that.currentImagePercent = e.percent
          // option.onProgress(e)
        }
      })
      console.log('上传结果', ret)
      if (ret.res.statusCode === 200) {
        // option.onSuccess(ret)
        console.log('上传成功', ret)
        return ret
      } else {
        // vm.disabled = false
        // option.onError('上传失败')
        console.log('上传失败', ret)
        return null
      }
    },
    async bridge () {
      if (window.android && window.android.onBridgeSuccess) {
        var fromAndroid = await window.android.onBridgeSuccess('桥接成功 from 内蒙患者录入页')
        console.log('fromAndroid', fromAndroid)
        const params = JSON.parse(fromAndroid)
        this.doctorId = params.doctorId
        this.doctorName = params.doctorName
        // this.onMounted()
      } else {
        alert(typeof (window.android))
        // this.title = '桥接失败'
      }
    }
  }
}
</script>

<style scoped>
.submit-button {
  margin: 16px;
}

.van-radio, .van-checkbox {
  margin: 4px;
}
</style>
