<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar style="position:fixed;top: 0;width: 100%" title="辅具租赁订单确认"/>
    <div style="margin-top: 46px">
      <van-contact-card v-if="!addressAdded" add-text="填写收件地址" type="add" @click="onAdd"/>
      <div v-else>
        <div style="padding:16px">
          <!--   收件信息  第一行 姓名 手机号，第二行地址  -->
          <div style="display: flex;align-items: center">
            <div style="font-size: 18px;color: #0a4b3d;font-weight: bold">{{ mailInfo.name }}</div>
            <div style="margin-left: 16px;font-size: 18px;color: #0a4b3d">{{ mailInfo.phone }}</div>
          </div>
          <div style="margin-top: 4px;font-size: 18px;color: #0a4b3d">{{ mailInfo.province }}{{
              mailInfo.city
            }}{{ mailInfo.address }}
          </div>
        </div>
      </div>

      <div style="height: 16px;background-color: #e3e3e3"/>

      <div class="good-info">
        <div>商品</div>
        <!--   产品名称和价格，一行显示     -->
        <div style="display: flex;align-items: center;justify-content: space-between">
          <div style="font-size: 18px;color: #0a4b3d;font-weight: bold">{{ goodDetail.name }}</div>
          <div style="margin-left: 16px;font-size: 18px;color: #c20000;font-weight: bold">租赁费用：
            ￥{{ goodDetail.rentPrice }} 元 / 天
          </div>
        </div>
      </div>
      <div>
        <!--   购买数量     -->
        <div style="display: flex;align-items: center;justify-content: space-between;padding: 16px">
          <div style="font-size: 18px;">租赁天数</div>
          <div style="display: flex;align-items: center">
            <van-stepper v-model="buyCount" :show-minus="true" integer min="1" s:how-plus="true"/>
          </div>
        </div>
      </div>
      <!--   订单备注   -->
      <div style="padding: 16px 0">
        <van-field v-model="mark" autosize label="订单备注" placeholder="请输入尺码、左右脚等产品型号信息" rows="1"
                   style="font-size: 18px"/>
      </div>

      <!--      <div style="height: 16px;background-color: #e3e3e3"/>-->

      <!--   选择微信支付   -->
      <div style="padding: 16px 0">
        <van-cell-group>
          <van-cell title="支付方式" value="微信支付"/>
        </van-cell-group>
      </div>

      <!--   提交订单栏   -->
      <van-submit-bar :price="totalPrice" button-text="提交订单" @submit="onSubmit"/>

      <van-popup v-model="showAddAddr" position="bottom" style="width: 100%">
        <!--  使用vant设计一个收件人信息录入模块：姓名、手机号、省份、城市、详细地址-->
        <div style="width: 100%;padding: 16px;box-sizing: border-box">
          <van-form>
            <van-row>
              <van-col>
                <van-field v-model="mailInfo.name" :rules="[{
                    required: true,
                    message: '请填写',
                    trigger: 'onBlur'
                }]" label="姓名" placeholder="请输入收件人姓名"/>
              </van-col>
              <van-col>
                <van-field v-model="mailInfo.phone"
                           :rules="[{ validator: verifyPhone, message: '请输入正确的手机号码' }]"
                           label="手机号"
                           placeholder="请输入手机号"/>
              </van-col>
            </van-row>
            <van-row>
              <van-col>
                <van-field v-model="mailInfo.province" :rules="[{
                    required: true,
                    message: '请填写',
                    trigger: 'onBlur'
                }]" label="省份" placeholder="请输入省份"/>
              </van-col>
              <van-col>
                <van-field v-model="mailInfo.city" :rules="[{
                    required: true,
                    message: '请填写',
                    trigger: 'onBlur'
                }]" label="城市" placeholder="请输入所在城市"/>
              </van-col>
            </van-row>
            <van-row>
              <van-field v-model="mailInfo.address" :rules="[{
                    required: true,
                    message: '请填写',
                    trigger: 'onBlur'
                }]" label="详细地址" placeholder="请输入详细收件地址"/>
            </van-row>
            <van-button style="width: 100%" type="primary" @click="submitAddr">提交</van-button>
          </van-form>
        </div>
      </van-popup>
      <van-popup v-model="showContract" close-icon="https://cdn.keihong.tech/img/202311181839262.png" closeable
                 position="bottom"
                 style="width: 100%">
        <div style="height: 85vh">
          <div style="text-align: center;padding: 16px">电子租赁合同</div>
          <div style="height: 70vh;overflow-y: scroll">
            <div>正式合同更正制作中，敬请期待</div>
            <img src="https://cdn.keihong.tech/img/202311181843883.png" style="width: 100%;display: block"/>
            <img src="https://cdn.keihong.tech/img/202311181843883.png" style="width: 100%;display: block"/>
          </div>
          <!--     我已同意《电子租赁合同》     -->
          <div style="display: flex;justify-content: center;align-items: center;padding: 16px">
            <!--            <van-icon name="circle"/>-->
            <van-button type="danger" @click="handleClickAcceptContract">我已同意《电子租赁合同》</van-button>
          </div>

        </div>
      </van-popup>
      <van-popup v-model="showSign" close-icon="https://cdn.keihong.tech/img/202311181839262.png" closeable>
        <div style="height: 85vh">
          <vue-esign ref="esign"
                     height="70vh" style="border: 1px solid #ddd;"
                     width="100vw"/>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import MallDao from '@/h5/doucan/network/MallDao'
import Vue from 'vue'
import vueEsign from 'vue-esign'

Vue.use(vueEsign)
export default {
  name: 'MallRentOrderConfirmPage',
  components: { MyNavBar },
  data () {
    return {
      showAddAddr: false,
      addressAdded: false,
      mailInfo: {
        name: '',
        phone: '',
        province: '',
        city: '',
        region: '-1',
        address: '',
        id: -1
      },
      goodDetail: null,
      buyCount: 1,
      mark: '',
      addressId: -1,
      showContract: false,
      showSign: false

    }
  },
  mounted () {
    this.goodDetail = this.$route.query.good
    console.log('goodDetail-1', this.goodDetail)
    this.mailInfo = this.$store.state.mall.addressInfo
    this.addressId = this.$store.state.mall.addressInfo.id
    if (this.addressId !== -1) {
      this.addressAdded = true
    }
  },
  methods: {
    handleClickAcceptContract () {
      this.showContract = false
      this.onConfirm()
      // this.showSign = true
    },
    verifyPhone (val) {
      return /^1\d{10}$/.test(val) // 返回true或false
    },
    onAdd () {
      this.showAddAddr = true
    },
    async submitAddr () {
      if (!this.mailInfo.name) {
        this.$toast('请填写姓名')
        return
      }
      if (!this.mailInfo.phone) {
        this.$toast('请填写手机号')
        return
      }
      if (!this.mailInfo.province) {
        this.$toast('请填写省份')
        return
      }
      if (!this.mailInfo.city) {
        this.$toast('请填写城市')
        return
      }
      if (!this.mailInfo.address) {
        this.$toast('请填写详细地址')
        return
      }

      console.log('收件信息', this.mailInfo)
      // 提交收件信息到服务器
      const id = await MallDao.addAddress(this.mailInfo)
      this.addressId = id.data
      this.mailInfo.id = this.addressId
      this.$store.commit('mall/setAddressInfo', this.mailInfo)
      this.addressAdded = true
      this.showAddAddr = false
    },
    async onSubmit () {
      if (!this.addressAdded) {
        this.$toast('请填写收件地址')
        return
      }
      this.showContract = true
    },
    async onConfirm () {
      const payInfo = {
        orderType: 'rent',
        goodId: this.goodDetail.id,
        count: this.buyCount,
        remark: this.mark,
        addressId: this.addressId,
        totalPrice: this.totalPrice / 100,
        phone: this.mailInfo.phone,
        goodName: this.goodDetail.name + '*' + this.buyCount
      }
      console.log('payInfo', payInfo)
      this.$router.push({
        path: '/PayPage',
        query: {
          payInfo: payInfo,
          mailInfo: this.mailInfo
        }
      })
    }
  },
  watch: {},
  computed: {
    totalPrice () {
      return this.goodDetail.rentPrice * this.buyCount * 100
    }
  }
}
</script>
<style scoped>
.good-info {
  padding: 16px;

}
</style>
