<template>
  <div>
    <van-nav-bar
      title="完善信息"
    />
    <!--  手机号、短信验证码登录表单模块，使用vant  -->
    <van-field
      v-model="name"
      label="姓名"
      placeholder="请输入姓名"
      type="text"
    />
    <!--  登录按钮  -->
    <div style="padding: 16px">
      <van-button block type="primary" @click="handleClick">下一步</van-button>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant'
import DistributionDao from '@/h5/doucan/pages/distribution/DistributionDao'

export default {
  name: 'DistributionRegisterPage',
  data () {
    return {
      disableBtn: false,
      name: '',
      user: null
    }
  },
  created () {
    this.user = this.$route.query.user
    console.log('user', this.user)
  },
  methods: {
    handleClick () {
      if (!this.name) {
        Notify('姓名不能为空')
        return
      }
      console.log('name', this.name)
      this.user.name = this.name
      DistributionDao.updateName(this.user).then((res) => {
        console.log('updateName', res.data)
        if (res.code === 200) {
          this.$store.commit('distributor/setData', res.data)
          this.$router.push({
            path: '/DistributionUserPage'
          })
        } else {
          Notify('失败:' + res.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.sms-btn {
  padding: 8px;
  width: 100px;
  height: 28px;
  background-color: #e8e8e8;
  color: #333333;
  text-align: center;
  font-size: 14px;
  line-height: 28px;
  font-weight: bolder;
  cursor: pointer;
}

.sms-btn-disabled {
  background-color: #cccccc;
  color: #999999;
  cursor: not-allowed;
}
</style>
