<template>
  <div>
    <MyNavBar :title="'服务机构选择'"/>
    <div v-if="showOne">
      <!--   您当前所在的医院机构:  selectHospital.name  -->
      <div style="padding: 16px">
        <div>
          您当前所在的机构如下，是否正确？
        </div>
        <div
          :style="selectHospital.selected?'background-color: #00b667;color:white':'background-color: #f6f6f6;color:black'"
          style="padding: 16px;text-align: center;font-size: 20px;margin-top: 8px;border-radius: 8px">
          {{ selectHospital.name }}
        </div>
        <!--   正确和错误的按钮     -->
        <div style="display: flex;justify-content: space-between;margin-top: 16px">
          <div
            style="width: 48%;height: 48px;border-radius: 24px;background-color: #f6f6f6;text-align: center;line-height: 48px;font-size: 20px;color: #00b667"
            @click="submit">
            正确
          </div>
          <div
            style="width: 48%;height: 48px;border-radius: 24px;background-color: #f6f6f6;text-align: center;line-height: 48px;font-size: 20px;color: #00b667"
            @click="onReSelect()">
            错误
          </div>
        </div>
      </div>
    </div>
    <div v-else style="padding: 16px">
      <div style="font-size: 18px;margin-bottom: 16px">请选择您所在的医院机构</div>
      <div v-for="(item) in hospitals" :key="item.inviteCode">
        <div
          :style="item.selected?'background-color: #00b667':'background-color: #f6f6f6'"
          style="padding: 16px;text-align: center;font-size: 20px;margin-top: 8px;border-radius: 8px"
          @click="onHospitalClick(item)">
          {{ item.name }}
        </div>
      </div>
      <div style="height: 80px"></div>
      <div
        style="position: fixed;bottom: 16px;left:16px;right:16px;border-radius: 24px;box-sizing: border-box;display:block;padding: 16px;background-color: #0d3d3d;color: white;margin-top: 32px;text-align: center;font-size: 18px"
        @click="submit">
        下一步
      </div>
    </div>
  </div>
</template>

<script>
import MyNavBar from '../../components/MyNavBar'
import RegisterDao from '../../network/RegisterDao'
import { Notify } from 'vant'

export default {
  name: 'HospitalSelectPage',
  components: { MyNavBar },
  data () {
    return {
      showOne: false,
      loading: true,
      hospitals: [],
      selectHospital: null,
      qrHospital: ''
    }
  },
  mounted () {
    // 读取store中的医院
    this.qrHospital = this.$store.state.user.qrCodeHospital
    console.log('qrHospital', this.qrHospital)
    this.loadHospital()
  },
  methods: {
    onReSelect () {
      this.showOne = false
      this.selectHospital.selected = false
      this.selectHospital = null
    },
    async loadHospital () {
      const registerHospital = await RegisterDao.getRegisterHospital()
      console.log('医院选项', registerHospital)
      if (this.qrHospital) {
        for (const registerHospitalKey in registerHospital) {
          // 判断registerHospital[registerHospitalKey]字符串是否包含qrHospital字符串
          if (registerHospital[registerHospitalKey].name.indexOf(this.qrHospital) !== -1) {
            this.selectHospital = registerHospital[registerHospitalKey]
            this.selectHospital.selected = true
            this.showOne = true
            break
          }
        }
      }
      this.hospitals = registerHospital
    },
    onHospitalClick (hospital) {
      if (this.selectHospital) {
        this.selectHospital.selected = false
      }
      hospital.selected = true
      this.selectHospital = hospital
    },
    submit () {
      if (!this.selectHospital) {
        Notify('请选择一家机构')
        return
      }
      this.$store.commit('login/setHospital',
        {
          inviteCode: this.selectHospital.inviteCode,
          registHospitalId: this.selectHospital.id,
          hospitalId: this.selectHospital.hospitalId
        })

      console.log('注册信息', this.$store.state.login)
      this.$router.push('/login/doctorSelectPage')
    }
  }
}
</script>

<style scoped>

</style>
