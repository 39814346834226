import axios from 'axios'

const PlanDao = {
  async loadList (phone) {
    const url = `/assessPlan/find?phone=${phone}`
    const httpResp = await axios.get(url)
    const { data: resp } = httpResp
    console.log(`请求:${url}`, httpResp)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async loadPlanById (planId) {
    const url = `/assessPlan/findone?id=${planId}`
    const httpResp = await axios.get(url)
    const { data: resp } = httpResp
    console.log(`请求:${url}`, httpResp)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async loadCourse (courseId) {
    const url = `/course_and_tag/id?courseId=${courseId}`
    const httpResp = await axios.get(url)
    const { data: resp } = httpResp
    console.log(`请求:${url}`, httpResp)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async loadCourseForApp (courseId) {
    const url = `/course/detail_by_id?courseId=${courseId}`
    const httpResp = await axios.get(url)
    const { data: resp } = httpResp
    // console.log(`请求:${url}`, httpResp)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }
}
export default PlanDao
