<template>
  <!-- 居家康复学组专栏 -->
  <div style="width: 100%; box-sizing: border-box">
    <my-nav-bar title="康复直播" />
    <iframe
      id="“frame”"
      src="https://wx.vzan.com/live/livedetail-265220304"
      style="width: 100%; border: 0px; margin: 0; display: none"
    ></iframe>
  </div>
</template>

<script>
import MyNavBar from '../../components/MyNavBar'

export default {
  name: 'KepuLive',
  components: { MyNavBar }
}
</script>

<style scoped>
</style>
