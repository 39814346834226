import axios from 'axios'

const PatientDataEntryClerkDao = {
  // 首页从0开始
  async login (mobile) {
    const url = `/leaveHospitalPatient/dataEntryClerk?mobile=${mobile}`
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async register (req) {
    const url = '/leaveHospitalPatient/dataEntryClerk'
    const { data: resp } = await axios.post(url, req)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async patientList (page, pageSize) {
    const url = `cms/patient/list?page=${page}&pageSize=${pageSize}`
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }
}
export default PatientDataEntryClerkDao
