<template>
  <div class="training-frequency">
    <div class="header">
      <div class="frequency">{{ frequencyDescription }}</div>
    </div>

    <!--    <div class="time-selector">-->
    <!--      <van-radio-group v-model="selectedTime">-->
    <!--        <van-radio name="morning">上午</van-radio>-->
    <!--        <van-radio name="afternoon">下午</van-radio>-->
    <!--        <van-radio name="evening">晚上</van-radio>-->
    <!--      </van-radio-group>-->
    <!--    </div>-->

    <div class="duration-days-container">
      <div class="card">
        <div class="card-title">每次运动时长</div>
        <div class="card-value">{{ durationPerSession }}</div>
      </div>
      <div class="card">
        <div class="card-title">一周训练天数</div>
        <div class="card-value">{{ trainingDaysPerWeek }}</div>
      </div>
    </div>

    <div class="training-days">
      <div class="training-description">{{ trainingDescription }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TrainingTimeDetails',
  components: {},
  props: {
    frequencyDescription: {
      type: String,
      default: '一天训练2次： 上午、下午'
    },
    durationPerSession: {
      type: String,
      default: '30分钟'
    },
    trainingDaysPerWeek: {
      type: String,
      default: '6天'
    },
    trainingDescription: {
      type: String,
      default: '每逢 周一 周二 周三 周四 周五 周六 进行训练'
    }
  },
  data () {
    return {}
  }
}
</script>

<style scoped>
.training-frequency {
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.title {
  font-size: 16px;
  color: #333;
}

.frequency {
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #008b72;
}

.time-selector {
  margin-bottom: 16px;
}

.van-radio-group {
  display: flex;
  justify-content: space-between;
}

.van-radio {
  font-size: 14px;
}

.duration-days-container {
  display: flex;
  justify-content: space-between;
  gap: 16px; /* 卡片之间的间距 */
  margin-bottom: 16px;
}

.card {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  flex: 1 1 48%; /* 每个卡片的宽度占父容器的48%，确保有足够的间距 */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  text-align: center;
  max-width: 48%; /* 最大宽度为48% */
}

.card-title {
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
}

.card-value {
  font-size: 24px;
  font-weight: bold;
  color: #008b72;
}

/* 响应式调整：在小屏幕下减少卡片之间的间距，保持整体显示 */
@media (max-width: 768px) {
  .duration-days-container {
    gap: 8px; /* 缩小间距以适应小屏幕 */
  }

  .card {
    flex: 1 1 48%;
    max-width: 48%; /* 保持卡片比例不变 */
  }
}

.training-days {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.training-description {
  font-size: 14px;
  color: #008b72;
}
</style>
