<template>
  <div style="height: 100vh;width:100vw;position: relative">
    <van-nav-bar
      :title="name"
      left-arrow
      left-text="返回"
      @click-left="onClickLeft"
    />
    <div class="main">
      <div id="remote-player-main" style="background-color: #9d7070;height: 100%;width: 100%;position: fixed"></div>
      <div id="remote-playerlist"
           style="height: 100%;width:25vw;position: fixed;right: 0">
        <div v-for="(val,key) in remoteUsers" :key="key">
          <div :id="`player-wrapper-${val.uid}`" style="position: relative">
            <div
              style="background-color: white;position: absolute;top: 0;bottom: 0;left: 0;right: 0;text-align: center">
              <div style="position: absolute;top: 50%;margin-top: -28px;left:50%;margin-left:-24px;font-size: 20px">正在<br/>讲话
              </div>
            </div>
            <div :id="`player-${val.uid}`" class="player" style="width: 25vw;height:25vw;"></div>
            <!--              <p class="player-name">{{ `(${val.uid})` }}</p>-->
          </div>
        </div>
      </div>
      <div id="local-player"
           style="width: 30vw;height: 30vw;position: fixed;left: 0px;top:48px;background-color: #333333;border-radius: 16px"></div>
    </div>
    <div style="position: fixed;bottom: 10px;">{{ `信息：ios ver${iosVer}` }}</div>
  </div>
</template>

<script>
import AgoraRTC from 'agora-rtc-sdk-ng'
import MdtTokenDao from '../../network/MdtTokenDao'
import { AGORA_APPID, AGORA_CERTIFICATE } from '../../constant/agora_config'
import { Dialog } from 'vant'

export default {
  name: 'MdtVideo',
  data () {
    return {
      userAccount: '',
      token: '', // 根据房间号生成的token(房间号和token对应)
      channel: '', // 房间号(房间号和token对应)
      uid: null,
      name: '',
      isSafari: false,
      agoraClient: null, // 实例
      // 信道
      localTracks: {
        videoTrack: null,
        audioTrack: null
      },
      options: {},
      remoteUsers: {},
      volumeMax: null,
      isIos: false,
      iosVer: 0.0
    }
  },
  mounted () {
    const query = this.$route.query
    if (query) {
      // this.$store.commit('setIsApp', query.isApp)
      this.uid = parseInt(query.uid)
      this.channel = query.channel
      this.name = query.name + `(${this.channel})`
      console.log(this.name)
      this.userAccount = query.userAccount
    }
    if (query?.from === 'safari') {
      this.isSafari = true
    }
    this.getToken()
  },
  methods: {
    async onClickLeft () {
      // todo 关闭远程视频资源
      await this.Leave()
      console.log('isSafari', this.isSafari)
      if (!this.isSafari) {

      } else {
        Dialog.alert({
          title: '远程视频已结束',
          message: '苹果iPhone用户请点击状态栏左上角的【微信】返回主界面'
        }).then(() => {
          // on close
        })
      }
      this.$router.back()
    },
    async getToken () {
      var agroaToken = await MdtTokenDao.getAgroaToken(AGORA_APPID, AGORA_CERTIFICATE, this.channel, this.userAccount, this.uid)
      if (agroaToken) {
        console.log('获取token', agroaToken)
        this.token = agroaToken
        this.shartRTC()
      }
    },
    // 开始
    shartRTC () {
      // 创建本地客户端 AgoraRTC 的实例
      this.agoraClient = AgoraRTC.createClient({
        mode: 'rtc',
        codec: 'vp8'
      })
      // 用户信息
      this.options = {
        appid: AGORA_APPID,
        channel: this.channel,
        uid: this.uid,
        token: this.token
      }

      if (this.$store.state.device.infos.isIOS) {
        console.log('ios版本', this.$store.state.device.infos.iosVer)
        this.iosVer = this.$store.state.device.infos.iosVer

        if (this.iosVer >= 15.0 && this.iosVer <= 15.3) {
          console.log('ios版本 15.0 - 15.3')
          AgoraRTC.setParameter('REMOTE_AUDIO_TRACK_USES_WEB_AUDIO', true)
        }
      }

      // 调整麦克风采集音量
      AgoraRTC.createMicrophoneAudioTrack().then(localAudioTrack => {
        // // 麦克风音量减半
        // localAudioTrack.setVolume(50);
        // 麦克风音量增大一倍
        localAudioTrack.setVolume(200)
        // // 将麦克风音量设置为 0
        // localAudioTrack.setVolume(0);
      })
      // 连接
      this.join()
    },
    // 获取
    async join () {
      console.log('join-token', this.token)

      // 如果远端用户开启了双流模式，本地用户调用 subscribe 后会直接订阅本方法中 streamType 参数所指定的流类型。如不设置，默认订阅大流。
      // 订阅的视频流类型，0 代表大流，1 代表小流
      await this.agoraClient.setRemoteDefaultVideoStreamType(1)
      // 启用音量提示后，无论频道中有没有人说话，SDK 都会每两秒触发 AgoraRTCClient.on("volume-indicator") 回调
      this.agoraClient.enableAudioVolumeIndicator()
      this.agoraClient.on('volume-indicator', this.handleVolume)
      // 添加事件侦听器以在远程用户发布时播放远程曲目.
      this.agoraClient.on('user-published', this.handleUserPublished)
      this.agoraClient.on('user-unpublished', this.handleUserUnpublished);
      // 加入频道
      [this.uid, this.localTracks.audioTrack, this.localTracks.videoTrack] = await Promise.all([
        // join the channel
        this.agoraClient.join(AGORA_APPID, this.channel, this.token, this.uid),
        // 使用麦克风和摄像头
        AgoraRTC.createMicrophoneAudioTrack(),
        AgoraRTC.createCameraVideoTrack()
      ])

      // 将本地曲目发布到频道
      await this.agoraClient.publish(Object.values(this.localTracks))
      // 播放本地视频曲目
      this.localTracks.videoTrack.play('local-player')
    },
    handleVolume (result) {
      const _that = this
      console.log('音量回调', result)

      let max = null

      // 音量范围为 0 到 100 之间。通常在列表中音量大于 5 的用户为持续说话的人（官方文档）
      result.forEach(function (volume, index) {
        console.log(`${index} UID ${volume.uid} Level ${volume.level}`)
        console.log('_that.uid', _that.uid)
        if (volume.uid === _that.uid) {
          // 自己在讲话
          console.log('自己的声音，不比较大小')
          return
        }
        if (volume.level < 10) {
          console.log('噪音', `${index} UID ${volume.uid} Level ${volume.level}`)
          return
        }
        if (!max) {
          max = {
            index: index,
            uid: volume.uid,
            level: volume.level
          }
          return
        }
        if (volume.level > max.volume) {
          max = {
            index: index,
            uid: volume.uid,
            level: volume.level
          }
        }
      })
      if (!max) {
        console.log('没人在讲话')
        return
      }
      if (max.uid === _that.uid) {
        // 自己在讲话
        console.log('自己在讲话，不用管')
        return
      }
      if (_that.volumeMax && _that.volumeMax.uid === max.uid) {
        console.log('依旧是上一位讲话最大声')
        return
      }
      console.log('瞬间讲话最大声的人换了', max)
      // 恢复上一位的画面
      // 订阅小流
      if (_that.volumeMax) {
        this.agoraClient.setRemoteVideoStreamType(_that.volumeMax.uid, 1)
        this.remoteUsers[_that.volumeMax.uid].videoTrack.play(`player-${_that.volumeMax.uid}`)
      }
      // 切换画面
      _that.volumeMax = max
      this.agoraClient.setRemoteVideoStreamType(_that.volumeMax.uid, 0)
      this.remoteUsers[max.uid].videoTrack.play('remote-player-main')
    },
    handleUserPublished (user, mediaType) {
      console.log('远端用户-加入', user)
      console.log('远端用户-加入', mediaType)
      const id = user.uid
      this.$set(this.remoteUsers, id, user)
      // this.remoteUsers[id] = user
      this.subscribe(user, mediaType)
    },
    handleUserUnpublished (user, mediaType) {
      console.log('远端用户-unpublished', user)
      const id = user.uid
      // delete this.remoteUsers[id]
      if (mediaType === 'video') {
        this.$delete(this.remoteUsers, id)
        this.$toast('远端用户退出：' + user.uid)
      }
    },
    async subscribe (user, mediaType) {
      const uid = user.uid
      // 订阅远程用户
      await this.agoraClient.subscribe(user, mediaType)

      for (const key in this.remoteUsers) {
        console.log('远端用户列表', this.remoteUsers[key].uid)
      }

      if (mediaType === 'video') {
        // const player = `<div id="player-wrapper-${uid}">
        //     <p class="player-name">remoteUser(${uid})</p>
        //     <div id="player-${uid}" class="player" style="width: 150px;height: 200px;"></div>
        //   </div>`
        // const elementById = document.getElementById('remote-playerlist')
        // console.log('remote-playerlist', elementById)
        // // elementById.append(player)
        // elementById.innerHTML += player
        setTimeout(() => {
          user.videoTrack.play(`player-${uid}`)
          user.audioTrack.play()
        }, 200)
      }
      if (mediaType === 'audio') {
        user.audioTrack.play()
      }
    },
    // 客户离开信道
    async Leave () {
      if (this.localTracks.videoTrack) {
        this.localTracks.videoTrack.stop()
        this.localTracks.videoTrack.close()
      }
      if (this.localTracks.audioTrack) {
        this.localTracks.audioTrack.stop()
        this.localTracks.audioTrack.close()
      }
      // remove remote users and player views
      this.remoteUsers = {}
      // document.getElementById('remote-playerlist').html('')
      // leave the channel
      await this.agoraClient.leave()
      // document.getElementById('local-player-name').text('')
      console.log('客户离开信道成功')
    }
  }
}
</script>

<style scoped>
.main {
  position: absolute;
  background-color: #0d3d3d;
  top: 46px;
  bottom: 0;
  right: 0;
  left: 0
}

.player {
  height: 100px;
  width: 100px;
}

.player-name {
  font-size: 18px;
}
</style>
