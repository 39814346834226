<template>
  <div class="not-found-page">
    <van-nav-bar title="404 Not found" />
    <van-empty image="error" description="抱歉，找不到您要访问的页面" />
  </div>
</template>

<!-- 404 页面 -->
<script>
import Vue from 'vue'

export default Vue.extend({
  created () {
    setTimeout(() => {
      this.$router.replace('/')
    }, 5000)
  }
})
</script>
<style scoped>
.not-found-page {
  text-align: center;
}
</style>
