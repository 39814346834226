import { render, staticRenderFns } from "./KepuFromDoctorPage.vue?vue&type=template&id=e90f7c30&scoped=true"
import script from "./KepuFromDoctorPage.vue?vue&type=script&lang=js"
export * from "./KepuFromDoctorPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e90f7c30",
  null
  
)

export default component.exports