<template>
  <!--  专项服务资质-->
  <div style="margin-bottom: 48px">
    <div class="main">
      <img class="img_item"
           src="https://cdn.keihong.tech/typora/2022-11-28-img_%E4%B8%93%E9%A1%B9%E6%9C%8D%E5%8A%A1%E4%BB%8B%E7%BB%8D3.png">
      <div @click="apply"
           style="height: 48px;width: 100vw;text-align: center;font-size: 24px;color: white;background-color: #0d3d3d;line-height: 48px">
        立即报名
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeApplyZxfwzz',
  data () {
    return {
      name: '',
      iosVer: ''
    }
  },
  mounted () {

  },
  methods: {
    apply () {
      window.location.href = 'https://www.wjx.cn/vm/YUtAuZg.aspx'
    }

  }
}
</script>

<style scoped>
.img_item {
  display: block;
  width: 100%;
}

.main {
  padding-top: 48px;
  padding-bottom: 48px;
}
</style>
