<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar back title="远程康复服务预约"
              @onBackClick="onBackClick"/>
    <!--  当前用户，管理员  -->
    <div v-if="isAdmin" style="text-align: center;">当前为管理员状态</div>
    <div class="container">
      <!--   显示当前所在医院   -->
      <div style="padding: 16px; font-size: 16px; font-weight: bold;">当前所在机构：{{ hospital }}</div>
      <div v-if="hospital==='瑞合康复'" style="padding: 16px">【 1980
        】元/月·人（包括：检查报告解读+制定本月康复方案+4次/月康复师视频带练指导+多对1小群管理+本月康复效果总结报告+居家运动小工具包）
      </div>
      <!--   分隔栏，高度16px   -->
      <div style="height: 16px; background-color: #F5F5F5"></div>
      <!--   服务的次数，可通过加减号调整   -->
      <div class="count">
        <div class="count-title">服务次数（周）</div>
        <div class="count-content">
          <van-stepper v-model="count" integer min="1" show-minus show-plus/>
        </div>
      </div>
      <!--   根据count计算服务价格小结-->
      <div class="price">
        <div class="price-title">服务价格</div>
        <div class="price-content">
          <div class="price-content-item">
            <div class="price-content-item-title">服务次数</div>
            <div class="price-content-item-value">{{ count }}周</div>
          </div>
          <div class="price-content-item">
            <div class="price-content-item-title">服务单价/周</div>
            <div class="price-content-item-value">￥{{ price }}元</div>
          </div>
          <div class="price-content-item">
            <div class="price-content-item-title">小计</div>
            <div class="price-content-item-value">￥{{ totalPrice }}元</div>
          </div>
        </div>
      </div>

      <!--   分隔栏，高度16px   -->
      <div style="height: 16px; background-color: #F5F5F5"></div>

      <!--   小标题：请输入被服务人信息   -->
      <div style="padding: 16px; font-size: 16px; font-weight: bold;">请输入患者信息</div>

      <!--  患者姓名、手机号输入框 使用vant  -->
      <van-cell-group>
        <van-field v-model="patientName" label="患者姓名" placeholder="请输入患者姓名"/>
        <!--     限制输入内容是手机号   -->

        <van-field v-model="patientMobile" :rules="[{ validator: verifyPhone, message: '请输入正确的手机号码'}]"
                   label="手机号"
                   placeholder="请输入手机号"/>
        <van-field v-model="doctorName"
                   label="医生"
                   placeholder="请输入医生姓名"/>
        <van-field v-model="remark"
                   label="备注"
                   placeholder="如：xxx公益活动、病情简要等"/>
      </van-cell-group>
      <!--   提交支付   -->
      <div style="margin-top: 20px; margin-bottom: 20px; padding: 16px;">
        <van-button block round type="primary" @click="pay">提交支付</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../../components/MyNavBar'
import { Dialog, Notify, Toast } from 'vant'
import WxPayDao from '@/h5/doucan/network/WxPayDao'
import wx from 'weixin-jsapi'
import axios from 'axios'

export default {
  name: 'PayServe1',
  components: { MyNavBar },
  data () {
    return {
      isAdmin: false,
      isSingle: false,
      hospital: '',
      patientName: '',
      patientMobile: '',
      count: 1,
      price: 200,
      testMobile: ['18008000808', '13610164743', '18520535303', '18501011010'],
      openId: '',
      payStatus: false,
      appsecret: '',
      appid: '',
      code: '',
      openid: '',
      paySignInfo: null,
      outTradeNo: '', // 动优支付系统里面的订单号 ordernum
      remark: '',
      doctorName: ''
    }
  },
  mounted () {
    // 判断是否是管理员
    this.isAdmin = this.$route.query.isAdmin
    this.isSingle = this.$route.query.isSingle
    if (this.isAdmin) {
      console.log('管理员下单', this.$route.query)
      // 从路由参数获取医院名称
      this.hospital = this.$route.query.hospital
      this.price = 0.01
      const patientName = this.$route.query.patientName
      if (patientName) {
        this.patientName = patientName
        this.doctorName = this.$route.query.doctorName
        this.patientMobile = this.$route.query.patientMobile
      }
    } else if (this.isSingle) {
      // 单独进来这个页面下单
      // 价格内容都预先调整
      this.hospital = this.$route.query.hospital
      this.price = this.$route.query.price
      this.count = this.$route.query.count
    } else {
      // 从state获取医院名称
      const qrCodeHospital = this.$store.state.user.qrCodeHospital
      if (qrCodeHospital) {
        this.hospital = qrCodeHospital
      } else {
        this.hospital = '动优远程康复平台'
      }
      this.checkPrice()
    }
    // 当前浏览器是否是微信浏览器
    const isWeChatBrowser = () => {
      const userAgent = navigator.userAgent.toLowerCase()
      return userAgent.includes('micromessenger')
    }

    if (isWeChatBrowser()) {
      // console.log("This is a WeChat browser");
      this.initWx()
    } else {
      // console.log("This is not a WeChat browser");
    }

    // const ua = window.navigator.userAgent.toLowerCase()
    // if (ua.match(/MicroMessenger/i) === 'micro messenger') {
    //   // alert('是微信浏览器')
    //   // 初始化微信-获取openid
    //   this.initWx()
    // }
  },
  methods: {
    onBackClick () {
      if (this.isAdmin) {
        this.$router.replace('/FreePatientOrderAddPage')
      } else {
        this.$router.replace('/home/square')
      }
    },
    async initWx () {
      await this.getAppid()
      const openid = this.$store.state.user.openId
      if (openid) {
        this.openid = openid
      } else {
        await this.getMpOpenID()
      }
    },
    checkPrice () {
      if (this.hospital === '广州和平骨科医院') {
        this.price = 200
        return
      }

      if (this.hospital === '瑞合康复') {
        this.price = 1980
        return
      }

      // 未指定医院则价格为统一价格
      this.price = 200
    },
    verifyPhone (val) {
      return /^1\d{10}$/.test(val) // 返回true或false
    },
    async pay () {
      if (!this.patientName || !this.patientMobile) {
        Notify({
          type: 'danger',
          message: '请填写姓名和手机号'
        })
        return
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      let fee = 0
      if (this.testMobile.includes(this.patientMobile)) {
        fee = 0.01
      } else {
        fee = this.totalPrice
      }
      let fromActivity = '通用远程康复服务预约'
      let goods = this.hospital + '-' + this.doctorName + '-' + this.count + '周-' + '(' + this.price + '元/周)'
      if (this.isAdmin) {
        fromActivity = '客服操作下单' + '-' + this.remark
        goods = this.hospital + '-' + this.doctorName + '-' + this.count + '周'
      }

      const profile = {
        serveType: 0,
        mobile: this.patientMobile, // 后期mobile从用户授权获得，即订单记录支付者登录的微信账号手机号及实际接受服务患者的手机号
        patientName: this.patientName,
        patientMobile: this.patientMobile,
        orderType: '',
        fee: fee,
        fromActivity: fromActivity,
        goods: goods,
        openId: this.openid
      }
      console.log('提交订单', profile)
      const data = await WxPayDao.createOrder(profile)
      console.log('支付qrcode', data)
      if (data.code === 1) {
        this.paySignInfo = data.data
        this.outTradeNo = data.data.outTradeNo
        // 调起微信支付
        wx.config({
          // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false
          debug: false,
          // 必填，公众号的唯一标识
          appId: this.paySignInfo.sign.appId,
          // 必填，生成签名的时间戳
          timestamp: this.paySignInfo.sign.timeStamp,
          // 必填，生成签名的随机串
          nonceStr: this.paySignInfo.sign.nonceStr,
          // 必填，签名
          signature: this.paySignInfo.sign.paySign,
          // 必填，需要使用的JS接口列表
          jsApiList: ['chooseWXPay']
        })
        wx.ready(() => {
          wx.checkJsApi({
            jsApiList: ['chooseWXPay'],
            success: function (res) {
              console.log('seccess')
              console.log('hskdjskjk', res)
            },
            fail: function (res) {
              console.log('fail')
              console.log(res)
            }
          })
          const _that = this
          wx.chooseWXPay({
            // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            timestamp: this.paySignInfo.sign.timeStamp,
            // 支付签名随机串，不长于 32 位
            nonceStr: this.paySignInfo.sign.nonceStr,
            // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            package: this.paySignInfo.sign.packageValue,
            // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            signType: this.paySignInfo.sign.signType,
            // 支付签名
            paySign: this.paySignInfo.sign.paySign,
            // 支付成功后的回调函数
            success: function (res) {
              if (res.errMsg === 'chooseWXPay:ok') {
                console.log('支付成功', res)
                _that.payStatus = true
                Dialog.alert({
                  message: '支付成功'
                }).then(() => {
                  // on close
                  // todo 跳转到支付成功页面，显示支付凭证和提示
                  _that.$router.push(
                    '/salepage/20230001/result?outTradeNo=' + _that.outTradeNo
                  )
                })
              } else {
                Dialog({ message: JSON.stringify(res) })
                _that.payStatus = false
              }
            },
            fail: function (res) {
              Dialog({ message: '支付失败:' + JSON.stringify(res) })
              _that.payStatus = false
            }
          })
        })
        wx.error((err) => {
          alert(err)
        })
      } else {
        console.log('支付失败', data)
      }
    },
    async getOpenId (code) {
      // this.info = '正在获取getOpenId'
      const url = '/quick/pay/openid?code=' + code

      axios
        .get(url)
        .then((res) => {
          // this.info = JSON.stringify(res)
          this.openid = res.data.data.openid
          console.log('openid = ', res.data.data.openid)
          if (!this.openid) {
            this.getCodeApi()
            return
          }
          this.$store.commit('user/setOpenId', this.openid)
          // const profile = this.$store.state.apply20230001.profile
          // profile.openId = this.openid
          // this.$store.commit('apply20230001/update', profile)
        })
        .catch((err) => {
          console.log('err', err)
          this.getCodeApi()
        })
    },
    getCodeApi () {
      const appid = 'wxcb56667c309e369b'
      const urlNow = encodeURIComponent(window.location.href)
      const scope = 'snsapi_base' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      // const scope = 'snsapi_userinfo' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      const url =
        'https://open.weixin.qq.com' +
        '/connect/oauth2/authorize?appid=' +
        appid +
        '&redirect_uri=' +
        urlNow +
        '&response_type=code&scope=' +
        scope +
        '#wechat_redirect'
      window.location.href = url
    },
    getUrlKey (name) {
      // 获取url 参数
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [undefined, ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    },
    async getAppid () {
      const url = '/quick/pay/appid'
      const res = await axios.get(url)
      console.log('appid', res)
      this.appid = res.data.data.codeName
      this.appsecret = res.data.data.codeValue
    },
    async getMpOpenID () {
      const code = this.getUrlKey('code')
      if (code) {
        this.code = code
        await this.getOpenId(code)
      } else {
        this.getCodeApi()
      }
    }
  },
  watch: {},
  computed: {
    totalPrice () {
      if (this.isAdmin) {
        return 0.01
      } else {
        // 保留数值小数点后两位，小数点后第三位直接舍去
        const number = this.count * this.price
        const result = (parseInt(number * 100) / 100).toFixed(2)
        return result
      }
    }
  }
}
</script>
<style scoped>
.count {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.price {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.price-title {
  font-size: 16px;
  font-weight: bold;
}

.price-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.price-content-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.price-content-item-title {
  font-size: 14px;
  color: #999999;
}

.price-content-item-value {
  font-size: 14px;
  color: #333333;
}
</style>
