export default {
  namespaced: true,
  state () {
    return {
      from: ''
    }
  },
  mutations: {
    setFrom (state, payload) {
      state.from = payload
    }
  }
}
// ————————————————
// 版权声明：本文为CSDN博主「run-Ameng」的原创文章，遵循CC 4.0 BY-SA版权协议，转载请附上原文出处链接及本声明。
// 原文链接：https://blog.csdn.net/m0_58861443/article/details/120247013
