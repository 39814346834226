<template>
  <!-- 居家康复学组专栏 -->
  <div style="width: 100%; box-sizing: border-box">
    <my-nav-bar v-if="showAppbar" title="康复体操"/>
    <div v-if="courseList">
      <div
        style="font-size: 16px;text-align: center;background-color: rgba(0,161,126,0.72);color: white;font-weight: bold;padding: 8px">
        点击下面的卡片进行播放哦
      </div>
      <div style="width: 100%;margin-top: 16px">
        <!--  四宫格，用于存放图片，且点击后进入详情页    -->
        <div class="image-gallery">
          <div v-for="(row, index) in imageRows" :key="index" class="image-row">
            <div class="image-column">
              <div v-for="item in row" :key="item.id">
                <div class="row_item" @click="handleCourseClick(item)">
                  <img :src="item.coverUrl" class="img_item"/>
                  <div style="padding: 8px;font-size: 18px">{{ item.title }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      <div v-for="(item, index) in courseList" :key="index">-->
      <!--        <div @click="handleCourseClick(item)">-->
      <!--          <img :src="item.coverUrl" class="img_item"/>-->
      <!--          <div style="padding: 16px">{{ item.title }}</div>-->
      <!--        </div>-->
      <!--        <div style="height: 12px"></div>-->
      <!--        <div style="height: 1px; background-color: #eeeeee; width: 100%"></div>-->
      <!--        <div style="height: 12px"></div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import MyNavBar from '../../components/MyNavBar'
import TicaoDao from '../../network/Ticao'
import ReportFeatureAccessDao from '@/h5/doucan/network/ReportFeatureAccessDao'

export default {
  name: 'KepuLive',
  components: { MyNavBar },
  data () {
    return {
      showAppbar: true,
      courseList: null
    }
  },
  created () {
    this.reportFeatureAccess()
    this.load()
  },
  mounted () {
    if (this.$route.query.from) {
      switch (this.$route.query.from) {
        case 'wxapp':
          this.showAppbar = false
          break
        default:
          this.showAppbar = false
      }
    }
  },
  computed: {
    imageRows () {
      const rows = []
      let currentRow = []
      this.courseList.forEach((image, index) => {
        currentRow.push(image)
        if ((index + 1) % 2 === 0) {
          rows.push(currentRow)
          currentRow = []
        }
      })
      if (currentRow.length > 0) {
        rows.push(currentRow)
      }
      console.log('rows', rows)
      return rows
    }
  },
  methods: {
    async load () {
      const list = await TicaoDao.list()
      this.courseList = list
      console.log('体操', this.courseList)
    },
    handleCourseClick (course) {
      console.log('选择了：', course.id)
      this.$router.push('/TicaoDetailPage?id=' + course.id)
    },
    reportFeatureAccess () {
      if (this.$store.state.route.from === '/KepuxuanjiaoMainPage') {
        ReportFeatureAccessDao.report(8, this.$store.state.user.qrCodeHospital, this.$store.state.user.openId).then(res => {
          console.log('上报访问成功', res)
        })
      }
    }
  }
}
</script>

<style scoped>
.img_item {
  display: block;
  width: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.row_item {
  width: 44vw;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  height: 180px;
}

.image-column {
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
