<template>
  <div
    style=" background-color: white;border-radius:16px;padding: 8px;display: flex;flex-direction: column;align-items: center">
    <div class="service-component">
      <!-- 左侧图片块 -->
      <img :src="leftImage" alt="左侧大块" class="left-image" @click="handleClick(leftLink)"/>

      <!-- 右侧动态按钮块 -->
      <div class="right-buttons">
        <div
          v-for="(item, index) in rightItems"
          :key="index"
          class="button-item"
          @click="handleClick(item.link)"
        >
          <my-img :alt="item.title" :url="item.image"/>
          <p class="button-text">{{ item.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyImg from '@/h5/doucan/components/MyImg.vue'

export default {
  components: { MyImg },
  props: {
    leftImage: {
      type: String,
      required: true
    },
    leftLink: {
      type: String,
      default: ''
    },
    rightItems: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleClick (link) {
      if (link) {
        window.location.href = link
      }
    }
  }
}
</script>

<style scoped>
.service-component {
  display: flex;
  align-items: center;
}

.left-image {
  margin-left: 4px;
  width: 36%;
  height: auto;
  cursor: pointer;
}

.right-buttons {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: space-between;
  gap: 8px;
}

.button-item {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button-image {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.button-text {
  font-size: 16px;
  font-weight: bold;
}
</style>
