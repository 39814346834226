<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar style="position:fixed;top: 0;width: 100%" title="确认订单"/>
    <div style="margin-top: 46px;">
      <div>
        <van-contact-card v-if="!addressAdded" add-text="填写收件地址" type="add" @click="onAdd"/>
        <div v-else>
          <div style="padding:16px">
            <!--   收件信息  第一行 姓名 手机号，第二行地址  -->
            <div style="display: flex;align-items: center">
              <div style="font-size: 18px;color: #0a4b3d;font-weight: bold">{{ mailInfo.name }}</div>
              <div style="margin-left: 16px;font-size: 18px;color: #0a4b3d">{{ mailInfo.phone }}</div>
            </div>
            <div style="margin-top: 4px;font-size: 18px;color: #0a4b3d">{{ mailInfo.province }}{{
                mailInfo.city
              }}{{ mailInfo.address }}
            </div>
          </div>
          <div style="padding: 16px;text-align: right;"
               @click="resetAddr">
            更换地址>>
          </div>
        </div>
      </div>
      <div style="height: 16px;background-color: #e3e3e3"/>

      <div class="good-info">
        <div>商品</div>
        <!--   产品名称和价格，一行显示     -->
        <div style="display: flex;align-items: center;justify-content: space-between">
          <div style="font-size: 18px;color: #0a4b3d;font-weight: bold">{{ goodDetail.name }}</div>
          <div style="margin-left: 16px;font-size: 18px;color: #c20000;font-weight: bold">￥{{ price }}</div>
        </div>
      </div>
      <div>
        <!--   购买数量     -->
        <div style="display: flex;align-items: center;justify-content: space-between;padding: 16px">
          <div style="font-size: 18px;">购买数量</div>
          <div style="display: flex;align-items: center">
            <van-stepper v-model="buyCount" :show-minus="true" integer min="1" s:how-plus="true"/>
          </div>
        </div>
      </div>
      <!--   订单备注   -->
      <div style="padding: 16px 0">
        <van-field v-model="mark" label="订单备注"
                   placeholder="请输入产品型号信息，如尺码、左右脚等"
                   rows="5"
                   style="font-size: 16px" type="textarea"/>
      </div>

      <!--      <div style="height: 16px;background-color: #e3e3e3"/>-->

      <!--   选择微信支付   -->
      <div style="padding: 16px 0">
        <van-cell-group>
          <van-cell title="支付方式" value="微信支付"/>
        </van-cell-group>
      </div>

      <!--   提交订单栏   -->
      <van-submit-bar :price="totalPrice" button-text="提交订单" @submit="onSubmit"/>

      <van-popup v-model="showAddAddr" position="bottom" style="width: 100%">
        <!--  使用vant设计一个收件人信息录入模块：姓名、手机号、省份、城市、详细地址-->
        <div style="width: 100%;padding: 16px;box-sizing: border-box">
          <van-form>
            <van-row>
              <van-col>
                <van-field v-model="mailInfo.name" :rules="[{
                    required: true,
                    message: '请填写',
                    trigger: 'onBlur'
                }]" label="姓名" placeholder="请输入收件人姓名"/>
              </van-col>
              <van-col>
                <van-field v-model="mailInfo.phone"
                           :rules="[{ validator: verifyPhone, message: '请输入正确的手机号码' }]"
                           label="手机号"
                           placeholder="请输入手机号"/>
              </van-col>
            </van-row>
            <van-row>
              <van-col>
                <van-field v-model="mailInfo.province" :rules="[{
                    required: true,
                    message: '请填写',
                    trigger: 'onBlur'
                }]" label="省份" placeholder="请输入省份"/>
              </van-col>
              <van-col>
                <van-field v-model="mailInfo.city" :rules="[{
                    required: true,
                    message: '请填写',
                    trigger: 'onBlur'
                }]" label="城市" placeholder="请输入所在城市"/>
              </van-col>
            </van-row>
            <van-row>
              <van-field v-model="mailInfo.address" :rules="[{
                    required: true,
                    message: '请填写',
                    trigger: 'onBlur'
                }]" label="详细地址" placeholder="请输入详细收件地址"/>
            </van-row>
            <div style="margin: 16px;padding: 8px;background-color: #ffb400;border-radius: 8px">
              如需送到病房， 详细地址请填写住院科室、楼层、病房号、病床号
            </div>
            <van-button style="width: 100%" type="primary" @click="submitAddr">提交</van-button>
          </van-form>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import MallDao from '@/h5/doucan/network/MallDao'
import { Toast } from 'vant'

export default {
  name: 'MallOrderConfirmPage',
  components: { MyNavBar },
  data () {
    return {
      showAddAddr: false,
      addressAdded: false,
      mailInfo: {
        name: '',
        phone: '',
        province: '',
        city: '',
        region: '-1',
        address: '',
        id: -1
      },
      goodDetail: null,
      buyCount: 1,
      mark: '',
      addressId: -1,
      skuOption: null,
      distributorId: null

    }
  },
  mounted () {
    this.goodDetail = this.$route.query.good
    this.skuOption = this.$route.query.sku
    // console.log('goodDetail-1', this.goodDetail)
    this.mailInfo = this.$store.state.mall.addressInfo
    this.addressId = this.$store.state.mall.addressInfo.id
    if (this.addressId !== -1) {
      this.addressAdded = true
    }
    if (this.$route.query.distributor) {
      this.distributorId = this.$route.query.distributor.id
      // console.log('distributor', this.distributorId)
    }
  },
  methods: {
    resetAddr () {
      this.addressAdded = false
    },
    verifyPhone (val) {
      return /^1\d{10}$/.test(val) // 返回true或false
    },
    onAdd () {
      this.showAddAddr = true
    },
    async submitAddr () {
      if (!this.mailInfo.name) {
        this.$toast('请填写姓名')
        return
      }
      if (!this.mailInfo.phone) {
        this.$toast('请填写手机号')
        return
      }
      if (!this.mailInfo.province) {
        this.$toast('请填写省份')
        return
      }
      if (!this.mailInfo.city) {
        this.$toast('请填写城市')
        return
      }
      if (!this.mailInfo.address) {
        this.$toast('请填写详细地址')
        return
      }

      // console.log('收件信息', this.mailInfo)
      // 提交收件信息到服务器
      const id = await MallDao.addAddress(this.mailInfo)
      this.addressId = id.data
      this.mailInfo.id = this.addressId
      this.$store.commit('mall/setAddressInfo', this.mailInfo)
      this.addressAdded = true
      this.showAddAddr = false
    },
    async getGoodDetailStock () {
      const goodDetail = await MallDao.findById(this.goodDetail.id)
      // console.log('goodDetail', goodDetail)
      return goodDetail.stock
    },
    async onSubmit () {
      const stock = await this.getGoodDetailStock()
      // console.log('检查库存：' + stock)
      if (stock <= 0) {
        Toast.fail('已售罄')
        return
      }
      if (!this.addressAdded) {
        this.$toast('请填写收件地址')
        return
      }
      const optionIds = []
      if (this.skuOption) {
        optionIds.push(this.skuOption.id)
      }
      const payInfo = {
        orderType: 'buy',
        goodId: this.goodDetail.id,
        count: this.buyCount,
        remark: this.mark,
        addressId: this.addressId,
        totalPrice: this.totalPrice / 100,
        phone: this.mailInfo.phone,
        goodName: this.goodDetail.name + '*' + this.buyCount,
        optionIds: optionIds,
        distributorId: this.distributorId
      }
      // console.log('payInfo', payInfo)
      this.$store.commit('mall/setPayInfo', payInfo)
      this.$store.commit('mall/setMailInfo', this.mailInfo)
      this.$router.push({
        path: '/PayPage'
      })
    }
  },
  watch: {},
  computed: {
    totalPrice () {
      if (this.skuOption) {
        return (this.goodDetail.price + this.skuOption.additonalCost) * this.buyCount * 100
      }
      return this.goodDetail.price * this.buyCount * 100
    },
    price () {
      if (this.skuOption) {
        return this.goodDetail.price + this.skuOption.additonalCost
      }
      return this.goodDetail.price
    }
  }
}
</script>
<style scoped>
.good-info {
  padding: 16px;
}
</style>
