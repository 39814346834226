<template>
  <div style="width: 100%">
    <my-nav-bar :title="'家庭病床康复-支付订单信息'"/>
    <div class="order-details">
      <div class="order-details__content">
        <div class="order-details__item">
          <strong>订单编号：</strong> {{ orderData.orderNum }}
        </div>
        <!--        <div class="order-details__item">-->
        <!--          <strong>活动名称：</strong> {{ orderData.fromActivity }}-->
        <!--        </div>-->
        <div class="order-details__item">
          <strong>服务名称：</strong> {{ orderData.goods }}
        </div>
        <div class="order-details__item">
          <strong>患者姓名：</strong> {{ orderData.patientName }}
        </div>
        <div class="order-details__item">
          <strong>患者电话：</strong> {{ orderData.patientMobile }}
        </div>
        <div class="order-details__item">
          <strong>支付金额：</strong> {{ orderData.payAmount }}
        </div>
        <div class="order-details__item">
          <strong>支付时间：</strong> {{ formatTime(orderData.updateTime) }}
        </div>
      </div>
    </div>
    <div>
      <img
        src="https://cdn.keihong.tech/typora/2023-06-18-%E5%88%87%E5%9B%BE%205%20-1-.png"
        style="max-width: 100vw"
      />
    </div>
  </div>
</template>

<script>
import MyNavBar from '../../../components/MyNavBar'
import OrderDao from '../../../network/OrderDao'

export default {
  name: 'SalePage20230001Result',
  components: {
    MyNavBar
  },
  data () {
    return {
      outTradeNo: '',
      orderData: null
    }
  },
  mounted () {
    const query = this.$route.query
    if (query) {
      // this.$store.commit('setIsApp', query.isApp)
      this.outTradeNo = query.outTradeNo
      this.getOrderInfo()
    }
  },
  methods: {
    formatTime (timestamp) {
      // 毫秒时间戳
      const date = new Date(timestamp)
      const formattedDate = date
        .toLocaleString('zh-CN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
        })
        .replace(
          /(\d{4})\D+(\d{2})\D+(\d{2})\D+(\d{2})\D+(\d{2})/,
          '$1年$2月$3日 $4:$5'
        )
      return formattedDate
    },
    async getOrderInfo () {
      const order = await OrderDao.loadQuickPayOrder(this.outTradeNo)
      if (order) {
        console.log(order)
        this.orderData = order
      }
    }
  }
}
</script>

<style scoped>
.order-details {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.order-details__title {
  font-size: 24px;
  margin-bottom: 10px;
}

.order-details__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.order-details__item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
}
</style>
