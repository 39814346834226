<template>
  <div>
    <div
      style="height: 6vh;text-align: center;width: 100%;line-height: 6vh;font-size: 18px;color: white;background-color: #00132d">
      动优|可视化隐私保护监控大屏
    </div>
    <div class="grid-container" style="background-color: #3d3d3d">
      <div v-for="(channel, index) in liveChannelArray" :key="index" class="grid-item">

        <!--        <div style="position: relative">-->

        <div v-if="!channel.fake" style="position: relative;width: 100%;height: 100%">
          <video :ref="channel.channel" controls muted
                 style="width: 100%;height: 100%;object-fit: fill"></video>
          <div style="position: absolute;top: 16px;left: 16px;color: white;font-size: 12px">动优康护 doUcan</div>
          <!--          <div v-if="index===0">-->
          <div v-if="channel.alarm.isAlarm"
               style="text-align: center;position: absolute;top: 10px;z-index: 100;left: 40%">
            <!--   水平展示一个图片和一个按钮，图片在右，按钮在左   -->
            <div
              style="background-color: #0a4b3d;padding: 8px;display: flex; flex-direction: row; align-items: center; justify-content: space-between">
              <div style="width: 32px; height: 32px">
                <img v-show="channel.alarm.isAlarmLight" src="../../assets/alarm-on.png"
                     style="width: 32px; height: 32px"/>
                <img v-show="!channel.alarm.isAlarmLight" src="../../assets/alarm-off.png"
                     style="width: 32px; height: 32px"/>
              </div>
              <van-button style="font-size: 14px;height: 32px;padding: 0" type="primary" @click="fixAlarm(channel)">关闭
              </van-button>
            </div>
          </div>
          <!--          </div>-->
          <div v-if="channel.liveLoading"
               style="width: 100%;background-color: #333333;">
            <div
              style="top: 50%;left:50%;margin-left: -60px;margin-top: -40px;position: absolute;display: flex;flex-direction: column;align-items: center;background-color: #e7e7e7;width: 100px;height: 80px;border-radius: 16px;justify-content: center">
              <van-loading color="#0a4b3d" size="24px" type="spinner"/>
              <div>视频连线中</div>
            </div>
          </div>
          <div
            style="position: absolute;top: 16px;right: 16px;color: black;font-size: 12px;background-color: white">
            {{ channel.title }}
          </div>
        </div>
        <div v-else style="width: 100%;height: 100%;box-sizing: border-box;overflow: hidden">
          <img src="https://cdn.keihong.tech/img/202401071504276.png" style="width: 100%;height: 100%">
        </div>
      </div>
    </div>
    <!--    </div>-->
    <audio ref="audioElement" loop preload src="../../assets/alarm1.mp3"></audio>

    <van-dialog v-model="showDialogVoiceConfirm" title="提醒">
      请点击以下按钮，以允许浏览器播放告警声音
      <template #footer>
        <van-button @click="startAudioPlayback">Start Playback</van-button>
      </template>
    </van-dialog>
  </div>
</template>
<script>
import MonitorDao from '@/h5/doucan/network/MonitorDao'
import DateUtils from '@/utils/DateUtils'
import flvjs from 'flv.js'

export default {
  name: 'BigScreenMonitor',
  components: {},
  data () {
    return {
      showDialogVoiceConfirm: true,
      columnNum: 2,
      liveChannelArray: [{
        title: '龙生-客厅-1',
        channel: 'zichuan-long-1',
        deviceName: '龙岳军-客厅',
        deviceId: 2,
        liveLoading: true,
        alarm: {
          newestAlarm: null,
          handledAlarmId: null,
          isAlarm: false,
          isAlarmLight: false
        }
      }, {
        title: '康康家-测试机-1',
        channel: 'zichuan-kang',
        deviceName: 'chenqikang01',
        deviceId: 3,
        liveLoading: true,
        alarm: {
          newestAlarm: null,
          handledAlarmId: null,
          isAlarm: false,
          isAlarmLight: false
        }
      }, {
        title: 'Canada1',
        channel: 'zichuan-ca-test-1',
        deviceName: 'Canada1',
        deviceId: 4,
        liveLoading: true,
        alarm: {
          newestAlarm: null,
          handledAlarmId: null,
          isAlarm: false,
          isAlarmLight: false
        }
      }]
    }
  },
  mounted () {
    this.$nextTick(() => {
      for (const itemsKey in this.liveChannelArray) {
        this.liveChannelArray[itemsKey].fake = false
      }
      // 计算数组最后一行缺少的个数
      const lastRowLackNum = this.columnNum - this.liveChannelArray.length % this.columnNum
      console.log('缺少', lastRowLackNum)
      // 补充缺少的个数
      for (let i = 0; i < lastRowLackNum; i++) {
        this.liveChannelArray.push({ fake: true })
      }
      this.init()
    })
  },
  methods: {
    startAudioPlayback () {
      // this.$refs.audioElement.play()
      this.showDialogVoiceConfirm = false
    },
    init () {
      this.liveChannelArray.forEach(channel => {
        this.initFlvPlayer(channel)
      })
      // 遍历所有的通道，每个通道都去加载最新的告警
      this.liveChannelArray.forEach(channel => {
        setInterval(() => {
          this.loadNewestAlarm(channel)
        }, 5000)
      })
    },
    initFlvPlayer (channel) {
      if (channel.fake) return
      console.log('initFlvPlayer:', channel)
      // const refName = 'videoFlv' + channel.channel
      // console.log('refName:', refName)
      const videoElement = this.$refs[`${channel.channel}`][0]
      console.log('videoElement:', videoElement)
      videoElement.addEventListener('loadeddata', () => {
        channel.liveLoading = false
        console.log('loadeddata:', channel.channel)
        // todo 读取告警
        // this.loadNewestAlarm()
        // setInterval(() => {
        //   this.loadNewestAlarm()
        // }, 5000)
        // this.connectSocket()
      })

      // 判断是否在pc浏览器
      const baseurl = 'https://www.doucan1758.com:8082/live/'
      // if (this.isPC()) {
      //   baseurl = 'https://171.111.219.37:8082/live/'
      // } else {
      //   baseurl = 'http://171.111.219.37:8081/live/'
      // }
      // const videoUrl = baseurl + channel.channel + '.m3u8'
      const videoUrl = baseurl + channel.channel + '.flv'
      if (flvjs.isSupported()) {
        const flvPlayer = flvjs.createPlayer({
          type: 'flv',
          // url: 'http://171.111.219.37:8081/live/' + channel.channel + '.flv',
          url: videoUrl,
          isLive: true
        })

        flvPlayer.attachMediaElement(videoElement)
        flvPlayer.load()
        flvPlayer.play()

        // var hls = new Hls()
        // hls.loadSource(videoUrl)
        // hls.attachMedia(videoElement)
        // hls.on(Hls.Events.MANIFEST_PARSED, function () {
        //   // videoElement.play()
        // })
      } else {
        // 弹窗提示
        this.$toast('您的浏览器不支持播放视频')
      }
      // else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      //   // HLS直接播放（主要针对iOS设备）
      //   videoElement.src = videoUrl
      //   videoElement.addEventListener('loadedmetadata', function () {
      //     this.play()
      //   })
      // }
    },
    isPC () {
      var userAgentInfo = navigator.userAgent
      var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
      var flag = true
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false
          break
        }
      }
      return flag
    },
    async loadNewestAlarm (channel) {
      const _that = this
      const result = await MonitorDao.newestAlarm(channel.deviceName)
      if (result) {
        result.base64 = 'data:image/jpeg;base64,' + result.base64

        channel.alarm.newestAlarm = result
        console.log('newestAlarm', channel.alarm.newestAlarm)
        // 已经处理过当前id的告警了
        if (channel.alarm.handledAlarmId === result.id) {
          return
        }
        // todo 判断是否需要告警：若告警时间和当前时间相差1分钟内，则告警
        const now = new Date()
        const alarmTime = new Date(result.createTime)
        // if ((now.getTime() - this.delayTime * 1000) - alarmTime.getTime() < 60 * 1000) {
        if (now.getTime() - alarmTime.getTime() < 60 * 1000) {
          if (channel.alarm.isAlarm) {
            return
          }
          setTimeout(() => {
            _that.$refs.audioElement.play()
          }, 200)
          // 告警信息
          channel.alarm.isAlarm = true
          this.downTime = DateUtils.formattedTime(new Date())
          //   开启一个定时器，每1秒钟闪烁一次，5秒后停止闪烁
          this.timer = setInterval(function () {
            if (channel.alarm.isAlarmLight) {
              channel.alarm.isAlarmLight = false
            } else {
              channel.alarm.isAlarmLight = true
            }
          }, 200)
        }
      }
    },
    fixAlarm (channel) {
      channel.alarm.isAlarm = false
      channel.alarm.handledAlarmId = channel.alarm.newestAlarm.id
      clearInterval(this.timer)
      this.$refs.audioElement.pause()
      this.$refs.audioElement.currentTime = 0
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

.grid-container {
  display: flex;
  flex-wrap: wrap;
}

.grid-item {
  flex: 1 0 50%;
  height: 47vh; /* 或者您需要的高度 */
  box-sizing: border-box;
  padding: 4px; /* 如果需要的话 */
}

</style>
