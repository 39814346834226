<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar style="position:fixed;top: 0;width: 100%" title="免费下单"/>
    <div style="margin-top: 56px">
      <!--   展示医院入口   -->
      <div style="padding: 16px">
        <div v-for="(hospital, index) in hospitals" :key="index">
          <van-cell-group>
            <van-cell :title="hospital" value=">" @click="handleClick(hospital)"/>
          </van-cell-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'

export default {
  name: 'FreePatientOrderAddPage',
  components: { MyNavBar },
  data () {
    return {
      hospitals: ['中山大学附属第三医院', '暨南大学附属第一医院', '暨南大学附属顺德医院', '东莞康华医院', '广州中医药大学顺德医院']
    }
  },
  mounted () {

  },
  methods: {
    handleClick (hospital) {
      // 路由参数传递 hospital isAdmin
      this.$router.push({
        path: '/payserve1',
        query: {
          hospital: hospital,
          isAdmin: true
        }
      })
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
