import axios from 'axios'

const KepuDao = {
  async loadList (patientId) {
    const url = `/hospital/mission/find/by_patient_id?patientId=${patientId}`
    const httpResp = await axios.get(url)
    const { data: resp } = httpResp
    console.log(`请求:${url}`, httpResp)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }
}
export default KepuDao
