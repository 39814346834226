import axios from 'axios'

const SmsDao = {
  /**
   * @param phone
   * @returns {code: '1465', phone: '13610164743'}
   */
  async getSmsCode (phone) {
    const url = '/sms/verification/code'
    const { data: resp } = await axios.get(url, {
      params: {
        phone: phone
      }
    })
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }
}
export default SmsDao
