<template>
  <div>
    <div
      style="
        height: 48px;
        width: 100%;
        position: fixed;
        z-index: 100;
        background: rgba(255, 255, 255, 0.49);
      "
    >
      <div
        style="
          font-size: 20px;
          line-height: 44px;
          position: absolute;
          left: 50%;
          margin-left: -20px;
          font-weight: 200;
        "
      >
        居家康复
      </div>
    </div>
    <div class="content">
      <div style="background-color: white; height: 48px; width: 100%">
      </div>
      <div style="width: 100%; height: 300px; background-color: #0066cc"></div>
      <div style="width: 100%; height: 300px; background-color: #5c6165"></div>
      <div
        style="width: 100%; height: 300px; background-color: rgb(59, 59, 59)"
      ></div>
      <div
        style="
          width: 100%;
          height: 300px;
          background-color: rgba(79, 192, 57, 0.27);
        "
      ></div>
      <div
        style="width: 100%; height: 300px; background-color: rgb(253, 78, 78)"
      ></div>
      <div style="height: 80px; width: 100%; color: white"></div>
    </div>
    <div
      class="footer"
      style="
        position: fixed;
        bottom: 0;
        height: 80px;
        width: 100%;
        padding: 16px;
        box-sizing: border-box;
      "
    >
      <div
        style="
          height: 100%;
          width: 100%;
          background: rgb(255, 255, 255);
          border-radius: 24px;
          text-align: center;
          line-height: 48px;
          font-size: 24px;
          font-weight: 300;
          box-shadow: 1px 1px 10px rgba(0, 139, 73, 0.7);
        "
      >
        立即预约
      </div>
    </div>
    <van-popup
      v-model="menuShow"
      :style="{ height: '30%' }"
      close-icon-position="top-left"
      closeable
      position="top"
      round
    />
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      menuShow: false
    }
  },
  mounted () {
    this.$router.push('/home/square')
  },
  methods: {
    menuClick () {
      this.menuShow = true
    }
  }
}
</script>

<style scoped>
</style>
