<template>
  <div>
    <base-title-bar-scroll-page ref="container" title="入院/入院 宣教">
      <div>
        <div v-for="(content, index) in contents" :key="index" @click="toDetail(content.id)">
          <div style="background-color: white;padding: 24px;display: flex;flex-direction: row">
            <div style="font-size: 18px;margin-right: auto">{{ index + 1 }}、{{ content.title }}</div>
            <div>
              <my-img :url="content.poster" style="width: 80px;height: 80px;margin-left: 8px"/>
            </div>
          </div>
          <div style="height: 1px;width: 100%;background-color: #eaeaea"></div>
        </div>
      </div>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import MyImg from '@/h5/doucan/components/MyImg.vue'
import ReportFeatureAccessDao from '@/h5/doucan/network/ReportFeatureAccessDao'

export default {
  name: 'RuyuanAndChuyuanKepu',
  components: {
    MyImg,
    BaseTitleBarScrollPage
  },
  data () {
    return {
      hospital: null,
      /*
      入院患者须知
出院患者须知
超方便！异地就医线上备案操作指南来啦！
想要现场办理异地就医备案？这份指南别错过！

       */
      contents: [{
        title: '患者入院用物准备清单',
        id: 427,
        poster: 'https://cdn.keihong.tech/img/202405191733406.png'
      },
      {
        title: '入院患者须知',
        id: 435,
        poster: 'https://cdn.keihong.tech/img/202405191733406.png'
      },
      {
        title: '超方便！异地就医线上备案操作指南来啦！',
        id: 405,
        poster: 'https://cdn.keihong.tech/img/202405191733406.png'
      },
      {
        title: '想要现场办理异地就医备案？这份指南别错过！',
        id: 406,
        poster: 'https://cdn.keihong.tech/img/202405191733406.png'
      }
      ]
    }
  },
  mounted () {
    this.hospital = this.$store.state.user.qrCodeHospital
    if (!this.hospital) {
      // this.$toast('请先扫码登录')
      // this.$router.push('/login')
    }
    console.log(this.hospital)
    this.reportFeatureAccess()
    this.hideLoading()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    toDetail (id) {
      this.$router.push('/feeds/detail/' + id)
    },
    reportFeatureAccess () {
      if (this.$store.state.route.from === '/KepuxuanjiaoMainPage') {
        ReportFeatureAccessDao.report(6, this.$store.state.user.qrCodeHospital, this.$store.state.user.openId).then(res => {
          console.log('上报访问成功', res)
        })
      }
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
