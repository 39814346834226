<template>
  <div>
    <div style="background-color: #fffcf6">
      <my-nav-bar
        :title="'资助详情'"
        back
        @onBackClick="onBackClick"
        style="position: fixed; width: 100%; top: 0"
      />
      <div style="margin-top: 46px">
        <img
          class="img_item"
          src="https://cdn.keihong.tech/typora/2023-04-16-%E5%9F%BA%E9%87%91%E4%BC%9A%E6%B4%BB%E5%8A%A8202301-step2-1.png"
        />
        <img
          class="img_item"
          src="https://cdn.keihong.tech/typora/2023-04-16-%E5%9F%BA%E9%87%91%E4%BC%9A%E6%B4%BB%E5%8A%A8202301-step2-2.png"
        />
        <img
          class="img_item"
          @click="handleHomeType1"
          v-if="homeType === 1"
          src="https://cdn.keihong.tech/typora/2023-04-16-%E5%9F%BA%E9%87%91%E4%BC%9A%E6%B4%BB%E5%8A%A8202301-step2-3-1.png"
        />
        <img
          class="img_item"
          @click="handleHomeType1"
          v-if="homeType === 0"
          src="https://cdn.keihong.tech/img/202306042042616.png"
        />
        <img
          class="img_item"
          src="https://cdn.keihong.tech/typora/2023-04-16-%E5%9F%BA%E9%87%91%E4%BC%9A%E6%B4%BB%E5%8A%A8202301-step2-4.png"
        />

        <div style="position: relative; padding: 16px">
          <img
            class="img_item"
            @click="handleWatchAgreement"
            src="https://cdn.keihong.tech/typora/2023-02-15-%E7%94%B3%E8%AF%B7%E6%B5%81%E7%A8%8B-1-%E8%B5%84%E5%8A%A9%E5%8D%8F%E8%AE%AE.png"
          />
          <div style="position: absolute; bottom: 32px; left: 24px">
            <div
              style="display: flex; flex-direction: row; align-items: center"
            >
              <div
                style="height: 46px; width: 46px"
                @click="handleAcceptAgreement"
              >
                <van-icon
                  v-if="!acceptAgreement"
                  name="circle"
                  size="24"
                  style="position: absolute; left: 11px; top: 11px"
                />
                <van-icon
                  v-else
                  name="passed"
                  size="24"
                  color="#599E00"
                  style="position: absolute; left: 11px; top: 11px"
                />
              </div>
              <div style="margin-left: 8px" @click="handleAcceptAgreement">
                我已查看并同意资助协议
              </div>
            </div>
          </div>
        </div>

        <img
          class="img_item"
          @click="handleUnfreeUser"
          src="https://cdn.keihong.tech/typora/2023-04-16-%E5%9F%BA%E9%87%91%E4%BC%9A%E6%B4%BB%E5%8A%A8202301-step2-5.png"
        />

        <!--        <div style="position: relative">-->
        <!--          <div style="text-align: center;position:absolute;bottom: 16px;width: 100%">-->
        <!--            <div>-->
        <!--              <img @click="handleHomeType1" style="width: 180px" :src="homeType1Sel"/>-->
        <!--              <img v-if="showTest" @click="handleHomeType2" style="width: 180px" :src="homeType2Sel"/>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div v-if="homeType!==0">-->
        <!--          <div v-if="homeType===2" style="text-align: center;color: black;font-size: 24px;">-->
        <!--            请点击选择服务时长-->
        <!--          </div>-->
        <!--          <div v-else style="text-align: center;color: black;font-size: 24px;">-->
        <!--            你将可免费获得如下服务时长:-->
        <!--          </div>-->
        <!--          <div style="width: 100%;padding: 8px;box-sizing: border-box">-->
        <!--            <img class="img_item" v-if="homeType!==0" @click="handleServeTypeClick(2)"-->
        <!--                 :src="serveType1">-->
        <!--            <img class="img_item" v-if="homeType === 2" @click="handleServeTypeClick(3)"-->
        <!--                 :src="serveType2">-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div style="padding: 16px;color: #383838;font-size: 16px">-->
        <!--          *温馨提示：<br/>-->
        <!--          1、每位患者仅可申请 1 次资助机会<br/>-->
        <!--          2、资助费用将由基金会直接支付给服务提供方<br/>-->
        <!--        </div>-->
        <div style="height: 160px"></div>
      </div>
    </div>
    <div
      @click="handleNextClick"
      style="
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: white;
        font-weight: bold;
        background-color: #529878;
        position: fixed;
        bottom: 0;
        width: 100%;
        font-size: 24px;
      "
    >
      下一步
    </div>
    <van-popup
      v-model="showAgreement"
      position="bottom"
      :style="{ height: '80%' }"
    >
      <div>
        <div
          style="margin-bottom: 56px; padding: 16px"
          v-html="agreement.htmlContent"
        ></div>
        <div
          @click="handleAcceptAgreementInDetail"
          style="
            width: 100%;
            height: 56px;
            background-color: #0d3d3d;
            color: white;
            font-size: 24px;
            text-align: center;
            line-height: 56px;
          "
        >
          我已知晓并同意本公益活动协议内容
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import MyNavBar from '../../../components/MyNavBar'
import { Dialog } from 'vant'

export default {
  name: 'ServeSale202301Step2',
  components: { MyNavBar },
  data() {
    return {
      acceptAgreement: false,
      showAgreement: false,
      showTest: true,
      homeType: 0,
      homeType1Sel:
        'https://cdn.keihong.tech/typora/2023-02-12-%E7%BB%84%2054.png',
      homeType2Sel:
        'https://cdn.keihong.tech/typora/2023-02-12-%E7%BB%84%2055.png',
      homeType1SelPic:
        'https://cdn.keihong.tech/typora/2023-02-15-%E4%BD%8E%E4%BF%9D%E5%AE%B6%E5%BA%AD-sel.png',
      homeType1UnSelPic:
        'https://cdn.keihong.tech/typora/2023-02-15-%E4%BD%8E%E4%BF%9D%E5%AE%B6%E5%BA%AD-unsel.png',
      homeType2SelPic:
        'https://cdn.keihong.tech/typora/2023-02-12-%E7%BB%84%2042.png',
      homeType2UnSelPic:
        'https://cdn.keihong.tech/typora/2023-02-12-%E7%BB%84%2055.png',
      serveType1:
        'https://cdn.keihong.tech/typora/2023-02-15-%E5%AD%A3%E5%BA%A6%E6%9C%8D%E5%8A%A1%E4%BB%B7%E6%A0%BC-unsel.png',
      serveType1HalfUnSel:
        'https://cdn.keihong.tech/typora/2023-02-15-%E5%AD%A3%E5%BA%A6%E6%9C%8D%E5%8A%A1%E4%BB%B7%E6%A0%BC-unsel.png',
      serveType1HalfSel:
        'https://cdn.keihong.tech/typora/2023-02-15-%E5%AD%A3%E5%BA%A6%E6%9C%8D%E5%8A%A1%E4%BB%B7%E6%A0%BC-sel.png',
      serveType1FreefSel:
        'https://cdn.keihong.tech/typora/2023-02-15-%E5%AD%A3%E5%BA%A6%E6%9C%8D%E5%8A%A1%E4%BB%B7%E6%A0%BC-0%E5%85%83-sel%20-1-.png',
      serveType2:
        'https://cdn.keihong.tech/typora/2023-02-15-%E5%8D%8A%E5%B9%B4%E6%9C%8D%E5%8A%A1%E4%BB%B7%E6%A0%BC-unsel.png',
      serveType2HalfUnSel:
        'https://cdn.keihong.tech/typora/2023-02-15-%E5%8D%8A%E5%B9%B4%E6%9C%8D%E5%8A%A1%E4%BB%B7%E6%A0%BC-unsel.png',
      serveType2HalfSel:
        'https://cdn.keihong.tech/typora/2023-02-15-%E5%8D%8A%E5%B9%B4%E6%9C%8D%E5%8A%A1%E4%BB%B7%E6%A0%BC-sel.png',
      serveType: 0,
      serverType: '',
      goods: '',
      agreement: {
        htmlContent: '加载中...'
      }
    }
  },
  created() {
    this.loadHtmlFile()
    const profile = this.$store.state.apply20230001.profile
    console.log('提交信息', profile)
    profile.status = 2
    this.$store.commit('apply20230001/update', profile)
  },
  methods: {
    async loadHtmlFile() {
      try {
        const response = await fetch(
          'https://cdn.keihong.tech/img/202307051844381.html'
        )
        this.agreement.htmlContent = await response.text()
      } catch (error) {
        console.error('Failed to load HTML file:', error)
      }
    },
    handleUnfreeUser() {
      // Dialog.alert({
      //   message:
      //     '非低保低收入家庭，请联系康复科助理，咨询更多详情活动\n咨询电话: 18520535303'
      // }).then(() => {
      //   // on close
      // })
      this.$router.push('/ServeSale202301/detail/2/needpay')
    },
    handleAcceptAgreement() {
      this.acceptAgreement = !this.acceptAgreement
    },
    handleWatchAgreement() {
      // if (!this.showTest) {
      //   return
      // }
      this.showAgreement = !this.showAgreement
    },
    handleAcceptAgreementInDetail() {
      this.acceptAgreement = true
      this.showAgreement = false
    },
    handleNextClick() {
      if (this.homeType === 0) {
        Dialog({ message: '请选择资助类型' })
        return
      }
      if (this.serveType === 0) {
        Dialog({ message: '请选择服务时长' })
        return
      }
      const profile = this.$store.state.apply20230001.profile
      profile.goods = this.goods
      profile.serveType = this.serveType
      this.$store.commit('apply20230001/update', profile)
      this.$router.push('/ServeSale202301/detail/3')
    },
    onBackClick() {
      const profile = this.$store.state.apply20230001.profile
      console.log('提交信息', profile)
      // this.$router.push('/ServeSale202301/detail/1')
      this.$router.back()
    },
    handleHomeType1() {
      if (this.homeType === 1) {
        this.homeType = 0
        this.onHomeTypeChange()
        return
      }
      this.homeType = 1
      this.homeType1Sel = this.homeType1SelPic
      this.homeType2Sel = this.homeType2UnSelPic
      this.onHomeTypeChange()
    },
    handleHomeType2() {
      this.homeType = 2
      this.homeType1Sel = this.homeType1UnSelPic
      this.homeType2Sel = this.homeType2SelPic
      this.onHomeTypeChange()
      this.amount = '未选择服务类型'
    },
    onHomeTypeChange() {
      switch (this.homeType) {
        case 1:
          this.serveType1 = this.serveType1FreefSel
          this.serveType = 1
          this.onServeTypeChange()
          break
        case 2:
          this.serveType1 = this.serveType1HalfUnSel
          this.serveType2 = this.serveType2HalfUnSel
          this.serveType = 0
          break
        case 0:
          this.serveType = 0
      }
    },
    handleServeTypeClick(type) {
      if (this.serveType === 1) {
        return
      }
      this.serveType = type
      if (type === 2) {
        this.serveType1 = this.serveType1HalfSel
        this.serveType2 = this.serveType2HalfUnSel
      }
      if (type === 3) {
        this.serveType1 = this.serveType1HalfUnSel
        this.serveType2 = this.serveType2HalfSel
      }
      this.onServeTypeChange()
    },
    onServeTypeChange() {
      const activity = this.$store.state.apply20230001.profile.fromActivity
      if (this.serveType === 1) {
        this.amount = '0.00'
        this.total = '0.00'
        this.goods = '季度(免费-全额资助)'
        this.serverType = '季度服务'
      }
      if (this.serveType === 2) {
        this.total = '3200'
        this.amount = '1600.0'
        this.goods = activity + '-' + '季度(半价)'
        this.serverType = '季度服务'
      }
      if (this.serveType === 3) {
        this.total = '6000'
        this.amount = '3000.0'
        this.goods = activity + '-' + '半年(半价)'
        this.serverType = '半年服务'
      }
    }
  }
}
</script>

<style scoped>
.img_item {
  display: block;
  width: 100%;
}
</style>
