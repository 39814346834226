import { render, staticRenderFns } from "./BedPayKepu.vue?vue&type=template&id=9f64bba0&scoped=true"
import script from "./BedPayKepu.vue?vue&type=script&lang=js"
export * from "./BedPayKepu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f64bba0",
  null
  
)

export default component.exports