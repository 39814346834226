<template>
  <div class="feeds-list-item van-hairline--bottom">
    <div class="title van-multi-ellipsis--l2">
      <span>{{ title }}</span>
    </div>
    <!-- 文章类型 -->
    <template v-if="type === 1">
      <div
        v-if="coverStyle !== 'one-only'"
        class="summary van-multi-ellipsis--l3"
      >
        <span>{{ summary }}</span>
      </div>
      <van-row v-if="coverStyle === 'triple'" gutter="2">
        <van-col v-for="(url, index) in coverList" :key="index" span="8">
          <div class="picture-container">
            <van-image
              :src="url"
              fit="cover"
              height="100%"
              radius="3"
              width="100%"
            />
          </div>
        </van-col>
      </van-row>
      <van-row v-if="coverStyle === 'one-only'" gutter="18">
        <van-col span="8">
          <div class="picture-container">
            <van-image
              :src="coverList[0]"
              fit="cover"
              height="100%"
              radius="3"
              width="100%"
            />
          </div>
        </van-col>
        <van-col span="16">
          <div class="summary van-multi-ellipsis--l3">
            <span>{{ summary }}</span>
          </div>
        </van-col>
      </van-row>
    </template>
    <!-- 图片类型 -->
    <!-- TODO 多种图片数量组合样式的适配 -->
    <template v-if="type === 2">
      <div class="summary bigger-text van-multi-ellipsis--l5">
        <span>{{ summary }}</span>
      </div>
      <van-row gutter="4" type="flex">
        <van-col v-for="(url, index) in picFullUrlList" :key="index" span="8">
          <div
            class="picture-container square-mode"
            @click.stop="handleToPreviewImage(index)"
          >
            <van-image
              :src="url"
              fit="cover"
              height="100%"
              radius="6"
              width="100%"
            />
          </div>
        </van-col>
      </van-row>
    </template>
    <!-- 视频类型 -->
    <template v-if="type === 3">
      <div class="summary bigger-text van-multi-ellipsis--l5">
        <span>{{ summary }}</span>
      </div>
      <VideoPlayer :poster-url="cover" :videoUrl="videoUrl"/>
    </template>
    <div v-if="type === 1" class="footer">{{ browseNumber }} 阅读</div>
    <div v-if="$store.state.user.canShowMall && showMall"
         style="width: 100%;text-align: center;font-size: 12px;box-sizing: border-box;padding: 16px"
         @click="handleClickToolMall">
      点击了解{{
        type === 3 ? '视频' : '图文'
      }}中提到的辅具>>
    </div>
  </div>
</template>

<!-- 列表项 -->
<script>
import VideoPlayer from '../../common-components/VideoPlayer/VideoPlayer.vue'
import systemConfig from '../../constants/systemConfig'
import { ImagePreview } from 'vant'

export default {
  components: { VideoPlayer },
  props: {
    type: Number,
    title: String,
    summary: String,
    cover: String,
    picList: Array,
    video: String,
    browseNumber: Number,
    tool: Boolean,
    mallGoodsId: Number
  },
  data () {
    return {
      OSS_HOST: systemConfig.OSS_HOST
    }
  },
  computed: {
    showMall () {
      return this.tool && this.mallGoodsId && this.type === 3
    },
    coverList () {
      let result = []
      if (typeof this.cover === 'string') {
        result = this.cover.split(',').map(url => `${this.OSS_HOST}${url}`)
      }
      return result
    },
    coverStyle () {
      const coverUrlCount = this.coverList?.length
      if (coverUrlCount === 3) return 'triple'
      if (coverUrlCount === 1) return 'one-only'
      return 'none'
    },
    picFullUrlList () {
      let result = []
      if (Array.isArray(this.picList)) {
        result = this.picList.map(url => `${this.OSS_HOST}${url}`)
      }
      return result
    },
    videoUrl () {
      return this.video ? `${this.OSS_HOST}${this.video}` : ''
    }
  },
  methods: {
    handleClickToolMall () {
      // 跳转
      this.$router.push('/MallDetailPage?from=kepulist&goodId=' + this.mallGoodsId)
    },
    handleToPreviewImage (itemIndex) {
      ImagePreview({
        images: this.picFullUrlList,
        startPosition: itemIndex
      })
    }
  }
}
</script>
<style lang="less" scoped>
.feeds-list-item {
  background-color: #f1f1f1;
  padding: 8px 8px;
  margin: 10px 0px;
  border-radius: 14px;

  .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
  }

  .summary {
    margin-bottom: 10px;
    font-size: 14px;
    color: rgb(100, 100, 100);
    line-height: 1.4rem;
    text-align: justify;

    &.bigger-text {
      font-size: 16px;
      line-height: 1.5rem;
    }
  }

  .picture-container {
    width: 100%;
    aspect-ratio: 4 / 3;

    &.square-mode {
      margin: 2px;
      aspect-ratio: 1 / 1;
      overflow: hidden;
    }
  }

  .footer {
    color: #999;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    font-size: 12px;
    line-height: 0.32rem;
    margin-top: 16px;
  }
}
</style>
