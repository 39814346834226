<template>
  <div>
    <van-nav-bar
      left-arrow
      left-text="退出"
      title="分销个人中心"
      @click-left="onBackClick"
    />
    <div style="box-sizing: border-box;width: 100vw;padding: 16px">
      <div class="user-info">
        <span class="name">{{ user.name }}</span>
        <span class="phone">{{ user.phone }}</span>
      </div>
      <!--      <span v-if="user.referralCode" class="phone">【分销邀请码：{{ user.referralCode }}】</span>-->
      <div
        style="margin-top: 8px;box-sizing: border-box;height: 8px;background-color: #454545;width: 100%;"></div>
      <div
        style="margin-top: 4px;box-sizing: border-box;height: 1px;background-color: #454545;width: 100%;"></div>
      <div style="text-align: center;margin-top: 16px">
        <div style="font-size: 32px;font-weight: bold">{{ toBeWithdrawn }}</div>
        <div style="font-size: 12px">可提现余额(未扣税)</div>
      </div>
      <div class="user-info" style="margin-top: 16px">
        <span class="name">已提现金额(元)</span>
        <span class="phone">{{ user.withdrawal }}</span>
      </div>
      <div class="user-info" style="margin-top: 16px">
        <span class="name">冻结金额(元)</span>
        <span class="phone">{{ frozenAmount }}</span>
      </div>
      <div class="name" style="font-size: 12px;color: #686868">*订单签收7天后无退货方可提现</div>
      <div class="user-info" style="margin-top: 16px" @click="onDetailClick">
        <span class="name" style="color: #454545">明细</span>
        <span class="phone">{{ '>' }}</span>
      </div>
      <div v-if="user.role === '一级分销者'" class="user-info" style="margin-top: 16px"
           @click="onNextDistributionClick">
        <span class="name" style="color: #3a3a3a">我邀请的二级分销团队</span>
        <span class="phone">{{ '>' }}</span>
      </div>
      <div v-if="user.role === '二级分销者'" class="user-info" style="margin-top: 16px">
        <span class="name" style="color: #3a3a3a">邀请者</span>
        <span class="phone">{{ firstInviter.name }}</span>
      </div>
      <div class="user-info" style="margin-top: 16px"
           @click="onDistributionRankClick">
        <span class="name" style="color: #3a3a3a">分销排行榜</span>
        <span class="phone">{{ '>' }}</span>
      </div>
      <div style="position: fixed;bottom: 0;width: 100%;right: 0;left: 0;padding: 16px;box-sizing: border-box">
        <div class="btn" @click="onShareMallGoodsClick">分享产品</div>
        <div class="btn" @click="onWithdrawnClick">提现</div>
        <div v-if="user.role=== '一级分销者'" class="btn" @click="onInviteClick">邀请二级分销</div>
      </div>
    </div>
    <van-popup v-model="showKefu" position="bottom">
      <div style="text-align: center">
        <img
          alt="图片"
          src="https://cdn.keihong.tech/img/202405122113097.png"
          style="margin-top: 16px"
          width="25%"
        />
        <div style="margin-bottom: 24px">添加客服微信，申请提现</div>

      </div>
    </van-popup>
    <van-popup v-model="showInvite" position="bottom" style="height: 50%">
      <div style="text-align: center">
        <div style="height: 16px"></div>
        <div style="margin-bottom: 24px;font-size: 10px;color: grey">二级分销</div>
        <div style="padding-right: 16px;padding-left: 16px">
          使用微信扫描以下二维码
        </div>
        <div style="margin-bottom: 24px;padding-right: 16px;padding-left: 16px">
          即可注册为康复辅具分销人员
        </div>
        <qrcode-vue v-if="showInvite" :margin="10" :size="100" :value="inviteUrl" level="H"></qrcode-vue>
        <div style="height: 16px"></div>
        <div>可截图保存到手机相册，通过微信转发</div>
        <div>或直接让二级分销人员扫码注册</div>
        <div style="height: 48px"></div>

      </div>
    </van-popup>
  </div>

</template>

<script>

import DistributionDao from '@/h5/doucan/pages/distribution/DistributionDao'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'DistributionUserPage',
  components: { QrcodeVue },

  data () {
    return {
      user: null,
      toBeWithdrawn: '加载中',
      frozenAmount: '加载中',
      showKefu: false,
      firstInviter: null,
      showInvite: false,
      inviteUrl: null

    }
  },
  created () {
    this.user = this.$store.state.distributor.data
    console.log('user', this.user)
    DistributionDao.loadUser(this.user.id).then(res => {
      console.log('loadDistributor', res)
      this.user = res
      this.$store.commit('distributor/setData', res)
      this.loadToBeWithdrawn()
      if (this.user.role === '一级分销者') {
        //

      }

      if (this.user.role === '二级分销者') {
        // 读取上级邀请者
        DistributionDao.loadUser(this.user.upperDistributorId).then(res => {
          this.firstInviter = res
        })
      }
    })
  },
  methods: {
    loadToBeWithdrawn () {
      DistributionDao.loadDetails(this.user.id).then(res => {
        this.toBeWithdrawn = 0
        this.frozenAmount = 0
        console.log('loadDetails', res)
        let toBeWithdrawn = 0
        if (res && res.length > 0) {
          res.forEach(item => {
            if (item.orderStatus === 3 && this.isOver7Days(item.gmtModified)) {
              toBeWithdrawn += item.distributorId === this.user.id ? item.distributionFee : item.distributionFeeSecond
            } else {
              this.frozenAmount += item.distributorId === this.user.id ? item.distributionFee : item.distributionFeeSecond
            }
          })
        }
        this.toBeWithdrawn = toBeWithdrawn - this.user.withdrawal
      })
    },
    onShareMallGoodsClick () {
      console.log('onShareMallGoodsClick')
      this.$router.push({
        path: '/MallMainPageNeimeng',
        query: {
          distributor: true
        }
      })
    },
    onDetailClick () {
      console.log('onDetailClick')
      this.$router.push({
        path: '/DistributionDetailsPage'
      })
    },
    onNextDistributionClick () {
      this.$router.push({
        path: '/DistributionMySecondListPage'
      })
    },
    onDistributionRankClick () {
      this.$router.push({
        path: '/DistributionRankPage'
      })
    },
    onWithdrawnClick () {
      console.log('onWithdrawnClick')
      this.showKefu = true
    },
    onInviteClick () {
      // todo 生成邀请文案+url
      const baseUrl = 'https://doucan.jianerkanggz.cn'
      // const baseUrl = 'http://192.168.0.119:8094/doucan'
      const url = `${baseUrl}/#/DistributionLoginPage?userType=2&inviterId=${this.user.id}`
      this.inviteUrl = url
      this.showInvite = true

      const shareText = `${this.user.name} 邀请您加入动优严选分销系统，点击以下链接进行注册：【${url}】`
      this.$copyText(shareText).then(e => {
        console.log('剪切板', e)
        this.$dialog({
          title: '复制成功，发送给好友吧',
          message: shareText,
          confirmText: '确认',
          messageAlign: 'left'
        })
      })
    },
    onBackClick () {
      this.$router.push('/DistributionLoginPage')
    },
    isOver7Days (gmtModified) {
      const now = new Date()
      const modified = new Date(gmtModified)
      const diff = now - modified
      const days = Math.floor(diff / (1000 * 60 * 60 * 24))
      if (days >= 7) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>
.user-info {
  display: flex;
  align-items: center;
}

.name {
  margin-right: auto; /* 将姓名推到左侧 */
}

.phone {
  /* 可以添加其他样式，比如颜色、字体大小等 */
}

.btn {
  width: 100%;
  height: 48px;
  line-height: 48px;
  margin-bottom: 8px;
  border-radius: 8px;
  text-align: center;
  background-color: #007373;
  color: white;
}
</style>
