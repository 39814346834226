<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar :back-text="backText" back style="position:fixed;top: 0;width: 100%" title="辅具商城"
              @onBackClick="onBackClick"/>
    <div style="margin-top: 46px">
      <div style="display: flex;">
        <div style="width: 30vw;background-color: #f3f3f3;height: 95vh">
          <div>
            <div v-for="(item) in catalogList" :key="item.id"
                 style="height: 56px;text-align: center;line-height: 56px;display: flex;flex-direction: row"
                 @click="onCatalogClick(item)">
              <div :style="{backgroundColor: item.selected?'#00b74f':'#b7b7b7'}" style="height: 56px;width: 8px;"></div>
              <div :style="{backgroundColor: item.selected?'#00b74f':'#e0e0e0'}" style="flex: 1"> {{
                  item.name
                }}
              </div>
            </div>
          </div>
        </div>
        <div
          style="width: 70vw;background-color: #ffffff;height: 95vh; /* 设置右侧高度为视口高度 */overflow: hidden">
          <!--  商品列表 使用vant -->
          <!--    <van-list :finished="finished" finished-text="没有更多了">-->
          <div style=" max-height: 100%;overflow-y: auto; /* 允许内容溢出时显示垂直滚动条 */">
            <div v-for="(item, index) in goods" :key="index">
              <van-card
                :desc="item.brief"
                :num="item.stock"
                :origin-price="item.originPrice"
                :price="item.price"
                :thumb="item.smallPicUrl"
                :title="item.name"
                @click="handleGoodClick(item)"
              >
                <template #footer>
                  <van-button size="mini">查看详情</van-button>
                  <!--            <van-button size="mini">按钮</van-button>-->
                </template>
              </van-card>
              <div style="height: 16px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <van-popup v-model="isShowDeliveryToBed" closeable position="bottom">
        <div style="padding: 16px">
          <img alt="Your Image Description" src="https://cdn.keihong.tech/img/202312181748503.png"
               style="width: 100%;display: block;margin-top: 32px"/>
          <div style="height: 16px"></div>
          <div style="font-size: 18px">
            💖“必备辅具”板块下的辅具是在住院手术/康复期间的刚需辅具，我们将提供免费送货上病房服务。
          </div>
          <div style="height: 16px"></div>
          <div style="font-size: 18px">
            🔔如您正在支持送货上病房的医院住院，可在2~24小时内为您免费送达病房。
          </div>
          <div style="height: 32px"></div>
          <div
            style="font-size: 18px;text-align: center;background-color: #136b59;padding: 16px;border-radius: 16px;color: white">
            <div style="margin-bottom: 16px">服务详情请致电咨询</div>
            <a :href="'tel:' + kefuphoneNumber" style="color: white">客服电话：{{ kefuphoneNumber }}</a>
            <div style="font-size: 14px">（点击上方电话号码可直接拨打📳）</div>

          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import MallDao from '@/h5/doucan/network/MallDao'

export default {
  name: 'MallMainPageV2',
  components: { MyNavBar },
  data () {
    return {
      kefuphoneNumber: '18902270462',
      finished: false,
      loading: false,
      from: '',
      backRoute: '',
      goods: [],
      backText: '',
      fromToolKepu: false,
      hospitalName: '',
      catalogList: [],
      currentCatalog: null,
      isShowDeliveryToBed: false
    }
  },
  mounted () {
    // 读取query参数
    const query = this.$route.query
    if (query.from === 'toolkepu') {
      this.fromToolKepu = true
      this.backText = '返回'
    } else {
      this.backText = '康复圈'
    }
    if (query.hospital) {
      this.hospitalName = query.hospital
    }
    // if (this.from) {
    //   if (this.from === 'square') {
    //     // 从康复圈页面进来（常规渠道）
    //     this.backRoute = '' // 返回按钮直接是返回
    //     return
    //   }
    //   // 从独立的商城二维码进来
    //   if (this.from === 'qrCode') {
    //     return
    //   }
    //   // 从科普文章进来(有专门的产品)
    //   if (this.from === 'article') {
    //
    //   }
    // }
    this.loadCatalogs()
    // this.loadGoods()
  },
  methods: {
    onBackClick () {
      if (this.fromToolKepu) {
        this.$router.back()
        return
      }
      console.log('返回')
      this.$router.replace('/home/square')
    },
    onCatalogClick (item) {
      this.currentCatalog.selected = false
      item.selected = true
      this.currentCatalog = item
      this.loadGoods()
    },
    loadCatalogs () {
      if (!this.hospitalName) {
        this.hospitalName = this.$store.state.user.qrCodeHospital
      }
      console.log('hospitalName', this.hospitalName)
      if (this.hospitalName !== '中国民间中医医药研究开发协会中蒙民族医药研究发展分会') {
        this.hospitalName = '动优康复'
        // this.isShowDeliveryToBed = true
      }
      MallDao.cataloglist(this.hospitalName).then(res => {
        console.log('catalogs', res)
        res.forEach(item => {
          item.selected = false
        })
        res[0].selected = true
        this.currentCatalog = res[0]
        this.catalogList = res
        this.loadGoods()
      })
    },
    async loadGoods () {
      console.log('读取商品目录下的产品： cata id = ' + this.currentCatalog.id)
      this.goods = await MallDao.listByCatalog(this.currentCatalog.id)
      if (this.hospital !== '中国民间中医医药研究开发协会中蒙民族医药研究发展分会') {
        // 倒序 goods
        this.goods = this.goods.reverse()
      }
      console.log('goods', this.goods)
    },
    handleGoodClick (item) {
      // 跳转到MallDetailPage: /MallDetailPage?goodId=item.id
      this.$router.push('/MallDetailPage?from=malllist&goodId=' + item.id)
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
