<template>
  <!--仪器押金支付页面（汕大附一版本）  -->
  <div>
    <!--    <div class="title-bar">付款</div>-->
    <base-title-bar-scroll-page ref="container" :back="true" back-text="主页" title="支付" @onBackClick="onBackClick">
      <div style="margin: 16px;background-color:white;border-radius: 16px;padding-top: 16px">
        <div style="font-weight: bold;font-size: 20px;text-align: center">仪器租赁押金单</div>
        <div style="font-size: 14px;text-align: center;margin-bottom: 16px">{{ hospital }}</div>
        <div class="divider"></div> <!-- Divider line -->
        <!--        <div id="payTitle"-->
        <!--             style="display: flex;background-color: white;border-radius: 16px;padding: 8px;align-items: center;">-->
        <!--          &lt;!&ndash;          <img alt="Logo" class="logo" src="https://cdn.keihong.tech/img/202312211710121.png">&ndash;&gt;-->
        <!--          &lt;!&ndash;          <div class="institution-name">{{ hospital }}</div>&ndash;&gt;-->
        <!--        </div>-->

        <!--        <div class="inputBoxWrapper">-->
        <!--          <van-field-->
        <!--            v-model="amount"-->
        <!--            class="inputBox"-->
        <!--            label="付款金额"-->
        <!--            placeholder="请输入付款金额"-->
        <!--            type="number"-->
        <!--          >-->
        <!--            <template #button>-->
        <!--              <span>元</span>-->
        <!--            </template>-->
        <!--          </van-field>-->
        <!--        </div>-->
        <div style="box-sizing: border-box;padding: 16px">
          <van-field label="租赁周期" required>
            <template #input>
              <div style="width: 100%;">
                <div class="right">
                  <div style="display: flex;flex-direction: row;justify-items: center">
                    <van-stepper v-model="form.count" integer min="1" style="padding: 0;margin: 0"/>
                    <div style="margin-left: 8px;line-height: 28px">个月</div>
                  </div>
                </div>
                <van-cell v-show="false" :value="form.count" title="服务周期">
                  <!--修改单位为周-->
                  <template #right-icon>
                    <span style="font-size: 12px">个月</span>
                  </template>
                </van-cell>
                <van-cell v-show="false" :value="form.price" title="服务单价">
                  <!--修改单位为元-->
                  <template #right-icon>
                    <span style="font-size: 12px">元/月</span>
                  </template>
                </van-cell>
                <van-cell :value="form.price  " title="押金">
                  <!--修改单位为元-->
                  <template #right-icon>
                    <span style="font-size: 12px">元/设备</span>
                  </template>
                </van-cell>
                <!--            <van-cell :value="form.count * form.price * 0.5" title="实际支付">-->
                <!--              &lt;!&ndash;修改单位为元&ndash;&gt;-->
                <!--              <template #right-icon>-->
                <!--                <span style="font-size: 12px">元</span>-->
                <!--              </template>-->
                <!--            </van-cell>-->
              </div>
            </template>
          </van-field>

          <!--          <van-field v-model="selectedHospital" label="选择医院" placeholder="请选择医院" @change="updateDepartments"/>-->
          <!--          <div style="color: #6e6e6e">请选择您所在的科室</div>-->
          <van-field v-show="false" label="病因" required>
            <template #input>
              <div style="width: 100%;text-align: right">
                <van-radio-group v-model="selectedSick" class="right"
                                 style="display: flex;flex-direction: row;flex-wrap: wrap">
                  <van-radio v-for="sick in sicks" :key="sick"
                             :name="sick"
                             style="margin-top: 8px;margin-left: 8px">{{
                      sick
                    }}
                  </van-radio>
                </van-radio-group>
              </div>
            </template>
          </van-field>
          <van-field label="科室" required>
            <template #input>
              <div style="width: 100%;text-align: right">
                <van-radio-group v-model="selectedDepartment" class="right"
                                 style="display: flex;flex-direction: row;flex-wrap: wrap"
                                 @change="onDepartmentSelected">
                  <van-radio v-for="department in hospitals[hospitalId].departments" :key="department.id"
                             :name="department"
                             style="margin-top: 8px;margin-left: 8px">{{
                      department.name
                    }}
                  </van-radio>
                </van-radio-group>
              </div>
            </template>
          </van-field>
          <van-field v-if="selectedDepartment" v-show="false" label="服务管理医生/治疗师" required>
            <template #input>
              <div style="width: 100%;text-align: right">
                <van-radio-group v-model="selectedDoctor" class="right"
                                 style="display: flex;flex-direction: row;flex-wrap: wrap">
                  <van-radio v-for="doctor in selectedDepartment.doctors" :key="doctor"
                             :name="doctor"
                             style="margin-top: 8px;margin-left: 8px">{{
                      doctor
                    }}
                  </van-radio>
                </van-radio-group>
              </div>
            </template>
          </van-field>
        </div>
        <div class="input-wrapper">
          <van-field
            v-model="patientName"
            label="姓名"
            placeholder="请输入姓名"
            required
            type="text"
          />
          <van-field
            v-model="patientMobile"
            label="手机号"
            placeholder="请输入手机号"
            required
            type="tel"
          />
        </div>
        <!--        <div class="textarea-wrapper">-->
        <!--          <van-field-->
        <!--            v-model="remarks"-->
        <!--            placeholder="请输入备注"-->
        <!--            rows="5"-->
        <!--            style=" border: 1px solid #ccc;border-radius: 8px;"-->
        <!--            type="textarea"-->
        <!--          />-->
        <!--        </div>-->
        <div class="button-wrapper">
          <van-button style="width: 100%;margin-bottom: 16px" type="primary" @click="submitPayment">立即支付
          </van-button>
        </div>
      </div>
      <div style="text-align: center;font-size: 14px;color: #494949">技术支持：动优远程康复服务云平台</div>
    </base-title-bar-scroll-page>
    <van-popup v-model="showPopup" :close-on-click-overlay="false" class="popup-wrapper" position="bottom">
      <div style="padding: 16px;text-align: center">
        <img alt="Success Logo" class="success-logo" src="https://cdn.keihong.tech/img/202312211813200.png">
        <div class="success-message">支付成功</div>
        <div style=" text-align: center;font-size: 14px;margin: 20px 0;">订单编号：{{ outTradeNo }}</div>
        <div style=" text-align: center;font-size: 14px;margin: 20px 0;">{{ orderCreateTime }}</div>
        <div style=" text-align: center;font-size: 18px;margin: 20px 0;">支付服务</div>
        <div style=" font-size: 14px;margin: 20px 0;text-align: left">{{ this.remark }}</div>
        <div style="color: red;font-size: 20px;font-weight: bold">
          请截图当前页面
        </div>
        <div style="color: #272727;text-align: left;font-size: 18px">
          请截图当前页面，保留订单信息，有疑问可联系康复助理了解详情
        </div>
        <van-button style="width: 100%;margin-top: 16px" type="primary" @click="refreshPage">确定</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>

import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import { Dialog, Notify, Toast } from 'vant'
import WxPayDao from '@/h5/doucan/network/WxPayDao'
import wx from 'weixin-jsapi'
import axios from 'axios'
import DateUtils from '@/utils/DateUtils'

export default {
  name: 'QuickPaySecurityDeposit1Page',
  components: { BaseTitleBarScrollPage },
  data () {
    return {
      showPopup: false,
      hospital: '',
      hospitalId: null,
      amount: '',
      remarks: '',
      patientName: '',
      patientMobile: '',
      testMobile: ['18008000808', '13610164743', '18520535303', '18501011010'],
      openId: '',
      payStatus: false,
      appsecret: '',
      appid: '',
      code: '',
      openid: '',
      paySignInfo: null,
      outTradeNo: '', // 动优支付系统里面的订单号 ordernum
      remark: '',
      hospitals: {
        48: {
          name: '汕大附一互联网医院',
          departments: [
            {
              name: '骨科'
              // doctors: ['王医生', '李医生', '张医生']
            },
            {
              name: '康复科'
              // doctors: ['王医生', '李医生', '张医生']
            },
            {
              name: '神经内科'
              // doctors: ['王医生', '李医生', '张医生']
            }
          ]
        }
      },
      // 脑梗塞/脑出血/脑损伤/骨科术后/慢性疼痛/运动损伤/脊柱康复/其它
      sicks: ['脑梗塞', '脑出血', '脑损伤', '骨科术后', '慢性疼痛', '运动损伤', '脊柱康复', '其它'],
      selectedSick: '',
      selectedDepartment: null,
      selectedDoctor: null,
      form: {
        count: 1,
        price: 1200
        // deposit: 1200
      },
      orderCreateTime: null
    }
  },
  mounted () {
    this.$refs.container.stopLoading()
    this.hospitalId = this.$route.query.hospital_id
    this.hospital = this.hospitals[this.hospitalId].name

    console.log('hospitalId: ' + this.hospitalId)
    console.log('hospital: ' + this.hospital)

    // if (this.hospitalId) {
    //   HospitalDao.getDoctorsForSelect(this.hospitalId).then(response => {
    //     this.hospitals[this.hospitalId].doctors = response
    //     console.log('doctors: ' + response)
    //   })
    // }

    // 判断是否是微信浏览器
    const isWeChatBrowser = () => {
      const userAgent = navigator.userAgent.toLowerCase()
      return userAgent.includes('micromessenger')
    }

    if (isWeChatBrowser()) {
      // console.log("This is a WeChat browser");
      this.initWx()
    } else {
      // console.log("This is not a WeChat browser");
    }

    // const ua = window.navigator.userAgent.toLowerCase()
    // if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    //   // alert('是微信浏览器')
    //   // 初始化微信-获取openid
    //   this.initWx()
    // }
  },
  methods: {
    onBackClick () {
      // 重定向到某个url
      if (this.hospital === '内蒙古益济堂养老服务有限公司') {
        this.hospital = '中国民间中医医药研究开发协会中蒙民族医药研究发展分会'
      }
      if (this.hospital === '汕大附一互联网医院') {
        this.hospital = '汕头大学医学院第一附属医院'
      }
      window.location.href = 'https://doucan.jianerkanggz.cn/#/home/square?hospital=' + this.hospital
    },
    refreshPage () {
      this.showPopup = false
      // location.reload()
      // window.location.href = 'https://doucan.jianerkanggz.cn/#/home/square?hospital=' + this.hospital
      this.onBackClick()
    },
    submitPayment () {
      // Add your payment submission logic here
      this.pay()
    },
    async initWx () {
      await this.getAppid()
      const openid = this.$store.state.user.openId
      if (openid) {
        this.openid = openid
      } else {
        await this.getMpOpenID()
      }
    },
    async pay () {
      if (!this.selectedSick) {
        this.selectedSick = '无'
      }

      if (!this.selectedDepartment) {
        Notify({
          type: 'danger',
          message: '请选择科室'
        })
        return
      }

      if (!this.selectedDoctor) {
        this.selectedDoctor = '无'
      }

      if (!this.patientName || !this.patientMobile) {
        Notify({
          type: 'danger',
          message: '请填写姓名和手机号'
        })
        return
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      let fee = 0
      if (this.testMobile.includes(this.patientMobile)) {
        fee = 0.01
      } else {
        fee = this.totalPrice
      }
      const fromActivity = '扫码支付-仪器租赁押金'
      // const goods = this.hospital + '-' + this.remarks
      const goods = this.hospital + '-' + '仪器租赁押金-' + this.form.count + '月' + '-d' + this.selectedDepartment.name + '-p' + this.patientName
      // if (this.isAdmin) {
      //   fromActivity = '客服操作下单' + '-' + this.remark
      //   goods = this.hospital + '-' + this.doctorName + '-' + this.count + '周'
      // }

      // this.remark = '远程康复服务单-' + this.hospital + '-' + this.selectedDepartment.name + '-' + this.selectedDoctor + '-' + this.selectedSick
      this.remark = `仪器租赁押金-${this.form.count}月-${this.hospital}-${this.selectedDepartment.name}-${this.patientName}-${this.patientMobile}`
      // this.showPopup = true
      const profile = {
        serveType: 0,
        mobile: this.patientMobile, // 后期mobile从用户授权获得，即订单记录支付者登录的微信账号手机号及实际接受服务患者的手机号
        patientName: this.patientName,
        patientMobile: this.patientMobile,
        orderType: '',
        fee: fee,
        fromActivity: fromActivity,
        goods: goods,
        openId: this.openid,
        doctor: this.selectedDoctor,
        sick: this.selectedSick,
        department: this.selectedDepartment.name,
        hospital: this.hospital,
        remark: this.remark
      }
      console.log('提交订单', profile)
      this.orderCreateTime = DateUtils.formateDate(new Date(), 'YYYY-MM-DD HH:mm:ss')
      const data = await WxPayDao.createOrder(profile)

      console.log('支付qrcode', data)
      if (data.code === 1) {
        this.paySignInfo = data.data
        this.outTradeNo = data.data.outTradeNo
        // 调起微信支付
        wx.config({
          // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false
          debug: false,
          // 必填，公众号的唯一标识
          appId: this.paySignInfo.sign.appId,
          // 必填，生成签名的时间戳
          timestamp: this.paySignInfo.sign.timeStamp,
          // 必填，生成签名的随机串
          nonceStr: this.paySignInfo.sign.nonceStr,
          // 必填，签名
          signature: this.paySignInfo.sign.paySign,
          // 必填，需要使用的JS接口列表
          jsApiList: ['chooseWXPay']
        })
        wx.ready(() => {
          wx.checkJsApi({
            jsApiList: ['chooseWXPay'],
            success: function (res) {
              console.log('seccess')
              console.log('hskdjskjk', res)
            },
            fail: function (res) {
              console.log('fail')
              console.log(res)
            }
          })
          const _that = this
          wx.chooseWXPay({
            // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            timestamp: this.paySignInfo.sign.timeStamp,
            // 支付签名随机串，不长于 32 位
            nonceStr: this.paySignInfo.sign.nonceStr,
            // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            package: this.paySignInfo.sign.packageValue,
            // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            signType: this.paySignInfo.sign.signType,
            // 支付签名
            paySign: this.paySignInfo.sign.paySign,
            // 支付成功后的回调函数
            success: function (res) {
              if (res.errMsg === 'chooseWXPay:ok') {
                console.log('支付成功', res)
                _that.payStatus = true
                Dialog.alert({
                  message: '支付成功'
                }).then(() => {
                  // on close
                  // todo 跳转到支付成功页面，显示支付凭证和提示
                  // _that.$router.push(
                  //   '/salepage/20230001/result?outTradeNo=' + _that.outTradeNo
                  // )
                  _that.showPopup = true
                })
              } else {
                Dialog({ message: JSON.stringify(res) })
                _that.payStatus = false
              }
            },
            fail: function (res) {
              Dialog({ message: '支付失败:' + JSON.stringify(res) })
              _that.payStatus = false
            }
          })
        })
        wx.error((err) => {
          alert(err)
        })
      } else {
        console.log('支付失败', data)
      }
    },
    async getOpenId (code) {
      // this.info = '正在获取getOpenId'
      const url = '/quick/pay/openid?code=' + code

      axios
        .get(url)
        .then((res) => {
          // this.info = JSON.stringify(res)
          this.openid = res.data.data.openid
          console.log('openid = ', res.data.data.openid)
          if (!this.openid) {
            this.getCodeApi()
            return
          }
          this.$store.commit('user/setOpenId', this.openid)
          // const profile = this.$store.state.apply20230001.profile
          // profile.openId = this.openid
          // this.$store.commit('apply20230001/update', profile)
        })
        .catch((err) => {
          console.log('err', err)
          this.getCodeApi()
        })
    },
    getCodeApi () {
      const appid = 'wxcb56667c309e369b'
      const urlNow = encodeURIComponent(window.location.href)
      const scope = 'snsapi_base' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      // const scope = 'snsapi_userinfo' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      const url =
        'https://open.weixin.qq.com' +
        '/connect/oauth2/authorize?appid=' +
        appid +
        '&redirect_uri=' +
        urlNow +
        '&response_type=code&scope=' +
        scope +
        '#wechat_redirect'
      window.location.href = url
    },
    getUrlKey (name) {
      // 获取url 参数
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [undefined, ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    },
    async getAppid () {
      const url = '/quick/pay/appid'
      const res = await axios.get(url)
      console.log('appid', res)
      this.appid = res.data.data.codeName
      this.appsecret = res.data.data.codeValue
    },
    async getMpOpenID () {
      const code = this.getUrlKey('code')
      if (code) {
        this.code = code
        await this.getOpenId(code)
      } else {
        this.getCodeApi()
      }
    },
    onDepartmentSelected () {
      console.log(this.selectedDepartment)
    }
  },
  watch: {
    'form.count' (val) {
      this.amount = val * this.form.price
      console.log('fee:', this.amount)
      // this.$emit('update-fee', this.fee, val)
    }
  },
  computed: {
    totalPrice () {
      return this.form.price
    }
  }
}
</script>
<style scoped>

.logo {
  float: left;
  width: 60px;
  height: 60px;
}

.institution-name {
  float: right;
  width: 100%;
  font-size: 14px;
  margin-left: 16px;
}

.divider {
  height: 1px;
  background-color: #ccc;
  margin: 16px;
}

.inputBoxWrapper {
  margin: 16px;
}

.inputBox {
  border: 1px solid #ccc;
  border-radius: 16px;
}

.textarea-wrapper {
  margin: 16px;
}

.textarea-wrapper .van-field__control {
}

.button-wrapper {
  margin: 16px;

}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
  color: #7e7e7e;
}

.input-wrapper {
  margin: 16px;
}

.popup-wrapper {
  height: 80vh;
}

.success-logo {
  width: 70px;
  height: 70px;
  margin: 0 auto;
}

.success-message {
  text-align: center;
  font-size: 20px;
  margin: 20px 0;
}

.right {
  float: right;
}

.van-cell__value {
  color: black;
}
</style>
