<template>
  <div class="grid-container">

    <div class="grid-item" :style="`width: calc(100%/${columnNum} - 20px)` /* 每行3列，减去间距的宽度 */"
         v-for="(item, index) in items" :key="index">
      <img v-if="!item.fake" :src="item.url" alt="image" width="100%" @click="handleClick(item,index)">
      <div v-else style="width: 100%"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Grid',
  props: {
    // 传入图片数组
    imageArray: {
      type: Array,
      default: () => []
    },
    // 宫格列数
    columnNum: {
      type: Number,
      default: 4
    }
  },
  data () {
    return {
      items: null
    }
  },
  mounted () {
    this.items = this.imageArray
    for (const itemsKey in this.items) {
      this.items[itemsKey].fake = false
    }
    // 计算数组最后一行缺少的个数
    const lastRowLackNum = this.columnNum - this.imageArray.length % this.columnNum
    console.log('缺少', lastRowLackNum)
    // 补充缺少的个数
    for (let i = 0; i < lastRowLackNum; i++) {
      this.items.push({ fake: true })
    }
  },
  methods: {
    handleClick (item, index) {
      this.$emit('click', item, index)
    }
  }
}
</script>

<style scoped>
.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.grid-item {
  margin-bottom: 10px; /* 间距 */
}
</style>
