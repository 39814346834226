<template>
  <div>
    <my-nav-bar :title="'基本信息'"/>
    <div style="padding: 16px">
      <div style="font-size: 22px;font-weight: lighter;text-align: center">请填写基本信息哦
      </div>
      <van-form style="margin-top: 32px" @submit="onSubmit">
        <van-field
          v-model="name"
          :rules="[{ required: true, message: '请填写姓名' }]"
          clearable
          label="姓名"
          name="name"
          placeholder="请输入 姓名"
          style="font-size: 20px"
        />
        <van-field :rules="[{ required: true, message: '请选择性别' }]" label="性别" name="sex"
                   style="font-size: 20px;margin-top: 8px;">
          <template #input>
            <van-radio-group v-model="sex" direction="horizontal">
              <van-radio name="男">男</van-radio>
              <van-radio name="女">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="age"
          :rules="[{ required: true, message: '请填写年龄' }]"
          clearable
          label="年龄"
          name="age"
          placeholder="请输入 年龄"
          style="font-size: 20px"
          type="number"
        />
        <div style="margin: 16px;">
          <van-button block color="#0d3d3d" native-type="submit" round style="font-size: 20px;height: 48px;margin-top: 32px"
                      type="info">
            填好了
          </van-button>
        </div>
      </van-form>
    </div>
  </div>

</template>

<script>
import MyNavBar from '../../components/MyNavBar'
import RegisterDao from '../../network/RegisterDao'

export default {
  name: 'UserBaseInfoEntryPage',
  components: { MyNavBar },
  data () {
    return {
      name: '',
      age: null,
      sex: '',
      result: null
    }
  },
  methods: {
    async onSubmit () {
      console.log('submit-name', this.name)
      console.log('submit-sex', this.sex)
      console.log('submit-age', this.age)

      this.$store.commit('login/setUserBaseInfo', {
        name: this.name,
        sex: this.sex,
        age: parseInt(this.age)
      })
      const { userAgent } = navigator
      console.log('设备', userAgent)
      this.$store.commit('login/setRegisterChannel', {
        channel: '网页版-动优-公众号',
        device: userAgent
      })

      console.log('store', this.$store.state.login.register)
      const result = await RegisterDao.postRegister(this.$store.state.login.register)
      console.log('注册返回结果', result)
      if (result) {
        this.result = result
        // 注册成功
        // 注册IM
        this.imRegist(result)
      }
    },
    imRegist (result) {
      this.imLogin()
    },
    imLogin () {
      this.onIMLoginSuccess()
    },
    onIMLoginSuccess () {
      // 记录登录信息
      this.$store.commit('user/setUser', {
        userId: this.result.userId,
        phone: this.result.phone,
        name: this.result.user.realname,
        passwd: this.result.passwd,
        token: this.result.token,
        hospitalName: this.result.hospitalName,
        isLogin: true
      })
      if (this.$store.state.user.qrCodeHospital === '宏乾健康') {
        this.$router.replace('/hongqian/person')
        return
      }
      // 跳转到主界面
      this.$router.replace('/home/home')
    }
  }
}
</script>

<style scoped>

</style>
