export default {
  namespaced: true,
  state () {
    return {
      addressInfo: {
        name: '',
        phone: '',
        province: '',
        city: '',
        region: '-1',
        address: '',
        id: -1
      },
      payInfo: null,
      mailInfo: null
    }
  },
  mutations: {
    // 修改用户信息，payload就是用户信息对象
    setAddressInfo (state, payload) {
      state.addressInfo = payload
    },
    setPayInfo (state, payload) {
      state.payInfo = payload
    },
    setMailInfo (state, payload) {
      state.mailInfo = payload
    }
  }
}
// ————————————————
// 版权声明：本文为CSDN博主「run-Ameng」的原创文章，遵循CC 4.0 BY-SA版权协议，转载请附上原文出处链接及本声明。
// 原文链接：https://blog.csdn.net/m0_58861443/article/details/120247013
