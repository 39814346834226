<template>
  <div style="padding: 16px;width: 100%;box-sizing: border-box">
    <div style="font-weight: bold">服务内容：</div>
    <div>1. 租赁 4通道FES低频治疗仪</div>
    <div>------</div>
    <div style="font-weight: bold">治疗仪适用于：</div>
    <div>通过最新技术“无错学习”法，进行步态训练、步态纠正、步态改善</div>
    <div style="font-weight: bold">适用对象：</div>
    <div>中风康复、脑损伤、脑瘫</div>
    <div style="font-weight: bold">费用说明：</div>
    <div style="">1、2400元/3个月，耗材960元/3个月（可增加数量，以3个月为一档次）</div>
    <div style="">2、押金2400元</div>
    <van-divider/>
    <div v-if="!patient" style="box-sizing: border-box">
      <van-field label="租赁时长（月）" required>
        <template #input>
          <div>
            <van-stepper v-model="form.count" :step="3" class="right" integer min="3"/>
            <van-cell :value="form.count" title="租赁时长">
              <!--修改单位为周-->
              <template #right-icon>
                <span style="font-size: 12px">个月</span>
              </template>
            </van-cell>
            <van-cell :value="form.price" title="服务单价">
              <!--修改单位为元-->
              <template #right-icon>
                <span style="font-size: 12px">元/3个月</span>
              </template>
            </van-cell>
            <van-cell :value="`${form.deposit}元`" title="押金"/>
            <van-cell :value="form.count/3 * form.price + form.deposit " title="总费用">
              <!--修改单位为元-->
              <template #right-icon>
                <span style="font-size: 12px">元</span>
              </template>
            </van-cell>
            <!--            <van-cell :value="form.count * form.price * 0.5" title="实际支付">-->
            <!--              &lt;!&ndash;修改单位为元&ndash;&gt;-->
            <!--              <template #right-icon>-->
            <!--                <span style="font-size: 12px">元</span>-->
            <!--              </template>-->
            <!--            </van-cell>-->
          </div>
        </template>
      </van-field>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ServiceTypeRentFesLowDevice',
  props: {
    patient: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fee: 0,
      form: {
        count: 1,
        price: 3360,
        deposit: 2400
      }
    }
  },
  created () {
    this.fee = this.form.count / 3 * this.form.price + this.form.deposit
    this.$emit('update-fee', this.fee, this.form.count)
  },
  watch: {
    'form.count' (val) {
      this.fee = val * this.form.price / 3 + this.form.deposit
      console.log('fee:', this.fee)
      this.$emit('update-fee', this.fee, val)
    }
  }
}
</script>

<style scoped>
.right {
  float: right;
}
</style>
