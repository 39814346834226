import axios from 'axios'

const AidsDao = {
  // 首页从0开始
  async list () {
    const url = 'api/v1/aids/list'
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }

}
export default AidsDao
