function getQuestionInfoDatas () {
  const questionInfoList = [
    createTitle(0, '脊柱侧弯评估表(AIS-SLS)'),
    createTitle(1, '基本信息'),
    createQPicker('1. 月经初潮（女）/喉结突出或变声（男）开始时间: ', '请选择开始时间', [
      // 第一列
      {
        values: getYears(),
        defaultIndex: 0
      },
      // 第二列
      {
        values: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        defaultIndex: 0
      }
    ]),
    createTitle(1, '一、脊柱侧弯检查'),
    createTitle(1, '1. 一般检查：'),
    createQRadio('1.1 双肩是否等高:', '', ['A', 'A、等高'], ['B', 'B、不等高']),
    createQRadio('1.2 左右肩胛骨在脊柱两侧是否对称:', '', ['A', 'A、对称'], ['B', 'B、不对称']),
    createQRadio('1.3 两侧肩胛下角是否等高:', '', ['A', 'A、等高'], ['B', 'B、不等高']),
    createQRadio('1.4 两侧腰凹是否对称:', '', ['A', 'A、对称'], ['B', 'B、不对称']),
    createQRadio('1.5 两侧髂嵴是否等高（即骨盆是否水平）:', '', ['A', 'A、水平'], ['B', 'B、不水平']),
    createQRadio('1.6 棘突连线是否偏离正中线:', '', ['A', 'A、正常'], ['B', 'B、偏离']),
    createTitle(1, '2. 前屈试验: （非必选）'),
    createQRadio('2.1 胸段', '', ['A', 'A、无侧弯'], ['B', 'B、左低右高'], ['C', 'C、右低左高']),
    createQPicker('2.1 胸段-躯干旋转角度（ATR）: ', '胸段-躯干旋转角度（ATR）', [
      // 第一列
      {
        values: getATROpts(),
        defaultIndex: 5
      }
    ]),
    createQRadio('2.2 腰胸段', '', ['A', 'A、无侧弯'], ['B', 'B、左低右高'], ['C', 'C、右低左高']),
    createQPicker('2.2 腰胸段-躯干旋转角度（ATR）: ', '胸段-躯干旋转角度（ATR）', [
      // 第一列
      {
        values: getATROpts(),
        defaultIndex: 5
      }
    ]),
    createQRadio('2.3 腰段', '', ['A', 'A、无侧弯'], ['B', 'B、左低右高'], ['C', 'C、右低左高']),
    createQPicker('2.3 腰段-躯干旋转角度（ATR）: ', '胸段-躯干旋转角度（ATR）', [
      // 第一列
      {
        values: getATROpts(),
        defaultIndex: 5
      }
    ]),
    createTitle(1, '二、脊柱运动试验 （非必选）'),
    createQRadio('1. 脊柱前屈活动范围（正常/受限）', '', ['A', 'A、正常'], ['B', 'B、受限']),
    createQRadio('2. 脊柱背伸活动范围（正常/受限）', '', ['A', 'A、正常'], ['B', 'B、受限']),
    createQRadio('3. 脊柱左侧弯活动范围（正常/受限）', '', ['A', 'A、正常'], ['B', 'B、受限']),
    createQRadio('4. 脊柱右侧弯范围（正常/受限）', '', ['A', 'A、正常'], ['B', 'B、受限']),
    createQRadio('5. 脊柱左右扭转运动（正常/受限）', '', ['A', 'A、正常'], ['B', 'B、受限']),
    createTitle(2, '6. 第二次 躯干旋转测量仪检查'),
    createQPicker('① 胸段ATR: ', '胸段ATR', [
      // 第一列
      {
        values: getATROpts(),
        defaultIndex: 5
      }
    ]),
    createQPicker('② 腰胸段ATR: ', '腰胸段ATR', [
      // 第一列
      {
        values: getATROpts(),
        defaultIndex: 5
      }
    ]),
    createQPicker('③ 腰段ATR: ', '腰段ATR', [
      // 第一列
      {
        values: getATROpts(),
        defaultIndex: 5
      }
    ]),
    createTitle(1, '三、长短腿检查'),
    createQRadio('1. 是否有长短腿', '', ['A', 'A、有'], ['B', 'B、无']),
    createInput('- 左 腿长度( CM ) :', '请输入左腿长度', 'cm'),
    createInput('- 右 腿长度( CM ) :', '请输入左腿长度', 'cm'),
    createSlot(),
    createTitle(1, '四、足弓检查'),
    createQRadio('1. 左足', '', ['A', 'A、高弓足'], ['B', 'B、扁平足'], ['C', 'C、正常'], ['D', 'D、其他']),
    createInput('左足-其他 :', '请输入其他情况', ''),
    createQRadio('1. 右足', '', ['A', 'A、高弓足'], ['B', 'B、扁平足'], ['C', 'C、正常'], ['D', 'D、其他']),
    createInput('右足-其他 :', '请输入其他情况', ''),
    // createInput('其他足弓情况 :', '请输入其他足弓情况', ''),
    createTitle(1, '五、站立位X片'),
    createInput('1. 主弯Cobb角( ° ):', '请输入主弯Cobb角度', ''),
    createQPicker('顶椎: ', '顶椎', [
      // 第一列
      {
        values: getDingJui(),
        defaultIndex: 5
      }
    ]),
    createInput('2. 次弯Cobb角( ° ):', '请输入次弯Cobb角度', ''),
    createQPicker('顶椎: ', '顶椎', [
      // 第一列
      {
        values: getDingJui(),
        defaultIndex: 5
      }
    ]),
    // createQPicker('3. 施罗斯分型(可选): ', '施罗斯分型', [
    //   // 第一列
    //   {
    //     values: getRoseType(),
    //     defaultIndex: 5
    //   }
    // ]),
    createQRadio('3. Risser征', 'horizontal', ['0', '0度'], ['1', 'Ⅰ度'], ['2', 'Ⅱ度'], ['3', 'Ⅲ度'], ['4', 'Ⅳ度'], ['5', 'Ⅴ度']),
    createCheckbox('4. 生理曲度', ['1', '1、正常'], ['2', '2、颈椎强直'], ['3', '3、颈椎反弓'], ['4', '4、胸椎后凸过度'], ['5', '5、腰椎前凸过度'], ['6', '6、胸椎后凸过少'], ['7', '7、腰椎前凸过少'], ['8', '8、骨盆前倾'], ['9', '9、骨盆后倾']),
    createTitle(1, '六、脊柱前后弯曲异常检查（非必选项）'),
    createQRadio('1. 一般检查 ', '', ['1', '①正常'], ['2', '②前凸体征'], ['3', '③后凸体征']),
    createTitle(2, '(②和③选项后进行俯卧试验)'),
    createQRadio('2. 俯卧试验 ', '', ['1', '①前后凸体征消失  '], ['2', '②前凸体征  '], ['3', '③后凸体征  ']),
    createQRadio('3. 脊柱前后弯曲类型 ', '', ['1', '①无前后弯曲异常'], ['2', '②脊柱前凸异常'], ['3', '③脊柱后凸异常']),
    createQRadio('3.1 脊柱后凸程度', '', ['1', '①脊柱后凸I度'], ['2', '②脊柱后凸Ⅱ度'], ['3', '③脊柱后凸Ⅲ度']),
    createTitle(1, '七、进行因子（风险系数）'),
    createInput('进行因子（风险系数）:', '请输入 进行因子（风险系数）', ''),
    createTitle(1, '八、 既往病史'),
    createQRadio('既往病史', '', ['1', '①无'], ['2', '②脊柱弯曲异常家族史'], ['3', '③脊柱外伤病史'], ['4', '④脊柱手术病史']),
    createInput('其他特殊情况:', '请输入 其他特殊情况', ''),
    createTitle(1, '筛查结果'),
    createQRadio('筛查结果', '', ['1', '①正常'], ['2', '②体态不良'], ['3', '③脊柱侧弯'], ['4', '④脊柱前凸异常'], ['5', '⑤脊柱后凸异常']),
    createQRadio('脊柱侧弯类别', '', ['0', '无侧弯'], ['1', '一级'], ['2', '二级'], ['3', '三级']),
    createInput('建议:', '请输入 建议', '')

  ]
  for (let i = 0; i < questionInfoList.length; i++) {
    questionInfoList[i].id = 1000 + i
    questionInfoList[i].answer = ''
  }
  return questionInfoList
}

function createSlot () {
  return {
    type: 'slot'
  }
}

function createInput (title, hint, suffix) {
  return {
    type: 'question',
    qType: 'input',
    title: title,
    hint: hint || '请输入信息',
    suffix: suffix || ''
  }
}

function createCheckbox (title, ...options) {
  const opts = []
  options.forEach((item) => {
    opts.push({
      value: item[0],
      label: item[1]
    })
  })
  return {
    type: 'question',
    qType: 'checkbox',
    title: title,
    checkboxOpts: opts
  }
}

function createQRadio (title, direction, ...options) {
  const opts = []
  options.forEach((item) => {
    opts.push({
      value: item[0],
      label: item[1]
    })
  })
  return {
    type: 'question',
    qType: 'radio',
    title: title,
    direction: direction,
    radioOpts: opts
  }
}

function createQPicker (title, pickerTitle, pickerOpts) {
  return {
    type: 'question',
    qType: 'picker',
    title: title,
    pickerTitle: pickerTitle || '请选择',
    pickerOpts: pickerOpts
  }
}

function createTitle (type, title) {
  return {
    type: 'title' + type,
    title: title
  }
}

function getATROpts () {
  const opts = []
  for (let i = 0; i <= 30; i++) {
    opts.push(i + '°')
  }
  return opts
}

function getYears () {
  const years = []
  const toYear = new Date().getFullYear()
  for (let i = toYear; i > 1979; i--) {
    years.push(i + '年')
  }
  return years
}

function getDingJui () {
  const datas = []
  for (let i = 1; i <= 12; i++) {
    datas.push('T' + i)
  }
  for (let i = 1; i <= 5; i++) {
    datas.push('L' + i)
  }
  return datas
}

function getRoseType () {
  return ['3CH-L', '3CH-R', '3CTL-L', '3CTL-R', '3C-L', '3C-R', '3CL-L', '3CL-R', '4C-L', '4C-R', '4CL-L', '4CL-R', '4CTL-L', '4CTL-R']
}

export default getQuestionInfoDatas
