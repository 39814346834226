<template>
  <div style="display: flex;flex-direction: column; height: 100vh;">

    <my-nav-bar v-if="showAppBar" :title="'训练方案'"/>
    <div v-if="test">{{ test }}</div>
    <video-player
      ref="videoPlayer"
      :options="playerOptions"
      :playsinline="true"
      :x5-video-player-fullscreen="true"
      class="video-player vjs-custom-skin"
      style="object-fit:fill"
      @click="fullScreen"
      @fullscreenchange="onFullscreenChange($event)"
      @pause="onPlayerPause($event)"
      @play="onPlayerPlay($event)"></video-player>
    <!--垂直滚动区域-->
    <div style="overflow-y: auto;box-sizing: border-box;flex: 1">
      <div>
        <div class="tab-item-container" style="padding: 16px; min-height: 50px;">
          <div v-if="playingCourse" style="font-size: 16px;font-weight: bold;color: #0a4b3d;text-align: justify">
            <span
              class="span-course-detail" style="font-size: 14px">当前播放</span>
            {{
              playingCourse.title
            }}
          </div>
          <div v-if="playingCourse" style="font-size: 14px;margin-top: 16px;text-align: justify"><span
            class="span-course-detail">动作指导</span>
            <span v-html="getCourseText(playingCourse.courseId)"></span>
            <!--            {{-->
            <!--              getCourseText(playingCourse.courseId)-->
            <!--            }}-->
          </div>
          <div v-if="playingCourse" style="font-size: 14px;margin-top: 16px;text-align: justify">
            <span class="span-course-detail">训练组数</span>
            {{
              getActionRequirement(playingCourse.courseId)
            }}
          </div>

        </div>
        <div style="display: flex;flex-direction: row;margin-top: 16px;position: relative">
          <div style="margin-left: 16px;font-weight: bolder;font-size: 14px">课程视频列表：</div>
          <div style="text-align: center;color: #606060;font-size: 14px">
            点击下方标题可切换视频
          </div>
        </div>
      </div>
      <div v-if="courses.length>0" class="video-playlist-container">
        <div class="video-playlist">
          <div v-for="(video, index) in courses" :key="index" :ref="'videoItem-' + index"
               class="video-item">
            <div
              :style="{ backgroundColor: playingCourse.courseId === video.courseId ? 'rgba(53,223,164,0.27)' : '#ffffff' }"
              class="video-card"
              @click="courseClick(video,index)"
            >
              <div style="font-size: 15px;font-weight: bold">{{ video.title }}</div>
              <div style="font-size: 14px;">视频时长: {{ formatDuration(video.duration) }}</div>
              <div style="position:relative;height: 32px">
                <button
                  v-show="playingCourse.courseId === video.courseId "
                  class="training-button"
                >
                  训练中
                </button>
              </div>
            </div>
          </div>

        </div>

      </div>
      <div class="tabs-container">
        <van-tabs v-model="activeTab" @change="onTabChange">
          <van-tab title="训练方案">
            <div class="tab-item-container">
              <div v-if="data" class="scheme-detail">

                <van-cell class="title-cell" title="方案标题">
                  <template #default>
                    <div style="display: flex;justify-content: right">
                      <div style="text-align: left">{{ data.plan.planTitle }}</div>
                    </div>
                  </template>
                </van-cell>
                <van-cell class="title-cell" title="方案描述">
                  <template #default>
                    <div style="display: flex;justify-content: right">
                      <div style="text-align: left">{{ data.plan.desp }}</div>
                    </div>
                  </template>
                </van-cell>

                <van-cell class="title-cell" title="康复目标">
                  <template #default>
                    <div style="display: flex;justify-content: right">
                      <span style="text-align: justify;">{{ rehabilitationGoals }}</span>
                    </div>
                  </template>
                </van-cell>

                <van-cell :value="data.doctorName" title="定制医生"/>
                <van-cell :value="data.gmtCreate" title="定制时间"/>
                <!--                <van-cell is-link title="方案描述" @click="viewDetails">-->
                <!--                  <template #right-icon>-->
                <!--                    <van-icon name="arrow"/>-->
                <!--                  </template>-->
                <!--                </van-cell>-->
                <my-title1 :fontColor="'#00703e'" :size="14" :title="'锻炼计划'"/>

                <div style="padding: 8px">
                  <TrainingTimeDetails
                    :durationPerSession="`${data.plan.exerciseTime}分钟`"
                    :frequencyDescription="`一天训练: ${data.plan.timesADay}次`"
                    :trainingDaysPerWeek="`${data.plan.days}天`"
                    :trainingDescription="`训练日：${formatTrainingDays(data.plan.trainDay)}`"
                  />
                </div>

                <div>
                  <my-title1 :fontColor="'#00703e'" :size="14" :title="'动作列表'"/>
                  <div>
                    <div v-for="(item,index) in data.plan.planCourseBeans" :key="item.courseId">
                      <!--                      <div style="font-size: 20px">{{ index + 1 }}、{{ item.courseName }}</div>-->
                      <van-cell :title="`${index+1}.${item.courseName}`" is-link @click="courseDetail(item.courseId)"/>
                    </div>
                  </div>
                </div>
                <div style="height: 16px"></div>
              </div>
            </div>
          </van-tab>
          <van-tab title="评估报告">
            <div class="tab-item-container">
              <div v-if="data&&data.assessType===3" style="padding: 16px;box-sizing: border-box">
                <van-button style="width: 100%" type="primary"
                            @click="onAssessmentClick">
                  查看评估报告
                </van-button>
              </div>
              <div v-else style="text-align: center">
                当前病种暂不支持在小程序查看评估报告
              </div>
            </div>
          </van-tab>
          <!--          <van-tab title="远程康复">-->
          <!--            <van-card-->
          <!--              desc="这是远程康复的详细描述，包含了康复的计划、远程监控和康复指导等内容。"-->
          <!--              thumb="https://via.placeholder.com/150"-->
          <!--              title="远程康复"-->
          <!--            />-->
          <!--          </van-tab>-->
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import MyNavBar from '../../components/MyNavBar'
import MyTitle1 from '../../components/MyTitle1'
import PlanDao from '../../network/PlanDao'
import DateUtils from '@/utils/DateUtils'
import TrainingTimeDetails from '@/h5/doucan/pages/plan/TrainingTimedetails.vue'

export default {
  name: 'PlanDetailPage',
  components: {
    TrainingTimeDetails,
    MyTitle1,
    MyNavBar
  },
  data () {
    return {
      test: '',
      showAppBar: true,
      phone: '',
      planId: '',
      playingCourse: null,
      courses: [],
      empty: true,
      loading: true,
      data: null,
      loadingCourse: true,
      playerOptions: {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          // type: 'video/mp4',
          src: ''
        }],
        poster: '你的封面地址',
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          // timeDivider: true,
          // durationDisplay: true,
          // remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      }
    }
  },
  async mounted () {
    if (this.$route.query) {
      console.log('query', this.$route.query)
      if (this.$route.query.from === 'wxapp') {
        this.showAppBar = false
        this.phone = this.$route.query.phone
        this.planId = this.$route.query.planId
      }
    }

    await this.loadDatas()
    this.loadCourseFirst()
  },
  computed: {
    player () {
      return this.$refs.videoPlayer.player
    },
    rehabilitationGoals () {
      const goals = []

      if (this.data.plan.goalShort && this.data.plan.goalShort !== '无') {
        goals.push(`短期目标: ${this.data.plan.goalShort}`)
      }

      if (this.data.plan.goalMiddle && this.data.plan.goalMiddle !== '无') {
        goals.push(`中期目标: ${this.data.plan.goalMiddle}`)
      }

      if (this.data.plan.goalLong && this.data.plan.goalLong !== '无') {
        goals.push(`长期目标: ${this.data.plan.goalLong}`)
      }

      return goals.length > 0 ? goals.join('；') : '无目标'
    }

  },
  methods: {
    courseDetail (courseId) {
      // 跳转 CourseDetailVideoPlayPage
      this.$router.push({
        path: '/CourseDetailVideoPlayPage',
        query: {
          courseId: courseId,
          userId: this.$store.state.user.profile.userId
        }
      })
    },
    courseClick (item, index) {
      if (item.courseId === this.playingCourse.courseId) {
        return
      }
      const player = this.player
      this.playingCourse = item
      player.src({
        type: 'video/mp4',
        src: item.videoUrl
      })
      player.ready(function () {
        // get
        // var howLoudIsIt = myPlayer.volume();
        // set
        // myPlayer.volume(0.5); // Set volume to half
        player.play()
      })
      const itemRef = this.$refs[`videoItem-${index}`]
      if (itemRef) {
        const itemview = Array.isArray(itemRef) ? itemRef[0] : itemRef
        itemview.scrollIntoView({
          behavior: 'smooth', // 使用平滑滚动
          block: 'nearest', // 确保滚动到可见位置
          inline: 'start' // 滚动到最左侧
        })
      }
    },
    async loadCourseFirst () {
      for (const e of this.data.plan.planCourseBeans) {
        const course = await this.loadCourse(e.courseId)
        this.courses.push(course)
      }
      console.log('课程', this.courses)
      this.playerOptions.sources[0].src = this.courses[0].videoUrl
      this.playerOptions.poster = this.courses[0].coverUrl
      this.playingCourse = this.courses[0]
      this.loadingCourse = false
    },
    async loadCourse (courseId) {
      const course = await PlanDao.loadCourse(courseId)
      console.log('课程细节', course)
      return course
    },
    async httpDatas () {
      if (this.planId) {
        // this.$toast('查询id 方案')
        const res = await PlanDao.loadPlanById(this.planId)
        // this.test = JSON.stringify(res)
        res.plan = JSON.parse(res.plan)
        res.gmtCreate = DateUtils.formate(res.gmtCreate, 'yyyy-MM-DD HH:mm:ss')
        // console.log(e.plan)
        this.data = res
      } else {
        this.data = this.$store.state.play.planDetail
      }
      console.log('方案数据', this.data)
    },
    async loadDatas () {
      this.loading = true
      await this.httpDatas()
      console.log('数据请求结果-方案 plan', this.data)
      if (this.data) {
        this.empty = false
      } else {
        this.empty = true
      }
      this.loading = false
    },
    onPlayerPause (event) {

    },
    onPlayerPlay (event) {
      this.player.play()
      console.log('播放视频')
      this.uploadIntegral()
    },
    onFullscreenChange (event) {

    },
    fullScreen (event) {
      const player = this.$refs.videoPlayer.player
      player.requestFullscreen()// 调用全屏api方法
      player.isFullscreen(true)
      player.play()
    },

    async uploadIntegral () {
      const url = '/integral/add_integral?mode=' + 3
      // const url = '/integral/add_integral'
      console.log(this.$store.state.user.profile.token)
      const { data: resp } = await this.$http.post(url, {
        headers: {
          token: this.$store.state.user.profile.token
        }
      })
      console.log(resp)
    },
    onAssessmentClick () {
      this.$store.commit('assessment/setJsonString', this.data.assess)
      this.$router.push({
        path: '/AssessmentSpine?isReadAssess=true&from=PatientWeb'
      })
    },
    formatDuration (duration) {
      const minutes = Math.floor(duration / 60000)
      const seconds = ((duration % 60000) / 1000).toFixed(0)
      if (minutes === 0) {
        return `${seconds}秒`
      }
      return `${minutes}分${seconds.padStart(2, '0')}秒`
    },
    getActionRequirement (courseId) {
      console.log('getActionRequirement', courseId)
      console.log('getActionRequirement', courseId)
      // 查找courseId对应的数据
      const course = this.data.plan.planCourseBeans.find(c => c.courseId === courseId)

      // 如果找到数据，则返回格式化文本
      if (course) {
        if (course.groups === 0) {
          return '无要求'
        }
        return `${course.groups}${course.groupsUnit}，每次做${course.round}组，组间适当休息${course.rest}秒或根据个人情况调整。`
      } else {
        return '未找到该课程'
      }
    },
    getCourseText (courseId) {
      if (this.playingCourse.text === '') {
        return '无'
      }
      // 将文本按 '/n' 分割成段落数组
      const paragraphs = this.playingCourse.text.split('/n')
      // 检查最后一个是否有内容
      if (paragraphs[paragraphs.length - 1].trim() === '') {
        paragraphs.pop()
      }

      // 遍历每一段，加上序号
      const numberedParagraphs = paragraphs.map((paragraph, index) => {
        return `${index + 1}. ${paragraph}`
      })

      // 将数组转换为字符串，并用 <br> 连接每一段
      return '<br>' + numberedParagraphs.join('<br>')
    },
    formatTrainingDays (data) {
      const daysMap = {
        mon: '周一',
        tue: '周二',
        wed: '周三',
        thu: '周四',
        fri: '周五',
        sat: '周六',
        sun: '周日'
      }

      const selectedDays = Object.keys(daysMap)
        .filter(day => data[day])
        .map(day => daysMap[day])

      return selectedDays.join('、')
    }
  }
}
</script>

<style scoped>
.container-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 5px grey;
}

.video-playlist-container {
  overflow-x: hidden;
  padding: 16px 0 16px 0;
}

.video-playlist {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.video-item {
  flex: 0 0 66.66%; /* 显示1.5个item，设置item宽度为父容器的2/3 */
}

.video-card {
  padding: 16px;
  margin: 4px 0 4px 16px;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100px; /* 设置固定高度，确保所有item高度一致 */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color 0.3s ease;

}

.training-button {
  margin-top: 8px;
  background-color: #38b48b;
  color: white;
  position: absolute;
  right: 0;
  bottom: 0;
  border: none;
  padding: 4px 16px;
  border-radius: 8px;
  cursor: pointer;
}

.tab-item-container {
  margin: 16px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: 200px;
  padding-top: 16px;
}

.title-cell {
  .van-cell__value {
    min-width: 70%;
  }
}

.span-course-detail {
  background-color: rgba(31, 136, 101, 0.68);
  color: white;
  font-weight: bold;
  border-radius: 5px;
  padding: 2px 5px;
}
</style>
