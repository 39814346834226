<template>
  <div>
    <base-title-bar-scroll-page ref="container" title="疾病科普宣教">
      <div id="srcollcontainer"
           style="padding: 16px;box-sizing: border-box;width: 100vw;height: 100vh;overflow-y: auto;/* 垂直滚动 */">
        <div style="width: 100%">
          <div v-for="(content, index) in contents" :key="index">
            <div
              :id="content.type"
              style="color: white;border-top-left-radius: 16px;border-top-right-radius: 16px;height: 48px;background-color: #23b346;font-size: 20px;font-weight: bold;line-height: 48px;text-align: center">
              {{ content.type }}
            </div>
            <div v-for="(article, index) in content.articles" :key="index" @click="toDetail(article)">
              <div style="background-color: white;padding: 24px;display: flex;flex-direction: row">
                <div style="font-size: 18px;margin-right: auto">{{ index + 1 }}、{{ article.title }}</div>
                <div>
                  <my-img :url="article.poster" style="width: 80px;height: 80px;margin-left: 8px;border-radius: 8px"/>
                </div>
              </div>
            </div>
            <div style="height: 1px;width: 100%;background-color: #eaeaea"></div>
          </div>
        </div>
      </div>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import MyImg from '@/h5/doucan/components/MyImg.vue'
import ReportFeatureAccessDao from '@/h5/doucan/network/ReportFeatureAccessDao'

const BASEURL = 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/'

export default {
  name: 'SickKepuShennei',
  components: {
    MyImg,
    BaseTitleBarScrollPage
  },
  data () {
    return {
      hospital: null,
      contents: [
        {
          type: '入院早期 | 疾病宣教',
          articles: [
            {
              title: '您知道阿尔茨海默病患者看到的世界是什么样的呢',
              id: 192,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1702890785199.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Mon-Dec-18-2023/您知道阿尔茨海默病患者看到的世界是什么样的呢？.mp4'
            },
            {
              title: '动脉粥样硬化且伴有钙化病变，应如何介入治疗？',
              id: 229,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917246614.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Wed-Apr-19-2023/动脉粥样硬化且伴有钙化病变，应如何介入治疗？.mp4'
            },
            {
              title: '溶栓手术动画',
              id: 204,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917231754.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Wed-Mar-01-2023/精美溶栓手术.mp4'
            },
            {
              title: '在保护伞的保护下颈动脉支架植入术',
              id: 197,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917226889.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Fri-Feb-24-2023/在保护伞的保护下颈动脉支架植入术.mp4'
            },
            {
              title: '解密神经介入取栓术',
              id: 187,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917218101.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Fri-Feb-03-2023/解密神经介入取栓术.mp4'
            },
            {
              title: '组合取栓技术是如何复通脑血管？',
              id: 87,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917094750.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Wed-Sep-28-2022/组合取栓技术是如何复通脑血管.mp4'
            },
            {
              title: '做脑血管造影会发生什么？',
              id: 130,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917185593.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Wed-Nov-16-2022/做脑血管造影会发生什么？.mp4'
            },
            {
              title: '什么是中风？',
              id: 102,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917122496.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Thu-Oct-13-2022/什么是中风？.mp4'
            },
            {
              title: '什么原因会导致中风？',
              id: 101,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917117370.jpg',
              videoUrl: BASEURL + 'article/feeds/什么原因会导致中风？/什么原因会导致中风？.mp4'
            },
            {
              title: '中风后能不能吃安宫牛黄丸？',
              id: 104,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917123916.jpg',
              videoUrl: BASEURL + 'article/feeds/中风后能不能 吃安宫牛黄丸？/中风后能不能吃安宫牛黄丸？.mp4'
            },
            {
              title: '缺血性卒中是怎么形成的？',
              id: 107,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917137887.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Sun-Oct-23-2022/缺血性卒中是怎么形成的.mp4'
            },
            {
              title: '缺血性卒中有什么表现？',
              id: 110,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917140831.jpg',
              videoUrl: BASEURL + 'article/feeds/缺血性卒中有什么表现/缺血性卒中有什么表现.mp4'
            },
            {
              title: '中风需要长期服药吗？',
              id: 114,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917147287.jpg',
              videoUrl: BASEURL + 'article/feeds/中风需要长期服药吗？/中风需要长期服药吗？.mp4'
            },
            {
              title: '如何预防中风？',
              id: 115,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917149745.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Fri-Oct-28-2022/如何预防中风？.mp4'
            },
            {
              title: '如果发生中风怎么办？',
              id: 116,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917152132.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Fri-Oct-28-2022/如果发生中风怎么办？.mp4'
            },
            {
              title: '为什么年轻人也会中风？',
              id: 117,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917154656.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Sat-Oct-29-2022/为什么年轻人也会中风？.mp4'
            },
            {
              title: '中风有先兆吗？',
              id: 119,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917160309.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Wed-Nov-02-2022/中风有先兆吗？.mp4'
            },
            {
              title: '患了中风能活多久？',
              id: 120,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917162644.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Wed-Nov-02-2022/10月7日.mp4'
            },
            {
              title: '中风急救为什么越快越好？',
              id: 121,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917164601.jpg',
              videoUrl: BASEURL + 'article/feeds/中风急救为什么越快越好？/中风急救为什么越快越好.mp4'
            },
            {
              title: '中风急救的时间窗是多久？',
              id: 122,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917167736.jpg',
              videoUrl: BASEURL + 'article/feeds/中风急救的时间窗是多久？/中风急救的时间窗是多久.mp4'
            },
            {
              title: '中风有哪些急救方式？',
              id: 123,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917169587.jpg',
              videoUrl: BASEURL + 'article/feeds/中风有哪些急救方式/中风有哪些急救方式.mp4'
            },
            {
              title: '不稳定斑块堵塞血管的过程',
              id: 83,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917083270.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Sat-Sep-24-2022/不稳定斑块堵塞血管的过程.mp4'
            },
            {
              title: '静脉溶栓怎么做？',
              id: 124,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917171775.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Sat-Nov-05-2022/静脉溶栓怎么做.mp4'
            },
            {
              title: '静脉溶栓药物的安全性？',
              id: 98,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917107952.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Mon-Oct-10-2022/静脉溶栓药物的安全性.mp4'
            },
            {
              title: '静脉溶栓治疗的疗效怎么样？',
              id: 126,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917174709.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Sat-Nov-05-2022/静脉溶栓治疗的疗效怎么样.mp4'
            },
            {
              title: '中风后为什么还要做CT 还要做磁共振？',
              id: 127,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917176336.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Sat-Nov-05-2022/中风后为什么还要做CT还要做磁共振.mp4'
            },
            {
              title: '中风后血压高要不要吃降压药？',
              id: 128,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917180427.jpg',
              videoUrl: BASEURL + 'article/feeds/中风后血压高要不要吃降压药？/中风后血压高要不要吃降压药？.mp4'
            }, {
              title: '紧张性头痛——健康宣教',
              id: 408,
              poster: BASEURL + 'article/feeds/未知标题文章/Thu-Mar-21-2024/头痛图.jpg'
            }, {
              title: '偏头痛——健康宣教',
              id: 407,
              poster: BASEURL + 'article/feeds/未知标题文章/Thu-Mar-21-2024/头痛图.jpg'
            },
            {
              id: 160,
              title: '无声的杀手-高血压：是如何一步步破坏各种器官的',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/无声的杀手-高血压：是如何一步步破坏各种器官的/48岁男子血压07.jpg'
            }, {
              id: 409,
              title: '血栓最容易堵在身体3个地方！有这4个信号一定要留意→',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/血栓最容易堵在身体3个地方！有这4个信号一定要留意→/血栓封面1.jpg'
            }, {
              id: 410,
              title: '国人“最佳血压值”出炉！改变1个小习惯，死亡风险大大降低',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/国人“最佳血压值”出炉！改变1个小习惯，死亡风险大大降低/高血压首图1.jpg'
            }, {
              id: 411,
              title: '超一半人不知道自己得了糖尿病！出现这些症状要警惕→',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/超一半人不知道自己得了糖尿病！出现这些症状要警惕→/糖尿病首图.jpg'
            }, {
              id: 412,
              title: '注意了！有这6种症状，提醒你该查血脂了',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/注意了！有这6种症状，提醒你该查血脂了/高血脂首图1.jpg'
            }, {
              id: 109,
              title: '一起了解高血压九大误区！这些错误不能犯',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/一起了解高血压九大误区！这些错误不能犯/微信图片_20221024220426.jpg'
            }, {
              id: 108,
              title: '为什么中国人最容易中风？',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/为什么中国人最容易中风？/微信图片_20221023201522.jpg'
            }, {
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题资讯/Fri-Oct-07-2022/从水锤效应看高血压脑出血.mp4',
              id: 92,
              title: '从水锤效应看高血压脑出血',
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917106868.jpg'
            }, {
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/中风有哪些急救方式/中风有哪些急救方式.mp4',
              id: 123,
              title: '中风有哪些急救方式？',
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917169587.jpg'
            }, {
              id: 147,
              title: '脑卒中后的大脑终生都有可塑性，千万别轻易放弃！',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题文章/Mon-Nov-28-2022/微信图片_20221128112401.jpg'
            }, {
              id: 207,
              title: '中风是如何影响语言功能的？',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/中风是如何影响语言功能的？/微信图片_20230117120811.png'
            }, {
              id: 265,
              title: '脑卒中康复|如何自己穿脱衣裤？',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/脑卒中康复|如何自己穿脱衣裤？/R-C.png'
            }, {
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题资讯/Mon-Dec-12-2022/中国康复医学会标准委员会主任观点：远程康复市场分析与展望.mp4',
              id: 154,
              title: '你觉得远程康复会给卒中患者带来怎么样的获益？',
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917202374.jpg'
            }, {
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题资讯/Mon-May-15-2023/三分钟了解脑卒中：类型、病因、症状和治疗 .mp4',
              id: 241,
              title: '三分钟了解脑卒中：类型、病因、症状和治疗 ',
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917254537.jpg'
            }, {
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题资讯/Wed-May-31-2023/脑梗死恢复期患者如何饮食指导？.mp4',
              id: 249,
              title: '脑梗死恢复期患者如何饮食指导？'
            }, {
              id: 274,
              title: '脑卒中康复|如何经口进食？',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/脑卒中康复|如何经口进食？/640.jpg'
            }, {
              id: 325,
              title: '关爱女性，远离卒中！',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/关爱女性，远离卒中！/妊娠（2）.png'
            }, {
              id: 326,
              title: '“卒”不及防，如何预防？',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/“卒”不及防，如何预防？/640.jpg'
            }, {
              id: 432,
              title: '远程康复是什么？患者该如何进行康复？',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/远程康复是什么？患者该如何进行康复？/容器 5@1x.png'
            }, {
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/房颤如何引起中风？ 如何急救？/房颤如何引起中风？如何急救？.mp4',
              id: 80,
              title: '房颤如何引起中风？ 如何急救？',
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917075638.jpg'
            }, {
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/中风发病后有哪些就诊陷阱？/中风发病后有哪些就诊陷阱02.mp4',
              id: 81,
              title: '中风发病后有哪些就诊陷阱？',
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917076426.jpg'
            }, {
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题资讯/Fri-Sep-23-2022/手术麻醉之房颤与中风.mp4',
              id: 82,
              title: '手术麻醉之房颤与中风',
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917077344.jpg'
            }
          ]
        }
      ]
    }
  },
  mounted () {
    this.hospital = this.$store.state.user.qrCodeHospital
    if (!this.hospital) {
      // this.$toast('请先扫码登录')
      // this.$router.push('/login')
    }
    console.log(this.hospital)
    this.hideLoading()
    this.reportFeatureAccess()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    toDetail (content) {
      if (content.videoUrl) {
        console.log(content.videoUrl)
        // 带参数跳转：/BaseVideoPlayPage
        this.$router.push({
          path: '/BaseVideoPlayPage',
          query: {
            videoUrl: content.videoUrl
          }
        })
      } else {
        this.$router.push('/feeds/detail/' + content.id)
      }
    },
    reportFeatureAccess () {
      if (this.$store.state.route.from === '/KepuxuanjiaoMainPage') {
        ReportFeatureAccessDao.report(11, this.$store.state.user.qrCodeHospital, this.$store.state.user.openId).then(res => {
          console.log('上报访问成功', res)
        })
      }
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
