<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <div></div>
    <ListPageV3 v-if="datas" :datas="datas"/>
  </div>
</template>
<script>

import ListPageV3 from '@/h5/feeds/ListPageV3.vue'

export default {
  name: 'template',
  components: { ListPageV3 },
  data () {
    return {
      datas: null
    }
  },
  mounted () {
    const path = 'assets/tool-jiantuo.json'
    fetch(path).then(resp => {
      console.log(resp)
      this.datas = resp.data
    })
  },
  methods: {},
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
