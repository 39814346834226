<template>
  <div>
    <van-nav-bar
      left-arrow
      left-text="返回"
      title="分销排行（直接分销）"
      @click-left="$router.back()"
    />
    <div style="text-align: center;padding-top: 16px;font-size: 24px">总分销订单数：{{ totalOrders }}单</div>
    <div style="text-align: center;color: #8a8a8a">统计日期：{{ DateUtils.formattedTime(new Date()) }}</div>

    <div style="box-sizing: border-box;width: 100vw;padding: 16px">
      <div v-if="orders.length">
        <div v-for="(item,index,id) in orders" :key="id">
          <div class="user-info" style="margin-top: 16px">

            <span class="name">{{ index + 1 }}. {{
                desensitizeName(item.distributor.name)
              }}</span>
            <span class="phone">{{ item.totalFirstOrders }}单</span>

          </div>

          <div style="width: 100%;height: 1px;background-color: #c6c6c6;margin-top: 8px"></div>

        </div>
      </div>
      <div v-else>
        <div style="text-align: center;margin-top: 16px">
          <div style="font-size: 14px;color: #686868">暂无推荐费明细</div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

import DistributionDao from '@/h5/doucan/pages/distribution/DistributionDao'
import DateUtils from '../../../../utils/DateUtils'

export default {
  name: 'DistributionRankPage',
  computed: {
    DateUtils () {
      return DateUtils
    }
  },
  data () {
    return {
      orders: null,
      user: null,
      totalOrders: 0
    }
  },
  created () {
    this.user = this.$store.state.distributor.data
    console.log('user', this.user)
    this.load()
  },
  methods: {

    load () {
      console.log('onDetailClick')
      DistributionDao.loadRank(this.user.id).then(res => {
        console.log('loadDetails', res)
        this.orders = res
        this.totalOrders = res.reduce((acc, cur) => acc + cur.totalFirstOrders, 0)
      })
    },
    // 脱敏姓名函数
    desensitizeName (name) {
      return name[0] + '*' + name[name.length - 1]
    }

  }
}
</script>

<style scoped>
.user-info {
  display: flex;
}

.name {
  margin-right: auto; /* 将姓名推到左侧 */
}

.phone {
  /* 可以添加其他样式，比如颜色、字体大小等 */
}

.btn {
  width: 100%;
  height: 48px;
  line-height: 48px;
  margin-bottom: 8px;
  border-radius: 8px;
  text-align: center;
  background-color: #007373;
  color: white;
}
</style>
