<template>
  <div class="container">
    <my-nav-bar :title="title" />
    <iframe class="iframe" :src="src"></iframe>
  </div>
</template>

<script>
import MyNavBar from './MyNavBar.vue'
export default {
  components: { MyNavBar },
  data() {
    return {
      src: '',
      title: ''
    }
  },
  mounted() {
    const query = this.$route.query
    console.log(query)
    if (query) {
      this.src = query.src
      this.title = query.title
    }
  },
  methods: {}
}
</script>

<style scoped>
.container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.iframe {
  flex: 1;
  width: 100%;
}
</style>