<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar title="创建MDT群"/>
    <div style="font-size: 10px;color: grey;text-align: center">群名称可修改</div>
    <!--  填写群名称  -->
    <div
      style="margin: 0 16px 16px 16px; border-radius: 8px; border: 1px solid #999999; padding: 8px; box-sizing: border-box">
      <van-field v-model="groupName" label="群名称" label-width="3em" placeholder="请输入群名称"/>
    </div>
    <!--  提供一个按钮，点击弹出框选择医生 -->
    <div style="margin: 0 16px 16px 16px">
      <van-button block type="primary" @click="onSelectDoctorClick">添加 医生/治疗师</van-button>
    </div>
    <!--  弹性布局横向展示doctorList的doctorName,右边有个删除按钮  -->
    <div style="margin: 0 16px 16px 16px;display: flex;flex-wrap: wrap;">
      <div v-for="(item, index) in doctorList" :key="index"
           style="margin-right: 8px; margin-bottom: 8px; border-radius: 8px; background-color: #e8e8e8 ;padding: 4px; box-sizing: border-box">
        <div style="display: flex;flex-wrap: wrap;align-items: center">
          <div style="flex: 1;font-size: 12px">{{ item.name }}</div>
          <div
            style="width: 16px; height: 16px; margin-left:8px;background-color: #999999; border-radius: 50%; text-align: center; line-height: 16px; color: white; font-size: 12px"
            @click="onRemoveDoctorClick(index)">X
          </div>
        </div>
      </div>
    </div>
    <div style="margin: 0 16px 16px 16px">
      <van-button block type="primary" @click="onSelectPatientClick">
        添加 患者/家属
      </van-button>
    </div>
    <!--  弹性布局横向展示doctorList的doctorName,右边有个删除按钮  -->
    <div style="margin: 0 16px 16px 16px;display: flex;flex-wrap: wrap;">
      <div v-for="(item, index) in patientList" :key="index"
           style="margin-right: 8px; margin-bottom: 8px; border-radius: 8px; background-color: #e8e8e8 ;padding: 4px; box-sizing: border-box">
        <div style="display: flex;flex-wrap: wrap;align-items: center">
          <div style="flex: 1;font-size: 12px">{{ item.name }}</div>
          <div
            style="width: 16px; height: 16px; margin-left:8px;background-color: #999999; border-radius: 50%; text-align: center; line-height: 16px; color: white; font-size: 12px"
            @click="onRemovePatientClick(index)">X
          </div>
        </div>
      </div>
    </div>
    <!--  提交服务器创建MDT群  -->
    <div style="margin: 0 16px 16px 16px">
      <van-button block style="background-color: #0d3d3d" type="primary" @click="onCreateMDTClick">提交并创建MDT群
      </van-button>
    </div>
    <!--  vant 底部弹出对话框   -->
    <van-popup v-model="showSelectDoctorDialog" :style="{ height: '70%' }" position="bottom">
      <!-- 弹出内容 -->
      <div class="search">
        <van-search
          v-model="searchValue"
          placeholder="请输入姓名或手机号"
          @search="onSearch"
        />
      </div>
      <div class="list">
        <van-list :finished="finished" finished-text="没有更多了">
          <van-cell
            v-for="item in list"
            :key="item.id"
            :label="item.phone"
            :title="item.realname"
            :value="item.id"
            @click="onItemClick(item)"
          />
        </van-list>
      </div>
    </van-popup>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import NumberUtil from '@/utils/NumberUtil'
import AdminDao from '@/h5/doucan/network/AdminDao'
import { Toast } from 'vant'

export default {
  name: 'MDTCreatePage',
  components: { MyNavBar },
  data () {
    return {
      groupName: '',
      patientId: '',
      doctorId: '',
      doctorName: '',
      patientName: '',
      showSelectDoctorDialog: false,
      searchValue: '',
      list: [],
      finished: true,
      doctorList: [],
      patientList: [],
      searchType: 0, // 1-医生 2-患者
      loading: false
    }
  },
  mounted () {
    // 将传递过来的参数取出赋值
    const {
      patientId,
      doctorId,
      doctorName,
      patientName
    } = this.$route.query
    if (!patientId || !doctorId || !doctorName || !patientName) {

    } else {
      this.patientId = patientId
      this.doctorId = doctorId
      this.doctorName = doctorName
      this.patientName = patientName

      this.groupName = this.patientName + '-' + this.doctorName + '的MDT群'

      this.doctorList.push({
        userId: this.doctorId,
        name: this.doctorName
      })
      this.patientList.push({
        userId: this.patientId,
        name: this.patientName
      })
    }

    this.doctorList.push({
      userId: '3113f594-f41c-4e40-8565-02e9fa1263e6',
      name: '康复助理'
    })

    this.doctorList.push({
      userId: '29752f6a-a1c1-4ade-9a34-d78c3af29a3e',
      name: '平台助理'
    })

    this.doctorList.push({
      userId: '12c43fdd-b133-4d19-bdb7-06c43d94f580',
      name: '平台技术'
    })
  },
  methods: {
    // 提交并创建MDT群
    onCreateMDTClick () {
      if (this.loading) {
        console.log('已有请求正在进行中')
        return
      }
      this.loading = true
      console.log('onCreateMDTClick')
      // 判断是否选择了医生
      if (this.doctorList.length === 0) {
        this.$toast('请选择医生')
        return
      }
      // 判断是否选择了患者
      if (this.patientList.length === 0) {
        this.$toast('请选择患者')
        return
      }
      // 判断是否填写了群名称
      if (this.groupName === '') {
        this.$toast('请填写群名称')
      }
      // 把patientList中的userId取出来
      const patientIds = []
      this.patientList.forEach(item => {
        patientIds.push(item.userId)
      })
      // 把doctorList中的userId取出来
      const doctorIds = []
      this.doctorList.forEach(item => {
        doctorIds.push(item.userId)
      })
      const toast = Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      // 请求创建MDT群
      AdminDao.createGroup({
        name: this.groupName,
        doctors: doctorIds,
        patients: patientIds
      }).then(res => {
        this.loading = false
        toast.clear()
        console.log('createMDT', res)
        if (res) {
          this.$toast('创建MDT群成功')
          // 弹窗对话框提示
          this.$dialog.alert({
            title: '创建MDT群成功',
            message: '回到主页'
          }).then(() => {
            // on close
            // 跳转回 /AdminPage,且不可返回
            this.$router.replace({
              path: '/AdminHomePage'
            })
          })
          // this.$router.push({
          //   path: '/mdtlist'
          // })
        }
      })
    },
    // 移除患者
    onRemovePatientClick (index) {
      console.log('onRemovePatientClick', index)
      this.patientList.splice(index, 1)
    },
    // 移除医生
    onRemoveDoctorClick (index) {
      console.log('onRemoveDoctorClick', index)
      this.doctorList.splice(index, 1)
    },
    // 点击选择患者
    onSelectPatientClick () {
      this.searchType = 2
      this.showSelectDoctorDialog = true
    },
    // 点击选择医生
    onSelectDoctorClick () {
      this.searchType = 1
      this.showSelectDoctorDialog = true
    },
    onSearch (value) {
      console.log('onSearch', value)
      // 判断是否是纯数字
      const isPhone = NumberUtil.isNumeric(value)

      // 判断是否是手机号
      if (isPhone) {
        this.searchByPhone(value)
        // 显示toast： 手机号查询
        this.$toast('查询手机号：' + value)
      } else {
        this.searchByName(value)
        // 显示toast： 姓名查询
        this.$toast('查询姓名：' + value)
      }
      this.searchValue = value
      this.list = []
    },
    onItemClick (item) {
      console.log('onItemClick', item)
      if (this.searchType === 1) {
        this.doctorList.push({
          userId: item.userId,
          name: item.realname
        })
      } else {
        this.patientList.push({
          userId: item.userId,
          name: item.realname
        })
      }
      this.searchValue = ''
      this.list = []
      this.showSelectDoctorDialog = false
    },
    loadMore () {
      this.finished = true
    },
    searchByPhone (value) {
      if (this.searchType === 1) {
        AdminDao.doctorByPhone(value).then(res => {
          console.log('doctorByPhone', res)
          if (!res) {
            this.$toast('没有查询到医生')
            return
          }
          this.list = res.content
        })
      } else {
        AdminDao.patientByPhone(value).then(res => {
          console.log('patientByPhone', res)
          if (res.content.length === 0) {
            this.$toast('没有查询到患者')
            return
          }
          this.list = res.content
        })
      }
    },
    searchByName (value) {
      if (this.searchType === 1) {
        AdminDao.doctorByName(value).then(res => {
          console.log('doctorByName', res)
          if (!res) {
            this.$toast('没有查询到医生')
            return
          }
          this.list = res.content
        })
      } else {
        AdminDao.patientByName(value).then(res => {
          console.log('patientByName', res)
          if (res.content.length === 0) {
            this.$toast('没有查询到患者')
            return
          }
          this.list = res.content
        })
      }
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
