<template>
  <div v-if="visible" class="loading-overlay">
    <div class="loading-spinner">
      <!-- 这里可以使用任何你喜欢的加载动画 -->
      加载中...
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean
  }
}
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  /* 样式调整 */
}
</style>
