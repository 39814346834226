<template>
  <div>
    <my-nav-bar :title="'申请体操'"/>
    <div style="display: flex;flex-direction: row;align-items: center">
      <img class="img" style="width: 12px;height: 12px;padding: 16px"
           src="https://cdn.keihong.tech/typora/2022-09-25-%E4%B8%89%E8%A7%92%E5%BD%A2%E6%A0%87.png"/>
      <div style="font-size: 18px">请选择康复体操类型：</div>
    </div>
    <div style="width: 100%;padding-left: 16px;padding-right: 16px;box-sizing: border-box">
      <div style="display: flex;flex-direction: column;align-items: center">
        <div v-for=" (item) in options" :key="item.id">
          <div style="display: flex;flex-direction: row;align-items: center;margin-top: 16px">
            <div class="selbox marginright"
                 :style="item[0].sel?'background-color: #00cb6b':''" @click="onSel(item[0])">{{ item[0].val }}
            </div>
            <div class="selbox"
                 :style="item[1].sel?'background-color: #00cb6b':''" @click="onSel(item[1])">{{ item[1].val }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 80px"></div>
    <MyButton :title="'提交申请'" style="position: fixed;bottom: 16px;left:16px;right:16px;border-radius: 24px;box-sizing: border-box;display:block;margin-left: 32px;margin-right: 32px;margin-top: 32px" @onClick="submit"/>
  </div>
</template>

<script>
import MyNavBar from '../../components/MyNavBar'
import MyButton from '../../components/MyButton'
import { Notify } from 'vant'
import GymnasticsDao from '../../network/GymnasticsDao'

export default {
  name: 'ApplyGymnastics',
  components: {
    MyButton,
    MyNavBar
  },
  data () {
    return {
      options: [
        [{
          id: 0,
          val: '脑溢血',
          sel: false
        }, {
          id: 1,
          val: '脑梗塞',
          sel: false
        }],
        [{
          id: 2,
          val: '脑内伤/脑外伤',
          sel: false
        }, {
          id: 3,
          val: '下肢骨科术后',
          sel: false
        }],
        [{
          id: 4,
          val: '呼吸康复',
          sel: false
        }, {
          id: 5,
          val: '骨质疏松体操',
          sel: false
        }],
        [{
          id: 6,
          val: '颈椎操',
          sel: false
        }, {
          id: 7,
          val: '肩周炎',
          sel: false
        }],
        [{
          id: 8,
          val: '椎间盘突出',
          sel: false
        }, {
          id: 9,
          val: '慢性膝关节炎',
          sel: false
        }]
      ],
      sel: null
    }
  },
  methods: {
    onSel (item) {
      this.options.forEach(o => {
        o[0].sel = false
        o[1].sel = false
      })
      item.sel = true
      this.sel = item
    },
    async submit () {
      if (!this.sel) {
        Notify('请选择一个')
      }
      console.log('submit', this.sel)
      // 申请体操接口
      const result = await GymnasticsDao.apply(this.$store.state.user.profile.userId, this.sel.id + '')
      console.log('体操申请结果', result)
      if (result) {
        this.$toast({
          message: '申请成功',
          type: 'success'
        })
        this.$router.replace('/home/home')
      }
    }
  }
}
</script>

<style scoped>
.selbox {
  padding: 16px;
  background-color: #f3f3f3;
  border-radius: 4px;
  border-style: solid;
  border-color: #d3d3d3;
  border-width: 1px;
  width: 30vw;
  text-align: center;
}

.marginright {
  margin-right: 16px;
}
</style>
