<template>
  <div>
    <van-nav-bar
      left-arrow
      left-text="返回"
      title="推荐费明细"
      @click-left="$router.back()"
    />
    <div style="text-align: center;padding: 16px">我的分销笔数：{{ orders.length ? orders.length : 0 }}</div>

    <div style="box-sizing: border-box;width: 100vw;padding: 16px">
      <div v-if="orders.length">
        <div v-for="(item,id) in orders" :key="id">
          <div class="user-info" style="margin-top: 16px">
            <span :style="{backgroundColor:item.distributorId === user.id?'#ec9b00':'#9f0009'}"
                  style="font-size: 12px;color: white;border-radius: 4px;padding-left: 4px;padding-right: 4px;margin-right: 4px">{{
                item.distributorId === user.id ? '直接分销' : `二级分销 | ${item.secondDistributorName}`
              }}</span>
            <span class="name">尾号{{ item.phone.slice(-4) }}购买了商品</span>
            <span class="phone">+{{
                item.distributorId === user.id ? item.distributionFee : item.distributionFeeSecond
              }} 元</span>
          </div>
          <div class="name" style="font-size: 12px;color: #686868">{{
              DateUtils.formate(item.gmtCreate, 'yyyy-MM-DD HH:mm:ss')
            }}
          </div>
          <div style="width: 100%;height: 1px;background-color: #c6c6c6;margin-top: 8px"></div>

        </div>
      </div>
      <div v-else>
        <div style="text-align: center;margin-top: 16px">
          <div style="font-size: 14px;color: #686868">暂无推荐费明细</div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

import DistributionDao from '@/h5/doucan/pages/distribution/DistributionDao'
import DateUtils from '../../../../utils/DateUtils'

export default {
  name: 'DistributionDetailsPage',
  computed: {
    DateUtils () {
      return DateUtils
    }
  },
  data () {
    return {
      orders: null,
      user: null
    }
  },
  created () {
    this.user = this.$store.state.distributor.data
    console.log('user', this.user)
    this.load()
  },
  methods: {

    load () {
      console.log('onDetailClick')
      DistributionDao.loadDetails(this.user.id).then(res => {
        console.log('loadDetails', res)
        this.orders = res
      })
    }

  }
}
</script>

<style scoped>
.user-info {
  display: flex;
  align-items: center;
}

.name {
  margin-right: auto; /* 将姓名推到左侧 */
}

.phone {
  /* 可以添加其他样式，比如颜色、字体大小等 */
}

.btn {
  width: 100%;
  height: 48px;
  line-height: 48px;
  margin-bottom: 8px;
  border-radius: 8px;
  text-align: center;
  background-color: #007373;
  color: white;
}
</style>
