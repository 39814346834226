import { render, staticRenderFns } from "./HongqianPerson.vue?vue&type=template&id=418632bd&scoped=true"
import script from "./HongqianPerson.vue?vue&type=script&lang=js"
export * from "./HongqianPerson.vue?vue&type=script&lang=js"
import style0 from "./HongqianPerson.vue?vue&type=style&index=0&id=418632bd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "418632bd",
  null
  
)

export default component.exports