// BrowserUtils.js
/**
 * 检查当前浏览器是否为微信内置浏览器
 * @returns {boolean} 如果是微信内置浏览器返回true，否则返回false
 */
export function isWechatBrowser () {
  return /MicroMessenger/i.test(navigator.userAgent)
}

// 使用示例（假设你是在一个支持ES6模块的环境中）
/*
import { isWechatBrowser } from './BrowserUtils';

if (isWechatBrowser()) {
  console.log('当前是微信内置浏览器');
} else {
  console.log('当前不是微信内置浏览器');
}
 */
