import axios from 'axios'

const DistributionDao = {
  async loginFirst (phone) {
    const url = '/distribution/login/first'
    const req = {
      phone: phone
    }
    const { data: resp } = await axios.post(url, req)
    return resp
  },
  async loginSecond (phone, inviterId) {
    const url = '/distribution/login/second'
    const req = {
      phone: phone,
      inviterId: inviterId
    }
    const { data: resp } = await axios.post(url, req)
    return resp
  },
  async updateName (req) {
    const url = '/distribution/updateName'
    const { data: resp } = await axios.post(url, req)
    return resp
  },
  async loadUser (userId) {
    const url = `/distribution/getDistributionUser?userId=${userId}`
    const httpResp = await axios.get(url)
    const { data: resp } = httpResp
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async loadDetails (userId) {
    const url = `/distribution/details?userId=${userId}`
    const httpResp = await axios.get(url)
    const { data: resp } = httpResp
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async loadMySecondDistributors (userId) {
    const url = `/distribution/mySecondDistributors?userId=${userId}`
    const httpResp = await axios.get(url)
    const { data: resp } = httpResp
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async loadRank (userId) {
    const url = '/distribution/distributorRank'
    const httpResp = await axios.get(url)
    const { data: resp } = httpResp
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }
}
export default DistributionDao
