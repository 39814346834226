<template>
  <div>
    <my-nav-bar v-if="isWeb" :title="'远程康复'"/>
    <div>
      <img v-for="(url, index) in urls" :key="index" :src="url" style="width: 100%;display: block">
    </div>
    <div style="text-align: center;font-size: 24px">远程康复典型案例</div>
    <video controls poster="https://cdn.keihong.tech/img/202408132150777.png"
           src="https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video_intro/%5B3%E5%88%86%E9%92%9F%EF%BC%8C0712%E6%9B%B4%E6%96%B0%5D%E3%80%8A%E5%BA%B7%E5%A4%8D%E7%9A%84%E7%A7%98%E5%AF%86%E3%80%8B%E2%80%94%E2%80%94%E6%9A%A8%E5%8D%97%E5%A4%A7%E5%AD%A6%E9%99%84%E5%B1%9E%E9%A1%BA%E5%BE%B7%E5%8C%BB%E9%99%A2%202023%E5%B9%B4%E9%A1%BA%E5%BE%B7%E5%8C%BA%E2%80%9C%E6%B0%B4%E9%9F%B5%E9%A1%BA%E5%BE%B7%20%E5%81%A5%E5%BA%B7%E6%9C%89%E9%81%93%E2%80%9D%E5%BE%AE%E8%A7%86%E9%A2%91%E5%BE%81%E9%9B%86%E5%A4%A7%E8%B5%9B%E5%8F%82%E8%B5%9B%E4%BD%9C%E5%93%81.mp4"
           style="width: 100%;max-height: 93vh"></video>

    <div style="height: 200px"></div>
  </div>
</template>
<script>

import MyNavBar from '@/h5/doucan/components/MyNavBar.vue'

export default {
  name: 'IntroPage',
  components: { MyNavBar },
  data () {
    return {
      isWeb: false,
      urls: []
    }
  },
  mounted () {
    this.isWeb = this.$route.query.isWeb
    /**
     * https://cdn.keihong.tech/img/202408161121170.png
     * https://cdn.keihong.tech/img/202408161121172.png
     * https://cdn.keihong.tech/img/202408161121173.png
     * @type {string[]}
     */
    this.urls = [
      'https://cdn.keihong.tech/img/202408161121170.png',
      'https://cdn.keihong.tech/img/202408161121172.png',
      'https://cdn.keihong.tech/img/202408161121173.png',
      'https://cdn.keihong.tech/img/202408132130244.png'
    ]
    // 'https://cdn.keihong.tech/img/202408132130244.png'
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
