<template>
  <div>
    <base-title-bar-scroll-page ref="container" title="远程康复活动公益接诊绩效费用">
      <!--   for循环 展示 currentData.pics   -->
      <div v-if="currentData" style="margin-top: 16px;margin-bottom: 32px">
        <div v-for="(item, index) in currentData.pics" :key="index" style="margin-top: 8px;">
          <img :src="item" alt="图片" style="width: 100%;display: block">
        </div>
      </div>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'

export default {
  name: 'AdminHospitalPerformanceStatistics',
  components: { BaseTitleBarScrollPage },
  data () {
    return {
      datas: [{
        hospital: '广州中医药大学顺德医院',
        pics: [
          'https://cdn.keihong.tech/img/202401211009867.png',
          'https://cdn.keihong.tech/img/202401211024294.png',
          'https://cdn.keihong.tech/img/202401211025143.png',
          'https://cdn.keihong.tech/img/202401211025841.png',
          'https://cdn.keihong.tech/img/202401211026323.png'

        ]
      }],
      currentData: null
    }
  },
  mounted () {
    this.hideLoading()
    const hospital = this.$route.query.hospitalId
    if (hospital) {
      this.currentData = this.datas.filter(item => item.hospital === hospital)[0]
      console.log('currentData', this.currentData)
      if (!this.currentData) {
        this.$toast('当前医院暂无上传绩效数据：' + hospital)
      }
    } else {
      console.log('参数错误：没有传递医院')
    }
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
