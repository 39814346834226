// constants.js
export const UniverseGuideData = {
  // ID1: 第一个视频的详情
  1: {
    id: 1,
    title: '如何关闭华为纯净模式',
    videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/user_guide/universe/%E5%8D%8E%E4%B8%BA%E7%BA%AF%E5%87%80%E6%A8%A1%E5%BC%8F.mp4',
    steps: []
  }

}
