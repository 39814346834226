<template>
  <van-dialog v-model="dialogVisible" :show-cancel-button="true" :show-confirm-button="true" :title="dialogTitle"
              @cancel="onCancel" @confirm="onSubmit">
    <van-field v-model="inputValue" :label="inputLabel" :placeholder="inputPlaceholder"/>
  </van-dialog>
</template>

<script>
export default {
  props: {
    dialogTitle: {
      type: String,
      default: 'Default Title'
    },
    inputLabel: {
      type: String,
      default: 'Default Input Label'
    },
    inputPlaceholder: {
      type: String,
      default: 'Default Input Placeholder'
    },
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputValue: '',
      dialogVisible: false
    }
  },
  methods: {
    openDialog () {
      this.dialogVisible = true
    },
    onSubmit () {
      this.$emit('submit', this.inputValue)
      this.showDialog = false
    },
    onCancel () {
      this.$emit('cancel')
      this.showDialog = false
    }
  }
}
</script>
