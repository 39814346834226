<template>
  <div>
    <base-title-bar-scroll-page ref="container" title="疾病科普宣教">
      <div id="srcollcontainer"
           style="padding: 16px;box-sizing: border-box;width: 100vw;height: 100vh;overflow-y: auto;/* 垂直滚动 */">
        <div style="width: 100%">
          <div v-for="(content, index) in contents" :key="index">
            <div
              :id="content.type"
              style="color: white;border-top-left-radius: 16px;border-top-right-radius: 16px;height: 48px;background-color: #23b346;font-size: 20px;font-weight: bold;line-height: 48px;text-align: center">
              {{ content.type }}
            </div>
            <div v-for="(article, index) in content.articles" :key="index" @click="toDetail(article)">
              <div style="background-color: white;padding: 24px;display: flex;flex-direction: row">
                <div style="font-size: 18px;margin-right: auto">{{ index + 1 }}、{{ article.title }}</div>
                <div>
                  <my-img :url="article.poster" style="width: 80px;height: 80px;margin-left: 8px;border-radius: 8px"/>
                </div>
              </div>
              <div v-if="index!=content.articles.length-1"
                   style="height: 0.5px;width: 100%;background-color: #eaeaea"></div>
            </div>

            <div
              style="background-color: white;height: 16px;border-bottom-left-radius: 16px;border-bottom-right-radius: 16px"></div>
          </div>
        </div>
      </div>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import MyImg from '@/h5/doucan/components/MyImg.vue'
import ReportFeatureAccessDao from '@/h5/doucan/network/ReportFeatureAccessDao'

const BASEURL = 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/'

export default {
  name: 'SickKepuKangfuyixueke',
  components: {
    MyImg,
    BaseTitleBarScrollPage
  },
  data () {
    return {
      hospital: null,
      contents: [
        {
          type: '入院早期 | 疾病宣教',
          articles: [
            {
              title: '解密慢性疼痛',
              id: 302,
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1693877549689.jpg',
              videoUrl: BASEURL + 'article/feeds/未知标题资讯/Mon-Sep-04-2023/解密慢性疼痛.mp4'
            }, {
              title: '腰椎间盘突出症康复宣教',
              id: 353,
              poster: BASEURL + 'article/feeds/腰椎间盘突出症康复宣教/腰椎间盘突出 康复指引_01(1).png'
            }, {
              title: '膝关节炎康复宣教',
              id: 352,
              poster: BASEURL + 'article/feeds/膝关节炎康复宣教/膝关节炎康复指引_01.png'
            }, {
              title: '腕管综合征康复宣教',
              id: 351,
              poster: BASEURL + 'article/feeds/腕管综合征/腕管综合征康复_01.png'
            }, {
              title: '康复自我管理系列--预防误吸宣教',
              id: 350,
              poster: BASEURL + 'article/feeds/康复自我管理系列--预防误吸宣教/吞咽障碍宣传_01.png'
            }, {
              title: '慢性阻塞性肺疾病(COPD)康复宣教',
              id: 339,
              poster: BASEURL + 'article/feeds/慢性阻塞性肺疾病(COPD)康复宣教/COPD首图1.png'
            }, {
              title: '失语症康复宣教',
              id: 349,
              poster: BASEURL + 'article/feeds/失语症康复宣教/失语症宣传册_01.png'
            }, {
              title: '烧伤康复宣教',
              id: 348,
              poster: BASEURL + 'article/feeds/烧伤康复宣教/烧伤康复_01.png'
            }, {
              title: '女性盆底功能障碍康复宣教',
              id: 347,
              poster: BASEURL + 'article/feeds/未知标题文章/Tue-Nov-28-2023/盆底康复宣传_01.png'
            }, {
              title: '脑卒中康复宣教',
              id: 346,
              poster: BASEURL + 'article/feeds/未知标题文章/Tue-Nov-28-2023/脑出血康复1.png'
            }, {
              title: '老年人认知障碍预防宣教',
              id: 345,
              poster: BASEURL + 'article/feeds/未知标题文章/Tue-Nov-28-2023/老年人认知障碍预防_01.png'
            }, {
              title: '颈椎病康复宣教',
              id: 344,
              poster: BASEURL + 'article/feeds/颈椎病康复宣教/颈椎病康复01.png'
            }, {
              title: '腱鞘炎康复宣教',
              id: 343,
              poster: BASEURL + 'article/feeds/腱鞘炎康复宣教/腱鞘炎康复宣教1.png'
            }, {
              title: '健康生活方式宣教',
              id: 342,
              poster: BASEURL + 'article/feeds/未知标题文章/Tue-Nov-28-2023/健康生活方式宣教01.png'
            }, {
              title: '肩周炎康复宣教',
              id: 341,
              poster: BASEURL + 'article/feeds/未知标题文章/Tue-Nov-28-2023/肩周炎1.png'
            }, {
              title: '脊柱侧弯康复宣教',
              id: 340,
              poster: BASEURL + 'article/feeds/脊柱侧弯康复宣教/脊柱侧弯1.png'
            }, {
              title: '防跌倒康复宣教',
              id: 338,
              poster: BASEURL + 'article/feeds/防跌倒康复宣教/防跌倒_01(1).png'
            }, {
              title: '冠心病康复宣教',
              id: 337,
              poster: BASEURL + 'article/feeds/冠心病康复宣教/图片1.png'
            }, {
              title: '脊髓损伤的康复宣教',
              id: 336,
              poster: BASEURL + 'article/feeds/脊髓损伤的康复宣教/SCI5.png'
            }, {
              title: '不想老了骨质疏松，这件事越早做越好！',
              id: 113,
              poster: BASEURL + 'article/feeds/不想老了骨质疏松，这件事越早做越好！/骨质疏松.jpg'
            },
            {
              id: 160,
              title: '无声的杀手-高血压：是如何一步步破坏各种器官的',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/无声的杀手-高血压：是如何一步步破坏各种器官的/48岁男子血压07.jpg'
            }, {
              id: 409,
              title: '血栓最容易堵在身体3个地方！有这4个信号一定要留意→',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/血栓最容易堵在身体3个地方！有这4个信号一定要留意→/血栓封面1.jpg'
            }, {
              id: 410,
              title: '国人“最佳血压值”出炉！改变1个小习惯，死亡风险大大降低',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/国人“最佳血压值”出炉！改变1个小习惯，死亡风险大大降低/高血压首图1.jpg'
            }, {
              id: 411,
              title: '超一半人不知道自己得了糖尿病！出现这些症状要警惕→',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/超一半人不知道自己得了糖尿病！出现这些症状要警惕→/糖尿病首图.jpg'
            }, {
              id: 412,
              title: '注意了！有这6种症状，提醒你该查血脂了',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/注意了！有这6种症状，提醒你该查血脂了/高血脂首图1.jpg'
            }, {
              id: 109,
              title: '一起了解高血压九大误区！这些错误不能犯',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/一起了解高血压九大误区！这些错误不能犯/微信图片_20221024220426.jpg'
            }, {
              id: 108,
              title: '为什么中国人最容易中风？',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/为什么中国人最容易中风？/微信图片_20221023201522.jpg'
            }, {
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题资讯/Fri-Oct-07-2022/从水锤效应看高血压脑出血.mp4',
              id: 92,
              title: '从水锤效应看高血压脑出血',
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917106868.jpg'
            }, {
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/中风有哪些急救方式/中风有哪些急救方式.mp4',
              id: 123,
              title: '中风有哪些急救方式？',
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917169587.jpg'
            }, {
              id: 147,
              title: '脑卒中后的大脑终生都有可塑性，千万别轻易放弃！',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题文章/Mon-Nov-28-2022/微信图片_20221128112401.jpg'
            }, {
              id: 207,
              title: '中风是如何影响语言功能的？',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/中风是如何影响语言功能的？/微信图片_20230117120811.png'
            }, {
              id: 265,
              title: '脑卒中康复|如何自己穿脱衣裤？',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/脑卒中康复|如何自己穿脱衣裤？/R-C.png'
            }, {
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题资讯/Mon-Dec-12-2022/中国康复医学会标准委员会主任观点：远程康复市场分析与展望.mp4',
              id: 154,
              title: '你觉得远程康复会给卒中患者带来怎么样的获益？',
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917202374.jpg'
            }, {
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题资讯/Mon-May-15-2023/三分钟了解脑卒中：类型、病因、症状和治疗 .mp4',
              id: 241,
              title: '三分钟了解脑卒中：类型、病因、症状和治疗 ',
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917254537.jpg'
            }, {
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题资讯/Wed-May-31-2023/脑梗死恢复期患者如何饮食指导？.mp4',
              id: 249,
              title: '脑梗死恢复期患者如何饮食指导？'
            }, {
              id: 274,
              title: '脑卒中康复|如何经口进食？',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/脑卒中康复|如何经口进食？/640.jpg'
            }, {
              id: 325,
              title: '关爱女性，远离卒中！',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/关爱女性，远离卒中！/妊娠（2）.png'
            }, {
              id: 326,
              title: '“卒”不及防，如何预防？',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/“卒”不及防，如何预防？/640.jpg'
            }, {
              id: 432,
              title: '远程康复是什么？患者该如何进行康复？',
              poster: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/远程康复是什么？患者该如何进行康复？/容器 5@1x.png'
            }, {
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/房颤如何引起中风？ 如何急救？/房颤如何引起中风？如何急救？.mp4',
              id: 80,
              title: '房颤如何引起中风？ 如何急救？',
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917075638.jpg'
            }, {
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/中风发病后有哪些就诊陷阱？/中风发病后有哪些就诊陷阱02.mp4',
              id: 81,
              title: '中风发病后有哪些就诊陷阱？',
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917076426.jpg'
            }, {
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/article/feeds/未知标题资讯/Fri-Sep-23-2022/手术麻醉之房颤与中风.mp4',
              id: 82,
              title: '手术麻醉之房颤与中风',
              poster: 'https://cdn.keihong.tech/doucan/img/feeds/video_cover/video-cover-1686917077344.jpg'
            }
          ]
        }
      ]
    }
  },
  mounted () {
    this.hospital = this.$store.state.user.qrCodeHospital
    if (!this.hospital) {
      // this.$toast('请先扫码登录')
      // this.$router.push('/login')
    }
    console.log(this.hospital)
    this.hideLoading()
    this.reportFeatureAccess()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    toDetail (content) {
      if (content.videoUrl) {
        console.log(content.videoUrl)
        // 带参数跳转：/BaseVideoPlayPage
        this.$router.push({
          path: '/BaseVideoPlayPage',
          query: {
            videoUrl: content.videoUrl
          }
        })
      } else {
        this.$router.push('/feeds/detail/' + content.id)
      }
    },
    reportFeatureAccess () {
      if (this.$store.state.route.from === '/KepuxuanjiaoMainPage') {
        ReportFeatureAccessDao.report(12, this.$store.state.user.qrCodeHospital, this.$store.state.user.openId).then(res => {
          console.log('上报访问成功', res)
        })
      }
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
