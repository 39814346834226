<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar style="position:fixed;top: 0;width: 100%" title="预约管理"/>
    <div style="margin-top: 56px">
      <!--   预约单信息录入 使用vant组件，录入内容为booking  -->
      <div style="padding: 16px">
        <van-field
          v-model="booking.patientName"
          label="患者姓名"
          placeholder="请输入患者姓名"
        />
        <van-field
          v-model="booking.doctorName"
          label="医生姓名"
          placeholder="请输入医生姓名"
        />
        <van-field
          v-model="booking.hospitalName"
          label="医院名称"
          placeholder="请输入医院名称"
        />
        <van-field
          v-model="booking.orderType"
          label="预约类型"
          placeholder="请输入预约类型"
        />
        <van-field
          v-model="booking.remark"
          label="备注"
          placeholder="请输入备注"
        />
        <van-field
          v-model="booking.times"
          label="预约次数"
          placeholder="预约次数"
        />
        <van-field
          v-model="booking.finishTimes"
          label="已完成次数"
          placeholder="已完成次数"
        />
        <van-field
          v-model="doctorBookingIds"
          label="医生预约单id"
          placeholder="请输入医生预约单id，使用[-]分割"
        />
        <van-button
          style="margin-top: 16px;width: 100%"
          type="primary"
          @click="handleCreate"
        >
          确定
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import AdminHospitalDao from '@/h5/doucan/network/AdminHospitalDao'

export default {
  name: 'BookingManager',
  components: { MyNavBar },
  data () {
    return {
      booking: {
        patientName: '',
        doctorName: '',
        hospitalName: '',
        orderType: '',
        remark: '',
        times: '',
        finishedTimes: '',
        doctorBookingIds: []
      },
      doctorBookingIds: ''
    }
  },
  mounted () {

  },
  methods: {
    async handleCreate () {
      const resp = await AdminHospitalDao.addRemoteGuideBooking(this.booking)
      if (resp.code === 200) {
        // dialog
        await this.$dialog.confirm({
          title: resp.message,
          message: '添加成功，是否继续添加？'
        })
      } else {
        await this.$dialog.alert({
          title: '提示',
          message: resp.message
        })
      }
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
