<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar style="position:fixed;top: 0;width: 100%" title="本院医生列表"/>
    <div style="margin-top: 56px">

      <!--  医生列表 用vant组件 -->
      <!--  loading  -->
      <div v-show="!doctors" style="text-align: center;margin-top: 16px">
        <van-loading/>
      </div>
      <!--  数量  -->
      <div v-if="doctors" style="padding: 16px">
        <div style="font-size: 20px">医生数量: {{ doctors.length }}</div>
      </div>
      <van-cell-group v-show="doctors">
        <van-cell
          v-for="(item, index) in doctors"
          :key="index"
          :label="`手机号: ${item.phone}`"
          :title="`姓名: ${item.realname}`"
          :value="`注册时间: ${DateUtils.formattedTime(item.createDate)}`"
        />
      </van-cell-group>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import AdminHospitalDao from '@/h5/doucan/network/AdminHospitalDao'
import DateUtils from '@/utils/DateUtils'

export default {
  name: 'AdminHospitalDoctorList',
  components: { MyNavBar },
  data () {
    return {
      doctors: null
    }
  },
  mounted () {
    this.loadDoctors()
  },
  methods: {
    async loadDoctors () {
      const datas = await AdminHospitalDao.doctorList()
      console.log(datas)
      this.doctors = datas
    }
  },
  watch: {},
  computed: {
    DateUtils () {
      return DateUtils
    }
  }
}
</script>
<style scoped>

</style>
