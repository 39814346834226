<template>
  <div>
    <base-title-bar-scroll-page ref="container" :show-title-bar="false" back title="缴费单" @onBackClick="onBackClick">

      <div style="padding: 16px">
        <div v-for="(data,index) in bills" :key="index">

          <van-card class="info-card" @click="handleClickBill(data)">
            <template #title>
              <div class="card-title">患者: {{ data.patientName }} ({{ data.age }}岁)</div>
            </template>

            <template #desc>
              <div>推送医生: {{ data.doctorName }}</div>
              <div>康复服务类型: {{ data.service.serviceType }}</div>
              <div v-if="data.service.id === 1">服务周期: {{ data.serviceCount }} 周</div>
              <div v-if="data.service.id === 2">服务周期: 6个月</div>
              <!--              <div>已报销比例: {{ data.serviceDiscount * 100 }}%</div>-->
              <!--              <div>总金额: ¥{{ data.totalAmount }} (原金额：{{ data.totalAmount / data.serviceDiscount }}元 )</div>-->
              <div :class="{'paid': data.payStatus, 'unpaid': !data.payStatus}">支付状态:
                {{ data.payStatus ? '已支付' : '未支付' }}
              </div>
            </template>
          </van-card>
        </div>
      </div>

      <!--   底部弹窗显示缴费单详情   -->
      <van-popup v-if="bill" v-model="showPopup" :style="{ height: '80%' }" position="bottom">
        <div>
          <div style="margin-bottom: 60px">
            <div v-if="bill.service.id === 2">
              <img src="https://cdn.keihong.tech/img/202403201027675.png" style="display: block;width: 100%">
              <div
                style="display: flex;flex-direction: row;justify-content: space-between;padding: 8px;margin: 16px;border-radius: 8px;background-color: #0a4b3d;color: white;"
                @click="openDeviceShop">
                <div>点击此处了解康复治疗仪</div>
                <div>{{ '>' }}</div>
              </div>
            </div>
            <service-type-guke-shuhou3month v-if="bill.service.id === 3"/>
            <service-type-zhongfeng3month v-if="bill.service.id === 4"/>
            <service-type-zhongfeng6month v-if="bill.service.id === 5"/>
            <service-type-rent-tens-ems-device v-if="bill.service.id === 6" patient/>
            <service-type-rent-fes-low-device v-if="bill.service.id === 7" patient/>
            <service-type-rent-fes-low-device-1-month v-if="bill.service.id === 8" patient/>
            <van-cell-group>
              <van-cell :value="bill.doctorName" title="推送医生"/>
              <van-cell :value="bill.service.serviceType" title="康复服务类型"/>
              <van-cell v-if="bill.service.id === 1" :value="bill.serviceCount +'周'" title="服务周期"/>
              <van-cell v-if="bill.service.id === 2" title="服务周期" value="6个月"/>
              <van-cell v-if="bill.service.id === 1" :value="bill.serviceDiscount * 100 + '%'" title="本次报销比例"/>
              <!--原金额-->
              <van-cell v-if="bill.service.id === 1" :value="bill.totalAmount / bill.serviceDiscount + ' 元'"
                        title="原金额"/>
              <van-cell v-if="bill.service.id === 1" :value="bill.totalAmount + ' 元'" title="报销后金额"/>
              <van-cell v-if="[3,5].includes(bill.service.id)" :value="bill.totalAmount + ' 元'" title="费用"/>
              <!--     租赁类         -->
              <van-cell v-if="[6,7,8].includes(bill.service.id)"
                        :value="`租赁${bill.serviceCount}个月,共,${bill.totalAmount}元(含押金)`" title="费用"/>
              <van-cell :value="bill.remark?bill.remark:'无'" title="备注信息"/>
              <van-cell :value="bill.aidsStr==='、 '?'无推荐':bill.aidsStr"
                        title="医生建议您，居家康复训练时，可采用以下相关辅具进行预防/改善"/>
              <van-cell :value="bill.payStatus ? '已支付' : '未支付'" title="支付状态"/>
              <van-cell :value="bill.patientName" title="患者姓名"/>
              <van-cell :value="bill.age" title="患者年龄"/>
            </van-cell-group>
            <div style="padding: 16px;background-color: #ffeded;margin: 8px;border-radius: 16px">
              远程康复服务是您当前就医科室专为本科室出院患者提供的互联网远程康复指导+随访服务，核心是居家康复训练方案和在线指导，配有康复科普、资讯、视频、康复体操等专业康复知识，可以保持和提高康复的效果。详情可咨询您的主治医生、治疗师、护士或康复助理。
            </div>
            <div style="padding: 16px;background-color: rgba(219,239,227,0.72);margin: 8px;border-radius: 16px">
              可拨打康复医学科助理电话了解服务详情：<a href="tel:">18520535303</a>
            </div>
          </div>
          <div
            style="position: fixed;bottom: 0;left: 0;right: 0;height: 56px;background-color: #f1f1f1;display: flex;flex-direction: row;justify-content: space-between">
            <!--两个按钮，左边 取消，右边 支付-->
            <van-button style="flex: 1;margin: 8px" type="default" @click="showPopup = false">返回</van-button>
            <van-button v-if="!bill.payStatus" style="flex: 1;margin: 8px" type="primary" @click="onClickPay">支付
            </van-button>
          </div>
        </div>

      </van-popup>

    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import { Dialog, Toast } from 'vant'
import WxPayDao from '@/h5/doucan/network/WxPayDao'
import wx from 'weixin-jsapi'
import axios from 'axios'
import ServiceTypeGukeShuhou3month from '@/h5/doucan/pages/doctor_patient_payment_bill/ServiceTypeGukeShuhou3month.vue'
import ServiceTypeZhongfeng3month from '@/h5/doucan/pages/doctor_patient_payment_bill/ServiceTypezhongfeng3month.vue'
import ServiceTypeZhongfeng6month from '@/h5/doucan/pages/doctor_patient_payment_bill/ServiceTypezhongfeng6month.vue'
import ServiceTypeRentTensEmsDevice
from '@/h5/doucan/pages/doctor_patient_payment_bill/ServiceTypeRentTensEmsDevice.vue'
import ServiceTypeRentFesLowDevice from '@/h5/doucan/pages/doctor_patient_payment_bill/ServiceTypeRentFesLowDevice.vue'
import ServiceTypeRentFesLowDevice1Month
from '@/h5/doucan/pages/doctor_patient_payment_bill/ServiceTypeRentFesLowDevice1Month.vue'

export default {
  name: 'DoctorPatientPaymentBillListForPatient',
  components: {
    ServiceTypeRentFesLowDevice1Month,
    ServiceTypeRentFesLowDevice,
    ServiceTypeRentTensEmsDevice,
    ServiceTypeZhongfeng6month,
    ServiceTypeZhongfeng3month,
    ServiceTypeGukeShuhou3month,
    BaseTitleBarScrollPage
  },
  data () {
    return {
      isApp: false,
      patientId: '',
      patientMobile: '',
      bills: null,
      showPopup: false,
      bill: null,
      testMobile: ['13610164743', '18008000808', '18520535303', '13902208129'], //
      payData: {
        openId: '',
        appsecret: '',
        appid: '',
        code: '',
        paySignInfo: null,
        outTradeNo: '' // 动优支付系统里面的订单号 ordernum
      }
    }
  },
  mounted () {
    this.isApp = this.$route.query.isApp
    this.patientId = this.$route.query.patientId
    if (this.isApp && !this.patientId) {
      this.checkApp()
    } else {
      console.log('patientId:', this.patientId)
      this.loadBills()
      this.initPay()
    }
  },
  methods: {
    openDeviceShop () {
      // https://doucan.jianerkanggz.cn/#/MallDetailPage?from=malllist&goodId=118
      window.location.href = 'https://doucan.jianerkanggz.cn/#/MallDetailPage?from=malllist&goodId=118'
    },
    checkApp () {
      const query = this.$route.query
      if (query?.isApp) {
        this.$store.commit('setIsApp', query.isApp)
        this.isApp = query.isApp
      }
      if (this.isApp) {
        // window.getAssessmentData = this.getAssessmentData
        // window.setAssessmentData = this.setAssessmentData
        this.reportBridgeSuccess()
      }
    },
    async reportBridgeSuccess () {
      if (!this.isApp) {
        return
      }
      if (window.android && window.android.onBridgeSuccess) {
        var fromAndroid = window.android.onBridgeSuccess('桥接成功 from 入院科普宣教')
        console.log('fromandroid 桥接', fromAndroid)
        console.log('document.visibilityState', document.visibilityState)
        // this.onMounted()
        this.patientId = await window.android.getPatientId()
        this.patientMobile = await window.android.getMobile()
        console.log('patientId from app', this.patientId)
        console.log('patientMobile from app', this.patientMobile)
        this.loadBills()
      } else {
        alert(typeof (window.android))
        // this.title = '桥接失败'
      }
    },
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    onClickPay () {
      this.submitPayment()
    },
    handleClickBill (bill) {
      this.bill = bill
      console.log('bill', bill)
      console.log('bill service', this.bill.service.serviceType)
      this.showPopup = true
    },
    loadBills () {
      this.$http.get(`doctorPatientPaymentBill/bills/patient/${this.patientId}`).then(res => {
        console.log('loadBills:', res)
        this.hideLoading()
        if (res.data.code === 200) {
          this.bills = res.data.data
        } else {
          this.$toast(res.data.message)
        }
      }).catch(err => {
        console.log('loadBills:', err)
        this.hideLoading()
      })
      // this.hideLoading()
    },
    onBackClick () {
      // 重定向到某个url
      // if (this.hospital === '内蒙古益济堂养老服务有限公司') {
      //   this.hospital = '中国民间中医医药研究开发协会中蒙民族医药研究发展分会'
      // }
      if (this.isApp) {
        // back
        this.$router.back()
      } else {
        window.location.href = 'https://doucan.jianerkanggz.cn/#/home/square'
      }
    },
    initPay () {
      // 判断是否是微信浏览器
      const isWeChatBrowser = () => {
        const userAgent = navigator.userAgent.toLowerCase()
        return userAgent.includes('micromessenger')
      }

      if (isWeChatBrowser()) {
        // console.log("This is a WeChat browser");
        this.initWx()
      } else {
        // console.log("This is not a WeChat browser");
      }
    },
    submitPayment () {
      // Add your payment submission logic here
      this.pay()
    },
    async initWx () {
      await this.getAppid()
      const openid = this.$store.state.user.openId
      if (openid) {
        this.payData.openid = openid
      } else {
        await this.getMpOpenID()
      }
    },
    async pay () {
      // if (!this.patientName || !this.patientMobile) {
      //   Notify({
      //     type: 'danger',
      //     message: '请填写姓名和手机号'
      //   })
      //   return
      // }
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      let fee = 0
      if (this.testMobile.includes(this.bill.patientPhone)) {
        fee = 0.01
        console.log('测试金额', fee)
      } else {
        fee = this.bill.totalAmount
      }
      const fromActivity = '缴费单'
      const name = this.bill.patientName
      // name脱敏
      const nameLen = name.length
      const nameStart = name.substring(0, 1)
      const nameEnd = name.substring(nameLen - 1, nameLen)
      const nameMask = nameStart + '*' + nameEnd
      var goods = ''
      switch (this.bill.service.id) {
        case 1:
          goods = '缴费单-' + this.bill.service.serviceType + '*' + this.bill.serviceCount + '周-' + nameMask
          break
        case 3:
        case 5:
          goods = '缴费单-' + this.bill.service.serviceType + '-' + nameMask
          break
        case 6:
        case 7:
        case 8:
          goods = '缴费单-' + this.bill.service.serviceType + '*' + this.bill.serviceCount + '月-' + nameMask
          break
      }

      // if (this.isAdmin) {
      //   fromActivity = '客服操作下单' + '-' + this.remark
      //   goods = this.hospital + '-' + this.doctorName + '-' + this.count + '周'
      // }

      const mobile = this.isApp ? this.patientMobile : this.$store.state.user.profile.phone
      const profile = {
        serveType: 0,
        mobile: mobile, // 后期mobile从用户授权获得，即订单记录支付者登录的微信账号手机号及实际接受服务患者的手机号
        patientName: this.bill.patientName,
        patientMobile: mobile,
        orderType: '',
        fee: fee,
        fromActivity: fromActivity,
        goods: goods,
        openId: this.payData.openid,
        billType: '缴费单',
        billId: this.bill.billId
      }
      if (this.isApp) {
        this.appPay(profile)
      } else {
        console.log('提交订单', profile)
        const data = await WxPayDao.createOrder(profile)
        console.log('支付qrcode', data)
        if (data.code === 1) {
          this.payData.paySignInfo = data.data
          this.payData.outTradeNo = data.data.outTradeNo
          // 调起微信支付
          wx.config({
            // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false
            debug: false,
            // 必填，公众号的唯一标识
            appId: this.payData.paySignInfo.sign.appId,
            // 必填，生成签名的时间戳
            timestamp: this.payData.paySignInfo.sign.timeStamp,
            // 必填，生成签名的随机串
            nonceStr: this.payData.paySignInfo.sign.nonceStr,
            // 必填，签名
            signature: this.payData.paySignInfo.sign.paySign,
            // 必填，需要使用的JS接口列表
            jsApiList: ['chooseWXPay']
          })
          wx.ready(() => {
            wx.checkJsApi({
              jsApiList: ['chooseWXPay'],
              success: function (res) {
                console.log('seccess')
                console.log('hskdjskjk', res)
              },
              fail: function (res) {
                console.log('fail')
                console.log(res)
              }
            })
            const _that = this
            wx.chooseWXPay({
              // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              timestamp: this.payData.paySignInfo.sign.timeStamp,
              // 支付签名随机串，不长于 32 位
              nonceStr: this.payData.paySignInfo.sign.nonceStr,
              // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              package: this.payData.paySignInfo.sign.packageValue,
              // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              signType: this.payData.paySignInfo.sign.signType,
              // 支付签名
              paySign: this.payData.paySignInfo.sign.paySign,
              // 支付成功后的回调函数
              success: function (res) {
                if (res.errMsg === 'chooseWXPay:ok') {
                  console.log('支付成功', res)
                  _that.payStatus = true
                  Dialog.alert({
                    message: '支付成功'
                  }).then(() => {
                    // on close
                    // todo 跳转到支付成功页面，显示支付凭证和提示
                    // _that.$router.push(
                    //   '/salepage/20230001/result?outTradeNo=' + _that.outTradeNo
                    // )
                    _that.showPopup = false
                    _that.loadBills()
                  })
                } else {
                  Dialog({ message: JSON.stringify(res) })
                  _that.payStatus = false
                }
              },
              fail: function (res) {
                Dialog({ message: '支付失败:' + JSON.stringify(res) })
                _that.payStatus = false
              }
            })
          })
          wx.error((err) => {
            alert(err)
          })
        } else {
          console.log('支付失败', data)
        }
      }
    },
    async getOpenId (code) {
      // this.info = '正在获取getOpenId'
      const url = '/quick/pay/openid?code=' + code

      axios
        .get(url)
        .then((res) => {
          // this.info = JSON.stringify(res)
          this.payData.openid = res.data.data.openid
          console.log('openid = ', res.data.data.openid)
          if (!this.payData.openid) {
            this.getCodeApi()
            return
          }
          this.$store.commit('user/setOpenId', this.payData.openid)
          // const profile = this.$store.state.apply20230001.profile
          // profile.openId = this.payData.openid
          // this.$store.commit('apply20230001/update', profile)
        })
        .catch((err) => {
          console.log('err', err)
          this.getCodeApi()
        })
    },
    getCodeApi () {
      const appid = 'wxcb56667c309e369b'
      const urlNow = encodeURIComponent(window.location.href)
      const scope = 'snsapi_base' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      // const scope = 'snsapi_userinfo' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      const url =
        'https://open.weixin.qq.com' +
        '/connect/oauth2/authorize?appid=' +
        appid +
        '&redirect_uri=' +
        urlNow +
        '&response_type=code&scope=' +
        scope +
        '#wechat_redirect'
      window.location.href = url
    },
    getUrlKey (name) {
      // 获取url 参数
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [undefined, ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    },
    async getAppid () {
      const url = '/quick/pay/appid'
      const res = await axios.get(url)
      console.log('appid', res)
      this.payData.appid = res.data.data.codeName
      this.payData.appsecret = res.data.data.codeValue
    },
    async getMpOpenID () {
      const code = this.getUrlKey('code')
      if (code) {
        this.payData.code = code
        await this.getOpenId(code)
      } else {
        this.getCodeApi()
      }
    },
    appPay (profile) {
      console.log('appPay', profile)
      window.android.pay(JSON.stringify(profile))
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>
.info-card {
  margin-bottom: 16px;
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 18px;
  font-weight: bold;
}

.paid {
  color: green;
}

.unpaid {
  color: red;
}
</style>
