<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar title="预约记录"/>
    <!--  说明  -->
    <div v-if="isAdmin" style="padding: 16px;background-color: #f5f5f5">
      <div style="font-size: 14px">
        1. 此处的预约记录为今天往后的【待执行预约】，已过期的预约记录请联系系统管理员查看
      </div>
      <div style="font-size: 14px">
        2. 接下来推出的功能：预约时间的修改，敬请期待
      </div>
    </div>
    <!--  渲染bookingList  -->
    <div v-for="(booking, index) in bookingList" :key="index">
      <!--   使用vant组件展示 预约日期 医生姓名 患者姓名   -->
      <van-cell-group>
        <van-cell :value="DateUtils.formattedTime(booking.bookingDateTime)" title="预约日期"/>
        <van-cell :value="booking.doctorName" title="医生姓名"/>
        <van-cell :value="booking.patientName" title="患者姓名"/>
      </van-cell-group>
      <div style="height: 16px;background-color: #f3f3f3"></div>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import AdminDao from '@/h5/doucan/network/AdminDao'
import DateUtils from '../../../../utils/DateUtils'

export default {
  name: 'BookingListPage',
  components: { MyNavBar },
  data () {
    return {
      isPatient: false,
      isDoctor: false,
      isAdmin: false,
      patientId: '',
      doctorId: '',
      bookingList: []
    }
  },
  mounted () {
    // 从query获取用户类型
    const { userType } = this.$route.query
    if (userType === 'patient') {
      this.isPatient = true
      this.patientId = this.$route.query.patientId
    } else if (userType === 'doctor') {
      this.isDoctor = true
      this.doctorId = this.$route.query.doctorId
    } else if (userType === 'admin') {
      this.isAdmin = true
    }

    if (this.isAdmin) {
      this.loadDataByAdmin()
    } else if (this.isPatient) {
      this.loadDataByPatient()
    } else if (this.isDoctor) {
      this.loadDataByDoctor()
    }
  },
  methods: {
    loadDataByAdmin () {
      // 获取数据
      AdminDao.getBookingListTodayStart().then(res => {
        console.log(res)
        this.bookingList = res
      })
    },
    loadDataByPatient () {
      // 获取数据
      AdminDao.getBookingListByPatientId(this.patientId).then(res => {
        console.log(res)
        this.bookingList = res
      })
    },
    loadDataByDoctor () {
      // 获取数据
      AdminDao.getBookingListByDoctorId(this.doctorId).then(res => {
        console.log(res)
        this.bookingList = res
      })
    }
  },
  watch: {},
  computed: {
    DateUtils () {
      return DateUtils
    }
  }
}
</script>
<style scoped>

</style>
