<template>
  <div style="display: flex;flex-direction: row;align-items: center">
    <img style="width: 12px;height: 12px;padding: 16px"
         src="https://cdn.keihong.tech/typora/2022-09-25-%E4%B8%89%E8%A7%92%E5%BD%A2%E6%A0%87.png"/>
    <div :style="`font-size: ${size}px;color: ${fontColor}`">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'MyTitle1',
  props: {
    title: {
      type: String,
      default: '标题'
    },
    size: {
      type: Number,
      default: 14
    },
    fontColor: {
      type: String,
      default: '#333333'
    }
  }
}
</script>

<style scoped>

</style>
