<template>
  <div>
    <base-title-bar-scroll-page ref="container" :showTitleBar="showTitle" title="使用指南">
      <div>
        <div v-if="showAll">
          <div style="height: 24px;background-color: #009688"></div>
          <van-panel v-if="showAll" class="panel-header" title="医生端">
            <van-cell-group>
              <van-cell v-for="(item,index) in DoctorUserGuideData" :key="index" :title="item.title"
                        @click="navigate('医生端', item.id)"/>
              <!--              <van-cell :title="DoctorUserGuideData['2'].title" @click="navigate('医生端', 2)"/>-->
              <!--              <van-cell :title="DoctorUserGuideData['3'].title" @click="navigate('医生端', 3)"/>-->
            </van-cell-group>
          </van-panel>
        </div>

        <div v-if="showAll || showPatient">
          <div style="height: 24px;background-color: #009688"></div>
          <van-panel class="panel-header" title="患者端">
            <van-cell-group>
              <van-cell v-for="(item,index) in PatientUserGuideData" :key="index" :title="item.title"
                        @click="navigate('患者端', item.id)"/>
              <!--              <van-cell :title="PatientUserGuideData['2'].title" @click="navigate('患者端', 2)"/>-->
              <!--              <van-cell :title="PatientUserGuideData['3'].title" @click="navigate('患者端', 3)"/>-->
            </van-cell-group>
          </van-panel>
        </div>
        <div style="height: 24px;background-color: #009688"></div>
        <van-panel class="panel-header" title="常见问题">
          <van-cell-group>
            <van-cell v-for="(item,index) in UniverseGuideData" :key="index" :title="item.title"
                      @click="navigate('常见问题', item.id)"/>
            <!--            <van-cell :title="UniverseGuideData['1'].title" @click="navigate('常见问题', 1)"/>-->
          </van-cell-group>
        </van-panel>
      </div>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import { DoctorUserGuideData } from '@/h5/doucan/pages/user_guide/DoctorUserGuideData'
import { PatientUserGuideData } from '@/h5/doucan/pages/user_guide/PatientUserGuideData'
import { UniverseGuideData } from '@/h5/doucan/pages/user_guide/UniverseGuideData'

export default {
  name: 'UserGuide',
  components: { BaseTitleBarScrollPage },
  data () {
    return {
      showTitle: true,
      showAll: false,
      showPatient: false
    }
  },
  mounted () {
    console.log(this.$route.query)
    if (this.$route.query.showall) {
      this.showAll = true
    }
    if (this.$route.query.showpatient) {
      this.showPatient = true
    }
    if (this.$route.query.isApp) {
      this.showTitle = false
    }
    console.log('showall', this.showAll, 'showpatient', this.showPatient)
    this.hideLoading()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    navigate (role, featureId) {
      this.$router.push(`/tutorial/${role}/${featureId}`)
    }
  },
  watch: {},
  computed: {
    UniverseGuideData () {
      return UniverseGuideData
    },
    PatientUserGuideData () {
      return PatientUserGuideData
    },
    DoctorUserGuideData () {
      return DoctorUserGuideData
    }
  }
}
</script>
<style scoped>
.panel-header .van-panel__header {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}
</style>
