<template>
  <div
    style="
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    "
  >
    <div style="max-width: 1080px">
      <img
        class="img_item_big"
        src="https://cdn.keihong.tech/202306021242723.png"
      />
      <div
        style="
          text-align: center;
          font-weight: bolder;
          font-size: 32px;
          margin-top: 16px;
        "
      >
        产品案例
      </div>
      <div style="text-align: center">
        南方医科大学南海医院 上线时间：2021年3月
      </div>
      <div style="display: flex;align-items: center">
        <img
          class="img_item"
          src="https://cdn.keihong.tech/202306021243661.png"
        />
        <img
          class="img_item"
          src="https://cdn.keihong.tech/202306021243633.png"
        />
      </div>
      <div style="text-align: center;padding: 16px;background-color: #dadada;color: white"><a style="font-size: 32px;" href="https://keihong.oss-cn-shenzhen.aliyuncs.com/%E5%8A%A8%E4%BC%98doucan-%E9%AA%A8%E5%AF%86%E5%BA%A6%E5%BA%B7%E5%A4%8D.apk">
        下载患者端
      </a></div>

    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.img_item_big {
  display: block;
  width: 100%;
}
.img_item {
  display: block;
  width: 50%;
}
</style>
