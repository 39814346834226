import axios from 'axios'

const WxPayDao = {

  async createOrder (order, miniapp = false) {
    const url = '/quick/pay/wx/activity' + (miniapp ? '/miniapp' : '')
    const { data: resp } = await axios.post(url, order)
    const {
      code,
      data,
      message
    } = resp
    if (code !== 200 || !data) {
      return {
        code: 2,
        msg: message
      }
    }
    return {
      code: 1,
      data: data
    }
  },
  async createFreeOrder (order) {
    const url = '/quick/pay/wx/activity/free'
    const { data: resp } = await axios.post(url, order)
    const {
      code,
      data,
      message
    } = resp
    if (code !== 200 || !data) {
      return {
        code: 2,
        msg: message
      }
    }
    return {
      code: 1,
      data: data
    }
  }
}
export default WxPayDao
