import axios from 'axios'
import { Toast } from 'vant'

const AdminDao = {
  // 删除患者
  async deletePatient (phone) {
    const url = 'deleteUser/deletePatient?phone=' + phone
    const { data: resp } = await axios.get(url)
    const {
      code,
      data,
      message
    } = resp
    if (code !== 200 || !data) {
      Toast.fail('删除患者失败：' + message) // 弹出错误提示
      return null
    }
    return data
  },
  // 商城订单
  async orderList () {
    const url = 'mallOrder/orders'
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  // 首页从0开始
  async webOrderList (page, pageSize) {
    const url = `quick/pay/order/list?page=${page}&pageSize=${pageSize}`
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async patientList (page, pageSize) {
    const url = `cms/patient/list?page=${page}&pageSize=${pageSize}`
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }, // 通过姓名查询患者 请求url： /cms/patient/phone?phone=xxx
  async patientByPhone (phone) {
    const url = `cms/patient/phone?phone=${phone}`
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }, // 通过姓名查询患者 请求url： /cms/patient/name?name=xxx
  async patientByName (name) {
    const url = `cms/patient/name?name=${name}`
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  // 通过姓名查询医生，请求url： /cms/doctor/search/name?name=xxx
  async doctorByName (name) {
    const url = `cms/doctor/search/name?name=${name}`
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  // 通过手机号查询医生，请求url： /cms/doctor/search/phone?phone=xxx
  async doctorByPhone (phone) {
    const url = `cms/doctor/search/phone?phone=${phone}`
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  // 创建群聊MDT:post  /add/byadmin
  async createGroup (req) {
    const url = '/groupMdt/add/byadmin'
    const { data: resp } = await axios.post(url, req)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  // 获取预约记录 get： /booking/bookingList/todayStart/all
  async getBookingListTodayStart () {
    const url = '/booking/bookingList/todayStart/all'
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200) {
      return null
    }
    return data
  },
  async getBookingListByPatientId (patientId) {
    const url = `/booking/patient?p_id=${patientId}`
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200) {
      return null
    }
    return data
  },
  async getBookingListByDoctorId (doctorId) {
    const url = `/booking/doctor?d_id=${doctorId}`
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200) {
      return null
    }
    return data
  },
  async doctorList (page, pageSize) {
    const url = `cms/doctor/info?page=${page}&pageSize=${pageSize}`
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }
}
export default AdminDao
