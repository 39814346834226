<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar title="医院管理入口"/>
    <!--  vant展示列表 hospitalAdmins  -->
    <van-cell-group>
      <van-cell v-for="(item, index) in hospitalAdmins" :key="index" :title="`医院id:${item.id} - ${item.userId}`"
                is-link
                @click="handleClick(item)"/>
    </van-cell-group>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import AdminHospitalDao from '@/h5/doucan/network/AdminHospitalDao'

export default {
  name: 'HospitalAdminListPage',
  components: { MyNavBar },
  data () {
    return {
      hospitalAdmins: [
        {
          userId: '惠州市第三人民医院',
          passwd: '20221226'
        },
        {
          userId: '东莞康华医院',
          passwd: '347891'
        },
        {
          userId: '广州中医药大学顺德医院',
          passwd: '252378'
        },
        {
          userId: '南方医科大学第七附属医院',
          passwd: '394832'
        },
        {
          userId: '暨南大学附属顺德医院',
          passwd: '876543'
        },
        {
          userId: '暨南大学附属第一医院',
          passwd: '432176'
        },
        {
          userId: '广州市天河区中医医院',
          passwd: '876543'
        }
      ]
    }
  },
  mounted () {
    AdminHospitalDao.hospitalList().then(datas => {
      // this.hospitalAdmins = datas
      console.log('hospitalList', datas)
      // 生成新的数组
      this.hospitalAdmins = datas.map(item => {
        return {
          userId: item.hospitalName,
          passwd: item.passwdPlain,
          id: item.hospitalId
        }
      })
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {
    handleClick (hospital) {
      this.$router.push({
        path: '/AdminHospital',
        query: {
          hospitalId: hospital.userId,
          passwd: hospital.passwd
        }
      })
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
