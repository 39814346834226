<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div
    style="position:fixed;right: 16px;bottom: 56px;height: 56px;width: 56px;background-color: white;border-radius: 28px;box-shadow: 1px 1px 20px #888888"
    @click="handleClick">
    <img :src="imgSrc"
         style="height: 48px;width: 48px;margin-left: 4px;margin-top: 4px"/>
  </div>
</template>
<script>

export default {
  name: 'FlowButton',
  props: {
    imgSrc: {
      type: String,
      default: 'https://cdn.keihong.tech/img/202309211523073.png'
    }
  },
  data () {
    return {}
  },
  mounted () {

  },
  methods: {
    handleClick () {
      // 组件外部调用组件内部的方法
      this.$emit('click')
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
