import axios from 'axios'

const MonitorDao = {
  async zichuan_ip () {
    const url = '/descp?taxon=zichuan_ip'
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async newestAlarm (deviceName) {
    const url = '/zichuan/alarm/device?deviceName=' + deviceName
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async newest10Alarm (deviceName) {
    const url = '/zichuan/alarm/device/top10?deviceName=' + deviceName
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async newest10AlarmByDeviceID (deviceID) {
    const url = '/zichuan/alarm/device/top10/did?dID=' + deviceID
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async newest10AlarmDetail (deviceId) {
    const url = '/zichuan/alarm/detail/top10?deviceId=' + deviceId
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  postAlarmDetail (req) {
    return axios.post('/zichuan/alarm/detail', req)
  }

}
export default MonitorDao
