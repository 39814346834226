<template>
  <div class="download-page">
    <div class="app-info">
      <div style="padding: 16px;text-align: center;background-color: #eaeaea;color: red">
        如您正在微信打开，请点击右上角菜单选择系统浏览器打开进行下载
      </div>
      <!--      <img alt="App Logo" class="app-logo" src="path/to/your/app-logo.png">-->
      <h1 class="app-name">动优康复-社区医生端</h1>
      <p class="app-description">点击下方下载 </p>
    </div>
    <div class="download-buttons">
      <!--      <van-button type="primary" @click="downloadApp('ios')">Download for iOS</van-button>-->
      <van-button type="primary" @click="downloadApp('android')">下载 安卓Android</van-button>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ShequDoctorAppDownload',
  data () {
    return {}
  },
  mounted () {

  },
  methods: {
    downloadApp (platform) {
      // Handle app download logic based on the platform
      if (platform === 'ios') {
        window.location.href = 'link_to_your_ios_app'
      } else if (platform === 'android') {
        window.location.href = 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/apk/communityOrz/%E7%A4%BE%E5%8C%BA%E7%AB%AF-2.9.61.apk'
      }
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>
.download-page {
  text-align: center;
  padding: 20px;
}

.app-logo {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.app-name {
  font-size: 24px;
  margin-bottom: 10px;
}

.app-description {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}

.download-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
