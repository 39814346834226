<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/WebOrderListPage.vue -->
<template>
  <div>
    <MyNavBar style="position:fixed;top: 0;width: 100%" title="商城订单"/>
    <div style="margin-top: 56px">
      <!--  loading  -->
      <div v-if="loading" style="text-align: center">加载中
        <van-loading v-if="loading" color="#1989fa" type="spinner"/>
      </div>
      <div>
        <van-pull-refresh :v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-if="list.length > 0"
            :finished="finished"
            :v-model="loading"
            finished-text="没有更多了"
            @load="loadMore"
          >
            <div v-for="(item, index) in list" :key="index" style="width: 100%">
              <!--            <div style="padding: 16px">-->
              <!--              <div style="font-size: 16px">订单编号{{ item.orderNum }}</div>-->
              <!--              <div style="display: flex">-->
              <!--                <div style="font-size: 24px">{{ item.patientName }}</div>-->
              <!--                <div-->
              <!--                  style="font-size: 16px; margin-left: auto; margin-top: auto"-->
              <!--                >-->
              <!--                  {{ formattedTime(item.createTime) }}-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div style="font-size: 16px">下单手机号:{{ item.mobile }}</div>-->
              <!--              <div style="font-size: 16px">-->
              <!--                {{ item.fromActivity }}-->
              <!--              </div>-->
              <!--              <div style="font-size: 16px">{{ item.goods }}</div>-->

              <!--              <div style="font-size: 16px">支付金额:{{ item.payAmount }}</div>-->
              <!--            </div>-->
              <div>
                <van-cell :value="item.orderId" title="订单编号" title-width="4em"/>
                <van-cell :value="formattedTime(item.date)" title="下单日期" title-width="4em"/>

                <van-cell :title="`支付金额`" :value="`${item.price?item.price:'0.00'}元`"/>

                <van-cell title="购买产品">
                  <template #default>
                    <div>
                      <!--                      <van-tag plain type="primary">{{ item.goodsName }}</van-tag>-->
                      <div>{{ item.goodsName }} x {{ item.count }}</div>
                    </div>
                  </template>
                </van-cell>

                <van-cell :value="item.remark" title="备注信息" title-width="4em"/>

                <van-cell :value="`${item.name}, ${item.phone}, ${item.address}`" title="收集信息" title-width="4em"/>

                <div
                  style="display: flex;flex-direction:row;justify-content: end;width: 100vw;padding: 16px;box-sizing: border-box">
                  <div style="font-size: 12px;padding: 4px;border: 1px solid black;width: auto;border-radius: 8px"
                       @click="copyAddress(item)">
                    复制收件信息
                  </div>
                </div>
                <!--      支付状态        -->
                <!--                <van-cell title="支付状态">-->
                <!--                  <template #default>-->
                <!--                    <div>-->
                <!--                      <van-tag v-if="item.orderStatus === 1" plain style="color: #00ad61" type="primary"> 已支付-->
                <!--                      </van-tag>-->
                <!--                      <van-tag v-else plain type="danger"> 未支付</van-tag>-->
                <!--                    </div>-->
                <!--                  </template>-->
                <!--                </van-cell>-->
              </div>
              <div
                style="
                height: 8px;
                background-color: rgb(232,232,232);
                width: 100%;
              "
              ></div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>
<script>
import AdminDao from '../../network/AdminDao'
import moment from 'moment'
import MyNavBar from '@/h5/doucan/components/MyNavBar.vue'

export default {
  name: 'MallOrderListPage',
  components: { MyNavBar },
  data () {
    return {
      list: [], // 数据列表
      pageNo: 0, // 当前页码
      pageSize: 10,
      totalPages: 0, // 总页数
      refreshing: false, // 下拉刷新状态,
      total: 0,
      loading: true,
      finished: false
    }
  },
  async mounted () {
    this.loadData() // 初始化加载数据

    // this.test()
  },
  methods: {
    copyAddress (item) {
      // 复制到剪贴板
      this.$copyText(
        `${item.name}, ${item.phone}, ${item.address}`
      ).then(
        () => {
          this.$dialog({
            title: '提示',
            message: '复制成功',
            confirmText: '确定'
          })
        },
        () => {
          this.$dialog({
            title: '提示',
            message: '复制失败',
            confirmText: '确定'
          })
        }
      )
    },
    test () {
      this.finished = true
      this.loading = false
      this.list = [
        {
          address: '广东省 广州市 -1 天河区天河北路368号都市华庭日彩轩32A',
          amount: '1184.00',
          count: 1,
          date: 1713683285000,
          freight: '包邮',
          goodsName: '动优康复宝',
          logisticsCode: '',
          logisticsCompany: '',
          name: '辛鹏',
          orderId: '1713683273874874428',
          payMethod: '微信支付',
          phone: '13903000597',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1711614661387.png',
          price: '1184.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 佛山市顺德区 -1 北滘镇顺德碧桂园西十二路9号',
          amount: '149.00',
          count: 1,
          date: 1713612781000,
          freight: '包邮',
          goodsName: '肩部脱臼带',
          logisticsCode: '',
          logisticsCompany: '',
          name: '黎小姐',
          orderId: '1713612769527935173',
          payMethod: '微信支付',
          phone: '13825536587',
          picUrl: 'https://cdn.keihong.tech/img/202309201725350.png',
          price: '149.00',
          remark: '左边',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 佛山市顺德区 -1 南方医科大学顺德医院住院部3号栋楼6层23号床',
          amount: '149.00',
          count: 1,
          date: 1713262227000,
          freight: '包邮',
          goodsName: '肩部脱臼带',
          logisticsCode: '',
          logisticsCompany: '',
          name: '何祖娟',
          orderId: '1713262216006252761',
          payMethod: '微信支付',
          phone: '13690848107',
          picUrl: 'https://cdn.keihong.tech/img/202309201725350.png',
          price: '149.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 佛山市顺德区 -1 伦教甲子路南方医科大学顺德医院3号楼6楼康复科50床',
          amount: '149.00',
          count: 1,
          date: 1713157524000,
          freight: '包邮',
          goodsName: '肩部脱臼带',
          logisticsCode: '',
          logisticsCompany: '',
          name: '韦桂龙',
          orderId: '1713157513710761571',
          payMethod: '微信支付',
          phone: '18666531835',
          picUrl: 'https://cdn.keihong.tech/img/202309201725350.png',
          price: '149.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 佛山市顺德区伦教街道 -1 长鹿农庄营销部',
          amount: '149.00',
          count: 1,
          date: 1713152490000,
          freight: '包邮',
          goodsName: '肩部脱臼带',
          logisticsCode: '',
          logisticsCompany: '',
          name: '朱美玲',
          orderId: '1713152473197439949',
          payMethod: '微信支付',
          phone: '18022286954',
          picUrl: 'https://cdn.keihong.tech/img/202309201725350.png',
          price: '149.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 佛山 -1 暨南大学附属顺德医院神经外科',
          amount: '129.00',
          count: 1,
          date: 1712904905000,
          freight: '包邮',
          goodsName: '医用弹力袜防静脉曲张治疗型抗血栓:一二级压力长筒袜男女',
          logisticsCode: '',
          logisticsCompany: '',
          name: '严碧炎',
          orderId: '1712904897773112852',
          payMethod: '微信支付',
          phone: '18718904834',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1712653573644.png',
          price: '129.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '湖南 岳阳 岳阳楼区 五里牌街道锦绣华城',
          amount: '111.00',
          count: 1,
          date: 1712488890000,
          freight: '包邮',
          goodsName: '四脚手杖防滑轻便伸缩拐仗老年人腿脚乏力',
          logisticsCode: '',
          logisticsCompany: '',
          name: '陈凌玲',
          orderId: '1712488890601692357',
          payMethod: '微信支付',
          phone: '15897309797',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1706971942116.jpg',
          price: '111.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '湖南 岳阳 岳阳楼区 五里牌街道锦绣华城',
          amount: '235.00',
          count: 1,
          date: 1712465047000,
          freight: '包邮',
          goodsName: '多功能单边助行器稳定四角手杖老人病人中风偏瘫康复',
          logisticsCode: '',
          logisticsCompany: '',
          name: '陈凌玲',
          orderId: '1712465047673727757',
          payMethod: '微信支付',
          phone: '15897309797',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1706963162198.jpg',
          price: '235.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 佛山市 -1 顺德医院',
          amount: '666.00',
          count: 1,
          date: 1712031493000,
          freight: '包邮',
          goodsName: '动优康复宝',
          logisticsCode: '',
          logisticsCompany: '',
          name: '严碧炎',
          orderId: '1712031485192247222',
          payMethod: '微信支付',
          phone: '18718904834',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1711614661387.png',
          price: '666.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 佛山市 -1 顺德本地容桂街道新友中路81号时代倾城1期',
          amount: '69.00',
          count: 1,
          date: 1711852842000,
          freight: '包邮',
          goodsName: '卧床病人坐垫圈中空防褥疮垫照顾老人南瓜花型压疮医护理垫高垫子',
          logisticsCode: '',
          logisticsCompany: '',
          name: '严碧炎',
          orderId: '1711852833445753446',
          payMethod: '微信支付',
          phone: '18718904834',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1704270856643.jpg',
          price: '69.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 佛山市 顺德区 暨南大学附属顺德医院神经外科',
          amount: '596.00',
          count: 4,
          date: 1711716091000,
          freight: '包邮',
          goodsName: '肩部脱臼带',
          logisticsCode: '',
          logisticsCompany: '',
          name: '严',
          orderId: '1711716091633779638',
          payMethod: '微信支付',
          phone: '18718904834',
          picUrl: 'https://cdn.keihong.tech/img/202309201725350.png',
          price: '149.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 佛山市 顺德区 暨南大学附属顺德医院神经外科',
          amount: '596.00',
          count: 4,
          date: 1711715416000,
          freight: '包邮',
          goodsName: '肩部脱臼带',
          logisticsCode: '',
          logisticsCompany: '',
          name: '严',
          orderId: '1711715416603379783',
          payMethod: '微信支付',
          phone: '18718904834',
          picUrl: 'https://cdn.keihong.tech/img/202309201725350.png',
          price: '149.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 广州 -1 天河区体育东横街44号701',
          amount: '666.00',
          count: 1,
          date: 1711697426000,
          freight: '包邮',
          goodsName: '动优康复宝',
          logisticsCode: '',
          logisticsCompany: '',
          name: '赵',
          orderId: '1711697418095534577',
          payMethod: '微信支付',
          phone: '13902399178',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1711614661387.png',
          price: '666.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 广州市番禺区 -1 洛溪丽江花园丽茵楼7座605',
          amount: '666.00',
          count: 1,
          date: 1711685033000,
          freight: '包邮',
          goodsName: '动优康复宝',
          logisticsCode: '',
          logisticsCompany: '',
          name: '龙女',
          orderId: '1711685013244262073',
          payMethod: '微信支付',
          phone: '18108479223',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1711614661387.png',
          price: '666.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 广州市番禺区 -1 洛溪丽江花园丽茵楼7座605',
          amount: '592.00',
          count: 1,
          date: 1711671967000,
          freight: '包邮',
          goodsName: '动优康复宝',
          logisticsCode: '',
          logisticsCompany: '',
          name: '龙女',
          orderId: '1711671958068549590',
          payMethod: '微信支付',
          phone: '18108479223',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1711614661387.png',
          price: '592.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 广州 -1 番禺区洛溪新城环岛北路8号11-401室',
          amount: '592.00',
          count: 1,
          date: 1711633087000,
          freight: '包邮',
          goodsName: '动优康复宝',
          logisticsCode: '',
          logisticsCompany: '',
          name: '刘炼',
          orderId: '1711633029065889718',
          payMethod: '微信支付',
          phone: '13826125616',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1711614661387.png',
          price: '592.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 广州市 -1 番禺区洛浦街洛溪环岛北路10号R1栋503房',
          amount: '592.00',
          count: 1,
          date: 1711630319000,
          freight: '包邮',
          goodsName: '动优康复宝',
          logisticsCode: '',
          logisticsCompany: '',
          name: '刘晗明',
          orderId: '1711630307418649146',
          payMethod: '微信支付',
          phone: '13560343290',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1711614661387.png',
          price: '592.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 广州 -1 番禺区洛溪新城环岛北路8号南天名苑11-1602房',
          amount: '592.00',
          count: 1,
          date: 1711624588000,
          freight: '包邮',
          goodsName: '动优康复宝',
          logisticsCode: '',
          logisticsCompany: '',
          name: '周生',
          orderId: '1711624578659156894',
          payMethod: '微信支付',
          phone: '13500005079',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1711614661387.png',
          price: '592.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 广州市 -1 番禺区南村镇里仁洞诺德名都花园5-2-1303',
          amount: '592.00',
          count: 1,
          date: 1711623831000,
          freight: '包邮',
          goodsName: '动优康复宝',
          logisticsCode: '',
          logisticsCompany: '',
          name: '刘建军',
          orderId: '1711623591748531453',
          payMethod: '微信支付',
          phone: '13580391448',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1711614661387.png',
          price: '592.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 广州市 -1 番禺区洛浦街道洛溪7号码头B 105房',
          amount: '592.00',
          count: 1,
          date: 1711620567000,
          freight: '包邮',
          goodsName: '动优康复宝',
          logisticsCode: '',
          logisticsCompany: '',
          name: '廖生',
          orderId: '1711620555409759026',
          payMethod: '微信支付',
          phone: '13928883118',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1711614661387.png',
          price: '592.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 广州 -1 南沙区万顷沙镇珠江街珠江西二路16号501房',
          amount: '592.00',
          count: 1,
          date: 1711620333000,
          freight: '包邮',
          goodsName: '动优康复宝',
          logisticsCode: '',
          logisticsCompany: '',
          name: '陈其康',
          orderId: '1711620325812869833',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1711614661387.png',
          price: '592.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 佛山市 -1 顺德医院神经外科',
          amount: '22.00',
          count: 1,
          date: 1710751184000,
          freight: '包邮',
          goodsName: '医院同款握力球手部康复训练器',
          logisticsCode: '',
          logisticsCompany: '',
          name: '严',
          orderId: '1710751173903856268',
          payMethod: '微信支付',
          phone: '18718904834',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1704447301092.jpg',
          price: '22.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 佛山 -1 顺德区暨南大学附属顺德医院神经外科6床',
          amount: '136.00',
          count: 2,
          date: 1710293515000,
          freight: '包邮',
          goodsName: '分指固定拉链球拍约束带',
          logisticsCode: '',
          logisticsCompany: '',
          name: '梁金凤',
          orderId: '1710293506515665463',
          payMethod: '微信支付',
          phone: '13590694563',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1704293268540.jpg',
          price: '68.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 佛山市 -1 顺德区容桂街道暨南大学附属顺医院',
          amount: '136.00',
          count: 2,
          date: 1709517854000,
          freight: '包邮',
          goodsName: '分指固定拉链球拍约束带',
          logisticsCode: '',
          logisticsCompany: '',
          name: '严碧炎',
          orderId: '1709517846693723413',
          payMethod: '微信支付',
          phone: '18718904834',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1704293268540.jpg',
          price: '68.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 佛山市 -1 顺德区容桂街道暨南大学附属顺医院',
          amount: '84.00',
          count: 2,
          date: 1709517821000,
          freight: '包邮',
          goodsName: '医院用四肢约束带手脚固定绑带老人束缚带卧床病人束手带医用捆绑',
          logisticsCode: '',
          logisticsCompany: '',
          name: '严碧炎',
          orderId: '1709517813685270069',
          payMethod: '微信支付',
          phone: '18718904834',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1704293845658.jpg',
          price: '42.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 佛山市 顺德区 暨南大学附属顺德医院神经外科',
          amount: '118.00',
          count: 1,
          date: 1709307954000,
          freight: '包邮',
          goodsName: '医用弹力袜防静脉曲张治疗型抗血栓：一二级压力连裤袜男女',
          logisticsCode: '',
          logisticsCompany: '',
          name: '严',
          orderId: '1709307954632522342',
          payMethod: '微信支付',
          phone: '18718904834',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1709305257224.jpg',
          price: '118.00',
          remark: '连裤袜 S码',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 广州市 天河区 燕都路8号203',
          amount: '85.00',
          count: 1,
          date: 1708830779000,
          freight: '包邮',
          goodsName: '益肤佳抗菌剂口腔抑菌喷雾',
          logisticsCode: '',
          logisticsCompany: '',
          name: '邹晖',
          orderId: '1708829253346349172',
          payMethod: '微信支付',
          phone: '13609649095',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1705047780221.jpg',
          price: '85.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 广州市 -1 海珠区东晓南路江南新苑c2-205',
          amount: '85.00',
          count: 1,
          date: 1707208809000,
          freight: '包邮',
          goodsName: '益肤佳抗菌剂口腔抑菌喷雾',
          logisticsCode: '',
          logisticsCompany: '',
          name: '粥公子',
          orderId: '1707208796325194631',
          payMethod: '微信支付',
          phone: '13719221221',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1705047780221.jpg',
          price: '85.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 化州市 -1 合江镇六龙管理区高灯笼村',
          amount: '241.00',
          count: 1,
          date: 1706952706000,
          freight: '包邮',
          goodsName: '助行器-9144(5寸轮)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '周才能',
          orderId: '1706952677585822373',
          payMethod: '微信支付',
          phone: '13500070043',
          picUrl: 'https://cdn.keihong.tech/img/202312071522707.png',
          price: '241.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '河南省 濮阳市 -1 范县新区阳光华府6号楼',
          amount: '318.00',
          count: 2,
          date: 1706338059000,
          freight: '包邮',
          goodsName: '踝足固定器AFO 非充气款',
          logisticsCode: '',
          logisticsCompany: '',
          name: '马宗林',
          orderId: '1706338033530831303',
          payMethod: '微信支付',
          phone: '18539338649',
          picUrl: 'https://cdn.keihong.tech/img/202309201744516.jpg',
          price: '159.00',
          remark: 'L码，左右脚各一个',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 广州市 -1 番禺区洛溪新城环岛北路9号中凯文化创意园1-506室',
          amount: '85.00',
          count: 1,
          date: 1705152304000,
          freight: '包邮',
          goodsName: '益肤佳抗菌剂口腔抑菌喷雾',
          logisticsCode: '',
          logisticsCompany: '',
          name: '潘企忠',
          orderId: '1705152289280159311',
          payMethod: '微信支付',
          phone: '13302382991',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1705047780221.jpg',
          price: '85.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 广州市 -1 番禺区洛浦街洛溪环岛北路10号R1栋503房',
          amount: '85.00',
          count: 1,
          date: 1705143800000,
          freight: '包邮',
          goodsName: '益肤佳抗菌剂口腔抑菌喷雾',
          logisticsCode: '',
          logisticsCompany: '',
          name: '刘晗明',
          orderId: '1705143788676599696',
          payMethod: '微信支付',
          phone: '13560343290',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1705047780221.jpg',
          price: '85.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 广州 -1 广州大道中',
          amount: '85.00',
          count: 1,
          date: 1705143190000,
          freight: '包邮',
          goodsName: '益肤佳抗菌剂口腔抑菌喷雾',
          logisticsCode: '',
          logisticsCompany: '',
          name: '龙生',
          orderId: '1705143181848514038',
          payMethod: '微信支付',
          phone: '13902208139',
          picUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/image/1705047780221.jpg',
          price: '85.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '内蒙古 赤峰市 -1 松山区星光天地小区14号楼4单元24楼，19904760171',
          amount: '0.00',
          count: 365,
          date: 1703599156000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '孙彪',
          orderId: '1703599156865653412',
          payMethod: '微信支付',
          phone: '19904760171',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '1、手动移位机一台；2、电动下肢训练机一台',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '内蒙古 赤峰市 -1 松山区星光天地小区14号楼4单元24楼，19904760171',
          amount: '0.00',
          count: 365,
          date: 1703598862000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '孙彪',
          orderId: '1703598862215747815',
          payMethod: '微信支付',
          phone: '19904760171',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '1、手动移位机一台；2、电动下肢训练机一台',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '内蒙古 赤峰市 松山区 星光天地小区14号楼4单元24楼2402',
          amount: '0.00',
          count: 2,
          date: 1703598582000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '孙彪',
          orderId: '1703598582161641075',
          payMethod: '微信支付',
          phone: '19904760171',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '1、手动移位机一台；2、电动下肢训练机一台',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '内蒙古 赤峰市 松山区 星光天地小区14号楼4单元24楼2402',
          amount: '0.00',
          count: 1,
          date: 1703598566000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '孙彪',
          orderId: '1703598566083226771',
          payMethod: '微信支付',
          phone: '19904760171',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '1、手动移位机一台；2、电动下肢训练机一台',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '内蒙古 赤峰市 松山区 星光天地小区14号楼4单元24楼2402',
          amount: '0.00',
          count: 2,
          date: 1703598355000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '孙彪',
          orderId: '1703598355942924570',
          payMethod: '微信支付',
          phone: '19904760171',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '1、手动移位机一台；2、下肢训练机一台',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '内蒙古 赤峰市 松山区 星光天地小区14号楼4单元24楼2402',
          amount: '0.00',
          count: 2,
          date: 1703598340000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '孙彪',
          orderId: '1703598340366393933',
          payMethod: '微信支付',
          phone: '19904760171',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '1、手动移位机一台；2、下肢训练机一台',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '内蒙古 赤峰市 松山区 星光天地小区14号楼4单元24楼2402',
          amount: '0.00',
          count: 2,
          date: 1703598331000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '孙彪',
          orderId: '1703598331439588157',
          payMethod: '微信支付',
          phone: '19904760171',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '1、手动移位机一台；2、下肢训练机一台',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '广东 顺德 -1 康复医学科 病房16-17',
          amount: '0.00',
          count: 1,
          date: 1703598117000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '沉思录',
          orderId: '1703598117829909275',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '广东 广州 -1 啊啊啊',
          amount: '0.00',
          count: 2,
          date: 1703587124000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '123',
          orderId: '1703587124908155594',
          payMethod: '微信支付',
          phone: '13902208129',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '广东 顺德 -1 康复医学科 病房16-17',
          amount: '0.00',
          count: 1,
          date: 1703586855000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '沉思录',
          orderId: '1703586855295680089',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '广东 顺德 -1 康复医学科 病房16-17',
          amount: '0.00',
          count: 1,
          date: 1703586842000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '沉思录',
          orderId: '1703586842039316163',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '广东 顺德 -1 康复医学科 病房16-17',
          amount: '0.00',
          count: 1,
          date: 1703586819000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '沉思录',
          orderId: '1703586819166521515',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '广东 顺德 -1 康复医学科 病房16-17',
          amount: '0.00',
          count: 1,
          date: 1703586794000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '沉思录',
          orderId: '1703586794006465667',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '广东 顺德 -1 康复医学科 病房16-17',
          amount: '0.00',
          count: 1,
          date: 1703586757000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '沉思录',
          orderId: '1703586757674363980',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '广东 广州 -1 广州',
          amount: '0.00',
          count: 1,
          date: 1703586627000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '陈其康',
          orderId: '1703586624790370387',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '九龍 九龍城 -1 公园前',
          amount: '0.00',
          count: 1,
          date: 1703586542000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: 'CAMP CHAN',
          orderId: '1703586540125522935',
          payMethod: '微信支付',
          phone: '1111',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '广东 顺德 -1 康复医学科 病房16-17',
          amount: '0.00',
          count: 1,
          date: 1703586278000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '沉思录',
          orderId: '1703586278235864707',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '广东 顺德 -1 康复医学科 病房16-17',
          amount: '0.00',
          count: 1,
          date: 1703586212000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '沉思录',
          orderId: '1703586212441697506',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '九龍 九龍城 -1 公园前',
          amount: '0.00',
          count: 1,
          date: 1703586184000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: 'CAMP CHAN',
          orderId: '1703586181725737003',
          payMethod: '微信支付',
          phone: '1111',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '广东 顺德 -1 康复医学科 病房16-17',
          amount: '0.00',
          count: 1,
          date: 1703586146000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '沉思录',
          orderId: '1703586146151306210',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '广东 顺德 -1 康复医学科 病房16-17',
          amount: '0.00',
          count: 1,
          date: 1703586121000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '沉思录',
          orderId: '1703586121235852209',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '广东 顺德 -1 康复医学科 病房16-17',
          amount: '0.00',
          count: 1,
          date: 1703586075000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '沉思录',
          orderId: '1703586075320151061',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '九龍 九龍城 -1 公园前',
          amount: '0.00',
          count: 1,
          date: 1703586062000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: 'CAMP CHAN',
          orderId: '1703586060177936702',
          payMethod: '微信支付',
          phone: '1111',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '九龍 九龍城 -1 公园前',
          amount: '0.00',
          count: 1,
          date: 1703585910000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: 'CAMP CHAN',
          orderId: '1703585908136769097',
          payMethod: '微信支付',
          phone: '1111',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '九龍 九龍城 -1 公园前',
          amount: '0.00',
          count: 1,
          date: 1703585768000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: 'CAMP CHAN',
          orderId: '1703585766449576095',
          payMethod: '微信支付',
          phone: '1111',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '广东 顺德 -1 康复医学科 病房16-17',
          amount: '0.00',
          count: 1,
          date: 1703585592000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '沉思录',
          orderId: '1703585592096704648',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '广东 顺德 -1 康复医学科 病房16-17',
          amount: '0.00',
          count: 1,
          date: 1703583649000,
          freight: '包邮',
          goodsName: '租赁服务-(免费)',
          logisticsCode: '',
          logisticsCompany: '',
          name: '沉思录',
          orderId: '1703583649090654604',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202312262139727.jpg',
          price: '0.00',
          remark: '',
          status: '待支付',
          statusCode: 0
        },
        {
          address: '地址已删除 地址已删除 地址已删除 地址已删除',
          amount: '169.00',
          count: 1,
          date: 1703412517000,
          freight: '包邮',
          goodsName: '踝足固定器AFO-188-充气款',
          logisticsCode: '',
          logisticsCompany: '',
          name: '地址已删除',
          orderId: '1703412487044594821',
          payMethod: '微信支付',
          phone: '地址已删除',
          picUrl: 'https://cdn.keihong.tech/img/202309201748369.jpg',
          price: '169.00',
          remark: 'M',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 广州 荔湾区东漖南路 芳和花园14栋404',
          amount: '0.01',
          count: 1,
          date: 1702896787000,
          freight: '包邮',
          goodsName: '踝足固定器-测试',
          logisticsCode: '',
          logisticsCompany: '',
          name: '陈其康',
          orderId: '1702896787608223950',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202309201748369.jpg',
          price: '0.01',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 顺德 -1 康复医学科 病房16-17',
          amount: '0.01',
          count: 1,
          date: 1702895548000,
          freight: '包邮',
          goodsName: '踝足固定器-测试',
          logisticsCode: '',
          logisticsCompany: '',
          name: '沉思录',
          orderId: '1702895540147892734',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202309201748369.jpg',
          price: '0.01',
          remark: '43 左',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 佛山市 顺德区 容桂暨南大学附属顺德医院16楼',
          amount: '235.00',
          count: 1,
          date: 1702870205000,
          freight: '包邮',
          goodsName: '助行器-9501',
          logisticsCode: '',
          logisticsCompany: '',
          name: '马子珺',
          orderId: '1702870205205815803',
          payMethod: '微信支付',
          phone: '13695248578',
          picUrl: 'https://cdn.keihong.tech/img/202312071523781.jpg',
          price: '235.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 佛山市 顺德区 容桂暨南大学附属顺德医院16楼',
          amount: '159.00',
          count: 1,
          date: 1702518805000,
          freight: '包邮',
          goodsName: '踝足固定器AFO 非充气款',
          logisticsCode: '',
          logisticsCompany: '',
          name: '马子珺',
          orderId: '1702518805236248683',
          payMethod: '微信支付',
          phone: '13695248578',
          picUrl: 'https://cdn.keihong.tech/img/202309201744516.jpg',
          price: '159.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '吉林省 四平市 -1 铁西区仁兴街道空军家属区一号院',
          amount: '880.00',
          count: 1,
          date: 1701171503000,
          freight: '包邮',
          goodsName: '颈椎减压止鼾枕',
          logisticsCode: '',
          logisticsCompany: '',
          name: '邹国明',
          orderId: '1701171488693228666',
          payMethod: '微信支付',
          phone: '18143111302',
          picUrl: 'https://cdn.keihong.tech/202310311411006.jpg',
          price: '880.00',
          remark: '175   S',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 广州 -1 广州',
          amount: '5.00',
          count: 1,
          date: 1700308813000,
          freight: '包邮',
          goodsName: '电动移位机',
          logisticsCode: '',
          logisticsCompany: '',
          name: '龙生',
          orderId: '1700308800874416480',
          payMethod: '微信支付',
          phone: '13902208129',
          picUrl: 'https://cdn.keihong.tech/img/202311181924550.jpg',
          price: '5.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '湖北省 随州市广水市 -1 城郊街道西门新农村',
          amount: '169.00',
          count: 1,
          date: 1700208989000,
          freight: '包邮',
          goodsName: '踝足固定器AFO-188-充气款',
          logisticsCode: '',
          logisticsCompany: '',
          name: '汪孟芹',
          orderId: '1700208980220666827',
          payMethod: '微信支付',
          phone: '136 1722 8279',
          picUrl: 'https://cdn.keihong.tech/img/202309201748369.jpg',
          price: '169.00',
          remark: '左脚 S码',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 佛山市 -1 顺德区容桂街道新有中路时代倾城',
          amount: '0.01',
          count: 1,
          date: 1696988092000,
          freight: '包邮',
          goodsName: '踝足固定器-测试',
          logisticsCode: '',
          logisticsCompany: '',
          name: '严碧炎',
          orderId: '1696988084684246605',
          payMethod: '微信支付',
          phone: '18718904834',
          picUrl: 'https://cdn.keihong.tech/img/202309201748369.jpg',
          price: '0.01',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '湖南省 岳阳市 -1 岳阳楼区巴陵东路港岳豪庭1',
          amount: '0.01',
          count: 1,
          date: 1696758004000,
          freight: '包邮',
          goodsName: '踝足固定器-测试',
          logisticsCode: '',
          logisticsCompany: '',
          name: '龙女',
          orderId: '1696757987164272702',
          payMethod: '微信支付',
          phone: '18108479223',
          picUrl: 'https://cdn.keihong.tech/img/202309201748369.jpg',
          price: '0.01',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '湖南省 岳阳市 -1 岳阳楼区巴陵东路港岳豪庭1',
          amount: '0.01',
          count: 1,
          date: 1695880374000,
          freight: '包邮',
          goodsName: '踝足固定器-测试',
          logisticsCode: '',
          logisticsCompany: '',
          name: '龙女',
          orderId: '1695880351923310744',
          payMethod: '微信支付',
          phone: '18108479223',
          picUrl: 'https://cdn.keihong.tech/img/202309201748369.jpg',
          price: '0.01',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 广州 -1 番禺恒达',
          amount: '0.01',
          count: 1,
          date: 1695879612000,
          freight: '包邮',
          goodsName: '踝足固定器-测试',
          logisticsCode: '',
          logisticsCompany: '',
          name: '龙生',
          orderId: '1695879602207502851',
          payMethod: '微信支付',
          phone: '18008000808',
          picUrl: 'https://cdn.keihong.tech/img/202309201748369.jpg',
          price: '0.01',
          remark: '10号发货',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 佛山市顺德区 -1 时代倾城',
          amount: '0.01',
          count: 1,
          date: 1695879554000,
          freight: '包邮',
          goodsName: '踝足固定器-测试',
          logisticsCode: '',
          logisticsCompany: '',
          name: '严碧炎',
          orderId: '1695879542326959229',
          payMethod: '微信支付',
          phone: '18718904834',
          picUrl: 'https://cdn.keihong.tech/img/202309201748369.jpg',
          price: '0.01',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '湖南省 岳阳市 岳阳楼区 巴陵东路港岳豪庭2-702',
          amount: '0.01',
          count: 1,
          date: 1695879399000,
          freight: '包邮',
          goodsName: '踝足固定器-测试',
          logisticsCode: '',
          logisticsCompany: '',
          name: '龙女',
          orderId: '1695879399819134458',
          payMethod: '微信支付',
          phone: '18108479223',
          picUrl: 'https://cdn.keihong.tech/img/202309201748369.jpg',
          price: '0.01',
          remark: '右脚',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 广州市 -1 荔湾区东漖南路芳和花园宝宝',
          amount: '0.01',
          count: 1,
          date: 1695879308000,
          freight: '包邮',
          goodsName: '踝足固定器-测试',
          logisticsCode: '',
          logisticsCompany: '',
          name: '陈其康',
          orderId: '1695879301401571110',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202309201748369.jpg',
          price: '0.01',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 广州市 -1 荔湾区东漖南路芳和花园宝宝',
          amount: '0.02',
          count: 2,
          date: 1695878461000,
          freight: '包邮',
          goodsName: '踝足固定器-测试',
          logisticsCode: '',
          logisticsCompany: '',
          name: '陈其康',
          orderId: '1695878454659501433',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202309201748369.jpg',
          price: '0.01',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 广州市 -1 荔湾区东漖南路芳和花园宝宝',
          amount: '0.01',
          count: 1,
          date: 1695878265000,
          freight: '包邮',
          goodsName: '踝足固定器-测试',
          logisticsCode: '',
          logisticsCompany: '',
          name: '陈其康',
          orderId: '1695878257548764070',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202309201748369.jpg',
          price: '0.01',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 广州市 -1 荔湾区东漖南路芳和花园宝宝',
          amount: '0.01',
          count: 1,
          date: 1695877661000,
          freight: '包邮',
          goodsName: '踝足固定器-测试',
          logisticsCode: '',
          logisticsCompany: '',
          name: '陈其康',
          orderId: '1695877652959806984',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202309201748369.jpg',
          price: '0.01',
          remark: 'back',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 广州市 -1 荔湾区东漖南路芳和花园宝宝',
          amount: '0.02',
          count: 2,
          date: 1695877630000,
          freight: '包邮',
          goodsName: '踝足固定器-测试',
          logisticsCode: '',
          logisticsCompany: '',
          name: '陈其康',
          orderId: '1695877618904856998',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202309201748369.jpg',
          price: '0.01',
          remark: '测试爸爸',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 广州市 -1 荔湾区东漖南路芳和花园宝宝',
          amount: '0.04',
          count: 4,
          date: 1695875799000,
          freight: '包邮',
          goodsName: '踝足固定器-测试',
          logisticsCode: '',
          logisticsCompany: '',
          name: '陈其康',
          orderId: '1695875790917777331',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'https://cdn.keihong.tech/img/202309201748369.jpg',
          price: '0.01',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 佛山市 顺德容桂 暨南大学附属顺德医院16楼康复科',
          amount: '159.00',
          count: 1,
          date: 1695870433000,
          freight: '包邮',
          goodsName: '踝足固定器AFO 非充气款',
          logisticsCode: '',
          logisticsCompany: '',
          name: '严小姐',
          orderId: '1695870433514243793',
          payMethod: '微信支付',
          phone: '18718904834',
          picUrl: 'https://cdn.keihong.tech/img/202309201744516.jpg',
          price: '159.00',
          remark: '代芮文升购买足托',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东省 佛山市 容桂 地址：广东省佛山市顺德区容桂街道桂洲大道中56号暨南大学附属顺德医院住院部16楼康复医学科18床',
          amount: '259.00',
          count: 1,
          date: 1695203325000,
          freight: '包邮',
          goodsName: '肩部脱臼带',
          logisticsCode: '',
          logisticsCompany: '',
          name: '芮文升',
          orderId: '1695203325896265615',
          payMethod: '微信支付',
          phone: '18924531448',
          picUrl: 'https://cdn.keihong.tech/img/202309201725350.png',
          price: '259.00',
          remark: '患者芮文升已经转账，客服代为下单支付',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 广州 越秀区 广州大道中111号',
          amount: '199.00',
          count: 1,
          date: 1661992566000,
          freight: '包邮',
          goodsName: '烹趣中链脂肪酸营养烹饪油330ml*3瓶经典礼盒装',
          logisticsCode: '',
          logisticsCompany: '',
          name: '龙生',
          orderId: '1661992566190415546',
          payMethod: '微信支付',
          phone: '13902208129',
          picUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/%E7%83%B9%E8%B6%A3%E4%B8%AD%E9%93%BE%E8%84%82%E8%82%AA%E9%85%B8%E8%90%A5%E5%85%BB%E7%83%B9%E9%A5%AA%E6%B2%B93%E7%93%B6/0.jpeg',
          price: '199.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        },
        {
          address: '广东 广州 荔湾区东漖南路 芳和花园14栋404',
          amount: '597.00',
          count: 3,
          date: 1642766802000,
          freight: '包邮',
          goodsName: '烹趣中链脂肪酸营养烹饪油330ml*3瓶经典礼盒装',
          logisticsCode: '',
          logisticsCompany: '',
          name: '陈其康',
          orderId: '1642766802579635796',
          payMethod: '微信支付',
          phone: '13610164743',
          picUrl: 'http://ihealthy.oss-cn-shenzhen.aliyuncs.com/mall/%E7%83%B9%E8%B6%A3%E4%B8%AD%E9%93%BE%E8%84%82%E8%82%AA%E9%85%B8%E8%90%A5%E5%85%BB%E7%83%B9%E9%A5%AA%E6%B2%B93%E7%93%B6/0.jpeg',
          price: '199.00',
          remark: '',
          status: '待发货',
          statusCode: 1
        }
      ]
    },
    async loadData () {
      this.finished = false
      const datas = await AdminDao.orderList()
      this.loading = false
      console.log('商城订单', datas)
      this.list = datas
      // this.totalPages = datas.totalPages
      // this.total = datas.totalElements
      this.refreshing = false
    },
    onRefresh () {
      console.log('刷新')
      this.pageNo = 0 // 重置页码
      this.loadData() // 刷新数据
    },
    loadMore () {
      if (this.pageNo < this.totalPages - 1) {
        this.pageNo++ // 增加页码
        // AdminDao.mallOrderList(this.pageNo, this.pageSize) // 假设接口地址为/api/data
        AdminDao.orderList() // 假设接口地址为/api/data
          .then(datas => {
            console.log('loadMore', datas)
            this.totalPages = datas.totalPages
            this.total = datas.totalElements
            this.list = this.list.concat(datas.content) // 追加数据
            // 加载状态结束
            this.loading = false
            if (this.list.length >= this.total) {
              this.finished = true
            }
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    formattedTime (timestamp) {
      return moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  watch: {
    refreshing (newVal) {
      if (!newVal) {
        this.$nextTick(() => {
          this.loadMore() // 加载更多数据
        })
      }
    }
  },
  computed: {}
}
</script>
<style scoped>
.van-tag--primary.van-tag--plain {
  color: #00ad61;
}
</style>
