<template>
  <div>
    <base-title-bar-scroll-page ref="container" title="疾病科普宣教">
      <div>
        <div v-for="(content, index) in contents" :key="index" @click="toDetail(content.id)">
          <div style="background-color: white;padding: 24px;display: flex;flex-direction: row">
            <div style="font-size: 18px;margin-right: auto">{{ index + 1 }}、{{ content.title }}</div>
            <div>
              <my-img :url="content.poster" style="width: 80px;height: 80px;margin-left: 8px"/>
            </div>
          </div>
          <div style="height: 1px;width: 100%;background-color: #eaeaea"></div>
        </div>
      </div>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import MyImg from '@/h5/doucan/components/MyImg.vue'
import ReportFeatureAccessDao from '@/h5/doucan/network/ReportFeatureAccessDao'

export default {
  name: 'SickKepu',
  components: {
    MyImg,
    BaseTitleBarScrollPage
  },
  data () {
    return {
      hospital: null,
      contents: [
        {
          title: '远程康复是什么？患者该如何进行康复？',
          id: 432,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '腰椎间盘突出症康复宣教',
          id: 353,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '膝关节炎康复宣教',
          id: 352,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '腕管综合征康复宣教',
          id: 351,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '康复自我管理系列--预防误吸宣教',
          id: 350,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '失语症康复宣教',
          id: 349,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '烧伤康复宣教',
          id: 348,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '女性盆底功能障碍康复宣教',
          id: 347,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '脑卒中康复宣教',
          id: 346,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '老年人认知障碍预防宣教',
          id: 345,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '颈椎病康复宣教',
          id: 344,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '腱鞘炎康复宣教',
          id: 343,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '肩周炎康复宣教',
          id: 341,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '脊柱侧弯康复宣教',
          id: 340,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '防跌倒康复宣教',
          id: 338,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '冠心病康复宣教',
          id: 337,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '脊髓损伤的康复宣教',
          id: 336,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '垂体瘤',
          id: 373,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '颅骨缺损',
          id: 375,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }, {
          title: '颈动脉海绵窦篓',
          id: 374,
          poster: 'https://cdn.keihong.tech/img/202405191732890.png'
        }
      ]
    }
  },
  mounted () {
    this.hospital = this.$store.state.user.qrCodeHospital
    if (!this.hospital) {
      // this.$toast('请先扫码登录')
      // this.$router.push('/login')
    }
    console.log(this.hospital)
    this.hideLoading()
    this.reportFeatureAccess()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    toDetail (id) {
      this.$router.push('/feeds/detail/' + id)
    },
    reportFeatureAccess () {
      if (this.$store.state.route.from === '/KepuxuanjiaoMainPage') {
        ReportFeatureAccessDao.report(14, this.$store.state.user.qrCodeHospital, this.$store.state.user.openId).then(res => {
          console.log('上报访问成功', res)
        })
      }
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
