<template>
  <div>
    <base-title-bar-scroll-page ref="container" title="远程康复联盟单位">
      <div>
        <!--img-->
        <div class="alliance-intro-img">
          <img alt="" src="https://cdn.keihong.tech/img/202401221838021.png">
          <img alt="" src="https://cdn.keihong.tech/img/202401221838847.png">
          <img alt="" src="https://cdn.keihong.tech/img/202401221838392.png">
        </div>

        <div style="padding: 16px">
          <van-button style="width: 100%;box-sizing: border-box;font-size: 24px;padding: 16px" type="primary"
                      @click="callNumber">
            <van-icon name="phone-o"/>
            床位预约
          </van-button>
        </div>
        <div style="text-align: center;background-color: #008337;color: white;padding: 32px">
          技术支持：动优远程康复服务云平台
        </div>
      </div>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'

export default {
  name: 'AllianceIntroPage',
  components: { BaseTitleBarScrollPage },
  data () {
    return {}
  },
  mounted () {
    this.hideLoading()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    callNumber () {
      window.location.href = 'tel:18902270462'
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>
.alliance-intro-img img {
  width: 100%;
  display: block;
}

</style>
