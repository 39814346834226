<template>
  <div class="page-content" :class="{'non-nav-bar': this.$store.state.isApp}">
  </div>
</template>

<script>
export default {
  name: 'PageMarginTop'
}
</script>

<style lang="less" scoped>
.page-content {
  margin-top: 48px;
  padding: 0;

  &.non-nav-bar {
    margin-top: 0px;
  }

}
</style>
