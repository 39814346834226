import axios from 'axios'

const GymnasticsDao = {

  async apply (userId, sick) {
    const url = '/gymnasticsApply/web'
    const { data: resp } = await axios.post(url, {
      userId: userId,
      sick: sick
    })
    const {
      code
    } = resp
    if (code !== 200) {
      return false
    }
    return true
  },
  async loadList (userId) {
    const url = '/inHospitalTraining'
    const { data: resp } = await axios.get(url, {
      params: {
        p_id: userId
      }
    })
    const {
      code,
      data
    } = resp
    if (code !== 200) {
      return null
    }
    return data
  },
  async detail (courseId) {
    const url = '/programme/find/course_detail'
    const { data: resp } = await axios.get(url, {
      params: {
        courseId: courseId
      }
    })
    console.log(`url:${url}`, resp)
    const {
      code,
      data
    } = resp
    if (code !== 200) {
      return null
    }
    return data
  }
}
export default GymnasticsDao
