import axios from 'axios'

const DescpDao = {
  async findByTaxon (taxon) {
    const url = '/descp?taxon=' + taxon
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }
}
export default DescpDao
