<template>
  <div>
    <base-title-bar-scroll-page ref="container" title="录入员登录">
      <div style="background-color: white;font-size: 12px;padding: 8px">您当前所在组织机构：{{ hospitalName }}-机构编号-{{
          hospitalId
        }}
      </div>
      <div style="padding: 16px">
        <div class="card">
          <div style="height: 20px"></div>
          <div v-if="showRegister">正在为您注册录入员，请填写姓名并提交</div>
          <van-form @submit="onSubmit">
            <van-field
              v-model="phone"
              label="手机号"
              name="phone"
              placeholder="请输入手机号进行登录"
              required
              type="tel"
            />
            <div v-if="!showRegister" style="font-size: 12px;color: #3d3d3d;margin-left: 8px">
              未注册的手机号会自动进行注册
            </div>
            <van-field v-if="showRegister"
                       v-model="name"
                       label="姓名"
                       name="name"
                       placeholder="请输入姓名进行录入员注册"
                       required
                       type="text"
            />
            <div style="height: 50px"></div>
            <van-button block native-type="submit" type="primary">提交</van-button>
          </van-form>
          <div style="height: 10px"></div>
        </div>
      </div>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import PatientDataEntryClerkDao from '@/h5/doucan/network/PatientDataEntryClerkDao'

export default {
  name: 'ClerkLogin',
  components: { BaseTitleBarScrollPage },
  data () {
    return {
      phone: '',
      name: '',
      showRegister: false,
      hospitalName: '',
      hospitalId: ''
    }
  },
  mounted () {
    this.hideLoading()
    this.hospitalName = this.$route.query.hospitalName
    this.hospitalId = this.$route.query.hospitalId
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    onLogin (userId, name) {
      console.log('onLogin', userId)
      this.$store.commit('setPatientDataEntryClerk', {
        userId: userId,
        name: name,
        hospitalId: this.hospitalId
      })
      this.$router.push({ path: '/ClerkPage' })
    },
    onSubmit (values) {
      if (!this.showRegister) {
        console.log('Submit:', values)
        PatientDataEntryClerkDao.login(values.phone).then(res => {
          if (res) {
            console.log('用户存在', res)
            this.onLogin(res.userId, res.name)
          } else {
            console.log('用户不存在', res)
            this.showRegister = true
          }
          // this.$router.push({ path: '/sys_clerk/home' })
        })
      } else {
        // 注册
        PatientDataEntryClerkDao.register({
          name: this.name,
          mobile: this.phone,
          hospitalId: this.hospitalId
        }).then(res => {
          console.log('注册成功', res)
          this.onLogin(res.userId, res.name)
          // this.$router.push({ path: '/sys_clerk/home' })
        })
      }
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>
.card {
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  padding: 0.5rem;
}

</style>
