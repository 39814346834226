import axios from 'axios'
import store from '@/h5/doucan/store'

const AdminHospitalDao = {
  token () {
    return store.state.loginAdmin.token
  },
  // 读取全部医院账号
  async hospitalList () {
    const url = '/api/hospital/user/findAllHospital'
    const { data: resp } = await axios.get(url, {
      headers: {
        token: this.token()
      }
    })
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async addRemoteGuideBooking (req) {
    const url = '/admin/hospital/remoteGuideBooking/add'
    const { data: resp } = await axios.post(url, req)
    return resp
  },
  async doctorList () {
    console.log('doctorList-token', this.token())
    const url = '/admin/hospital/doctor/list'
    const { data: resp } = await axios.get(url, {
      headers: {
        token: this.token()
      }
    })
    const {
      code,
      data
    } = resp
    console.log('doctorList', resp)
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async patientList () {
    const url = '/admin/hospital/patient/list'
    const { data: resp } = await axios.get(url, {
      headers: {
        token: this.token()
      }
    })
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async patientListPage (page) {
    const url = '/admin/hospital/patient/list/page'
    const { data: resp } = await axios.get(url, {
      params: {
        page: page
      },
      headers: {
        token: this.token()
      }
    })
    const {
      code,
      data
    } = resp
    console.log('patientListPage', resp)
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async planList () {
    const url = '/admin/hospital/plan/list'
    const { data: resp } = await axios.get(url, {
      headers: {
        token: this.token()
      }
    })
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async bookingList () {
    const url = '/admin/hospital/remoteGuideBooking/list'
    const { data: resp } = await axios.get(url, {
      headers: {
        token: this.token()
      }
    })
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async remoteGuideList () {
    const url = '/admin/hospital/remoteGuideRecord/list'
    const { data: resp } = await axios.get(url, {
      headers: {
        token: this.token()
      }
    })
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  // 创建群聊MDT:post /admin/hospital/login
  async login (userid, passwd) {
    const url = '/admin/hospital/login'
    const { data: resp } = await axios.post(url, {
      userid: userid,
      passwd: passwd
    })
    // console.log(url, resp)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async loginCheck (token) {
    console.log('loginCheck', token)
    const url = '/admin/hospital/checkLogin'
    const { data: resp } = await axios.post(url, {
      token: token
    })
    console.log(resp)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }
}
export default AdminHospitalDao
