import axios from 'axios'

const RegisterDao = {

  async loginSms (phone) {
    const url = '/login/patient/sms_code/v2/web'
    const { data: resp } = await axios.post(url, {
      phone: phone
    })
    console.log('验证码登录', resp)
    const {
      code,
      data,
      message
    } = resp
    if (code !== 200 || !data) {
      return {
        code: 2,
        msg: message
      }
    }
    return {
      code: 1,
      data: data
    }
  },
  /**
   * @param phone
   * @returns {code: '1465', phone: '13610164743'}
   */
  async getRegisterHospital () {
    const url = '/regist/hospitals'
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async getRegisterDoctor (hospitalId) {
    const url = '/regist/doctors'
    const { data: resp } = await axios.get(url, {
      params: {
        h_id: hospitalId
      }
    })
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async getSickKind () {
    const url = '/regist/sickkinds'
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async postRegister (req) {
    const url = '/register/patient/web'
    const { data: resp } = await axios.post(url, req)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }
}
export default RegisterDao
