import { render, staticRenderFns } from "./AdminHospital.vue?vue&type=template&id=d6bd1060&scoped=true"
import script from "./AdminHospital.vue?vue&type=script&lang=js"
export * from "./AdminHospital.vue?vue&type=script&lang=js"
import style0 from "./AdminHospital.vue?vue&type=style&index=0&id=d6bd1060&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6bd1060",
  null
  
)

export default component.exports