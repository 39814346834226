<template>
  <div>
    <base-title-bar-scroll-page ref="container" :show-title-bar="!isApp" title="视频播放">
      <div v-if="loading"></div>
      <div v-else>
        <video :poster="courseDetail.coverUrl" :src="courseDetail.videoUrl" autoplay controls
               style="width: 100%;max-height: 93vh"></video>
        <div style="padding-left: 16px;padding-right: 16px">
          <div style="font-size: 18px;font-weight: bold"> {{ courseDetail.title }}</div>
          <div style="font-size: 12px;color: #656565">课程编号：{{ courseDetail.courseNum }}</div>
          <van-divider/>
          <div style="font-size: 14px;font-weight: bold">摘要：</div>
          <!--          <div style="white-space: pre-wrap;font-size: 16px">{{ courseDetail.text }}</div>-->
          <span style="text-align: justify;font-size: 16px" v-html="getCourseText(courseDetail.brief)"></span>
          <van-divider/>
          <div style="font-size: 14px;font-weight: bold">动作指导：</div>
          <!--          <div style="white-space: pre-wrap;font-size: 16px">{{ courseDetail.text }}</div>-->
          <span style="text-align: justify;font-size: 16px" v-html="getCourseText(courseDetail.text)"></span>
          <van-divider/>
          <div style="font-size: 14px;font-weight: bold">训练说明：</div>
          <!--          <div style="white-space: pre-wrap;font-size: 16px">{{ courseDetail.text }}</div>-->
          <span style="text-align: justify;font-size: 16px" v-html="getCourseText(courseDetail.trainingMethods)"></span>
          <van-divider/>
          <div style="font-size: 14px;font-weight: bold">注意事项：</div>
          <!--          <div style="white-space: pre-wrap;font-size: 16px">{{ courseDetail.text }}</div>-->
          <span style="text-align: justify;font-size: 16px" v-html="getCourseText(courseDetail.attention)"></span>
        </div>
      </div>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import PlanDao from '@/h5/doucan/network/PlanDao'

/**
 * test ：
 * http://192.168.0.119:8094/doucan/#/CourseDetailVideoPlayPage?courseId=e5ec334d-1023-4b52-9a0d-21432fc975cb&isApp=true&userId=1
 */
export default {
  name: 'CourseDetailVideoPlayPage',
  components: { BaseTitleBarScrollPage },
  data () {
    return {
      loading: true,
      isApp: false,
      userId: null,
      courseId: null,
      courseDetail: null
    }
  },
  mounted () {
    this.courseId = this.$route.query.courseId
    this.userId = this.$route.query.userId
    this.isApp = this.$route.query.isApp
    console.log('userId', this.userId)
    console.log('isApp', this.isApp)

    this.loadCourseDetail()
    this.hideLoading()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    loadCourseDetail () {
      PlanDao.loadCourseForApp(this.courseId).then(res => {
        this.courseDetail = res
        console.log('courseDetail', this.courseDetail)
        this.loading = false
      })
    },
    getCourseText (text) {
      if (text === '') {
        return '无'
      }
      // 将文本按 '/n' 分割成段落数组
      const paragraphs = text.split('/n')
      // 检查最后一个是否有内容
      if (paragraphs[paragraphs.length - 1].trim() === '') {
        paragraphs.pop()
      }

      // 遍历每一段，加上序号
      const numberedParagraphs = paragraphs.map((paragraph, index) => {
        // return `${index + 1}、 ${paragraph}`
        return `· ${paragraph}`
      })

      // 将数组转换为字符串，并用 <br> 连接每一段
      return '<br>' + numberedParagraphs.join('<br>')
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
