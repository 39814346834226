<template>
  <div style="padding: 16px">
    <div style="font-weight: bold">服务内容：</div>
    <div>1. 由“医生+康复治疗师+康复助理”团队联合提供3个月远程康复服务</div>
    <div>2. 多功能电疗综合治疗仪免费租赁</div>
    <div>------</div>
    <div style="font-weight: bold">治疗仪适用于：</div>
    <div>疼痛缓解、肌肉痉挛缓解、筋膜粘连改善、肌肉锻炼</div>
    <div style="font-weight: bold">适用对象：</div>
    <div>骨科术后、运动损伤、慢性疼痛、中风康复</div>
    <div style="font-weight: bold">费用：</div>
    <div style="text-align: right">术后远程康复季度服务包:  2400元</div>
    <div style="text-align: right">押金:  1200元 </div>
    <div style="text-align: right">合计:  3600元 </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceTypeGukeShuhou3month',
  data () {
    return {
      fee: 3600
    }
  },
  created () {
    this.$emit('update-fee', this.fee)
  }
}
</script>

<style scoped>

</style>
