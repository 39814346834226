<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar title="选择医生可预约时间"/>
    <div v-if="loading" style="text-align: center">
      <!--居中显示 loading-->
      <van-loading color="#1989fa" size="24px" style="margin-top: 64px" type="spinner"/>
    </div>
    <div v-else>
      <!--   如果bookingTimeList为空，显示 该医生未开放可预约时间，请联系康复助理   -->
      <div v-if="bookingTimeList.length === 0" style="text-align: center">
        <img src="https://cdn.keihong.tech/img/202308240951899.png" style="width: 50%;"/>
        <div style="margin-top: 16px; color: #999999; font-size: 18px">该医生未开放可预约时间</div>
        <div style="margin-top: 16px; color: #00b74f; font-size: 18px">请联系康复助理</div>
      </div>
      <div v-else>
        <!--    展示医生的预约时间表    -->
        <!--        <div style="font-size: 18px; color: #333333; margin-bottom: 16px;text-align: center">选择预约时间</div>-->
        <!--   一个有圆角的白底边框，内含一段文字：您还未选择时间，请点击下方进行选择。若已经选择了，则显示：您当前选择了：xxx     -->
        <div style="margin: 16px; border-radius: 8px; border: 1px solid #999999; padding: 16px; box-sizing: border-box">
          <div v-if="!selectedBookingTime" style="font-size: 16px; color: #999999; text-align: center">
            您还未选择时间，请点击下方进行选择
          </div>
          <div v-else style="font-size: 16px; color: #00b428; text-align: center;font-weight: bold">
            您当前选择了：{{ selectedBookingTime.bookingDayStr }} - {{ selectedBookingTime.bookingTimeStr }}
          </div>
        </div>
        <!--    提示文字：灰白色的为已经被预约的时间段，不可选择    -->
        <!--        <div style="font-size: 10px; color: #999999; margin-bottom: 16px;text-align: center">-->
        <!--          (灰白色的为已经被预约的时间段，不可选择)-->
        <!--        </div>-->
        <!--  预约类型：单次、月度、季度 三个单选项，一行显示，均分宽度     -->
        <van-radio-group v-model="timesType"
                         style="margin-top: 16px; margin-left: 16px; margin-right: 16px;display: flex;flex-wrap: wrap;"
                         @change="onChange">
          <div style="text-align: center;font-size: 10px;margin-right: 16px">
            <van-radio name="1">单次
            </van-radio>
          </div>
          <div style="text-align: center;font-size: 10px;margin-right: 16px">
            <van-radio name="4">月度(4次)
            </van-radio>
          </div>
          <div style="text-align: center;font-size: 10px;margin-right: 16px">
            <van-radio name="12">季度(12次)
            </van-radio>
          </div>
          <div style="text-align: center;font-size: 10px;margin-right: 16px">
            <van-radio name="-1">其他
            </van-radio>
          </div>
        </van-radio-group>
        <!--当timesType===4时显示，数字输入框-->
        <div v-if="timesType === '-1'" style="margin-top: 16px; margin-left: 16px; margin-right: 16px;">
          <van-field v-model="times" label="请输入次数" placeholder="请输入服务预约次数" type="number"/>
        </div>
        <!--    一行显示，均分宽度，每个预约时间的下方显示一个患者名称    -->
        <div
          style="margin-top: 16px; margin-left: 16px; margin-right: 16px;display: flex;flex-wrap: wrap;margin-bottom: 56px">
          <div v-for="(item, index) in bookingTimeList" :key="index"
               :style="{flex: '0 0 50%',textAlign:'center',marginBottom:'16px'}">
            <!--            <div style="font-size: 16px; color: #333333">{{ item.date }}</div>-->
            <van-button :disabled="item.patientId!==''"
                        :style="{backgroundColor:item === selectedBookingTime?'#ff8c31':'green'}" type="primary"
                        @click="handleBookingTimeClick(item)">
              {{ item.bookingDayStr }} - {{ item.bookingTimeStr }}
            </van-button>
            <!--        若item.patientId不为空，显示该id对应的患者名称    -->
            <div v-if="item.patientId !== ''" style="font-size: 10px; color: #999999">{{ '已经有人预约啦' }}</div>
          </div>
        </div>
        <!--        底部固定，button 下一步-->
        <div style="position: fixed; bottom: 0; width: 100%; padding: 16px;box-sizing: border-box">
          <van-button block round type="primary" @click="handleNextClick">生成预约单</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'

export default {
  name: 'DoctorBookingTimeSelectPage',
  components: { MyNavBar },
  data () {
    return {
      times: 0,
      timesType: 1,
      loading: true,
      doctorId: '',
      patientId: '',
      bookingTimeList: [],
      selectedBookingTime: null
    }
  },
  mounted () {
    // 打印传递过来的参数
    console.log('DoctorBookingTimeSelectPage', this.$route.query)
    // 把传递过来的参数取出
    const {
      doctorId,
      patientId
    } = this.$route.query
    this.doctorId = doctorId
    this.patientId = patientId

    this.getDoctorBookingTime(1)
  },
  methods: {
    // 预约类型改变
    onChange (name) {
      console.log('onChange', Number(name))

      if (name !== '-1') {
        // value转换成数字
        this.times = Number(name)
      } else {
        this.times = 0
      }
    },
    // 点击下一步
    handleNextClick () {
      // 判断次数是否为0
      if (this.times === 0) {
        this.$toast('请选择预约次数')
        return
      }
      console.log('handleNextClick-日期', this.selectedBookingTime)
      console.log('handleNextClick-次数', this.times)
      if (!this.selectedBookingTime) {
        this.$toast('请选择预约时间')
      }
      // 请求生成预约单 get方法  /booking/bookingByAdmin
      this.$http.get('/booking/bookingByAdmin', {
        params: {
          p_id: this.patientId,
          d_id: this.doctorId,
          date: this.selectedBookingTime.bookingDayStr,
          time: this.selectedBookingTime.bookingTimeStr,
          times: this.times
        }
      }).then(res => {
        console.log('handleNextClick', res)
        // 弹出对话框，提示预约单生成成功，是否要继续生成mdt，若是则跳转到mdtcreate页面，否则跳转到首页
        this.$dialog({
          title: '提示',
          message: '预约单生成成功，是否要继续前往建立MDT群？',
          showCancelButton: true,
          confirmButtonText: '前往建立MDT',
          cancelButtonText: '返回首页'
        }).then(() => {
          // on confirm
          this.$router.push({
            path: '/mdtcreate',
            query: {
              doctorId: this.$route.query.doctorId,
              doctorName: this.$route.query.doctorName,
              patientId: this.$route.query.patientId,
              patientName: this.$route.query.patientName
            }
          })
        }).catch(() => {
          // on cancel
          this.$router.replace({
            path: '/AdminHomePage'
          })
        })
      })
    },
    // 点击预约时间
    handleBookingTimeClick (item) {
      console.log('handleBookingTimeClick', item)
      this.selectedBookingTime = item
      // this.$router.push({
      //   path: '/admin/doctorBookingTimeSelectPage',
      //   query: {
      //     doctorId: this.doctorId,
      //     patientId: this.patientId,
      //     bookingTimeId: item.id
      //   }
      // })
    },
    // 读取医生可预约时间
    getDoctorBookingTime (type) {
      // 读取医生预约列表 - 最近7天 . type:预约类型（1-本周，2-下周）
      this.$http.get('/booking/doctor/days/7', {
        params: {
          doctor_id: this.doctorId,
          type: type
        }
      }).then(res => {
        console.log('getDoctorBookingTime', res)
        this.bookingTimeList.push(...res.data.data)
        if (type === 2) {
          console.log('bookingTimeList', this.bookingTimeList)
          this.loading = false
          return
        }
        this.getDoctorBookingTime(2)
      })
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
