<template>
  <!--  暨大顺医 中风康复 报名申请页-->
  <div style="margin-bottom: 48px">
    <div class="main">
      <!-- <img
        class="img_item"
        @click="toOtherService"
        src="https://cdn.keihong.tech/typora/2022-11-04-%E5%85%B6%E4%BB%96%E6%9C%8D%E5%8A%A1-%E5%85%AC%E7%9B%8A%E6%9C%8D%E5%8A%A1.png"
      /> -->
      <div>跳转中</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home1',
  data() {
    return {
      name: '',
      iosVer: ''
    }
  },
  mounted() {
    window.location.href =
      'https://doucan.jianerkanggz.cn/#/home/square?hospital=%E6%9A%A8%E5%8D%97%E5%A4%A7%E5%AD%A6%E9%99%84%E5%B1%9E%E9%A1%BA%E5%BE%B7%E5%8C%BB%E9%99%A2'
  },
  methods: {}
}
</script>

<style scoped>
.img_item {
  display: block;
  width: 100%;
}

.main {
  padding-top: 48px;
  padding-bottom: 48px;
}
</style>
