import { render, staticRenderFns } from "./WebOrderListPage.vue?vue&type=template&id=d2286afc&scoped=true"
import script from "./WebOrderListPage.vue?vue&type=script&lang=js"
export * from "./WebOrderListPage.vue?vue&type=script&lang=js"
import style0 from "./WebOrderListPage.vue?vue&type=style&index=0&id=d2286afc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2286afc",
  null
  
)

export default component.exports