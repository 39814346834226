<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar :back-text="isArticle?'康复圈':'返回'" back style="position:fixed;top: 0;width: 100%"
              title="辅具租赁详情" @onBackClick="onBackClick"/>
    <div style="margin-top: 46px;">
      <div v-if="error" style="width: 100%;text-align: center;margin-top: 32px">
        商品编号有误，找不到该商品(id:{{ goodId }})
      </div>
      <div v-else>
        <!--  loading  vant  -->
        <van-loading v-if="loading" color="#1989fa" size="24px" style="text-align: center;margin-top: 74px"/>
        <div v-else>
          <div style="width: 100%;">
            <div style=";padding-bottom: 16px; background-color: #88a98a">
              <!--    vant image 宽度占满，高度自适应          -->
              <van-image :src="goodDetail.bigPicUrl" style="display: block" width="100%"/>

              <div style="height: 12px"/>
              <!--      产品标题和简介模块          -->
              <div class="product-info">
                <!--       价格 模块，现价 + 原价（删除线）      -->
                <div style="display: flex;align-items:center;padding: 8px 16px;width: 100%">
                  <div
                    style="font-size: 24px;color: #c20000;font-weight: bold;">
                    租赁费用： ￥{{ goodDetail.rentPrice }} 元 / 天
                  </div>
                  <div style="margin-left: 16px;font-size: 18px;color: #8f8f8f;text-decoration: line-through">￥{{
                      goodDetail.price + 50
                    }}
                  </div>
                </div>
                <div class="product-title">
                  {{ goodDetail.name }}
                </div>
                <div class="product-intro">
                  {{ goodDetail.brief }}
                </div>
              </div>
            </div>
            <!--     图片列表       -->
            <div style="margin-bottom: 56px">
              <div v-for="(item, index) in goodDetail.detailPics" :key="index">
                <img :src="item" style="  display: block;width: 100%;"/>
              </div>
            </div>
            <van-goods-action>
              <van-goods-action-icon icon="chat-o" text="客服" @click="onClickKefu"/>
              <!--              <van-goods-action-icon icon="cart-o" text="购物车" @click="onClickIcon"/>-->
              <van-goods-action-icon v-if="showMoreButton" icon="shop-o" text="更多辅具" @click="onClickHome"/>
              <van-goods-action-button
                text="立即租赁"
                type="danger"
                @click="onClickBuy"
              />
            </van-goods-action>
          </div>
        </div>
      </div>

    </div>
    <van-popup v-model="showKefu" position="bottom">
      <img
        alt="图片"
        src="https://cdn.keihong.tech/img/202309072319723.png"
        width="100%"
      />
    </van-popup>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import MallDao from '@/h5/doucan/network/MallDao'

export default {
  name: 'MallRentDetailPage',
  components: { MyNavBar },
  data () {
    return {
      loading: true,
      isArticle: false,
      isMallList: false,
      isKepulist: false,
      goodId: '',
      error: false,
      goodDetail: null,
      showKefu: false,
      showMoreButton: true
    }
  },
  mounted () {
    if (this.$store.state.user.qrCodeHospital === '中国民间中医医药研究开发协会中蒙民族医药研究发展分会') {
      this.showMoreButton = false
    }
    if (this.$route.query.article) {
      this.isArticle = true
    }

    this.goodId = this.$route.query.goodId
    if (!this.goodId) {
      this.error = true
      return
    }

    if (this.$route.query.from === 'malllist') {
      this.isMallList = true
    }
    if (this.$route.query.from === 'kepulist') {
      this.isKepulist = true
    }
    this.getGoodDetail()
  },
  methods: {
    onClickBuy () {
      //     this.$router.push('/MallOrderConfirmPage')
      this.$router.push({
        path: '/MallRentOrderConfirmPage',
        query: {
          good: this.goodDetail
        }
      })
    },
    onBackClick () {
      // 返回上一页
      const isBack = this.isMallList || this.isKepulist
      if (isBack) {
        this.$router.go(-1)
        return
      }
      if (this.isArticle) {
        // this.$router.replace('/home/square')
        this.$router.push('/ToolKepuHomePage')
        return
      }
      this.$router.push('/ToolKepuHomePage')
    },
    onClickKefu () {
      this.showKefu = true
    },
    onClickHome () {
      // 跳转到MallMainPage
      this.$router.replace('/MallMainPage')
    },
    async getGoodDetail () {
      this.goodDetail = await MallDao.findById(this.goodId)
      console.log('good detail', this.goodDetail)
      this.loading = false
      if (!this.goodDetail) {
        this.error = true
      }
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>
.product-info {
  margin: 8px;
  background-color: #fffbf3;
  display: flex;
  box-shadow: 1px 1px 2px rgba(63, 63, 63, 0.6);
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px 16px;
}

.product-title {
  font-size: 20px;
  width: 100%;
  text-align: left;
  font-weight: bold;
  margin-bottom: 2px;
}

.product-intro {
  font-size: 16px;
  width: 100%;
  color: #505050;
  text-align: left;
}
</style>
