import axios from 'axios'

const HospitalDao = {
  async downloadPageInfo (inviteCode) {
    const url = '/hospitalManager/downloadPage'
    const { data: resp } = await axios.get(url, {
      params: {
        hospital: inviteCode
      }
    })
    const {
      code,
      data
    } = resp
    if (code !== 200) {
      return null
    }
    return data
  },
  async getDoctorsForSelect (hospitalId) {
    const url = '/doctor/doctorsForSelect?hospitalId=' + hospitalId
    const { data: resp } = await axios.get(url)
    console.log(resp)
    const {
      code,
      data
    } = resp
    if (code !== 200) {
      return null
    }
    return data
  }
}
export default HospitalDao
