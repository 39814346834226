<template>
  <div>
    <div>
      <my-nav-bar :title="'家庭病床康复'" back @onBackClick="onBackClick"/>
      <!--      <nav-bar title="家庭病床康复"/>-->
      <div style="background-color: #fffbf3">
        <my-img :url="'https://cdn.keihong.tech/img/202306241124583.png'"/>
      </div>
      <my-img
        :url="'https://cdn.keihong.tech/typora/2023-03-27-sdyyjtbc-2.png'"
      />
      <my-img
        :url="'https://cdn.keihong.tech/typora/2023-03-27-sdyyjtbc-3.png'"
      />
      <my-img
        :url="'https://cdn.keihong.tech/typora/2023-03-27-sdyyjtbc-4.png'"
      />
      <VideoPlayer
        poster-url="https://cdn.keihong.tech/img/202306281922239.png"
        videoUrl="https://cdn.keihong.tech/doucan/video/202306271641934.mp4"
      />
      <my-img
        :url="'https://cdn.keihong.tech/typora/2023-03-27-sdyyjtbc-5.png'"
      />
    </div>
    <div style="height: 56px"></div>
    <div
      style="
        text-align: center;
        font-size: 28px;
        background-color: #ffe45b;
        height: 56px;
        line-height: 56px;
        position: fixed;
        bottom: 0;
        width: 100%;
      "
    >
      活动已结束
    </div>
  </div>
</template>

<script>
import MyNavBar from '../../../../components/MyNavBar'
import MyImg from '../../../../components/MyImg'
import VideoPlayer from '../../../../../../common-components/VideoPlayer/VideoPlayer.vue'

export default {
  name: 'SalePageDongguangkanghua01',
  components: {
    MyImg,
    MyNavBar,
    VideoPlayer
  },
  data () {
    return {
      showPoster: true
    }
  },
  created () {
  },
  methods: {
    onBackClick () {
      console.log('返回康复科普')
      this.$router.replace('/home/square?hospital=东莞康华医院')
    },
    next () {
      this.$router.push('/salepage/20230001/step2?hospital=东莞康华医院')
    }
  }
}
</script>

<style scoped>
</style>
