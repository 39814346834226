<template>
  <div>
    <van-empty :description="emptyDesc"/>
  </div>
</template>

<script>
export default {
  name: 'MyEmptyView',
  props: {
    emptyDesc: {
      type: String,
      default: '暂无数据'
    }
  }
}
</script>

<style scoped>

</style>
