<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/SelectDoctor.vue -->
<template>
  <div>
    <my-nav-bar title="第二步：选择医生"/>
    <div class="page">
      <div class="search">
        <van-search
            v-model="searchValue"
            placeholder="请输入医生姓名或手机号"
            @search="onSearch"
        />
      </div>
      <div class="list">
        <van-list :finished="finished" finished-text="没有更多了">
          <van-cell
              v-for="item in list"
              :key="item.id"
              :label="item.phone"
              :title="item.realname"
              :value="item.id"
              @click="onItemClick(item)"
          />
        </van-list>
      </div>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import NumberUtil from '@/utils/NumberUtil'
import AdminDao from '@/h5/doucan/network/AdminDao'

export default {
  name: 'SelectDoctorPage',
  components: { MyNavBar },
  data () {
    return {
      searchValue: '',
      list: [],
      finished: true
    }
  },
  mounted () {

  },
  methods: {
    onSearch (value) {
      console.log('onSearch', value)
      // 判断是否是纯数字
      const isPhone = NumberUtil.isNumeric(value)

      // 判断是否是手机号
      if (isPhone) {
        this.searchByPhone(value)
        // 显示toast： 手机号查询
        this.$toast('查询手机号：' + value)
      } else {
        this.searchByName(value)
        // 显示toast： 姓名查询
        this.$toast('查询姓名：' + value)
      }
      this.searchValue = value
      this.list = []
    },
    onItemClick (item) {
      console.log('onItemClick', item)
      // 跳转到医生预约时间选择页面
      this.$router.push({
        path: '/doctorbookingtimeselect',
        query: {
          doctorId: item.userId,
          doctorName: item.realname,
          patientId: this.$route.query.patientId,
          patientName: this.$route.query.patientName
        }
      })
    },
    loadMore () {
      this.finished = true
    },
    searchByPhone (value) {
      AdminDao.doctorByPhone(value).then(res => {
        console.log('doctorByPhone', res)
        if (!res) {
          this.$toast('没有查询到医生')
          return
        }
        this.list = res.content
      })
    },
    searchByName (value) {
      AdminDao.doctorByName(value).then(res => {
        console.log('doctorByName', res)
        if (!res) {
          this.$toast('没有查询到医生')
          return
        }
        this.list = res.content
      })
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
