<template>
  <div style="background-color: #FFF5D2;width: 100vw;padding-bottom: 32px">
    <div style="position: relative">
      <van-swipe autoplay="3000" loop style="width: 100vw">
        <van-swipe-item v-for="(image, index) in images" :key="index" style="width: 100vw">
          <my-img :url="image" style="width: 100vw"/>
        </van-swipe-item>
      </van-swipe>
      <my-img style="width: 66%;position: absolute;top: 2px;left: 2px"
              url="https://cdn.keihong.tech/img/202408232318097.png"/>
      <div style="padding: 0 16px;position:absolute; bottom: -48px;left: 0">
        <my-img style="width: 100%;"
                url="https://cdn.keihong.tech/img/202411151731131.png"/>
      </div>
    </div>
    <div style="height: 56px"></div>
    <div style="padding: 16px">
      <ServiceImageButtonGroup
        :leftImage="'https://cdn.keihong.tech/img/202411151758264.png'"
        :rightItems="serviceItems1"
      />
      <div style="height: 16px"/>
      <ServiceImageButtonGroup
        :left-link="'#/MallMainPageNeimeng?hospital=宏乾健康&type=护理康复服务'"
        :leftImage="'https://cdn.keihong.tech/img/202411151818876.png'"
        :rightItems="serviceItems2"
      />
      <div style="height: 16px"/>
      <ServiceImageButtonGroup
        :leftImage="'https://cdn.keihong.tech/img/202411151821222.png'"
        :rightItems="serviceItems3"
      />
    </div>

    <div style="margin-top: 64px;font-size: 12px;color: #505050;text-align: center;margin-bottom: 16px">
      技术支持：动优科技
    </div>

  </div>
</template>
<script>
import MyImg from '@/h5/doucan/components/MyImg.vue'
import ServiceImageButtonGroup from '@/h5/doucan/components/ServiceImageButtonGroup.vue'

export default {
  name: 'HongQianHomePage',
  components: {
    ServiceImageButtonGroup,
    MyImg
  },
  data () {
    return {
      images: [
        'https://cdn.keihong.tech/img/202411161337244.jpg',
        'https://cdn.keihong.tech/img/202411161337784.jpg',
        'https://cdn.keihong.tech/img/202411161337195.jpg'
      ],
      serviceItems1: [
        {
          image: 'https://cdn.keihong.tech/img/202411151803944.png',
          title: '',
          link: '#/DiancanJielongHomePage'
        },
        {
          image: 'https://cdn.keihong.tech/img/202411151759609.png',
          title: '',
          link: '#/PingTuanHomePage'
        },
        {
          image: 'https://cdn.keihong.tech/img/202411151803888.png',
          title: '',
          link: '#/MallMainPageNeimeng?hospital=宏乾健康&mode=动优辅具&type=生活超市'
        }
      ],
      serviceItems2: [
        {
          image: 'https://cdn.keihong.tech/img/202411151819825.png',
          title: '',
          link: '#/MallMainPageNeimeng?hospital=宏乾健康&type=护理康复服务&select=上门护理'
        },
        {
          image: 'https://cdn.keihong.tech/img/202411161239029.png',
          title: '',
          link: '#/MallMainPageNeimeng?hospital=宏乾健康&type=护理康复服务&select=上门康复'
        },
        {
          image: 'https://cdn.keihong.tech/img/202411161240018.png',
          title: '',
          link: '#/MallMainPageNeimeng?hospital=宏乾健康&type=护理康复服务&select=社区康复'
        }
      ],
      serviceItems3: [
        {
          image: 'https://cdn.keihong.tech/img/202411151820537.png',
          title: '',
          link: '#/LoginCheckPage?route=/mdt/home'
        },
        {
          image: 'https://cdn.keihong.tech/img/202411151820016.png',
          title: '',
          link: '#/LoginCheckPage?route=/planListPage'
        },
        {
          image: 'https://cdn.keihong.tech/img/202411151821047.png',
          title: '',
          link: '#/LoginCheckPage?route=/PatientDataHomePage'
        }
      ]
    }
  },
  mounted () {
    this.hideLoading()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
