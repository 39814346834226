<template>
  <!-- 暨南大学附属顺德医院 -->
  <div>
    <div>
      <my-nav-bar :title="'家庭病床康复'" back @onBackClick="onBackClick"/>
      <!--      <nav-bar title="家庭病床康复"/>-->
      <div style="background-color: #fffbf3">
        <my-img
          :url="'https://cdn.keihong.tech/typora/2023-03-27-sdyyjtbc-1.png'"
        />
      </div>
      <my-img
        :url="'https://cdn.keihong.tech/typora/2023-03-27-sdyyjtbc-2.png'"
      />
      <my-img
        :url="'https://cdn.keihong.tech/typora/2023-03-27-sdyyjtbc-3.png'"
      />
      <my-img
        :url="'https://cdn.keihong.tech/typora/2023-03-27-sdyyjtbc-4.png'"
      />
      <VideoPlayer
        poster-url="https://cdn.keihong.tech/img/202306281922239.png"
        videoUrl="https://cdn.keihong.tech/doucan/video/202306271641934.mp4"
      />
      <VideoPlayer
        poster-url="https://cdn.keihong.tech/img/202306271802548.png"
        videoUrl="https://cdn.keihong.tech/doucan/video/%E8%BF%9C%E7%A8%8B%E5%B1%85%E5%AE%B6%E5%BA%B7%E5%A4%8D%E6%9C%8D%E5%8A%A1%E7%A4%BA%E4%BE%8B%281%29.mp4"
      />
      <my-img
        :url="'https://cdn.keihong.tech/typora/2023-03-27-sdyyjtbc-5.png'"
      />
    </div>
    <div style="height: 56px"></div>
    <div
      style="
        text-align: center;
        font-size: 20px;
        background-color: #ffe45b;
        height: 56px;
        line-height: 56px;
        position: fixed;
        bottom: 0;
        width: 100%;
      "
    >
      找康复助理了解远程康复服务
    </div>
    <!--    <div-->
    <!--      style="-->
    <!--        text-align: center;-->
    <!--        font-size: 28px;-->
    <!--        background-color: #ffe45b;-->
    <!--        height: 56px;-->
    <!--        line-height: 56px;-->
    <!--        position: fixed;-->
    <!--        bottom: 0;-->
    <!--        width: 100%;-->
    <!--      "-->
    <!--      @click="next"-->
    <!--    >-->
    <!--      立即点击预约-->
    <!--    </div>-->
  </div>
</template>

<script>
import MyNavBar from '../../../components/MyNavBar'
import MyImg from '../../../components/MyImg'
import VideoPlayer from '../../../../../common-components/VideoPlayer/VideoPlayer.vue'

export default {
  name: 'SalePage20230001',
  components: {
    MyImg,
    MyNavBar,
    VideoPlayer
  },
  data () {
    return {
      showPoster: false,
      showPoster2: false
    }
  },
  created () {
  },
  mounted () {

  },
  methods: {
    onBackClick () {
      console.log('返回康复科普')
      this.$router.replace('/home/square?hospital=暨南大学附属顺德医院')
    },
    next () {
      this.$router.push(
        '/salepage/20230001/step2?hospital=暨南大学附属顺德医院'
      )
    }
  }
}
</script>

<style scoped>
#EasyPlayer1,
#EasyPlayer2 {
  width: 100vw;
  height: 60vw;
  margin: 0 auto;
}

.cover-container {
  /* 样式设置封面容器的样式，如宽度、高度等 */
  /* 例如：width: 640px; height: 360px; */
  width: 100vw;
  height: 60vw;
  margin: 0 auto;
}

.player-container {
  /* 样式设置播放器容器的样式，如宽度、高度等 */
  /* 例如：width: 640px; height: 360px; */
  width: 100vw;
  height: 60vw;
  margin: 0 auto;
}
</style>
