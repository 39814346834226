<template>
  <div>
    <base-title-bar-scroll-page ref="container" title="患者录入数据">
      <van-cell-group v-for="patient in patientList" :key="patient.id">
        <van-cell :value="patient.patientName" is-link title="患者姓名" @click="handleDetailClick(patient)">
          <template #right-icon>
            <!--            <van-icon class="search-icon" name="search"/>-->
            <div style="color: #009f7e"> | 查看详情 ></div>
          </template>
        </van-cell>
        <van-cell :value="patient.doctorName" title="录入者"/>
        <van-cell :value="formatDate(patient.gmtCreate)" title="创建时间"/>
        <van-cell :value="patient.age" title="年龄"/>
        <van-cell :value="patient.sex === 1 ? '男' : '女'" title="性别"/>
        <van-cell :value="formatPhone(patient.phone)" title="电话"/>
        <van-cell :value="patient.serviceType" title="服务类型"/>
        <van-divider/>
      </van-cell-group>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import DoctorInputPatientDao from '@/h5/doucan/network/DoctorInputPatientDao'

export default {
  name: 'AdminHospitalPatientInputList',
  components: { BaseTitleBarScrollPage },
  data () {
    return {
      hospitalId: '',
      patientList: null,
      isClerk: false
    }
  },
  mounted () {
    try {
      this.hospitalId = this.$store.state.loginAdmin.adminData.hospitalId
    } catch (e) {
      this.hospitalId = this.$store.state.patientDataEntryClerk.hospitalId
    }

    this.isClerk = this.$route.query.clerk
    // console.log('this.hospitalId', this.hospitalId)
    if (this.hospitalId) {
      this.loadData()
    }
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    async loadData () {
      if (this.isClerk) {
        const resp = await DoctorInputPatientDao.loadByHospitalIdAndClerk(this.hospitalId, true, this.hospitalId = this.$store.state.patientDataEntryClerk.userId)
        console.log('resp', resp)
        this.hideLoading()
        if (resp) {
          this.patientList = resp
        }
      } else {
        const resp = await DoctorInputPatientDao.loadByHospitalId(this.hospitalId, true)
        console.log('resp', resp)
        this.hideLoading()
        if (resp) {
          this.patientList = resp
        }
      }
    },
    handleDetailClick (patient) {
      this.$router.push({
        path: '/NeimengPatientBaseInfoForm',
        query: {
          patient: patient
        }
      })
    },
    formatPhone (phone) {
      return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
    },
    formatDate (timestamp) {
      const date = new Date(timestamp)
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }
      return new Intl.DateTimeFormat('zh-CN', options).format(date)
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
