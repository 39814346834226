<template>
  <div class="bg" style="width: 100%;height: 100%;min-height: 100vh;">
    <MyNavBar v-show="!isApp" back style="position: fixed;top: 0;left: 0;right: 0" title="我的打卡"
              @onBackClick="onBack"/>
    <!--    <van-pull-refresh :v-model="isLoading">-->
    <!-- 顶部：本周打卡进度 -->
    <div v-show="!isApp" style="height: 44px"></div>
    <div>
      <div>
        <my-img url="https://cdn.keihong.tech/img/202408191154870.png"/>
      </div>
      <div style="width: 100%;padding: 16px;box-sizing: border-box;margin-top: -32px;z-index: 100;position:relative;">
        <div
          style="background-color: #12B96A;border-radius: 100px;text-align: center;font-size: 18px;font-weight: bold;color: white;padding: 8px">
          坚持每天训练打卡，早日康复！
        </div>
      </div>
      <div v-if="isLoading" style="text-align: center">
        <!--  loading  -->
        <van-loading color="#1989fa" size="48px" style="margin-top: 32px;" type="spinner"/>
        <div>加载数据中</div>
      </div>
      <div v-else>
        <div style="background-color: white;border-radius: 8px;margin: 0 16px">
          <div class="week-overview">
            <!--        <van-progress :percentage="completedDays / totalDays * 100" show-pivot/>-->
            <div style="text-align: center;padding: 8px;font-size: 18px;font-weight: bold;">本周已打卡：{{
                completedDays
              }}
              天 累计打卡：{{ totalDays }} 天
            </div>
          </div>

          <div v-if="todayFinish" style="text-align: center;font-weight: bold;font-size: 20px;color: #136b59">
            今天已打卡，棒棒哒！
          </div>
          <div v-else style="text-align: center;font-size: 20px;color: #FFA200">今天还没打卡哦，锻炼起来吧！</div>
        </div>
        <div style="padding: 0 16px;margin-top: 16px">
          <div
            style="box-sizing: border-box;width: 100%;text-align: center;display: flex;flex-direction: row;justify-content: center">
            <van-uploader v-model="videoSelect" :after-read="afterRead" accept="video/*"
                          style="width: 100%;padding: 0;margin: 0;text-align: center;display: flex;flex-direction: row;justify-content: center">
              <!--              <div-->
              <!--                style="text-align: center;padding: 16px;font-size: 18px;font-weight: bold;display: flex;flex-direction: row;justify-content: center;align-items: center;">-->
              <!--                <div>一键打卡</div>-->
              <!--                <div style="width: 16px"></div>-->
              <!--                &lt;!&ndash; 使用 upload-icon 插槽来自定义上传按钮 &ndash;&gt;-->
              <!--                <div style="height: 32px;width: 32px;background-color: #FF973E;padding: 4px;border-radius: 8px">-->
              <!--                  <img alt="Upload" src="https://cdn.keihong.tech/img/202404291958455.png"-->
              <!--                       style="width: 100%;height: 100%"/>-->
              <!--                  &lt;!&ndash; 替换为你的图片路径 &ndash;&gt;-->
              <!--                </div>-->
              <!--              </div>-->
              <my-img url="https://cdn.keihong.tech/img/202408191332146.png"/>
            </van-uploader>
          </div>
        </div>
        <div
          style="background-color: white;margin: 16px;border-radius: 8px;padding: 8px;box-shadow: 0 2px 4px 2px rgba(0,0,0,0.2);">

          <!-- 中间：每日打卡状态 -->
          <div class="container">
            <div v-for="(day) in weekDays" :key="day.id" style="width: 14.285%;">
              <div v-if="day.status === 'completed'" class="item-text">已打卡</div>
              <div v-if="day.status === 'missed'" class="item-text">缺卡</div>
              <div v-if="day.status === 'pending'" class="item-text">未打卡</div>
            </div>
            <div v-for="(day, index) in weekDays" :key="index" class="square" @click="handleDayClick(day)">
              <div :style="{ backgroundColor: getBackgroundColor(day.status) }" class="content">{{ day.date }}</div>
            </div>
          </div>

          <!-- 底部：今日学习内容 -->
          <div class="today-tasks" style="margin-top: 8px">
            <van-cell-group>
              <van-cell>
                <template #title>
                  <div><b>本周打卡作业：</b></div>
                  <div><b>《{{ todayTasks.title }}》</b></div>
                </template>
                <template #default>
                  <div v-for="(detail,index) in todayTasks.details" :key="detail">
                    {{ index + 1 }}. {{ detail }}
                  </div>
                </template>
              </van-cell>
            </van-cell-group>
          </div>

        </div>

        <div style="padding: 16px">
          <my-img url="https://cdn.keihong.tech/img/202408191102509.png"/>
        </div>
        <!--    打卡记录    -->
        <div style="background-color: white;margin: 0 16px;border: #d1d1d1 0.5px solid;border-radius: 8px 8px 0 0">
          <div style="text-align: center;font-weight: bold;font-size: 20px">打卡记录</div>
          <div style="text-align: center;font-size: 14px;color: #878787">点 击 播 放</div>
        </div>
        <div v-if="!dakaRecord">
          加载打卡记录中...
        </div>
        <div>
          <div v-for="(record) in dakaRecord" :key="record.id"
               style="margin:0 16px;background-color: white;padding: 8px;border: #d1d1d1 0.5px solid;text-align: center"
               @click="handleRecordClick(record)">
            {{ record.gmtCreate }}
          </div>
        </div>
        <div style="height: 48px"></div>
        <!--    </van-pull-refresh>-->
      </div>
    </div>
    <van-popup v-model="showVideoPopup" :style="{ height: '60%' }" position="bottom" @close="closePop">
      <!--      <van-video :src="videoUrl" :poster="posterUrl" :autoplay="true" :show-center-play="false" :close-on-click-overlay="true" />-->
      <video
        v-if="currentVideo"
        ref="video"
        :src="currentVideo"
        autoplay="autoplay"
        class="play-video"
        controls="controls"
      >
      </video>
      <!--   按钮   -->
      <div style="text-align: center;padding: 16px">
        <van-button style="width: 100%" type="primary" @click="closePop">关闭</van-button>
      </div>
    </van-popup>
    <van-popup v-model="showUploadLoading"
               :close-on-click-overlay="false"
               style="height: 30%;width: 100%;box-sizing:border-box;padding: 32px;background: transparent">
      <div
        style="position: relative;width: 100%;height: 100%;background: white;border-radius: 32px;text-align: center;padding: 32px;box-sizing: border-box">
        <div style="font-size: 18px">上传进度</div>
        <div style="font-size: 32px;font-weight: bold">{{ currentUploadPercent }}%</div>
        <div v-if="uploadFinish"
             style="font-size: 18px;background-color: #ff6200;color: white;padding: 16px;position: absolute;bottom: 0;left: 0;right: 0;border-bottom-left-radius: 32px;border-bottom-right-radius: 32px"
             @click="onUploadClose">
          完成并关闭
        </div>
      </div>
    </van-popup>
    <div v-if="isTest" style="position: fixed;top: 0;left: 0;right: 0;background-color: rgba(0,0,0,0.2)">
      <div>isApp: {{ isApp }}</div>
      <div>userId: {{ userId }}</div>
      <div>phone: {{ phone }}</div>
    </div>
  </div>
</template>
<script>

import MyNavBar from '@/h5/doucan/components/MyNavBar.vue'
import PlanDao from '@/h5/doucan/network/PlanDao'
import DakaDao from '@/h5/doucan/network/DakaDao'
import DateUtils from '@/utils/DateUtils'
import MyImg from '@/h5/doucan/components/MyImg.vue'

export default {
  name: 'PatientDataHomePage',
  components: {
    MyImg,
    MyNavBar
  },
  data () {
    return {
      // isTest: true,
      isApp: false,
      userId: null,
      phone: null,
      videoSelect: [],
      functionItems: [
        {
          url: 'https://cdn.keihong.tech/img/202312122245163.png',
          name: '信息录入'
        },
        {
          url: 'https://cdn.keihong.tech/img/202312122246398.png',
          name: '录入记录'
        }
      ],
      isLoading: true,
      completedDays: -1,
      totalDays: -1,
      weekDays: [],
      plan: null,
      plans: [],
      todayTasks: {
        title: '学习内容',
        details: ['上肢训练', '下肢训练', '头部训练']
      },
      dakaRecord: null,
      showVideoPopup: false,
      showUploadLoading: false,
      currentVideo: null,
      currentUploadPercent: 0,
      uploadFinish: false,
      todayFinish: false
    }
  },
  async mounted () {
    this.isApp = this.$route.query.isApp
    const from = this.$route.query.from
    if (from === 'wxapp') {
      this.isApp = true
      this.userId = this.$route.query.userId
      this.phone = this.$route.query.phone
    } else if (this.isApp) {
      this.userId = this.$route.query.userId
      this.phone = this.$route.query.phone
    } else {
      this.userId = this.$store.state.user.profile.userId
      this.phone = this.$store.state.user.profile.phone
    }

    console.log('user id', this.userId)
    if (this.userId) {
      await this.loadPlan()
      await this.loadDaka()
      this.isLoading = false
    }
  },
  methods: {
    onBack () {
      if (this.$store.state.user.qrCodeHospital === '宏乾健康') {
        this.$router.push('/hongqian/home')
      } else {
        this.$router.back()
      }
    },
    onUploadClose () {
      this.showUploadLoading = false
      // 刷新
      this.$router.go(0)
    },
    handleRecordClick (record) {
      this.showVideoPopup = true
      this.currentVideo = record.videoUrl
    },
    handleDayClick (day) {
      console.log('day', day)
      if (day.status === 'completed') {
        this.showVideoPopup = true
        this.currentVideo = day.newestVideoUrl
      } else {
        this.$toast('该日期未打卡')
      }
    },
    async closePop () {
      this.showVideoPopup = false
      this.$refs.video.pause()
    },
    async loadPlan () {
      const datas = await PlanDao.loadList(this.phone)

      if (datas && datas.length >= 1) {
        this.plans = datas
        this.plan = datas[0].plan
        this.plan = JSON.parse(this.plan)
        // this.gmtCreate = DateUtils.formate(this.gmtCreate, 'yyyy-MM-dd')
        console.log('方案', this.plan)
        this.todayTasks.title = this.plan.planTitle
        this.todayTasks.details = this.plan.planCourseBeans.map(course => course.courseName)
      } else {
        this.todayTasks.title = '您暂未有个性化训练方案，请联系医生或康复助理'
        this.todayTasks.details = ['无']
      }
    },
    async loadDaka () {
      this.dakaRecord = await DakaDao.load(this.userId)
      // 格式化dakaRecord中每个item的gmtCreate字段
      if (this.dakaRecord && this.dakaRecord.length > 0) {
        this.dakaRecord.forEach(item => {
          item.gmtCreate = DateUtils.formattedTime(item.gmtCreate)
        })
      }
      console.log('打卡记录', this.dakaRecord)
      this.getWeekStatus()
      this.getTotalDays()
    },
    getWeekStatus () {
      // 筛选本周数据
      const thisWeekRecords = this.dakaRecord.filter(record => this.isDateInThisWeek(record.gmtCreate))

      // 按日期分组并排序
      const recordsGroupedByDay = thisWeekRecords.reduce((acc, record) => {
        var date = DateUtils.formate(new Date(record.gmtCreate).getTime(), 'yyyy-MM-DD') // 获取日期部分，忽略时间
        if (!acc[date]) {
          acc[date] = []
        }
        acc[date].push(record)
        // 确保每天的记录按时间倒序排列
        acc[date].sort((a, b) => b.gmtCreate - a.gmtCreate)
        return acc
      }, {})

      console.log('本周打卡数据', recordsGroupedByDay)
      this.completedDays = Object.keys(recordsGroupedByDay).length

      this.weekDays = this.processWeekdata(recordsGroupedByDay)
      console.log('本周打卡统计', this.weekDays)

      // 今天打卡了没 todayFinish
      this.todayFinish = this.weekDays.some(day => {
        if (day.dateStr === DateUtils.formateDate(new Date(), 'yyyy-MM-DD')) {
          if (day.status === 'completed') {
            return true
          } else {
            return false
          }
        }
      })
    },
    getTotalDays () {
      // 筛选本周数据

      // 按日期分组并排序
      const recordsGroupedByDay = this.dakaRecord.reduce((acc, record) => {
        var date = DateUtils.formateDate(new Date(record.gmtCreate), 'yyyy-MM-DD') // 获取日期部分，忽略时间
        if (!acc[date]) {
          acc[date] = []
        }
        acc[date].push(record)
        // 确保每天的记录按时间倒序排列
        acc[date].sort((a, b) => b.gmtCreate - a.gmtCreate)
        return acc
      }, {})

      console.log('全部打卡数据', recordsGroupedByDay)
      this.totalDays = Object.keys(recordsGroupedByDay).length
    },
    getBackgroundColor (status) {
      switch (status) {
        case 'completed':
          return 'rgb(0,183,79)'
        case 'missed':
          return 'grey'
        case 'pending':
          return 'rgba(19,107,89,0.35)'
        default:
          return 'transparent'
      }
    },
    // 上传视频
    async afterRead (file) {
      this.uploadFinish = false
      this.showUploadLoading = true
      console.log('afterRead', file)
      const result = await this.uploadToAliyun(file.file, 0)
      const videourl = result.res.requestUrls[0].substring(0, result.res.requestUrls[0].indexOf('?'))
      console.log('【视频URL】', videourl)
      await DakaDao.add(this.userId, videourl)
      this.videoSelect = []
      this.uploadFinish = true
    },
    async uploadToAliyun (file, index) {
      this.currentUploadImageIndex = index
      const _that = this
      const OSS = require('ali-oss')
      const ossConfig = {
        // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
        region: 'oss-cn-shenzhen',
        // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
        accessKeyId: 'LTAIAcClE56I1mY0',
        accessKeySecret: 'IIPoiW0o3noCHU9bRT5vyAw0EDtem8',
        bucket: 'ihealthy'
      }
      const client = new OSS(ossConfig)
      // console.log(file)
      // 分片上传文件
      const s = 'daka_video/' + file.name
      console.log('上传文件', s)
      const ret = await client.multipartUpload(s, file, {
        progress: async function (p) {
          // console.log('multipartUpload', p)
          const e = {}
          e.percent = parseInt(p * 100)
          console.log(`上传进度:  第${index + 1}张图片 - ${e.percent} - `)

          _that.currentUploadPercent = e.percent
          // option.onProgress(e)
        }
      })
      console.log('上传结果', ret)
      if (ret.res.statusCode === 200) {
        // option.onSuccess(ret)
        console.log('上传成功', ret)
        return ret
      } else {
        // vm.disabled = false
        // option.onError('上传失败')
        console.log('上传失败', ret)
        return null
      }
    },
    handlePlanClick () {
      this.$router.push('/plangymnasticsSel')
    },
    // 获取本周开始日期（周一）和结束日期（周日）
    getWeekRange () {
      const now = new Date()
      const dayOfWeek = now.getDay() === 0 ? 7 : now.getDay() // 获取当前日期是一周中的第几天，0表示周日，1表示周一，以此类推
      const date = now.getDate() // 获取当前的日，如今天是6月9日，则date=9
      console.log('dayOfWeek', dayOfWeek)
      console.log('date', date)
      const startDate = new Date(now)
      startDate.setDate(date - dayOfWeek + 1) // 如果是周日，则保持不变，否则向前推到周一
      startDate.setHours(0, 0, 0, 0) // 设置时间为00:00:00

      const endDate = new Date(startDate)
      endDate.setDate(endDate.getDate() + 6) // 增加6天到达周日
      endDate.setHours(23, 59, 59, 999) // 设置时间为23:59:59.999，接近但不等于下周一的00:00:00

      return {
        startDate,
        endDate
      }
    },
    // 判断给定日期是否在本周内
    isDateInThisWeek (dateString) {
      var date = new Date(dateString)
      var {
        startDate,
        endDate
      } = this.getWeekRange()
      // date = DateUtils.formate(date.getMilliseconds(), 'yyyy-MM-dd HH:mm')
      // startDate = DateUtils.formate(startDate.getMilliseconds(), 'yyyy-MM-dd HH:mm')
      // endDate = DateUtils.formate(endDate.getMilliseconds(), 'yyyy-MM-dd HH:mm')
      console.log('【日期】', date, '【开始日期】', startDate, '【结束日期】', endDate)
      return date >= startDate && date <= endDate
    },
    processWeekdata (data) {
      const weekDays = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
      const result = []
      const today = new Date()
      const dayOfWeek = today.getDay() // 获取今天是周几 (0 是周日)
      const mondayIndex = dayOfWeek === 0 ? 6 : dayOfWeek - 1 // 获取周一的索引，如果今天是周日(dayOfWeek === 0)，则昨天是周六(index 6)

      // 生成本周一至周日的日期
      const datesOfWeek = weekDays.map((_, index) => {
        const date = new Date(today)
        date.setDate(today.getDate() - mondayIndex + index)
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      })

      // 处理每一天的数据
      datesOfWeek.forEach((date, index) => {
        const records = data[date]
        let status = 'pending'
        let newestVideoUrl = ''

        if (records && records.length > 0) {
          // 存在记录，找到最新的视频URL
          const sortedRecords = records.sort((a, b) => new Date(b.gmtCreate) - new Date(a.gmtCreate))
          newestVideoUrl = sortedRecords[0].videoUrl
          status = 'completed'
        } else if (new Date(date) < today) {
          // 日期已过且没有记录
          status = 'missed'
        }

        result.push({
          dateStr: date,
          date: weekDays[index],
          status: status,
          newestVideoUrl: newestVideoUrl
        })
      })

      return result
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>
.bg {
  background: linear-gradient(180deg, #D3FFEC 0%, #94FFD0 29%, #FFFFFF 100%);
}

.daily-status .van-grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}

.container {
  display: flex; /* 使用 flex 布局 */
  flex-wrap: wrap; /* 允许内容换行 */
  width: 100%; /* 容器占满整个父元素宽度 */
}

.square {
  width: 14.285%; /* 每个 square 占行宽的 1/7 */
  padding-bottom: 14.285%; /* 使用 padding-bottom 设置高度相等于宽度，确保正方形 */
  position: relative; /* 相对定位 */
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; /* 设置内容的文字颜色 */
  margin: 2px;
  border-radius: 50%;

}

.item-text {
  text-align: center;
  font-size: 12px;
}

.custom-upload-icon img {
  width: 100%; /* 根据需要调整宽度 */
  height: auto; /* 保持图片比例 */
}

.play-video {
  width: 100%;
  height: calc(100vw * 9 / 16);
}
</style>
