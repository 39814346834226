<template>
  <video-player
    ref="playerRef"
    :options="playerOptions"
    :playsinline="true"
    class="video-player vjs-big-play-centered"
    customEventName="customstatechangedeventname"
    @pause="onPlayerPause($event)"
    @play="onPlayerPlay($event)"
    @ready="playerReadied"
    @statechanged="playerStateChanged($event)"
  >
  </video-player>
</template>

<!-- @ended="onPlayerEnded($event)"
@waiting="onPlayerWaiting($event)"
@playing="onPlayerPlaying($event)"
@loadeddata="onPlayerLoadeddata($event)"
@timeupdate="onPlayerTimeupdate($event)"
@canplay="onPlayerCanplay($event)"
@canplaythrough="onPlayerCanplaythrough($event)" -->

<script>
// Similarly, you can also introduce the plugin resource pack you want to use within the component
// import 'some-videojs-plugin'
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
import './styles/video-player.less'

let cachedPlayer = null

export default {
  components: {
    videoPlayer
  },
  props: {
    videoUrl: String,
    posterUrl: String
  },
  data () {
    return {
      playerOptions: {
        // videojs options
        muted: false,
        language: 'zh-cn',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        preload: 'false', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        fluid: true, // 当 true 时，Video.js player 将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        poster: ''
      },
      isPlaying: true
    }
  },
  created () {
    this.playerOptions.poster = this.posterUrl
  },
  watch: {
    videoUrl (url) {
      if (!!url && this.player) {
        this.player.src(url)
      }
    }
  },
  mounted () {
    if (!!this.videoUrl && this.player) {
      this.player.src(this.videoUrl)
    }
    // if ()
    // console.log()
  },
  computed: {
    player () {
      return this.$refs.playerRef.player
    }
  },
  methods: {
    togglePlay () {
      console.log('双击播放器')
      const videoPlayer = this.$refs.playerRef // 获取 video-player 组件实例
      if (this.isPlaying) {
        videoPlayer.pause() // 暂停视频播放
      } else {
        videoPlayer.play() // 恢复视频播放
      }
      this.isPlaying = !this.isPlaying // 切换播放状态
    },
    // listen event
    onPlayerPlay (player) {
      if (player === cachedPlayer) {
        return
      }
      if (cachedPlayer) {
        console.log('暂停上一个视频')
        cachedPlayer.pause()
      }
      cachedPlayer = player
    },
    onPlayerPause (player) {
      // console.log('player pause!', player)
    },
    // ...player event

    // or listen state event
    playerStateChanged (playerCurrentState) {
      console.log('player current update state', playerCurrentState)
    },

    // player is ready
    playerReadied (player) {
      // you can use it to do something...
      // player.[methods]
    }
  }
}
</script>

<style lang="less">
.vjs-big-play-button {
  background-color: rgba(43, 51, 63, 0.6) !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-image: url('myimage.png');
  background-repeat: no-repeat;
  background-size: 46px;
  background-position: 50% calc(50% - 10px);
  border: none !important;
  box-shadow: none !important;
  border-radius: 1.5rem !important;
}

.vjs-poster {
  background-size: 100% 100% !important;
  background-position: center !important;
}
</style>
