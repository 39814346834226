// constants.js
export const DoctorUserGuideData = {
  // ID1: 第一个视频的详情
  1: {
    id: 1,
    title: '使用教程1-录入患者并发送训练方案',
    description: '从工作台的患者管理模块进行录入患者和发送训练方案',
    videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/user_guide/doctor/%E4%BD%BF%E7%94%A8%E6%95%99%E7%A8%8B1-%E5%8C%BB%E7%94%9F%E7%AB%AF%E5%BD%95%E5%85%A5%E6%82%A3%E8%80%85%E5%B9%B6%E5%8F%91%E9%80%81%E8%AE%AD%E7%BB%83%E6%96%B9%E6%A1%88.mp4',
    steps: [
      {
        description: '录入患者',
        images: ['https://cdn.keihong.tech/img/202405171159920.png', 'https://cdn.keihong.tech/img/202405171325148.png']
      },
      {
        description: '更新中...具体可看视频中的操作',
        images: []
      }
    ]
  },
  2: {
    id: 2,
    title: '使用教程2-在主页-我的患者列表界面如何发送方案',
    description: '从主页-我的患者列表发送训练方案',
    videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/user_guide/doctor/%E4%BD%BF%E7%94%A8%E6%95%99%E7%A8%8B-%E5%8C%BB%E7%94%9F%E7%AB%AF-%E6%88%91%E7%9A%84%E6%82%A3%E8%80%85%E5%88%97%E8%A1%A8%E5%8F%91%E9%80%81%E8%AE%AD%E7%BB%83%E6%96%B9%E6%A1%88.mp4',
    steps: [
      {
        description: '主页的我的患者列表界面，点击患者的【详情页】按钮即可进入【患者管理主页】',
        images: ['https://cdn.keihong.tech/img/202405171405927.png']
      }
    ]
  },
  3: {
    id: 3,
    title: '使用教程3-一对一在线指导-如何在预定时间进行视频指导（一对一）',
    description: '',
    videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/user_guide/doctor/%E6%8E%A5%E8%AF%8A-%E5%8C%BB%E7%94%9F%E7%AB%AF-%E4%B8%80%E5%AF%B9%E4%B8%80.mp4',
    steps: [{
      description: '',
      images: ['https://cdn.keihong.tech/img/202405181600342.png']
    }]
  },
  4: {
    id: 4,
    title: '使用教程4-MDT在线指导-如何在预定时间进行视频指导（MDT）',
    description: '',
    videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/user_guide/doctor/%E6%8E%A5%E8%AF%8A-%E5%8C%BB%E7%94%9F%E7%AB%AF-Mdt.mp4',
    steps: [{
      description: '',
      images: ['https://cdn.keihong.tech/img/202405181601759.png']
    }]
  }

}
