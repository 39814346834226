<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div style="background-color: #2E2F33">
    <MyNavBar :title="'告警详情'"/>
    <div style="text-align: center;background-color: #373E48;color: white;padding: 16px;margin-bottom: 16px">
      闯入线标注信息
    </div>
    <div>

    </div>
    <div>
      <!--   设备ID、进入告警事件ID、离开告警事件ID、告警类型、进入告警时间、
      离开告警时间、是否需要告警、计时状态(0-不需要告警 1-正在计时 2-已结束计时)、进入告警文案、离开文案、停留时间   -->
      <!--      <table class="table table-bordered">-->
      <!--        <thead>-->
      <!--        <tr>-->
      <!--          <th>设备ID</th>-->
      <!--          <th>告警细节ID</th>-->
      <!--          <th>告警类型</th>-->
      <!--          <th>其他告警ID</th>-->
      <!--          <th>闯入线-进入告警ID</th>-->
      <!--          <th>闯入线-离开告警ID</th>-->
      <!--          <th>进入时间</th>-->
      <!--          <th>离开时间</th>-->
      <!--          <th>是否需要告警</th>-->
      <!--          <th>计时状态</th>-->
      <!--          <th>停留时间</th>-->
      <!--          <th>进入告警文案</th>-->
      <!--          <th>离开告警文案</th>-->
      <!--        </tr>-->
      <!--        <tr v-for="alarm in newest10AlarmDetail" :key="alarm.id">-->
      <!--          <td>{{ alarm.deviceId }}</td>-->
      <!--          <td>{{ alarm.id }}</td>-->
      <!--          <td>{{ alarm.alarmType }}</td>-->
      <!--          <td>{{ alarm.alarmIdEnter }}</td>-->
      <!--          <td>{{ alarm.alarmIdExit }}</td>-->
      <!--          <td>{{ alarm.enterTime }}</td>-->
      <!--          <td>{{ alarm.leaveTime }}</td>-->
      <!--          <td>{{ alarm.needAlarm }}</td>-->
      <!--          <td>{{ alarm.timeStatus }}</td>-->
      <!--          <td>{{ alarm.stayTime }}</td>-->
      <!--          <td>{{ alarm.enterText }}</td>-->
      <!--          <td>{{ alarm.leaveText }}</td>-->
      <!--        </tr>-->
      <!--        </thead>-->
      <!--      </table>-->
      <div v-if="newestAlarmDetail">
        <van-cell-group inset>
          <van-cell :title="`${newestAlarmDetail.deviceName}(deviceID:${newestAlarmDetail.deviceId})`"/>
          <van-cell :label="`告警ID:${newestAlarmDetail.alarmIdEnter}`"
                    title="闯入线告警-【进入】">
            <template #right-icon>
              <!--              <van-icon class="search-icon" name="search"/>-->
              <div style="text-align: right" @click="previewImage(newestAlarmDetail.base64Enter)">
                <div>时间:{{ DateUtils.formattedTime(newestAlarmDetail.enterTime) }}}</div>
                <img :src="newestAlarmDetail.base64Enter" height="64" width="64"
                     @click="previewImage(newestAlarmDetail.base64Enter)"/>
              </div>
            </template>
          </van-cell>
          <van-cell :label="`告警ID:${newestAlarmDetail.alarmIdExit? newestAlarmDetail.alarmIdExit : '无'}`"
                    title="闯入线告警-【离开】">
            <div style="text-align: right" @click="previewImage(newestAlarmDetail.base64Leave)">
              <div>时间:{{
                  newestAlarmDetail.leaveTime ? DateUtils.formattedTime(newestAlarmDetail.leaveTime) : '无'
                }}
              </div>
              <img :src="newestAlarmDetail.base64Leave" height="64" width="64"
                   @click="previewImage(newestAlarmDetail.base64Leave)"/>
            </div>
          </van-cell>
          <van-cell :value="newestAlarmDetail.needAlarm?'是':'否'" title="是否需要告警"/>
          <van-cell :value="newestAlarmDetail.timeStatus===1?'正在计时':'计时结束'" is-link title="计时状态"/>
          <van-cell :value="newestAlarmDetail.enterText" title="进入告警文案"/>
          <van-cell :value="newestAlarmDetail.leaveText" title="离开告警文案"/>
          <van-cell :value="`${newestAlarmDetail.stayTime}${newestAlarmDetail.needAlarm?'(5秒后刷新)':'(已结束计时)'}`"
                    title="停留时间"/>
        </van-cell-group>
        <div style="height: 16px;"></div>
      </div>
      <div v-else style="padding: 16px;text-align: center;background-color: white;margin: 16px;border-radius: 8px">
        暂无标注数据
      </div>

    </div>
    <div style="text-align: center;background-color: #373E48;color: white;padding: 16px;margin-bottom: 16px">
      告警信息【最新10条】
    </div>

    <div v-for="alarm in newest10Alarm" :key="alarm.id">
      <van-cell-group inset>
        <van-cell :title="`${alarm.deviceName}(deviceID:${alarm.deviceId})`" :value="`${alarm.createTime}`"/>
        <van-cell :title="`告警ID：${alarm.id}`"
                  :value="`告警类型:${alarm.eventType}(${alarm.eventType==='10003'?'摔倒':'闯入线'})`"/>
        <van-cell :title="`抓拍`">
          <template #right-icon>
            <!--              <van-icon class="search-icon" name="search"/>-->
            <img :src="alarm.base64" height="64" width="64" @click="previewImage(alarm.base64)"/>
          </template>
        </van-cell>
        <van-cell is-link title="告警数据" value="点击查看" @click="handleClickAlarmData(alarm.alarmData)"/>
        <van-cell is-link title="操作" value="点击添加标注" @click="handleClickAddAlarmDetail(alarm)"/>

      </van-cell-group>
      <div style="height: 16px;"></div>
    </div>
    <van-popup v-if="currentSettingAlarm" v-model="showDetailSetting" position="bottom">
      <div style="padding: 16px;">
        <img :src="currentSettingAlarm.base64" style="width: 100%;display: block;border-radius: 8px"
             @click="previewImage(currentSettingAlarm.base64)"/>
      </div>
      <van-cell
        :value="`${currentSettingAlarm.eventType}-${currentSettingAlarm.eventType === '10003' ? '摔倒告警' : '闯入线告警'}`"
        title="当前告警类型"/>
      <div style="max-height: 95vh;">
        <!--    表单，录入告警信息    -->
        <div style="width: 100%;padding: 16px;box-sizing: border-box;">
          <van-form ref="form" :model="formData">
            <van-field
              v-model="formData.deviceID"
              :rules="rules.deviceID"
              label="设备ID"
              placeholder="请输入设备ID"
              required
            ></van-field>
            <van-field
              v-model="formData.deviceName"
              :rules="rules.deviceName"
              label="设备名称"
              placeholder="请输入设备名称"
              required
            ></van-field>
            <van-field
              v-model="formData.alarmID"
              :rules="rules.alarmID"
              label="告警ID"
              placeholder="请输入告警ID"
              required
            ></van-field>
            <van-field
              v-model="formData.alarmTime"
              :rules="rules.alarmTime"
              label="告警时间"
              placeholder="请选择告警时间"
              required
              type="datetime"
            ></van-field>
            <van-field label="是否需要开启告警倒计时(30分钟后开始告警)" name="switch" required>
              <template #input>
                <van-switch v-model="formData.needAlarm" size="20"/>
              </template>
            </van-field>
            <van-field label="闯入类型" name="radio" required>
              <template #input>
                <van-radio-group v-model="formData.entryType" direction="horizontal">
                  <van-radio name="enter">进入</van-radio>
                  <van-radio name="leave">离开</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field label="区域" name="radio" required>
              <template #input>
                <van-radio-group v-model="formData.roomType" direction="horizontal">
                  <van-radio name="bathroom">卫生间</van-radio>
                  <van-radio name="livingroom">客厅</van-radio>
                  <van-radio name="bedroom-oldman">房间-老人</van-radio>
                  <van-radio name="bedroom-child">房间-孩子</van-radio>
                  <van-radio name="bedroom-parent">房间-父母</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              v-if="formData.entryType === 'enter'"
              v-model="formData.enterAlarmText"
              label="进入告警文案"
              placeholder="请输入进入告警文案"
              required
            ></van-field>
            <van-field
              v-if="formData.entryType === 'leave'"
              v-model="formData.leaveAlarmText"
              label="离开告警文案"
              placeholder="请输入离开告警文案"
              required
            ></van-field>
            <van-button style="width: 100%" type="primary" @click="onSubmit">提交</van-button>
          </van-form>
        </div>
      </div>
    </van-popup>
    <div
      style="position:fixed;right: 16px;bottom: 56px;height: 56px;width: 56px;background-color: white;border-radius: 28px;box-shadow: 1px 1px 20px #888888"
      @click="refresh">
      <img src="https://cdn.keihong.tech/img/202309211523073.png"
           style="height: 48px;width: 48px;margin-left: 4px;margin-top: 4px"/>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import MonitorDao from '@/h5/doucan/network/MonitorDao'
import { Dialog, ImagePreview } from 'vant'
import DateUtils from '@/utils/DateUtils'

export default {
  name: 'MonitorAdminLabel',
  components: { MyNavBar },
  data () {
    return {
      timer: null,
      alarmId: null,
      deviceId: null,
      deviceName: null,
      newest10Alarm: null,
      newest10AlarmDetail: null,
      newestAlarmDetail: null,
      showDetailSetting: false,
      currentSettingAlarm: null,
      formData: {
        deviceID: '',
        deviceName: '',
        alarmID: '',
        alarmTime: '',
        needAlarm: true,
        entryType: '',
        roomType: '',
        enterAlarmText: '',
        leaveAlarmText: ''
      },
      rules: {
        deviceID: [{
          required: true,
          message: '请输入设备ID',
          trigger: 'blur'
        }],
        deviceName: [{
          required: true,
          message: '请输入设备名称',
          trigger: 'blur'
        }],
        alarmID: [{
          required: true,
          message: '请输入告警ID',
          trigger: 'blur'
        }],
        alarmTime: [{
          required: true,
          message: '请选择告警时间',
          trigger: 'change'
        }],
        needAlarm: [{
          required: true,
          message: '请选择是否需要告警',
          trigger: 'change'
        }],
        entryType: [{
          required: true,
          message: '请选择进入/离开类型',
          trigger: 'change'
        }],
        enterAlarmText: [{
          required: true,
          message: '请输入进入告警文案',
          trigger: 'blur'
        }],
        leaveAlarmText: [{
          required: true,
          message: '请输入离开告警文案',
          trigger: 'blur'
        }]
      }
    }
  },
  async mounted () {
    this.deviceId = this.$route.query.dID
    // this.alarmId = this.$route.query.alarmId
    // this.deviceName = this.$route.query.deviceName
    // console.log('alarmId', this.alarmId)
    // console.log('deviceId', this.deviceId)
    await this.loadNewest10Alarm()
    await this.loadNewest10AlarmDetail()
    if (this.newestAlarmDetail.needAlarm) {
      // 定时器，每5秒执行一次
      const _that = this
      this.timer = setInterval(() => {
        _that.loadNewest10AlarmDetail()
        if (!_that.newestAlarmDetail.needAlarm) {
          _that.timer.clearInterval()
        }
      }, 5000)
    }
  },
  methods: {
    refresh () {
      window.location.reload()
    },
    onSubmit () {
      console.log('表单数据1：', this.formData)
      this.$refs.form.validate().then(() => {
        // 表单验证通过，可以提交数据
        console.log('表单数据：', this.formData)
        // 提交数据的逻辑
        this.postAlarmDetail(this.formData)
        this.showDetailSetting = false
      }).catch((error) => {
        // 捕获并处理错误
        console.error('表单验证错误：', error)
      })
    },
    postAlarmDetail (data) {
      const entryType = data.entryType === 'enter' ? 1 : 2 // 进入-1、离开-2
      const req = {
        deviceId: data.deviceID,
        alarmIdEnter: entryType === 1 ? data.alarmID : '',
        alarmIdExit: entryType === 2 ? data.alarmID : '',
        alarmType: this.currentSettingAlarm.eventType === '10003' ? 1 : 2, // 摔倒-1、闯入线-2
        needAlarm: data.needAlarm,
        entryType: entryType, // 进入-1、离开-2
        roomType: data.roomType,
        enterText: entryType === 1 ? data.enterAlarmText : '',
        leaveText: entryType === 2 ? data.leaveAlarmText : ''
      }
      console.log('告警标注', req)
      MonitorDao.postAlarmDetail(req).then(res => {
        console.log('告警标注结果：', res)
        if (res.data.code === 200) {
          Dialog.alert({
            title: '提示',
            message: '告警标注成功'
          }).then(() => {
            // on close
            // 刷新页面
            window.location.reload()
          })
        } else {
          Dialog.alert({
            title: '提示',
            message: '告警标注失败'
          })
        }
      })
    },
    handleClickAddAlarmDetail (alarm) {
      console.log('添加标注', alarm)
      this.currentSettingAlarm = alarm
      this.formData.deviceID = alarm.deviceId
      this.formData.deviceName = alarm.deviceName
      this.formData.alarmID = alarm.id
      this.formData.alarmTime = alarm.createTime
      this.showDetailSetting = true
    },
    handleClickAlarmData (alarmData) {
      console.log(alarmData)
      Dialog.alert({
        title: '告警数据',
        message: alarmData || '告警数据为空(如 摔倒告警暂无具体数据)'
      }).then(() => {
        // on close
      })
    },
    previewImage (base64) {
      const images = []
      images.push(base64)
      ImagePreview({
        images: images,
        startPosition: 0
      })
    },
    async loadNewest10AlarmDetail () {
      const result = await MonitorDao.newest10AlarmDetail(this.deviceId)
      if (result) {
        this.newest10AlarmDetail = result
        result[0].base64Leave = 'data:image/png;base64,' + result[0].base64Leave
        result[0].base64Enter = 'data:image/png;base64,' + result[0].base64Enter
        // result[0].stayTime 秒 转 时分秒
        result[0].stayTime = DateUtils.formatedSecond(result[0].stayTime)
        this.newestAlarmDetail = result[0]
        console.log('告警标注', this.newest10AlarmDetail)
      }
    },
    async loadNewest10Alarm () {
      const result = await MonitorDao.newest10AlarmByDeviceID(this.deviceId)
      console.log('newest10Alarms', result)
      if (result) {
        result.forEach(item => {
          item.createTime = DateUtils.formattedTime(item.createTime)
          item.base64 = 'data:image/png;base64,' + item.base64
        })
        this.newest10Alarm = result
      }
    },
    onBackClick () {
      console.log('返回到监控管理员界面')
      // this.$router.replace('/home/square?hospital=暨南大学附属顺德医院')
    }
  },
  watch: {
    'formData.entryType': {
      handler () {
        if (this.formData.entryType === 'leave') {
          this.formData.needAlarm = false
        }
      }
    },
    'formData.roomType': {
      handler () {
        let room = ''
        switch (this.formData.roomType) {
          case 'bathroom':
            room = '卫生间'
            break
          case 'livingroom':
            room = '客厅'
            break
          case 'bedroom-oldman':
            room = '老人的房间'
            break
          case 'bedroom-parent':
            room = '父母的房间'
            break
          case 'bedroom-child':
            room = '孩子的房间'
            break
        }
        this.formData.enterAlarmText = '1人-进入-' + room
        this.formData.leaveAlarmText = '1人-离开-' + room
      }
    }
  },
  computed: {
    DateUtils () {
      return DateUtils
    }
  }
}
</script>
<style scoped>

</style>
