<template>
  <img class="img_item"
       :src="url">
</template>

<script>
export default {
  name: 'MyImg',
  props: {
    url: String
  }
}
</script>

<style scoped>
.img_item {
  display: block;
  width: 100%;
}
</style>
