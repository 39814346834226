<template>
  <div>
    <!--    <div class="title-bar">付款</div>-->
    <base-title-bar-scroll-page ref="container" :back="true" back-text="主页" title="支付" @onBackClick="onBackClick">
      <div style="margin: 16px;height: 90%;background-color: white;border-radius: 16px;padding-top: 16px">
        <div id="payTitle"
             style="display: flex;background-color: white;border-radius: 16px;padding: 8px;align-items: center;">
          <img alt="Logo" class="logo" src="https://cdn.keihong.tech/img/202312211710121.png">
          <div class="institution-name">{{ hospital }}</div>
        </div>
        <div class="divider"></div> <!-- Divider line -->
        <div class="inputBoxWrapper">
          <van-field
            v-model="amount"
            class="inputBox"
            label="付款金额"
            placeholder="请输入付款金额"
            type="number"
          >
            <template #button>
              <span>元</span>
            </template>
          </van-field>
        </div>
        <div class="input-wrapper">
          <van-field
            v-model="patientName"
            label="姓名"
            placeholder="请输入姓名"
            type="text"
          />
          <van-field
            v-model="patientMobile"
            label="手机号"
            placeholder="请输入手机号"
            type="tel"
          />
        </div>
        <div class="textarea-wrapper">
          <van-field
            v-model="remarks"
            placeholder="请输入备注"
            rows="5"
            style=" border: 1px solid #ccc;border-radius: 8px;"
            type="textarea"
          />
        </div>
        <div class="button-wrapper">
          <van-button style="width: 100%;" type="primary" @click="submitPayment">立即支付</van-button>
        </div>
        <div class="footer">技术支持：动优远程康复服务云平台</div>
      </div>
    </base-title-bar-scroll-page>
    <van-popup v-model="showPopup" :close-on-click-overlay="false" class="popup-wrapper" position="bottom">
      <div style="padding: 16px;text-align: center">
        <img alt="Success Logo" class="success-logo" src="https://cdn.keihong.tech/img/202312211813200.png">
        <div class="success-message">支付成功</div>
        <div style=" text-align: center;font-size: 14px;margin: 20px 0;">订单编号：{{ outTradeNo }}</div>
        <van-button style="width: 100%;" type="primary" @click="refreshPage">确定</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>

import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import { Dialog, Notify, Toast } from 'vant'
import WxPayDao from '@/h5/doucan/network/WxPayDao'
import wx from 'weixin-jsapi'
import axios from 'axios'

export default {
  name: 'QuickPayPage',
  components: { BaseTitleBarScrollPage },
  data () {
    return {
      showPopup: false,
      hospital: '',
      amount: '',
      remarks: '',
      patientName: '',
      patientMobile: '',
      testMobile: ['18008000808', '13610164743', '18520535303', '18501011010'],
      openId: '',
      payStatus: false,
      appsecret: '',
      appid: '',
      code: '',
      openid: '',
      paySignInfo: null,
      outTradeNo: '', // 动优支付系统里面的订单号 ordernum
      remark: ''
    }
  },
  mounted () {
    this.$refs.container.stopLoading()
    this.hospital = this.$route.query.hospital

    // 判断是否是微信浏览器
    const isWeChatBrowser = () => {
      const userAgent = navigator.userAgent.toLowerCase()
      return userAgent.includes('micromessenger')
    }

    if (isWeChatBrowser()) {
      // console.log("This is a WeChat browser");
      this.initWx()
    } else {
      // console.log("This is not a WeChat browser");
    }

    // const ua = window.navigator.userAgent.toLowerCase()
    // if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    //   // alert('是微信浏览器')
    //   // 初始化微信-获取openid
    //   this.initWx()
    // }
  },
  methods: {
    onBackClick () {
      // 重定向到某个url
      if (this.hospital === '内蒙古益济堂养老服务有限公司') {
        this.hospital = '中国民间中医医药研究开发协会中蒙民族医药研究发展分会'
      }
      window.location.href = 'https://doucan.jianerkanggz.cn/#/home/square?hospital=' + this.hospital
    },
    refreshPage () {
      this.showPopup = false
      // location.reload()
      // window.location.href = 'https://doucan.jianerkanggz.cn/#/home/square?hospital=' + this.hospital
      this.onBackClick()
    },
    submitPayment () {
      // Add your payment submission logic here
      this.pay()
    },
    async initWx () {
      await this.getAppid()
      const openid = this.$store.state.user.openId
      if (openid) {
        this.openid = openid
      } else {
        await this.getMpOpenID()
      }
    },
    async pay () {
      if (!this.patientName || !this.patientMobile) {
        Notify({
          type: 'danger',
          message: '请填写姓名和手机号'
        })
        return
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      let fee = 0
      if (this.testMobile.includes(this.patientMobile)) {
        fee = 0.01
      } else {
        fee = this.totalPrice
      }
      const fromActivity = '扫码支付'
      const goods = this.hospital + '-' + this.remarks
      // if (this.isAdmin) {
      //   fromActivity = '客服操作下单' + '-' + this.remark
      //   goods = this.hospital + '-' + this.doctorName + '-' + this.count + '周'
      // }

      const profile = {
        serveType: 0,
        mobile: this.patientMobile, // 后期mobile从用户授权获得，即订单记录支付者登录的微信账号手机号及实际接受服务患者的手机号
        patientName: this.patientName,
        patientMobile: this.patientMobile,
        orderType: '',
        fee: fee,
        fromActivity: fromActivity,
        goods: goods,
        openId: this.openid
      }
      console.log('提交订单', profile)
      const data = await WxPayDao.createOrder(profile)
      console.log('支付qrcode', data)
      if (data.code === 1) {
        this.paySignInfo = data.data
        this.outTradeNo = data.data.outTradeNo
        // 调起微信支付
        wx.config({
          // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false
          debug: false,
          // 必填，公众号的唯一标识
          appId: this.paySignInfo.sign.appId,
          // 必填，生成签名的时间戳
          timestamp: this.paySignInfo.sign.timeStamp,
          // 必填，生成签名的随机串
          nonceStr: this.paySignInfo.sign.nonceStr,
          // 必填，签名
          signature: this.paySignInfo.sign.paySign,
          // 必填，需要使用的JS接口列表
          jsApiList: ['chooseWXPay']
        })
        wx.ready(() => {
          wx.checkJsApi({
            jsApiList: ['chooseWXPay'],
            success: function (res) {
              console.log('seccess')
              console.log('hskdjskjk', res)
            },
            fail: function (res) {
              console.log('fail')
              console.log(res)
            }
          })
          const _that = this
          wx.chooseWXPay({
            // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            timestamp: this.paySignInfo.sign.timeStamp,
            // 支付签名随机串，不长于 32 位
            nonceStr: this.paySignInfo.sign.nonceStr,
            // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            package: this.paySignInfo.sign.packageValue,
            // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            signType: this.paySignInfo.sign.signType,
            // 支付签名
            paySign: this.paySignInfo.sign.paySign,
            // 支付成功后的回调函数
            success: function (res) {
              if (res.errMsg === 'chooseWXPay:ok') {
                console.log('支付成功', res)
                _that.payStatus = true
                Dialog.alert({
                  message: '支付成功'
                }).then(() => {
                  // on close
                  // todo 跳转到支付成功页面，显示支付凭证和提示
                  // _that.$router.push(
                  //   '/salepage/20230001/result?outTradeNo=' + _that.outTradeNo
                  // )
                  _that.showPopup = true
                })
              } else {
                Dialog({ message: JSON.stringify(res) })
                _that.payStatus = false
              }
            },
            fail: function (res) {
              Dialog({ message: '支付失败:' + JSON.stringify(res) })
              _that.payStatus = false
            }
          })
        })
        wx.error((err) => {
          alert(err)
        })
      } else {
        console.log('支付失败', data)
      }
    },
    async getOpenId (code) {
      // this.info = '正在获取getOpenId'
      const url = '/quick/pay/openid?code=' + code

      axios
        .get(url)
        .then((res) => {
          // this.info = JSON.stringify(res)
          this.openid = res.data.data.openid
          console.log('openid = ', res.data.data.openid)
          if (!this.openid) {
            this.getCodeApi()
            return
          }
          this.$store.commit('user/setOpenId', this.openid)
          // const profile = this.$store.state.apply20230001.profile
          // profile.openId = this.openid
          // this.$store.commit('apply20230001/update', profile)
        })
        .catch((err) => {
          console.log('err', err)
          this.getCodeApi()
        })
    },
    getCodeApi () {
      const appid = 'wxcb56667c309e369b'
      const urlNow = encodeURIComponent(window.location.href)
      const scope = 'snsapi_base' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      // const scope = 'snsapi_userinfo' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      const url =
        'https://open.weixin.qq.com' +
        '/connect/oauth2/authorize?appid=' +
        appid +
        '&redirect_uri=' +
        urlNow +
        '&response_type=code&scope=' +
        scope +
        '#wechat_redirect'
      window.location.href = url
    },
    getUrlKey (name) {
      // 获取url 参数
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [undefined, ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    },
    async getAppid () {
      const url = '/quick/pay/appid'
      const res = await axios.get(url)
      console.log('appid', res)
      this.appid = res.data.data.codeName
      this.appsecret = res.data.data.codeValue
    },
    async getMpOpenID () {
      const code = this.getUrlKey('code')
      if (code) {
        this.code = code
        await this.getOpenId(code)
      } else {
        this.getCodeApi()
      }
    }
  },
  watch: {},
  computed: {
    totalPrice () {
      return this.amount
    }
  }
}
</script>
<style scoped>

.logo {
  float: left;
  width: 60px;
  height: 60px;
}

.institution-name {
  float: right;
  width: 100%;
  font-size: 14px;
  margin-left: 16px;
}

.divider {
  height: 1px;
  background-color: #ccc;
  margin: 16px;
}

.inputBoxWrapper {
  margin: 16px;
}

.inputBox {
  border: 1px solid #ccc;
  border-radius: 16px;
}

.textarea-wrapper {
  margin: 16px;
}

.textarea-wrapper .van-field__control {
}

.button-wrapper {
  margin: 16px;

}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
  color: #7e7e7e;
}

.input-wrapper {
  margin: 16px;
}

.popup-wrapper {
  height: 50vh;
}

.success-logo {
  width: 70px;
  height: 70px;
  margin: 0 auto;
}

.success-message {
  text-align: center;
  font-size: 20px;
  margin: 20px 0;
}

</style>
