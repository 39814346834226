<template>
  <div style="text-align: center;padding-top: 10vh">
    <van-loading type="spinner" color="#1989fa" vertical>加载中</van-loading>
  </div>
</template>

<script>
export default {
  name: 'MyLoading'
}
</script>

<style scoped>

</style>
