<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar :title="title"/>
    <!--  搜索框：请输入姓名或手机号进行查询   -->
    <div>
      <van-search
        v-model="searchValue"
        placeholder="请输入姓名或手机号进行查询"
        @search="onSearch"
      />
    </div>
    <!--  搜索结果列表：显示姓名和手机号   -->
    <div class="list">
      <van-list :finished="finished" finished-text="没有更多了">
        <van-cell
          v-for="item in list"
          :key="item.id"
          :label="item.phone"
          :title="item.realname"
          :value="item.id"
          @click="onItemClick(item)"
        />
      </van-list>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import NumberUtil from '@/utils/NumberUtil'
import AdminDao from '@/h5/doucan/network/AdminDao'

export default {
  name: 'SearchUserPage',
  components: { MyNavBar },
  data () {
    return {
      title: '',
      type: '',
      searchValue: '',
      list: [],
      finished: true,
      userType: ''

    }
  },
  mounted () {
    // 打印传递过来的参数
    console.log('template', this.$route.query)
    // 把传递过来的参数取出
    const {
      title,
      type,
      userType
    } = this.$route.query
    this.title = title
    this.type = type
    this.userType = userType

    // toast： 搜索类型 this.type
    this.$toast({
      message: '搜索类型：' + this.type,
      duration: 1000
    })
  },
  methods: {
    // 点击搜索按钮
    onSearch (value) {
      console.log('onSearch', value)
      const isPhone = NumberUtil.isNumeric(value)

      // 判断是否是手机号
      if (isPhone) {
        this.searchByPhone(value)
        // 显示toast： 手机号查询
        this.$toast('查询手机号：' + value)
      } else {
        this.searchByName(value)
        // 显示toast： 姓名查询
        this.$toast('查询姓名：' + value)
      }
    },
    searchByPhone (value) {
      if (this.userType === 'doctor') {
        AdminDao.doctorByPhone(value).then(res => {
          console.log('doctorByPhone', res)
          if (!res) {
            this.$toast('没有查询到医生')
            return
          }
          this.list = res.content
        })
      } else {
        AdminDao.patientByPhone(value).then(res => {
          console.log('patientByPhone', res)
          if (res.content.length === 0) {
            this.$toast('没有查询到患者')
            return
          }
          this.list = res.content
        })
      }
    },
    searchByName (value) {
      if (this.userType === 'doctor') {
        AdminDao.doctorByName(value).then(res => {
          console.log('doctorByName', res)
          if (!res) {
            this.$toast('没有查询到医生')
            return
          }
          this.list = res.content
        })
      } else {
        AdminDao.patientByName(value).then(res => {
          console.log('patientByName', res)
          if (res.content.length === 0) {
            this.$toast('没有查询到患者')
            return
          }
          this.list = res.content
        })
      }
    },
    onItemClick (item) {
      console.log('onItemClick', item)
      // 根据this.type判断跳转的页面,使用switch
      switch (this.type) {
        case '患者的全部预约':
          this.$router.push({
            path: '/bookinglist',
            query: {
              userType: 'patient',
              patientId: item.userId
            }
          })
          break
        case '医生的全部预约':
          this.$router.push({
            path: '/bookinglist',
            query: {
              userType: 'doctor',
              doctorId: item.userId
            }
          })
          break
        case '患者的全部病历':
          this.$router.push({
            path: '/medicalrecordlist',
            query: {
              patientId: item.userId
            }
          })
          break
        case '患者的全部报告':
          this.$router.push({
            path: '/reportlist',
            query: {
              patientId: item.userId
            }
          })
          break
        case '患者的全部病理':
          this.$router.push({
            path: '/pathologylist',
            query: {
              patientId: item.userId
            }
          })
          break
        case '患者的全部影像':
          this.$router.push({
            path: '/imagelist',
            query: {
              patientId: item.userId
            }
          })
          break
        case '患者的全部会诊':
          this.$router.push({
            path: '/consultationlist',
            query: {
              patientId: item.userId
            }
          })
          break
        case '患者的全部MDT':
          this.$router.push({
            path: '/mdtlist',
            query: {
              patientId: item.userId
            }
          })
          break
        case '患者的全部随访':
          this.$router.push({
            path: '/followuplist',
            query: {
              patientId: item.userId
            }
          })
          break
        case '患者的全部病例':
          this.$router.push({
            path: '/caselist',
            query: {
              patientId: item.userId
            }
          })
          break
      }
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
