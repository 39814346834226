<template>
  <div>
    <div>
      <my-nav-bar
        :title="'资助详情'"
        back
        @onBackClick="onBackClick"
        style="position: fixed; width: 100%; top: 0"
      />
      <div style="margin-top: 48px">
        <img
          class="img_item"
          src="https://cdn.keihong.tech/img/202306042146052.png"
        />
      </div>
      <div
        @click="toQuestionnaire"
        style="
          height: 48px;
          text-align: center;
          position: fixed;
          width: 100vw;
          bottom: 0;
          color: white;
          font-size: 24px;
          background-color: forestgreen;
        "
      >
        提交并填写申请表
      </div>
      <!-- <div :v-show="false">
        <img
          @click="toQuestionnaire"
          class="img_item"
          src="https://cdn.keihong.tech/typora/2023-04-16-%E5%9F%BA%E9%87%91%E4%BC%9A%E6%B4%BB%E5%8A%A8202301-step3-2.png"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import MyNavBar from '../../../components/MyNavBar'
import { Dialog, Toast } from 'vant'
import WxPayDao from '../../../network/WxPayDao'
import wx from 'weixin-jsapi'

export default {
  name: 'ServeSale202301Step3',
  components: { MyNavBar },
  data() {
    return {
      testMobile: ['18008000808', '13610164743', '18520535303'],
      total: '0.00',
      amount: '0.00',
      amountPic: '',
      serveType: 0,
      payStatus: false,
      paySignInfo: ''
    }
  },
  created() {
    const profile = this.$store.state.apply20230001.profile
    console.log('提交信息', profile)
    profile.status = 3
    this.$store.commit('apply20230001/update', profile)
    this.onServeType()
  },
  methods: {
    onBackClick() {
      const profile = this.$store.state.apply20230001.profile
      console.log('提交信息', profile)
      this.$router.back()
    },
    onServeType() {
      const profile = this.$store.state.apply20230001.profile
      const serveType = profile.serveType
      this.serveType = serveType
      if (serveType === 1) {
        this.amount = '0.00'
        this.total = '0.00'
        this.amountPic = 'https://cdn.keihong.tech/img/202306042113292.png'
      }
      if (serveType === 2) {
        this.total = '3200'
        this.amount = '1600.0'
        this.amountPic =
          'https://cdn.keihong.tech/typora/2023-02-15-%E8%B4%B9%E7%94%A8%E6%94%AF%E4%BB%98-%E5%AD%A3%E5%BA%A6.png'
      }
      if (serveType === 3) {
        this.total = '6000'
        this.amount = '3000.0'
        this.amountPic =
          'https://cdn.keihong.tech/typora/2023-02-15-%E8%B4%B9%E7%94%A8%E6%94%AF%E4%BB%98-%E5%8D%8A%E5%B9%B4.png'
      }
      profile.fee = this.amount
      this.$store.commit('apply20230001/update', profile)
    },
    async handlePay() {
      // 未获得openid
      const profile = this.$store.state.apply20230001.profile
      if (!profile.openId) {
        Dialog({ message: 'openid获取失败，请重新扫码进入本页面' })
        return
      }
      console.log('发起支付')
      if (this.testMobile.includes(profile.patientMobile)) {
        this.amount = '0.01'
        profile.fee = this.amount
        this.$store.commit('apply20230001/update', profile)
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })

      console.log('提交订单', profile)
      const data = await WxPayDao.createOrder(profile)
      console.log('支付qrcode', data)
      if (data.code === 1) {
        this.paySignInfo = data.data
        // 调起微信支付
        wx.config({
          // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false
          debug: false,
          // 必填，公众号的唯一标识
          appId: this.paySignInfo.sign.appId,
          // 必填，生成签名的时间戳
          timestamp: this.paySignInfo.sign.timeStamp,
          // 必填，生成签名的随机串
          nonceStr: this.paySignInfo.sign.nonceStr,
          // 必填，签名
          signature: this.paySignInfo.sign.paySign,
          // 必填，需要使用的JS接口列表
          jsApiList: ['chooseWXPay']
        })
        wx.ready(() => {
          wx.checkJsApi({
            jsApiList: ['chooseWXPay'],
            success: function (res) {
              console.log('seccess')
              console.log('hskdjskjk', res)
            },
            fail: function (res) {
              console.log('fail')
              console.log(res)
            }
          })
          const _that = this
          wx.chooseWXPay({
            // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            timestamp: this.paySignInfo.sign.timeStamp,
            // 支付签名随机串，不长于 32 位
            nonceStr: this.paySignInfo.sign.nonceStr,
            // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            package: this.paySignInfo.sign.packageValue,
            // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            signType: this.paySignInfo.sign.signType,
            // 支付签名
            paySign: this.paySignInfo.sign.paySign,
            // 支付成功后的回调函数
            success: function (res) {
              if (res.errMsg === 'chooseWXPay:ok') {
                Dialog({ message: '支付成功' })
                _that.payStatus = true
              } else {
                Dialog({ message: JSON.stringify(res) })
                _that.payStatus = false
              }
            },
            fail: function (res) {
              Dialog({ message: '支付失败:' + JSON.stringify(res) })
              _that.payStatus = false
            }
          })
        })
        wx.error((err) => {
          alert(err)
        })
      } else {
        console.log('支付失败', data)
      }
    },
    async toQuestionnaire() {
      // 全额资质的需要单独做一次0元订单
      if (this.serveType === 1) {
        Toast.loading({
          message: '加载中...',
          forbidClick: true
        })
        const data = await WxPayDao.createFreeOrder(
          this.$store.state.apply20230001.profile
        )
        if (data.code === 1) {
          this.gotoQuestionnaire()
        } else {
          Dialog({ message: '订单提交失败，请重试或联系客服' })
        }
      } else {
        if (!this.payStatus) {
          Dialog({ message: '您尚未支付，请点击支付按钮支付' })
          return
        }
        this.gotoQuestionnaire()
      }
    },
    gotoQuestionnaire() {
      const profile = this.$store.state.apply20230001.profile
      console.log('提交信息', profile)
      profile.status = 4
      this.$store.commit('apply20230001/update', profile)
      window.location.href = 'https://www.wjx.top/vm/PzzRF7i.aspx#'
    }
  }
}
</script>

<style scoped>
.img_item {
  display: block;
  width: 100%;
}

.hr-dashed {
  margin-left: 16px;
  margin-right: 16px;
  border: 0;
  border-top: 1px dashed #a2a9b6;
}
</style>
