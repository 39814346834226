<template>
  <div class="exchange-success">
    <van-icon class="success-icon" name="checked"/>
    <h2>兑换成功</h2>
    <div class="info">
      您的兑换码已成功核销,请扫描或长按以下二维码，添加客服微信,我们的康复客服将尽快与您联系，安排后续服务的对接，请留意来电！
    </div>
    <!--    <div class="info">请长扫描或按以下二维码，添加客服微信</div>-->
    <!--    <div class="info">我们的康复客服将尽快与您联系</div>-->
    <!--    <div class="info">客服会联系您安排后续服务的对接，请留意来电</div>-->
    <div style="margin-top: 16px">
      <my-img style="width: 40vw" url="https://cdn.keihong.tech/img/202406051208186.png"/>
    </div>
    <div style="height: 32px"></div>
    <van-button type="primary" @click="goHome">前往康复圈了解更多康复知识</van-button>
  </div>
</template>

<script>
import MyImg from '@/h5/doucan/components/MyImg.vue'

export default {
  components: { MyImg },
  methods: {
    goHome () {
      // 假设你有一个名为 'home' 的路由
      this.$router.push('/home/square')
    }
  }
}
</script>

<style scoped>
.exchange-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
}

.success-icon {
  font-size: 60px;
  color: #07c160;
}

h2 {
  margin-top: 20px;
}

.info {
  margin-bottom: 4px;
  font-size: 18px;
  text-align: justify;
}
</style>
