import axios from 'axios'

const DoctorInputPatientDao = {
  async inputPatient (patient) {
    const url = '/leaveHospitalPatient/add'
    const { data: resp } = await axios.post(url, patient)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async loadByHospitalId (hospitalId, isManualCreate) {
    const url = `/leaveHospitalPatient/byHospital?hospitalId=${hospitalId}&onlyManualCreate=${isManualCreate}`
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async loadByHospitalIdAndClerk (hospitalId, isManualCreate, clerkUserId) {
    const url = `/leaveHospitalPatient/byHospital/clerk?hospitalId=${hospitalId}&onlyManualCreate=${isManualCreate}&entryClerkUserId=${clerkUserId}`
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async loadByPatientId (patientId) {
    const url = `/leaveHospitalPatient/byPatientId?patientId=${patientId}`
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  },
  async checkPhone (phone, doctorId) {
    const url = `/leaveHospitalPatient/checkPhone?phone=${phone}&doctorId=${doctorId}`
    const { data: resp } = await axios.get(url)
    const {
      code,
      message
    } = resp
    if (code !== 200) {
      return {
        status: false,
        msg: message + ' , 请检查手机号是否正确'
      }
    }
    return {
      status: true,
      msg: '当前手机号可以添加（未被注册或录入过）'
    }
  }
}
export default DoctorInputPatientDao
