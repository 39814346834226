<template>
  <div>
    <div v-if="isDebug" style="position: fixed;top: 0;z-index: 10;width: 100%;background-color: rgba(0,0,0,0.26)">
      {{ debugLog }}
    </div>
    <base-title-bar-scroll-page ref="container" :show-title-bar="!isApp" title="筛查类型选择">

      <div>
        <van-cell :value="doctorName" title="医生"/>
        <van-cell :value="`${patientName}(${patientPhone})`" title="患者"/>
      </div>

      <div style="padding-top: 16px">
        <van-cell-group inset>
          <van-cell v-for="option in options" :key="option" :title="option.title" is-link
                    @click="onAssessmentClick(option)"/>
        </van-cell-group>

      </div>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'

export default {
  name: 'ScreeningTableListPage',
  components: { BaseTitleBarScrollPage },
  data () {
    return {
      isDebug: true,
      debugLog: '',
      isApp: false,
      options: [
        {
          title: '青少年脊柱侧弯',
          path: '/AssessmentSpine'
        },
        {
          title: '老年性痴呆',
          path: ''
        },

        {
          title: '高血压',
          path: ''
        },

        {
          title: '糖尿病',
          path: ''
        }

      ],
      doctorId: '',
      doctorName: '',
      patientId: '',
      patientName: '',
      patientPhone: ''
    }
  },
  mounted () {
    this.hideLoading()
    if (this.$route.query.isApp) {
      this.isApp = true
    }

    if (this.isApp) {
      // 获取医生、患者信息
      this.loadArgsFromAndroid()
    }
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    loadArgsFromAndroid () {
      const argsString = window.android.getUserInfoJsonString('来自web请求参数，谢谢')
      console.log('来自Android的参数', argsString)
      const args = JSON.parse(argsString)
      // this.debugLog = args
      this.doctorId = args.doctorId
      this.doctorName = args.doctorName
      this.patientId = args.patientId
      this.patientName = args.patientName
      this.patientPhone = args.patientPhone
    },
    onAssessmentClick (item) {
      if (item.path) {
        // this.$router.push({
        //   path: item.path + '?isApp=true&from=ScreeningTableListPage',
        //   query: {
        //     doctorId: this.doctorId,
        //     doctorName: this.doctorName,
        //     patientId: this.patientId,
        //     patientName: this.patientName,
        //     patientPhone: this.patientPhone
        //   }
        // })
        if (item.title === '青少年脊柱侧弯') {
          window.android.toAssessmentPlan('脊柱侧弯')
        }
      }
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>
.group {
  margin-top: 16px;
}
</style>
