import axios from 'axios'

const TicaoDao = {
  async list () {
    const url = '/programme/find/all_course'
    const { data: resp } = await axios.get(url)
    const {
      code,
      data
    } = resp
    if (code !== 200 || !data) {
      return null
    }
    return data
  }
}
export default TicaoDao
