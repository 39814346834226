<template>
  <div
    style="border-radius: 24px;padding: 16px;background-color: #0d3d3d;color: white;margin-top: 32px;text-align: center;font-size: 18px"
    @click="submit">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'MyButton',
  props: {
    title: String
  },
  methods: {
    submit () {
      this.$emit('onClick')
    }
  }
}
</script>

<style scoped>

</style>
