<template>
  <div>
    <my-nav-bar :title="'康复体操列表'"/>
    <container-list :empty-desc="'暂未申请康复体操'" :empty="empty" :loading="loading">
      <div v-for="(item,index) in datas" :key="index">
        <div style="font-size: 32px;padding: 16px;margin-bottom: 16px" @click="itemClick(item)">{{
            item.courseName
          }}
        </div>
        <van-divider/>
      </div>
    </container-list>
  </div>
  <!--  <div>-->
  <!--    <MyNavBar :title="'康复体操列表'"/>-->
  <!--    <div>-->
  <!--      <MyLoading v-if="loading"/>-->
  <!--      <div v-else>-->
  <!--        <div v-for="(item,index) in datas" :key="index">-->
  <!--          <div style="font-size: 32px;padding: 16px;margin-bottom: 16px" @click="itemClick(item)">{{-->
  <!--              item.courseName-->
  <!--            }}-->
  <!--          </div>-->
  <!--          <div style="height: 1px;background-color: #c0c0c0"></div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
import MyNavBar from '../../components/MyNavBar'
import GymnasticsDao from '../../network/GymnasticsDao'
import ContainerList from '../../components/ContainerList'

export default {
  name: 'GymnasticsListPage',
  components: {
    ContainerList,
    MyNavBar
  },
  data () {
    return {
      empty: true,
      loading: true,
      datas: []
    }
  },
  mounted () {
    this.loadDatas()
  },
  methods: {
    async httpDatas () {
      const datas = await GymnasticsDao.loadList(this.$store.state.user.profile.userId)
      console.log('体操列表', datas)
      this.datas = datas
    },
    async loadDatas () {
      this.loading = true

      await this.httpDatas()
      console.log('数据请求结果', this.datas)
      if (this.datas.length) {
        this.empty = false
      } else {
        this.empty = true
      }
      this.loading = false
    },
    itemClick (item) {
      console.log('体操点击', item)
      this.$store.commit('play/setGym', item)
      this.$router.push('/GymnasticsDetailPage')
    }
  }
}
</script>

<style scoped>

</style>
