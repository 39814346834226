<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar :back-text="backText" back style="position:fixed;top: 0;width: 100%" title="辅具严选"
              @onBackClick="onBackClick"/>
    <div style="margin-top: 46px">
      <!--  商品列表 使用vant -->
      <!--    <van-list :finished="finished" finished-text="没有更多了">-->
      <div v-for="(item, index) in goods" :key="index">
        <van-card
          :desc="item.brief"
          :origin-price="item.price+50"
          :price="item.price"
          :thumb="item.smallPicUrl"
          :title="item.name"
          tag="活动"
          @click="handleGoodClick(item)"
        >
          <template #footer>
            <van-button size="mini">查看详情</van-button>
            <!--            <van-button size="mini">按钮</van-button>-->
          </template>
        </van-card>
        <div style="height: 16px"></div>
      </div>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import MallDao from '@/h5/doucan/network/MallDao'

export default {
  name: 'MallMainPage',
  components: { MyNavBar },
  data () {
    return {
      finished: false,
      loading: false,
      from: '',
      backRoute: '',
      goods: [],
      backText: '',
      fromToolKepu: false
    }
  },
  mounted () {
    // 读取query参数
    const query = this.$route.query
    if (query.from === 'toolkepu') {
      this.fromToolKepu = true
      this.backText = '返回'
    } else {
      this.backText = '康复圈'
    }
    // if (this.from) {
    //   if (this.from === 'square') {
    //     // 从康复圈页面进来（常规渠道）
    //     this.backRoute = '' // 返回按钮直接是返回
    //     return
    //   }
    //   // 从独立的商城二维码进来
    //   if (this.from === 'qrCode') {
    //     return
    //   }
    //   // 从科普文章进来(有专门的产品)
    //   if (this.from === 'article') {
    //
    //   }
    // }

    this.loadGoods()
  },
  methods: {
    onBackClick () {
      if (this.fromToolKepu) {
        this.$router.back()
        return
      }
      console.log('返回')
      this.$router.replace('/home/square')
    },
    async loadGoods () {
      const goods = await MallDao.listByCatalog(5)
      // 倒序 goods
      this.goods = goods.reverse()
      console.log('goods', this.goods)
    },
    handleGoodClick (item) {
      // 跳转到MallDetailPage: /MallDetailPage?goodId=item.id
      this.$router.push('/MallDetailPage?from=malllist&goodId=' + item.id)
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
