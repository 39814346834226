<template>
  <div>
    正在检查账号...
  </div>
</template>
<script>

export default {
  name: 'LoginCheckPage',
  data () {
    return {}
  },
  mounted () {
    const isLogin = this.$store.state.user.profile.isLogin
    if (isLogin) {
      if (this.$route.query.route) {
        this.$router.push(this.$route.query.route)
        return
      }
      if (this.$route.query.method) {
        switch (this.$route.query.method) {
          case 'planList':
            this.$router.push('/planListPage')
            break
        }
      }
    }
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
