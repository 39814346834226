import { render, staticRenderFns } from "./Marquee.vue?vue&type=template&id=1f7af266"
import script from "./Marquee.vue?vue&type=script&lang=js"
export * from "./Marquee.vue?vue&type=script&lang=js"
import style0 from "./Marquee.vue?vue&type=style&index=0&id=1f7af266&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports