<template>
  <div style="display: flex;flex-direction: column; height: 100vh;">

    <my-nav-bar v-if="showAppBar" :title="'训练方案'"/>
    <div style="z-index: 999;position: absolute;top: 2px;right: 2px;color: rgba(62,62,62,0.38);font-size: 10px">
      当前用户：{{
        phone
      }}
    </div>
    <div v-if="test">{{ test }}</div>
    <div v-if="!coursePackage">loading</div>
    <div v-else>
      <div v-if="!playingCourse">
        <my-img :url="coursePackage.coverUrl" style="width: 100%"/>
      </div>
      <video-player
        v-else
        id="video"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="true"
        :webkit-playsinline="true"
        :x5-video-player-fullscreen="true"
        class="video-player vjs-custom-skin"
        style="object-fit:fill"
        x-webkit-airplay="allow"
        x5-playsinline
        x5-video-player-type="h5"
        @click="fullScreen"
        @fullscreenchange="onFullscreenChange($event)"
        @pause="onPlayerPause($event)"
        @play="onPlayerPlay($event)"
        @ready="onVideoPlayerReady"></video-player>
      <div>
        <!--    课程标题和简介    -->
        <div style="padding: 16px 16px 0 16px;">
          <div style="font-weight: bold;font-size: 18px">{{ coursePackage.courseTitle }}</div>
          <div style="font-size: 16px;color: #474747">{{ coursePackage.courseDescription }}</div>
          <div style="font-size: 14px;color: #8f8f8f">
            <div>课节数：{{ coursePackage.chapterList.length }}</div>
          </div>
        </div>
      </div>
      <!--垂直滚动区域-->
      <div style="overflow-y: auto;box-sizing: border-box;flex: 1">

        <div style="display: flex;flex-direction: row;margin-top: 16px;position: relative">
          <div style="margin-left: 16px;font-weight: bolder;font-size: 16px">课程视频列表：</div>
          <div style="flex: 1;margin-right: 16px;text-align: right;color: #606060;font-size: 12px">
            左右滑动查看全部课程
          </div>
        </div>

        <div style="text-align: left;color: #606060;font-size: 12px;margin-left: 16px">
          点击下方标题可播放视频
        </div>
      </div>
      <div v-if="courses.length>0" class="video-playlist-container">
        <div class="video-playlist">
          <div v-for="(video, index) in courses" :key="index" :ref="'videoItem-' + index"
               class="video-item">
            <div
              :style="{ backgroundColor: playingCourse?.courseId === video.courseId ? 'rgba(53,223,164,0.27)' : '#ffffff' }"
              class="video-card"
              @click="courseClick(video,index)"
            >
              <div style="font-size: 15px;font-weight: bold">{{ video.title }}</div>
              <div style="font-size: 14px;">视频时长: {{ video.duration }}</div>
              <!--                <div style="font-size: 14px;">视频时长: {{ formatDuration(video.duration)  }}</div>-->
              <div style="position:relative;height: 32px">
                <button
                  v-show="playingCourse?.courseId === video.courseId "
                  class="training-button"
                >
                  训练中
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="playingCourse"
             style="font-size: 14px;margin-top: 16px;padding: 0 16px 0 16px;text-align: justify"><span
          class="span-course-detail">动作指导</span>
          <!--              <span v-html="getCourseText(playingCourse.courseId)"></span>-->
          <span style="margin-left: 4px;font-size: 16px">{{ playingCourse.intro }}</span>
          <!--            {{-->
          <!--              getCourseText(playingCourse.courseId)-->
          <!--            }}-->
        </div>

      </div>
      <div class="tabs-container">
        <van-tabs color="#1989fa">
          <van-tab style="color: #1989fa" title="课程介绍">
            <my-img :url="coursePackage.introImg"/>
          </van-tab>
          <!--            <van-tab title="评估报告">-->

          <!--            </van-tab>-->
          <!--          <van-tab title="远程康复">-->
          <!--            <van-card-->
          <!--              desc="这是远程康复的详细描述，包含了康复的计划、远程监控和康复指导等内容。"-->
          <!--              thumb="https://via.placeholder.com/150"-->
          <!--              title="远程康复"-->
          <!--            />-->
          <!--          </van-tab>-->
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import MyNavBar from '../../components/MyNavBar'
import MyImg from '@/h5/doucan/components/MyImg.vue'
import WeixinJSBridge from 'q'

export default {
  name: 'CoursePage',
  components: {
    MyImg,
    MyNavBar
  },
  data () {
    return {
      test: '',
      showAppBar: true,
      phone: '',
      playingCourse: null,
      courses: [],
      empty: true,
      loading: true,
      data: null,
      loadingCourse: true,
      playerOptions: {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。 使用player.play()方法进行编程自动播放，避免使用autoplay属性/选项。
        muted: false, // 默认情况下将会消除任何音频。 将提高自动播放的更功率。
        loop: true, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: 'video/mp4',
          src: ''
        }],
        poster: '你的封面地址',
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          // timeDivider: true,
          // durationDisplay: true,
          // remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      },
      coursePackage: null,
      courseList: [
        {
          courseId: 1,
          courseTitle: '司机及久坐人士疼痛康复理疗课程',
          // 介绍
          courseDescription: '本套课程包含15个动作，专为缓解久坐人士的常见关节和肌肉不适设计。',
          coverUrl: 'https://cdn.keihong.tech/img/202411131236131.png',
          introImg: 'https://cdn.keihong.tech/img/202411140924422.png',
          // 课程列表（章节）
          chapterList: [
            {
              courseId: 'b23a8c5f-b0c1-4a76-b99e-100aedae5d77',
              title: '颈部训练课程：动作1 颈枕区按摩激活',
              duration: '02:23',
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/None-a5b2ec63-颈椎功能训练 训练动作02颈枕区按摩.mp4',
              coverUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/None-a5b2ec63-颈椎功能训练 训练动作02颈枕区按摩.jpg',
              intro: '颈枕区按摩激活 - 放松颈部肌肉，减少弯腰低头导致的紧张'
            },
            {
              courseId: 'b93a0c59-e682-4376-bc0a-c275eae5fb12',
              title: '颈部训练课程：动作2 头顶天训练',
              duration: '01:59',
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/None-c7b69e67-颈椎功能训练 训练动作08头顶天.mp4',
              coverUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/None-c7b69e67-颈椎功能训练 训练动作08头顶天.jpg',
              intro: '头顶天训练 - 增强颈部肌肉的支撑力，减轻颈部疲劳'
            },
            {
              courseId: '1bc4c69d-080d-434a-b992-b628e83e16a4',
              title: '颈部训练课程：动作3 颈椎稳定训练—头手对抗',
              duration: '03:05',
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/None-f1e36c9c-颈椎功能训练 训练动作10头手对抗.mp4',
              coverUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/None-f1e36c9c-颈椎功能训练 训练动作10头手对抗.jpg',
              intro: '颈椎稳定训练—头手对抗 - 提升颈部的稳定性和肌耐力'
            },
            {
              courseId: '605a164f-1de3-4749-b01d-e3dfcf20f47d',
              title: '肩背训练课程：动作1 胸肌拉伸',
              duration: '02:12',
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/None-9f44c692-肩背功能训练 训练动作07胸肌拉伸.mp4',
              coverUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/None-9f44c692-肩背功能训练 训练动作07胸肌拉伸.jpg',
              intro: '胸肌拉伸 - 放松因搬抬重物引起的胸部紧绷'
            },
            {
              courseId: 'e3552201-1f7c-4fcd-a5d8-30f6716fc9b8',
              title: '肩背训练课程：动作2 肩关节后侧牵拉',
              duration: '02:20',
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/None-4c5403f3-肩背功能训练 训练动作08肩关节后侧牵拉.mp4',
              coverUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/None-4c5403f3-肩背功能训练 训练动作08肩关节后侧牵拉.jpg',
              intro: '肩关节后侧牵拉 - 缓解肩关节僵硬，提升灵活性'
            },
            {
              courseId: '8e8cd452-a00a-418f-9523-c3210cfcc6dd',
              title: '肩背训练课程：动作3 I-Y-U-W训练',
              duration: '02:36',
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/None-47318638-肩背功能训练 训练动作09iyuw训练.mp4',
              coverUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/None-47318638-肩背功能训练 训练动作09iyuw训练.jpg',
              intro: 'I-Y-U-W训练 - 增强肩背部肌群的稳定性和支撑力'
            },
            {
              courseId: '8c7ac11b-d100-4ab5-aa94-d3bbd4da5b6e',
              title: '核心训练课程：动作1 武士蹲',
              duration: '04:54',
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/None-d2503d6f-核心稳定第一阶段01武士蹲.mp4',
              coverUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/None-d2503d6f-核心稳定第一阶段01武士蹲.jpg',
              intro: '武士蹲 - 强化核心肌群，减少因久坐导致的腰部压力'
            },
            {
              courseId: 'b502f968-aa59-4ed5-a0bf-5464e0d02e20',
              title: '核心训练课程：动作2 单腿站立',
              duration: '02:54',
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/None-12c9ca34-核心稳定第一阶段13单腿站立.mp4',
              coverUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/None-12c9ca34-核心稳定第一阶段13单腿站立.jpg',
              intro: '单腿站立 - 提高平衡感，增加腰腹和下肢的稳定性'
            },
            {
              courseId: '56952529-08a6-44f6-b1ef-f9e587e0d288',
              title: '核心训练课程：动作3 单腿硬拉',
              duration: '02:44',
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/None-af7af660-核心稳定第二阶段02单腿硬拉.mp4',
              coverUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/None-af7af660-核心稳定第二阶段02单腿硬拉.jpg',
              intro: '单腿硬拉 - 增强腰部和臀部的力量，预防腰痛'
            },
            {
              courseId: '0f06f044-203e-4607-a497-caf4392b05f5',
              title: '腿部训练课程：动作1 提踵训练',
              duration: '02:12',
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/None-fabbc11a-膝关节功能训练第一阶段06提踵训练.mp4',
              coverUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/None-fabbc11a-膝关节功能训练第一阶段06提踵训练.jpg',
              intro: '提踵训练 - 促进下肢血液循环，缓解久站引起的腿部酸胀'
            },
            {
              courseId: 'c24fe534-3f1a-49a6-be9a-1031dfab2c5e',
              title: '腿部训练课程：动作2 单腿支撑踢腿训练',
              duration: '03:11',
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/None-13a291c8-膝关节功能训练第二阶段01单腿支撑踢腿训练.mp4',
              coverUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/None-13a291c8-膝关节功能训练第二阶段01单腿支撑踢腿训练.jpg',
              intro: '单腿支撑踢腿训练 - 提高腿部力量和支撑能力，减少膝盖压力'
            },
            {
              courseId: '472bc2bb-4a90-4710-a573-1dfd4316233a',
              title: '腿部训练课程：动作3 弓箭步训练',
              duration: '02:40',
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/None-e60eb06e-膝关节功能训练第二阶段04弓箭步.mp4',
              coverUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/None-e60eb06e-膝关节功能训练第二阶段04弓箭步.jpg',
              intro: '第二阶段04弓箭步训练 - 改善髋关节的灵活性，缓解久站后的不适'
            },
            {
              courseId: '366a3bc9-87de-44dc-9916-5a76b3dfaf47',
              title: '足部训练课程：动作1 踝关节伸屈',
              duration: '02:10',
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/None-57fdca8d-足踝功能训练第一阶段06踝关节伸屈.mp4',
              coverUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/None-57fdca8d-足踝功能训练第一阶段06踝关节伸屈.jpg',
              intro: '第一阶段06踝关节伸屈 - 激活足部肌群，缓解足底疲劳'
            },
            {
              courseId: 'cdeabb93-4d50-42d2-8dbe-2a2e06205947',
              title: '足部训练课程：动作2 足缩短训练',
              duration: '04:33',
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/None-d99149e8-足踝功能训练第一阶段07足缩短训练.mp4',
              coverUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/None-d99149e8-足踝功能训练第一阶段07足缩短训练.jpg',
              intro: '第一阶段07足缩短训练 - 增强足部支撑力，减少久站引起的足底痛'
            },
            {
              courseId: '613dee29-e6c9-401d-9404-5a805d67b745',
              title: '足部训练课程：动作3 脚跟行走',
              duration: '02:46',
              videoUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/None-8f86713b-膝关节功能训练第二阶段08脚跟行走.mp4',
              coverUrl: 'https://ihealthy.oss-cn-shenzhen.aliyuncs.com/video/courses/covers/None-8f86713b-膝关节功能训练第二阶段08脚跟行走.jpg',
              intro: '第二阶段08脚跟行走 - 改善足部稳定性，减轻家务活动后的不适'
            }
          ]
        }
      ]
    }
  },
  async mounted () {
    if (this.$route.query) {
      console.log('query', this.$route.query)
      if (this.$route.query.platform === 'wxapplet') {
        this.showAppBar = false
        this.phone = this.$route.query.phone
      }
    }

    // 读取课程，根据query的courseId，读取 courseList中的chapterList
    this.coursePackage = this.courseList.find(item => item.courseId === parseInt(this.$route.query.courseId))
    console.log('coursePackage', this.coursePackage)
    this.courses = this.coursePackage.chapterList
    console.log('courses', this.courses)
    // await this.loadDatas()
    // this.loadCourseFirst()
  },
  computed: {
    player () {
      // nexttick后返回
      return this.$refs.videoPlayer.player
    },
    rehabilitationGoals () {
      const goals = []

      if (this.data.plan.goalShort && this.data.plan.goalShort !== '无') {
        goals.push(`短期目标: ${this.data.plan.goalShort}`)
      }

      if (this.data.plan.goalMiddle && this.data.plan.goalMiddle !== '无') {
        goals.push(`中期目标: ${this.data.plan.goalMiddle}`)
      }

      if (this.data.plan.goalLong && this.data.plan.goalLong !== '无') {
        goals.push(`长期目标: ${this.data.plan.goalLong}`)
      }

      return goals.length > 0 ? goals.join('；') : '无目标'
    }

  },
  methods: {
    courseDetail (courseId) {
      // 跳转 CourseDetailVideoPlayPage
      this.$router.push({
        path: '/CourseDetailVideoPlayPage',
        query: {
          courseId: courseId,
          userId: this.$store.state.user.profile.userId
        }
      })
    },
    courseClick (item, index) {
      if (this.playingCourse && item.courseId === this.playingCourse.courseId) {
        return
      }
      this.playingCourse = item
      const itemRef = this.$refs[`videoItem-${index}`]
      if (itemRef) {
        const itemview = Array.isArray(itemRef) ? itemRef[0] : itemRef
        itemview.scrollIntoView({
          behavior: 'smooth', // 使用平滑滚动
          block: 'nearest', // 确保滚动到可见位置
          inline: 'start' // 滚动到最左侧
        })
      }
      this.$nextTick(() => {
        this.playerOptions.sources[0].src = this.playingCourse.videoUrl
        this.playerOptions.poster = this.playingCourse.coverUrl
        const player = this.player
        player.src({
          type: 'video/mp4',
          src: this.playingCourse.videoUrl
        })
        player.load() // 重新加载新视频
        player.ready(function () {
          player.play()
        })
      })
    },
    onPlayerPause (event) {

    },
    onPlayerPlay (event) {
      this.player.play()
      console.log('播放视频')
      this.uploadIntegral()
    },
    onVideoPlayerReady (event) {
      const ua = navigator.userAgent.toLowerCase()
      let isQQ = false
      const isQQInstalled = false

      // if(ua.indexOf(' qq') > -1 && ua.indexOf('mqqbrowser') < 0){
      //     //qq内置浏览器
      //     isQQInstalled = true;
      //     return;
      // }
      if (ua.indexOf('mqqbrowser') > -1 && ua.indexOf(' qq') < 0) {
        // qq浏览器
        isQQ = true
      }
      var isAndroid = /Android/i.test(navigator.userAgent)
      if (isQQ && isAndroid) {
        this.player.autoplay(true)
        return
      } else {
        setTimeout(() => {
          this.player.play()
        }, 1000)
      }

      // 微信播放
      const that = this
      if (window.WeixinJSBridge) {
        WeixinJSBridge.invoke('getNetworkType', {}, function (e) {
          that.player.play()
        }, false)
      } else {
        document.addEventListener('WeixinJSBridgeReady', function () {
          WeixinJSBridge.invoke('getNetworkType', {}, function (e) {
            that.player.play()
          })
        }, false)
      }
      //
      // ————————————————
      //
      //                             版权声明：本文为博主原创文章，遵循 CC 4.0 BY-SA 版权协议，转载请附上原文出处链接和本声明。
      //
      // 原文链接：https://blog.csdn.net/aaa123aaasqw/article/details/140375092
    },
    onFullscreenChange (event) {

    },
    fullScreen (event) {
      const player = this.$refs.videoPlayer.player
      player.requestFullscreen()// 调用全屏api方法
      player.isFullscreen(true)
      player.play()
    },

    async uploadIntegral () {
      const url = '/integral/add_integral?mode=' + 3
      // const url = '/integral/add_integral'
      console.log(this.$store.state.user.profile.token)
      const { data: resp } = await this.$http.post(url, {
        headers: {
          token: this.$store.state.user.profile.token
        }
      })
      console.log(resp)
    },
    onAssessmentClick () {
      this.$store.commit('assessment/setJsonString', this.data.assess)
      this.$router.push({
        path: '/AssessmentSpine?isReadAssess=true&from=PatientWeb'
      })
    },
    formatDuration (duration) {
      const minutes = Math.floor(duration / 60000)
      const seconds = ((duration % 60000) / 1000).toFixed(0)
      if (minutes === 0) {
        return `${seconds}秒`
      }
      return `${minutes}分${seconds.padStart(2, '0')}秒`
    },
    getActionRequirement (courseId) {
      console.log('getActionRequirement', courseId)
      console.log('getActionRequirement', courseId)
      // 查找courseId对应的数据
      const course = this.data.plan.planCourseBeans.find(c => c.courseId === courseId)

      // 如果找到数据，则返回格式化文本
      if (course) {
        if (course.groups === 0) {
          return '无要求'
        }
        return `${course.groups}${course.groupsUnit}，每次做${course.round}组，组间适当休息${course.rest}秒或根据个人情况调整。`
      } else {
        return '未找到该课程'
      }
    },
    getCourseText (courseId) {
      if (this.playingCourse.text === '') {
        return '无'
      }
      // 将文本按 '/n' 分割成段落数组
      const paragraphs = this.playingCourse.text.split('/n')
      // 检查最后一个是否有内容
      if (paragraphs[paragraphs.length - 1].trim() === '') {
        paragraphs.pop()
      }

      // 遍历每一段，加上序号
      const numberedParagraphs = paragraphs.map((paragraph, index) => {
        return `${index + 1}. ${paragraph}`
      })

      // 将数组转换为字符串，并用 <br> 连接每一段
      return '<br>' + numberedParagraphs.join('<br>')
    },
    formatTrainingDays (data) {
      const daysMap = {
        mon: '周一',
        tue: '周二',
        wed: '周三',
        thu: '周四',
        fri: '周五',
        sat: '周六',
        sun: '周日'
      }

      const selectedDays = Object.keys(daysMap)
        .filter(day => data[day])
        .map(day => daysMap[day])

      return selectedDays.join('、')
    }
  }
}
</script>

<style scoped>
.container-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 5px grey;
}

.video-playlist-container {
  overflow-x: hidden;
  padding: 16px 0 16px 0;
}

.video-playlist {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.video-item {
  flex: 0 0 66.66%; /* 显示1.5个item，设置item宽度为父容器的2/3 */
}

.video-card {
  padding: 16px;
  margin: 4px 0 4px 16px;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100px; /* 设置固定高度，确保所有item高度一致 */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color 0.3s ease;

}

.training-button {
  margin-top: 8px;
  background-color: #38b48b;
  color: white;
  position: absolute;
  right: 0;
  bottom: 0;
  border: none;
  padding: 4px 16px;
  border-radius: 8px;
  cursor: pointer;
}

.tab-item-container {
  margin: 16px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: 200px;
  padding-top: 16px;
}

.title-cell {
  .van-cell__value {
    min-width: 70%;
  }
}

.span-course-detail {
  background-color: rgba(31, 136, 101, 0.68);
  color: white;
  font-weight: bold;
  border-radius: 5px;
  padding: 2px 5px;
}
</style>
