<template>
  <div class="rich-content-container" v-html="richText"></div>
</template>

<!-- 基础富文本显示 -->
<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    richText: String
  }
})
</script>
<style src="./styles/rich-content.css">
</style>
