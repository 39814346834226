<template>
  <div>
    <base-title-bar-scroll-page ref="container" title="患者录入员工作台">
      <div>
        <Grid :column-num="4" :image-array="functionItems" style="width: 100%;padding: 32px"
              @click="handleFuntionMenuClick"/>
      </div>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import Grid from '@/h5/doucan/components/Grid.vue'

export default {
  name: 'ClerkPage',
  components: {
    Grid,
    BaseTitleBarScrollPage
  },
  data () {
    return {
      functionItems: [
        {
          url: 'https://cdn.keihong.tech/img/202312122245163.png',
          name: '信息录入'
        },
        {
          url: 'https://cdn.keihong.tech/img/202312122246398.png',
          name: '录入记录'
        }

      ]
    }
  },
  mounted () {
    this.hideLoading()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    handleFuntionMenuClick (item, index) {
      console.log('点击', item, index)
      if (item.name === '信息录入') {
        this.$router.push({ path: '/NeimengPatientBaseInfoForm?from=webEntry' })
        return
      }
      if (item.name === '录入记录') {
        this.$router.push({ path: '/AdminHospitalPatientInputList?clerk=ture' })
      }
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
