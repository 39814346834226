<template>
  <div>
    <van-nav-bar
      :title="title"
    />
    <div v-if="loginCheck"
         style="font-size: 14px;background-color: rgba(10,75,61,0.34);color: #0c0c0c;text-align: center;padding-top: 8px;padding-bottom: 8px">
      检测到您已登录过，可直接登录，如需登陆其他账号，请手动输入
    </div>
    <!--  手机号、短信验证码登录表单模块，使用vant  -->
    <van-field
      v-model="phone"
      label="手机号"
      maxlength="11"
      placeholder="请输入手机号"
      type="tel"
    />
    <div style="position: relative">
      <van-field
        v-model="smsCode"
        :button="smsBtn"
        label="验证码"
        placeholder="请输入验证码"
        type="text"
      />
      <!-- 获取短信验证码按钮   -->
      <div :class="{ 'sms-btn-disabled': disableBtn }" class="sms-btn"
           style="position: absolute;right: 16px;top: 0;bottom: 0;border-radius: 4px"
           @click="handleClick">
        {{ smsBtn }}
      </div>
    </div>
    <!--新用户将会自动进行注册-->
    <div style="padding: 16px">
      <div style="display: flex;justify-content: space-between">
        <div style="font-size: 14px;color: #999999">新用户将会自动进行注册</div>
        <!--        <div style="font-size: 14px;color: #999999">忘记密码？</div>-->
      </div>
    </div>
    <!--  登录按钮  -->
    <div style="padding: 16px">
      <van-button block type="primary" @click="onSubmit">登录</van-button>
    </div>
  </div>
</template>

<script>
import SmsDao from '../../network/SmsDao'
import { Notify } from 'vant'
import DistributionDao from '@/h5/doucan/pages/distribution/DistributionDao'

export default {
  name: 'DistributionLoginPage',
  data () {
    return {
      disableBtn: false,
      timeLeft: 0,
      phone: '',
      smsCode: '',
      smsBtn: '获取验证码',
      smsCount: 60,
      smsVerify: null,
      testSmsCode: 'jek888',
      result: null,
      isFirst: false,
      title: '登录',
      inviterId: null,
      loginCheck: false
    }
  },
  created () {
    const userType = this.$route.query.userType
    console.log(this.$route.query.isFirst)
    console.log(this.isFirst)
    this.inviterId = this.$route.query.inviterId
    console.log('inviterId', this.inviterId)

    if (userType === '1') {
      this.isFirst = true
      this.title = '一级分销 - 分销登录/注册'
    }

    if (this.inviterId || userType === '2') {
      this.isFirst = false
      this.title = '普通分销 - 分销登录/注册'
    }

    if (this.$store.state.distributor.data) {
      this.$toast('检测到您已登录过，可直接登录，如需登陆其他账号，请手动输入')
      this.loginCheck = true
      this.phone = this.$store.state.distributor.data.phone
      this.smsCode = this.$store.state.distributor.data.phone.slice(-4)
    }

    // this.phone = '13610164743'
    // this.smsCode = '4743'
  },
  methods: {
    handleClick () {
      if (!this.phone) {
        Notify('请输入手机号')
        return
      }
      if (!this.disableBtn) {
        this.disableBtn = true
        this.timeLeft = this.smsCount
        this.startCountdown()
        // 调用发送短信验证码的逻辑
        this.getSmsCode()
      }
    },
    startCountdown () {
      const timer = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--
          this.smsBtn = `${this.timeLeft}秒`
        } else {
          this.disableBtn = false
          this.smsBtn = '重新获取验证码'
          clearInterval(timer)
        }
      }, 1000)
    },
    async onSubmit (values) {
      console.log('submit', values)
      console.log(this.phone)
      console.log(this.smsCode)
      if (!this.phone) {
        Notify('请输入手机号')
        return
      }
      // 获取手机号后四位
      const phoneLast4 = this.phone.slice(-4)
      console.log('phoneLast4', phoneLast4)
      if ((this.smsCode !== this.testSmsCode && this.smsCode !== phoneLast4) && !this.smsVerify) {
        Notify('请先获取短信验证码')
        return
      }
      if (!this.smsCode) {
        Notify('请输入短信验证码')
        return
      }

      if (this.checkSmsCode()) {
        // 成功通知
        Notify({
          type: 'success',
          message: '短信验证成功'
        })

        this.onSmsCheckSuccess()
      } else {
        Notify('验证码错误')
      }
    },
    checkSmsCode () {
      if (this.smsCode === this.testSmsCode) {
        return true
      }
      const phoneLast4 = this.phone.slice(-4)
      if (this.smsCode === phoneLast4) {
        return true
      }
      if (this.smsCode === this.smsVerify.code && this.phone === this.smsVerify.phone) {
        return true
      } else {
        return false
      }
    },
    async getSmsCode () {
      const result = await SmsDao.getSmsCode(this.phone)
      if (result) {
        console.log('短信验证', result)
        this.smsVerify = result
        console.log(this.smsVerify)
      }
    },

    async onSmsCheckSuccess () {
      if (this.isFirst) {
        // 一级分销注册登陆
        const resp = await DistributionDao.loginFirst(this.phone)
        const {
          code,
          message,
          data
        } = resp
        if (code === 200) {
          console.log('登录成功', data)
          if (data.name) {
            console.log('已完善姓名')
            this.$store.commit('distributor/setData', data)
            this.$router.push({
              path: '/DistributionUserPage'
            })
          } else {
            this.$router.push({
              path: '/DistributionRegisterPage',
              query: {
                user: data
              }
            })
          }
        } else {
          console.log('登录失败', message)
        }
      } else {
        // 二级分销注册登陆
        if (this.inviterId) {
          console.log('二级分销 - 来自邀请链接')
        } else {
          console.log('二级分销 - 来自扫码登陆')
        }
        const resp = await DistributionDao.loginSecond(this.phone, this.inviterId)
        const {
          code,
          message,
          data
        } = resp
        if (code === 200) {
          console.log('登录成功', data)
          if (data.name) {
            console.log('已完善姓名')
            this.$store.commit('distributor/setData', data)
            this.$router.push({
              path: '/DistributionUserPage'
            })
          } else {
            this.$router.push({
              path: '/DistributionRegisterPage',
              query: {
                user: data
              }
            })
          }
        } else {
          console.log('登录失败', message)
        }
      }
    }
  }
}
</script>

<style scoped>
.sms-btn {
  padding: 8px;
  width: 100px;
  height: 28px;
  background-color: #e8e8e8;
  color: #333333;
  text-align: center;
  font-size: 14px;
  line-height: 28px;
  font-weight: bolder;
  cursor: pointer;
}

.sms-btn-disabled {
  background-color: #cccccc;
  color: #999999;
  cursor: not-allowed;
}
</style>
