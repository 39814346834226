<template>
  <div>
    <base-title-bar-scroll-page ref="container" :show-title-bar="showAppBar" title="隐私协议">
      <iframe height="100%"
              src="https://cdn.keihong.tech/img/202406152151643.htm"
              width="100%"/>

    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'

export default {
  name: 'DoucanPrivate',
  components: { BaseTitleBarScrollPage },
  data () {
    return {
      showAppBar: true
    }
  },
  mounted () {
    if (this.$route.query.isApp) {
      this.showAppBar = false
    }
    this.hideLoading()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
