  vue
<template>
  <div class="marquee-container" @click="nav">
    <div class="title">{{ title }}</div>
    <div class="marquee">
      <div class="marquee-content">
        <template v-for="(item, index) in items">
          <span :key="index">{{ item }}</span>
          <span v-if="index !== items.length - 1"
            >&nbsp;&nbsp;&nbsp;&nbsp;</span
          >
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '点击观看直播>>',
      items: [] // 根据您的需求，这里是文字数组
    }
  },
  mounted() {
    for (let index = 0; index < 20; index++) {
      this.items.push(
        '《基于“互联网+”技术的健身气功“八段锦”在颈肩腰腿痛患者康复中的应用(适宜技术推广项目)》'
      )
    }
  },
  methods: {
    nav() {
      //   this.$router.push(
      //     '/WebPage?title=直播&src=https://wx.vzan.com/live/tvchat-940593298?v=638241051882872041'
      //   )
      window.location.href =
        'https://wx.vzan.com/live/tvchat-940593298?v=638241051882872041'
    }
  }
}
</script>

<style>
.marquee-container {
  background-color: brown;
  color: white;
  padding: 16px;
  display: flex;
  align-items: center;
}

.title {
  flex-shrink: 0;
  margin-right: 10px;
  width: auto;
  text-decoration: underline;
}

.marquee {
  overflow: hidden;
  position: relative;
}

.marquee-content {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 180s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
  