<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar back title="操作指引" @onBackClick="onBackClick"/>
    <div style="text-align: center;margin-top: 32px">正在更新维护中....</div>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'

export default {
  name: 'GuidePage',
  components: { MyNavBar },
  data () {
    return {}
  },
  mounted () {

  },
  methods: {
    onBackClick () {
      console.log('返回')
      this.$router.replace('/home/square')
    }
  },
  watch: {},
  computed: {}
}
</script>
<style scoped>

</style>
