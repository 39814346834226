<template>
  <div style="padding: 16px;">
    <MyNavBar v-if="!isApp" :title="'我的课程'"/>
    <!--    <div style="text-align: center;padding-top: 16px;padding-left:16px;padding-right:16px;font-size: 18px">-->
    <!--      以下是您的专属科普哦-->
    <!--    </div>-->
    <!--    <div style="text-align: center;padding: 16px;font-size: 18px">请多多查看，祝你早日康复</div>-->

    <div v-if="loading" style="text-align: center;margin-top: 64px">
      加载中...
    </div>
    <div v-else-if="list">
      <div v-for="item in list" :key="item.id">
        <div
          style="background-color: #ffffff;padding: 8px;margin-bottom: 16px;border-radius: 2px;border-style: dashed;border-width: 1px;border-color: black">
          <div class="title">{{ item.title }}</div>
          <div>推送日期：{{ item.gmtCreate }}</div>
          <div class="description">备注：{{ item.otherDescription ? item.otherDescription : '无备注' }}</div>
          <div class="description">课程内容，请点击下方小方块查看</div>
          <!--          <van-grid :column-num="3">-->
          <!--            <van-grid-item v-for="(c, index) in item.articleModelList" :key="index" class="my-grid-item"-->
          <!--                           @click="showVideo(c)">-->
          <!--              <div style="width: 100%;padding:4px;">{{ c.title }}</div>-->
          <!--            </van-grid-item>-->
          <!--          </van-grid>-->
          <GridText :column-num="3" :itemArray="item.articleModelList" @click="handleItemClick"/>
        </div>
      </div>
    </div>
    <div v-else
         style="background-color: #0a4b3d;color: white;font-size: 16px;text-align: center;height: 100px;line-height: 100px">
      暂无课程内容
    </div>
    <!--  您暂未有专属科普内容，请联系您的护士获取  -->
    <van-popup v-model="showPopup" :style="{ height: '90%' }" position="bottom" @close="closePop">
      <!--      <van-video :src="videoUrl" :poster="posterUrl" :autoplay="true" :show-center-play="false" :close-on-click-overlay="true" />-->
      <div style="font-size: 18px;text-align: center;padding: 16px">{{ currentVideo.title }}</div>
      <video
        ref="video"
        :src="currentVideo.videoUrl"
        autoplay="autoplay"
        class="play-video"
        controls="controls"
      >
      </video>
      <!--   按钮   -->
      <div style="text-align: center;padding: 16px">
        <van-button style="width: 100%" type="primary" @click="closePop">关闭</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import MyNavBar from '../../components/MyNavBar'
import KepuDao from '@/h5/doucan/network/KepuDao'
import GridText from '@/h5/doucan/components/GridText.vue'
import ReportFeatureAccessDao from '@/h5/doucan/network/ReportFeatureAccessDao'

export default {
  name: 'MyKepuXuanJiaoMainPage',
  components: {
    GridText,
    MyNavBar
  },
  data () {
    return {
      loading: true,
      list: null,
      currentVideo: '',
      showPopup: false,
      isApp: false,
      patientId: null
    }
  },
  async mounted () {
    const patientId = this.$route.query.patientId
    if (patientId) {
      this.patientId = patientId
    } else {
      this.checkApp()
      if (this.isApp) {
        this.patientId = await window.android.getPatientId()
        console.log('patientId', this.patientId)
      } else {
        this.patientId = this.$store.state.user.profile.userId
      }
    }
    this.reportFeatureAccess()
    await this.load()
  },
  methods: {
    checkApp () {
      const query = this.$route.query
      if (query?.isApp) {
        this.$store.commit('setIsApp', query.isApp)
        this.isApp = query.isApp
      }
      if (this.isApp) {
        // window.getAssessmentData = this.getAssessmentData
        // window.setAssessmentData = this.setAssessmentData
        this.reportBridgeSuccess()
      }
    },
    reportBridgeSuccess () {
      if (!this.isApp) {
        return
      }
      if (window.android && window.android.onBridgeSuccess) {
        var fromAndroid = window.android.onBridgeSuccess('桥接成功 from 入院科普宣教')
        console.log('fromandroid 桥接', fromAndroid)
        console.log('document.visibilityState', document.visibilityState)
        // this.onMounted()
      } else {
        alert(typeof (window.android))
        // this.title = '桥接失败'
      }
    },
    onMounted () {
      // window.android2.onMounted('onMounted')
    },
    async closePop () {
      this.showPopup = false
      this.$refs.video.pause()
    },
    async load () {
      const resp = await KepuDao.loadList(this.patientId)
      console.log('kepu', resp)
      console.log('document.visibilityState', document.visibilityState)
      // 给title添加序号
      for (const item of resp) {
        for (const [index, articleModel] of item.articleModelList.entries()) {
          articleModel.title = `${index + 1}. ${articleModel.title}`
        }
      }
      this.list = resp
      this.loading = false
    },
    handleItemClick (item) {
      if (item.contentType === 1) {
        this.$router.push({
          path: '/WebPage',
          query: {
            src: item.refLink,
            title: item.title
          }
        })
        return
      }
      if (item.contentType === 2) {
        this.showVideo(item)
      }
    },
    showVideo (video) {
      this.currentVideo = video
      this.showPopup = true
    },
    reportFeatureAccess () {
      ReportFeatureAccessDao.report(13, this.$store.state.user.qrCodeHospital, this.$store.state.user.openId).then(res => {
        console.log('上报访问成功', res)
      })
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.description {
  font-size: 16px;
  margin-bottom: 10px;
}

.play-video {
  width: 100%;
  //height: 100%; object-fit: fill;
}

.my-grid-item {
  background-color: #0c4fa8;
}

</style>
