// 医院管理员
export default {
  namespaced: true,
  state () {
    return {
      token: '',
      adminData: null
    }
  },
  mutations: {
    setToken (state, payload) {
      state.token = payload
    },
    setAdminData (state, payload) {
      state.adminData = payload
    }
  }
}
