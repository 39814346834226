<template>
  <div style="padding: 16px">
    <div style="font-weight: bold">服务内容：</div>
    <div>1. 由“医生+康复治疗师+康复助理”团队联合提供 6个月 远程康复服务</div>
    <div>2. 助行功能性电刺激技术治疗仪 免费租赁</div>
    <div>------</div>
    <div style="font-weight: bold">治疗仪适用于：</div>
    <div>通过最新技术“无错学习”法，进行步态训练、步态纠正、步态改善</div>
    <div style="font-weight: bold">适用对象：</div>
    <div>中风康复、脑损伤、脑瘫</div>
    <div style="font-weight: bold">费用：</div>
    <div style="text-align: right">远程康复6个月服务包: 4800元</div>
    <div style="text-align: right">押金: 2400元</div>
    <div style="text-align: right">合计: 7200元</div>
  </div>
</template>

<script>
export default {
  name: 'ServiceTypeZhongfeng6month',
  data () {
    return {
      fee: 7200
    }
  },
  created () {
    this.$emit('update-fee', this.fee)
  }
}
</script>

<style scoped>

</style>
