import axios from 'axios'

const FeatureAccessDao = {
  // 登录
  async report (modeId, hospital, wxOpenId) {
    const url = `/integral/add_integral/v2?mode=${modeId}&wxOpenId=${wxOpenId}&hospitalName=${hospital}`
    const response = await axios.post(url)
    return response.data
  }
  // async generateCode (name) {
  //   const url = '/api/exchangeCode/generateExchangeCode?enterpriseName=' + name
  //   const response = await axios.get(url)
  //   return response.data
  // },
  // async redeemCode (diseaseId, phoneNumber, exchangeCode) {
  //   const url = '/api/exchangeCode/redeem'
  //   const response = await axios.post(url, {
  //     diseaseId,
  //     phoneNumber,
  //     exchangeCode
  //   })
  //   return response.data
  // },
  // async unusedCode (enterpriseId) {
  //   const url = '/api/enterprise/unused/' + enterpriseId
  //   const response = await axios.get(url)
  //   return response.data
  // },
  // async codeStatistics (enterpriseId) {
  //   const url = `/api/exchangeCode/${enterpriseId}/statistics`
  //   const response = await axios.get(url)
  //   return response.data
  // }
}

export default FeatureAccessDao
