import axios from 'axios'

const ExchangeDao = {
  // 登录
  async loginEnterprise (name, password) {
    const url = '/api/enterprise/login'
    const response = await axios.post(url, {
      name,
      password
    })
    return response.data
  },
  async generateCode (name) {
    const url = '/api/exchangeCode/generateExchangeCode?enterpriseName=' + name
    const response = await axios.get(url)
    return response.data
  },
  async redeemCode (diseaseId, phoneNumber, exchangeCode, description) {
    const url = '/api/exchangeCode/redeem'
    const response = await axios.post(url, {
      diseaseId,
      phoneNumber,
      exchangeCode,
      description
    })
    return response.data
  },
  async unusedCode (enterpriseId) {
    const url = '/api/enterprise/unused/' + enterpriseId
    const response = await axios.get(url)
    return response.data
  },
  async codeStatistics (enterpriseId) {
    const url = `/api/exchangeCode/${enterpriseId}/statistics`
    const response = await axios.get(url)
    return response.data
  }
}

export default ExchangeDao
