<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar style="position:fixed;top: 0;width: 100%" title="预约单记录"/>
    <div style="margin-top: 56px">
      <!--  医生列表 用vant组件 -->
      <!--  loading  -->
      <div v-show="!datas" style="text-align: center;margin-top: 16px">
        <van-loading/>
      </div>
      <div v-if="datas" style="padding: 16px">
        <div style="font-size: 20px">预约单数: {{ datas.length }}</div>
      </div>
      <!-- 渲染datas -->
      <div v-for="(data, index) in datas" :key="index">
        <!--   使用vant组件展示 预约日期 医生姓名 患者姓名   -->
        <van-cell-group>
          <van-cell :value="data.createTime" title="下单日期"/>
          <van-cell :value="data.patientName" title="患者姓名"/>
          <van-cell :value="data.doctorName" title="医生姓名"/>
          <van-cell :value="data.hospitalName" title="医院名称"/>
          <van-cell :value="data.orderType" title="预约类型"/>
          <van-cell :value="data.times" title="预约次数"/>
          <!--          <van-cell :value="`已完成${data.finishTimes}次，剩余${data.times-data.finishTimes}次`" title="完成情况"/>-->
          <van-cell :value="data.finishedTimes" title="完成情况"/>
          <van-cell :value="data.remark" title="备注"/>
        </van-cell-group>
        <div style="height: 16px;background-color: #f3f3f3"></div>
      </div>
    </div>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import AdminHospitalDao from '@/h5/doucan/network/AdminHospitalDao'
import DateUtils from '@/utils/DateUtils'

export default {
  name: 'AdminHospitalBookingList',
  components: { MyNavBar },
  data () {
    return {
      datas: []
    }
  },
  mounted () {
    this.loadDatas()
  },
  methods: {
    async loadDatas () {
      const datas = await AdminHospitalDao.bookingList()
      for (const datasKey in datas) {
        const data = datas[datasKey]
        data.finishedTimes = `已完成${data.finishedTimes}次，剩余${data.times - data.finishedTimes}次`
        data.createTime = DateUtils.formattedDay(data.createTime)
      }
      console.log(datas)
      this.datas = datas
    }
  },
  watch: {},
  computed: {
    DateUtils () {
      return DateUtils
    }
  }
}
</script>
<style scoped>

</style>
