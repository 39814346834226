// 用户模块
export default {
  namespaced: true,
  state () {
    return {
      // 用户信息
      profile: {
        isLogin: false,
        userId: '',
        phone: '',
        name: '',
        token: '',
        passwd: '',
        hospitalName: ''
      },
      qrCodeHospital: null,
      openId: '',
      canShowMall: true
    }
  },
  mutations: {
    // 修改用户信息，payload就是用户信息对象
    setUser (state, payload) {
      state.profile = payload
    },
    logout (state) {
      state.profile.isLogin = false
    },
    setQrCodeHospital (state, payload) {
      state.qrCodeHospital = payload
    },
    getQrCodeHospital (state) {
      return state.qrCodeHospital
    },
    setOpenId (state, payload) {
      state.openId = payload
    },
    setCanShowMall (state, payload) {
      state.canShowMall = payload
    }
  }
}
// ————————————————
// 版权声明：本文为CSDN博主「run-Ameng」的原创文章，遵循CC 4.0 BY-SA版权协议，转载请附上原文出处链接及本声明。
// 原文链接：https://blog.csdn.net/m0_58861443/article/details/120247013
