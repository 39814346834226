<template>
  <div>
    <my-nav-bar
      :title="'中风患者家庭康复科普公益活动'"
      back
      @onBackClick="onBackClick"
    />
    <img
      class="img_item"
      src="https://cdn.keihong.tech/img/202306042023224.png"
    />
    <img
      class="img_item"
      src="https://cdn.keihong.tech/typora/2023-04-16-%E5%9F%BA%E9%87%91%E4%BC%9A%E6%B4%BB%E5%8A%A8202301-%E5%88%87%E5%9B%BE%202-1.png"
    />
    <img
      class="img_item"
      src="https://cdn.keihong.tech/typora/2023-04-16-%E5%9F%BA%E9%87%91%E4%BC%9A%E6%B4%BB%E5%8A%A8202301-%E5%88%87%E5%9B%BE%202.png"
    />
    <img
      class="img_item"
      src="https://cdn.keihong.tech/img/202306042024287.png"
    />
    <img
      class="img_item"
      src="https://cdn.keihong.tech/typora/2023-04-16-%E5%9F%BA%E9%87%91%E4%BC%9A%E6%B4%BB%E5%8A%A8202301-%E5%88%87%E5%9B%BE%204.png"
    />
    <img
      class="img_item"
      src="https://cdn.keihong.tech/img/202306042025817.png"
    />
    <div style="height: 56px"></div>
    <div
      style="
        width: 100%;
        text-align: center;
        font-size: 24px;
        line-height: 56px;
        height: 56px;
        background-color: #ff946e;
        color: white;
        position: fixed;
        bottom: 0;
      "
    >
      已结束
    </div>
  </div>
</template>

<script>
import MyNavBar from '../../../components/MyNavBar'

export default {
  name: 'ServeSale202301',
  components: { MyNavBar },
  data () {
    return { from: '' }
  },
  created () {
    console.log('created from', this.$route.query.from)
    this.from = this.$route.query.from
    console.log('this.from', this.from)
  },
  methods: {
    handleDetailClick () {
      this.$router.push('/ServeSale202301/detail/1')
    },
    onBackClick () {
      if (this.from === '1') {
        this.$router.replace('/home/square?from=1')
      } else {
        this.$router.replace('/home/square')
      }
    }
  }
}
</script>

<style scoped>
.img_item {
  display: block;
  width: 100%;
}
</style>
