<template>
  <div>
    <MyLoading v-if="loading"/>
    <my-empty-view v-else-if="empty" :empty-desc="emptyDesc"/>
    <div v-else>
      <slot>
        <div style="text-align: center;padding-top: 20vh">数据内容Slot</div>
      </slot>
    </div>
  </div>
</template>

<script>
import MyLoading from './MyLoading'
import MyEmptyView from './MyEmptyView'

export default {
  name: 'ContainerList',
  components: {
    MyEmptyView,
    MyLoading
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    emptyDesc: String,
    empty: {
      type: Boolean,
      default: true
    },
    datas: {
      type: Array,
      default: null
    }
  }
}
</script>

<style scoped>

</style>
