<template>
  <div>
    <my-nav-bar
      :title="'资助详情'"
      back
      @onBackClick="onBackClick"
      style="position: fixed; width: 100%; top: 0"
    />
    <div style="margin-top: 46px">
      <div>
        <img
          class="img_item"
          src="https://cdn.keihong.tech/typora/2023-04-16-%E5%9F%BA%E9%87%91%E4%BC%9A%E6%B4%BB%E5%8A%A8202301-step1-1.png"
        />
        <img
          class="img_item"
          src="https://cdn.keihong.tech/typora/2023-04-16-%E5%9F%BA%E9%87%91%E4%BC%9A%E6%B4%BB%E5%8A%A8202301-step1-2.png"
        />
        <img
          class="img_item"
          src="https://cdn.keihong.tech/typora/2023-04-16-%E5%9F%BA%E9%87%91%E4%BC%9A%E6%B4%BB%E5%8A%A8202301-step1-3.png"
        />
      </div>
      <div style="padding: 8px" id="form">
        <div style="background-color: white; border-radius: 8px; padding: 8px">
          <van-form>
            <van-field
              v-model="patientName"
              name="姓名"
              label="姓名"
              style="font-size: 24px"
              label-width="80px"
              placeholder="请填写姓名"
              :rules="[{ required: true, message: '请填写姓名' }]"
            />
            <van-field
              v-model="patientMobile"
              type="tel"
              name="手机号"
              maxlength="11"
              style="font-size: 24px"
              label="手机号"
              label-width="80px"
              placeholder="请填写手机号"
              :rules="telRules"
            />
          </van-form>
        </div>
      </div>
      <div style="height: 32px"></div>
    </div>
    <!--    <div style="position:relative;padding: 8px">-->
    <!--      <img class="img_item" @click="handleWatchAgreement"-->
    <!--           src="https://cdn.keihong.tech/typora/2023-02-15-%E7%94%B3%E8%AF%B7%E6%B5%81%E7%A8%8B-1-%E8%B5%84%E5%8A%A9%E5%8D%8F%E8%AE%AE.png">-->
    <!--      <div style="position: absolute;bottom: 32px;left: 24px">-->
    <!--        <div style="display: flex;flex-direction: row;align-items: center">-->
    <!--          <div style="height: 46px;width: 46px;" @click="handleAcceptAgreement">-->
    <!--            <van-icon v-if="!acceptAgreement" name="circle" size="24" style="position: absolute;left: 11px;top:11px"/>-->
    <!--            <van-icon v-else name="passed" size="24" color="#599E00" style="position: absolute;left: 11px;top:11px"/>-->
    <!--          </div>-->
    <!--          <div style="margin-left: 8px" @click="handleAcceptAgreement">我已查看并同意资助协议</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div style="height: 100px"></div>
    <div
      @click="handleNextClick"
      style="
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: white;
        font-weight: bold;
        background-color: #529878;
        position: fixed;
        bottom: 0;
        width: 100%;
        font-size: 24px;
      "
    >
      下一步
    </div>
    <van-popup
      v-model="showAgreement"
      position="bottom"
      :style="{ height: '80%' }"
    >
      <div>
        <img
          class="img_item"
          src="https://cdn.keihong.tech/typora/2023-02-15-%E8%B5%84%E5%8A%A9%E5%8D%8F%E8%AE%AE%20-2-_1.png"
        />
        <img
          class="img_item"
          src="https://cdn.keihong.tech/typora/2023-02-15-%E8%B5%84%E5%8A%A9%E5%8D%8F%E8%AE%AE%20-2-_2.png"
        />
        <img
          class="img_item"
          src="https://cdn.keihong.tech/typora/2023-02-15-%E8%B5%84%E5%8A%A9%E5%8D%8F%E8%AE%AE%20-2-_3.png"
        />
        <img
          class="img_item"
          src="https://cdn.keihong.tech/typora/2023-02-15-%E8%B5%84%E5%8A%A9%E5%8D%8F%E8%AE%AE%20-2-_4.png"
        />
        <img
          class="img_item"
          src="https://cdn.keihong.tech/typora/2023-02-15-%E8%B5%84%E5%8A%A9%E5%8D%8F%E8%AE%AE%20-2-_5.png"
        />
        <div
          @click="handleAcceptAgreementInDetail"
          style="
            width: 100%;
            height: 56px;
            background-color: #0d3d3d;
            color: white;
            font-size: 24px;
            text-align: center;
            line-height: 56px;
          "
        >
          我已知晓并同意本公益活动协议内容
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import MyNavBar from '../../../components/MyNavBar'
import axios from 'axios'
import { Dialog } from 'vant'

export default {
  name: 'ServeSale202301Step1',
  components: { MyNavBar },
  data () {
    return {
      showTest: false,
      telRules: [
        {
          required: true,
          message: '请填写手机号',
          trigger: 'onBlur'
        },
        {
          validator: (value) => {
            return /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(
              value
            )
          },
          message: '请输入正确格式的手机号码',
          trigger: 'onBlur'
        }
      ],
      patientMobile: '',
      patientName: '',
      activty: '广州科普活动2023001-中风家庭康复科普公益活动',
      info: '',
      appid: '',
      code: '',
      openid: '',
      mobile: '',
      acceptAgreement: false,
      showAgreement: false,
      isWeChatBrowser: true
    }
  },
  async created () {
    this.checkWeChatBrowser()
    const profile = this.$store.state.apply20230001.profile

    if (profile.status === 2) {
      this.patientName = profile.patientName
      this.patientMobile = profile.patientMobile
      this.mobile = profile.patientMobile
      this.acceptAgreement = true
    }

    profile.status = 1
    profile.fromActivity = this.activty
    this.$store.commit('apply20230001/update', profile)
    console.log('提交信息', profile)

    if (this.isWeChatBrowser) {
      await this.getAppid()
      // pc调试时注释
      if (!profile.openId) {
        await this.getMpOpenID()
      }
    }
  },
  methods: {
    checkWeChatBrowser () {
      const userAgent = navigator.userAgent.toLowerCase()
      this.isWeChatBrowser = /micromessenger/.test(userAgent)
    },
    handleNextClick () {
      if (!this.patientMobile || !this.patientMobile) {
        const element = document.getElementById('form')
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth' // 可选，平滑滚动
        })
      }
      if (!this.patientName) {
        Dialog({ message: '请填写联系人信息(姓名)' })
        return
      }
      if (!this.patientMobile) {
        Dialog({ message: '请填写联系人信息(手机号)' })
        return
      }
      // if (!this.acceptAgreement) {
      //   Dialog({ message: '请同意资助协议' })
      //   return
      // }
      const profile = this.$store.state.apply20230001.profile
      profile.mobile = this.patientMobile
      profile.patientMobile = this.patientMobile
      profile.patientName = this.patientName
      this.$store.commit('apply20230001/update', profile)
      this.$router.push('/ServeSale202301/detail/2')
    },
    handleAcceptAgreementInDetail () {
      this.acceptAgreement = true
      this.showAgreement = false
    },
    handleAcceptAgreement () {
      this.acceptAgreement = !this.acceptAgreement
    },
    handleWatchAgreement () {
      // if (!this.showTest) {
      //   return
      // }
      this.showAgreement = !this.showAgreement
    },
    getCodeApi () {
      const appid = 'wxcb56667c309e369b'
      const urlNow = encodeURIComponent(window.location.href)
      const scope = 'snsapi_base' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      // const scope = 'snsapi_userinfo' // snsapi_userinfo 非静默授权用户有感知 snsapi_base 静默授权用户无感知
      const url =
        'https://open.weixin.qq.com' +
        '/connect/oauth2/authorize?appid=' +
        appid +
        '&redirect_uri=' +
        urlNow +
        '&response_type=code&scope=' +
        scope +
        '#wechat_redirect'
      window.location.href = url
    },
    getUrlKey (name) {
      // 获取url 参数
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [undefined, ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    },
    async getOpenId (code) {
      this.info = '正在获取getOpenId'
      const url = '/quick/pay/openid?code=' + code

      axios
        .get(url)
        .then((res) => {
          // this.info = JSON.stringify(res)
          this.openid = res.data.data.openid
          console.log('success = openid', res.data)
          if (!this.openid) {
            this.getCodeApi()
            return
          }
          const profile = this.$store.state.apply20230001.profile
          profile.openId = this.openid
          this.$store.commit('apply20230001/update', profile)
          this.$store.commit('user/setOpenId', this.openid)
        })
        .catch((err) => {
          console.log('err', err)
          this.getCodeApi()
        })
    },
    onBackClick () {
      this.$router.replace('/ServeSale202301')
    },
    async getAppid () {
      const url = '/quick/pay/appid'
      const res = await axios.get(url)
      console.log('appid', res)
      this.appid = res.data.data.codeName
      this.appsecret = res.data.data.codeValue
    },
    async getMpOpenID () {
      const code = this.getUrlKey('code')
      if (code) {
        this.code = code
        await this.getOpenId(code)
      } else {
        this.getCodeApi()
      }
    }
  }
}
</script>

<style scoped>
.img_item {
  display: block;
  width: 100%;
}
</style>
