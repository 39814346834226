<template>
  <div style="padding: 16px">
    <div style="font-weight: bold">服务内容：</div>
    <div>1. 由“医生+康复治疗师+康复助理”团队联合提供 3个月 远程康复服务</div>
    <div>2. 助行功能性电刺激技术治疗仪 免费租赁</div>
    <div>------</div>
    <div style="font-weight: bold">费用：</div>
    <div style="text-align: right">远程康复3个月服务包:  2400元</div>
    <div style="text-align: right">押金:  2400元 </div>
    <div style="text-align: right">合计:  4800元 </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceTypeZhongfeng3month',
  data () {
    return {
      fee: 3600
    }
  },
  created () {
    this.$emit('update-fee', this.fee)
  }
}
</script>

<style scoped>

</style>
