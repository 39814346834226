<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <BaseTitleBarScrollPage ref="container" title="医生列表">
    <div>
      <div style="text-align: center">数据总数：{{ total }}</div>
      <!--      <div>当前页数：{{ page }} / {{ totalPages }}</div>-->
    </div>
    <list-view ref="lv" @onLoad="load" @onRefresh="onRefresh">
      <template v-slot:item="slotProps">
        <div style="padding: 16px;background: white;box-sizing: content-box;">
          <!--    显示医生姓名、注册时间、注册医院      -->
          <div style="display: flex;justify-content: space-between">
            <div>{{ slotProps.data.realname }}</div>
            <div style="font-size: 12px;color: #333333">注册日期：{{
                DateUtils.formattedTime(slotProps.data.createDate)
              }}
            </div>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div style="font-size: 12px;color: #333333">注册医院：{{
                slotProps.data.hospital
              }}
            </div>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div style="font-size: 12px;color: #333333">自主填写医院：{{
                slotProps.data.realHospital
              }}
            </div>
          </div>
        </div>
      </template>
      <!--      <template #divider>-->
      <!--        <div style="height: 16px;background-color: #f5f5f5"></div>-->
      <!--      </template>-->
    </list-view>

  </BaseTitleBarScrollPage>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import ListView from '@/h5/doucan/components/ListView.vue'
import AdminDao from '@/h5/doucan/network/AdminDao'
import DateUtils from '../../../../utils/DateUtils'

export default {
  name: 'DoctorListPage',
  components: {
    ListView,
    BaseTitleBarScrollPage
  },
  data () {
    return {
      page: 1, // 当前页码
      pageSize: 20,
      totalPages: 0, // 总页数
      total: 0,
      datas: []
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    onRefresh () {
      this.page = 1
      this.load()
    },
    async load () {
      const result = await AdminDao.doctorList(this.page, this.pageSize)
      console.log('医生数据', result)
      this.total = result.totalElements
      this.totalPages = result.totalPages
      this.$refs.container.stopLoading()
      if (result.content.length > 0) {
        this.page++
        // 延迟1秒
        setTimeout(() => {
          this.$refs.lv.addData(result.content)
          if (result.content.length < this.pageSize) {
            this.$refs.lv.finish()
          }
        }, 500)
      } else {
        this.$refs.container.showEmpty()
      }
    }
  },
  watch: {},
  computed: {
    DateUtils () {
      return DateUtils
    }
  }
}
</script>
<style scoped>

</style>
