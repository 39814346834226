class NumberUtil {
  /**
   * 判断一个字符串是否为纯数字
   * @param {string} value 需要判断的字符串
   * @return {boolean} 如果是纯数字返回true,否则返回false
   */
  static isNumeric (value) {
    if (typeof value !== 'string') {
      return false
    }
    return /^\d+$/.test(value)
  }
}

export default NumberUtil
