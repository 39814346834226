<template>
  <div class="exchange-page">
    <div class="vip-container">
      <div style="font-size: 18px;font-weight: bold;margin-bottom: 16px">温馨提示</div>
      <div class="message">
        感谢您成为汐香记VIP客户，您免费获得了1台多功能电疗治疗仪，以及获得了1次远程康复服务免费赠送，将由三甲级医院的医生/康复治疗师为您提供服务，在点击最下方的“输入兑换码”进行兑换激活。
      </div>
      <div class="message">
        激活后，请您按照指引添加[客服微信]，客服会为您安排后续服务及预约三甲医院医生的视频指导。
      </div>

    </div>
    <!-- 产品服务介绍详情图 -->
    <div class="product-images">
      <van-image
        v-for="(image, index) in productImages"
        :key="index"
        :src="image"
        class="product-image"
        fit="contain"
      />
    </div>
    <div style="height: 80px"></div>

    <!-- 底部弹出的输入模块 -->
    <van-popup v-model="showPopup" position="bottom" style="padding: 8px;box-sizing: border-box">
      <!-- 病种选择 -->
      <van-field
        v-model="selectedDisease"
        clickable
        label="选择病种"
        placeholder="请选择病种"
        readonly
        @click="showDiseasePicker = true"
      />
      <van-popup v-model="showDiseasePicker" position="bottom">
        <van-picker
          :columns="diseases"
          show-toolbar
          @cancel="() => showDiseasePicker = false"
          @confirm="onConfirmDisease"
        />
      </van-popup>
      <!-- 兑换码输入 -->
      <van-field
        v-model="description"
        label="咨询病情、健康问题简述"
        placeholder="请输入咨询病情、健康问题简述"
      />

      <!-- 手机号输入 -->
      <van-field
        v-model="phoneNumber"
        label="手机号"
        placeholder="请输入手机号"
        type="tel"
      />

      <!-- 兑换码输入 -->
      <van-field
        v-model="exchangeCode"
        label="兑换码"
        placeholder="请输入兑换码"
      />

      <!-- 服务说明 -->
      <div class="service-notice">
        <!--        *请留意来自18520530303的客服电话，以便进行后续服务权益的兑现。-->
      </div>

      <!-- 提交按钮 -->
      <van-button block type="primary" @click="submitExchange">提交兑换</van-button>
    </van-popup>

    <!-- 触发弹出的按钮 -->
    <van-button block class="btn-bottom" type="info" @click="showPopup = true">输 入 兑 换 码</van-button>
  </div>
</template>

<script>
import RegisterDao from '@/h5/doucan/network/RegisterDao'
import ExchangeDao from '@/h5/doucan/network/ExchangeDao'
import { Toast } from 'vant'
import enterpriseFinder from '@/h5/doucan/giftcard/enterprise/EnterpriseFinder'

export default {
  data () {
    return {
      productImages: [
        'https://cdn.keihong.tech/img/202405131020187.png',
        'https://cdn.keihong.tech/img/202405131020051.png',
        'https://cdn.keihong.tech/img/202405131021356.png',
        'https://cdn.keihong.tech/img/202405131021250.png'
      ],
      showPopup: false,
      selectedDisease: '',
      selectedDiseaseIndex: -1,
      description: '',
      phoneNumber: '',
      exchangeCode: '',
      diseasesOrigin: [/* 病种选项数组 */],
      showDiseasePicker: false,
      enterprise: ''

    }
  },
  mounted () {
    this.enterprise = enterpriseFinder.findNameById(Number(this.$route.query.ep))

    RegisterDao.getSickKind().then(res => {
      console.log(res)
      this.diseasesOrigin = res
      // 从res数组抽取name字段，组成新的数组
    })
  },
  methods: {
    onConfirmDisease (value, index) {
      this.selectedDisease = value
      this.selectedDiseaseIndex = index
      this.showDiseasePicker = false
    },
    submitExchange () {
      // 提交兑换信息的逻辑
      console.log(this.diseasesOrigin[this.selectedDiseaseIndex].id, this.phoneNumber, this.exchangeCode)
      // 例如，调用API提交这些信息

      // 判空
      if (this.selectedDiseaseIndex === -1) {
        this.$toast('请选择病种')
        return
      }
      if (this.phoneNumber === '') {
        this.$toast('请输入手机号')
        return
      }
      if (this.phoneNumber.length !== 11) {
        this.$toast('请输入正确的手机号')
        return
      }
      if (this.exchangeCode === '') {
        this.$toast('请输入兑换码')
      }
      if (!this.description) {
        this.description = '未填写'
      }
      ExchangeDao.redeemCode(
        this.diseasesOrigin[this.selectedDiseaseIndex].id,
        this.phoneNumber,
        this.exchangeCode,
        this.description
      ).then(res => {
        console.log('兑换结果', res)
        if (res.code === 200) {
          // 跳转
          this.$router.push('/ExchangeSuccessPage')
        } else {
          Toast.fail('兑换失败，请检查兑换码是否正确')
        }
      })
    }
  },
  computed: {
    diseases () {
      return this.diseasesOrigin.map(item => item.name)
    }
  }
}
</script>

<style scoped>
.btn-bottom {
  position: fixed;
  bottom: 0;
  background-color: #ff6c1f;
  color: #ffffff;
  padding: 32px;
  border-color: #0a4b3d;
  font-weight: bold;
  border-width: 8px;
  font-size: 24px;
  box-sizing: border-box;
}

.exchange-page {
  display: flex;
  flex-direction: column;
}

.product-images {
  overflow-y: auto;
}

.product-image {
  display: block;
}

.service-notice {
  margin-top: 10px;
  color: #464646;
}

.vip-container {
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #fff4d1;
}

.message {
  margin-bottom: 20px;
  line-height: 1.5;
  color: #136b59;
  font-size: 18px;
}

.value-class {
  color: #1989fa;
  font-weight: bold;
}

</style>
