<template>
  <div>
    <div v-if="childInfo.type==='title0'" class="title0">{{ childInfo.title }}</div>
    <div v-else-if="childInfo.type==='title1'" class="title1">{{ childInfo.title }}</div>
    <div v-else-if="childInfo.type==='title2'" class="title2">{{ childInfo.title }}</div>
    <div v-else-if="childInfo.type==='question'" class="question">{{ childInfo.title }}</div>
    <!--    <div v-else>无标题</div>-->
    <div v-if="childInfo.qType==='picker'">
      <div style="position: relative;" @click=" pickerShow=true">
        <div
          style=" margin: 16px;padding: 8px;font-size: 20px;color: #797979"
          :style="childInfo.answer ?'color: #171717FF':''"
        >{{ childInfo.answer ? childInfo.answer : '请点击选择' }}
        </div>
        <van-divider/>
        <div
          style="position: absolute; bottom: 50%;right: 16px;font-size: 20px;margin-bottom: -6px;color: #008598">
          选择
        </div>
      </div>
      <van-popup v-model="pickerShow" position="bottom">
        <van-picker
          show-toolbar
          :columns="childInfo.pickerOpts"
          @confirm="onConfirmPicker"
          @change="onChangePicker"
          :title="childInfo.pickerTitle"
          @cancel="pickerShow = false"
        />
      </van-popup>
    </div>
    <div v-else-if="childInfo.qType==='radio'" style="padding: 16px">
      <van-radio-group v-model="modelRadio" :direction="childInfo.direction||'vertical'" @change="onRadioChange">
        <div v-for="(item,index) in childInfo.radioOpts" :key="index">
          <van-radio :name="item.value" class="radio">{{ item.label }}
          </van-radio>
          <van-divider/>
        </div>
      </van-radio-group>
    </div>
    <div v-else-if="childInfo.qType==='input'" style="padding: 16px">
      <van-field v-model="modelInput" :placeholder="childInfo.hint"
                 style="font-size: 20px;margin-top: 0;background-color: #eaeaea; border-radius: 10px"
                 rows="2"
                 autosize
                 type="textarea"
                 @input="onInputChange"/>
    </div>
    <div v-else-if="childInfo.qType==='checkbox'" style="padding: 16px">
      <van-checkbox-group v-model="modelCheckbox" direction="horizontal" @change="onCheckboxChange">
        <van-checkbox v-for="(item,index) in childInfo.checkboxOpts" :key="index" :name="item.value" icon-size="28px"
                      shape="square" checked-color="#0da65e" class="optionMargin">{{ item.label }}
        </van-checkbox>
      </van-checkbox-group>
    </div>
    <slot v-if="childInfo.type === 'slot'">这是默认的slot
    </slot>
    <!--    <div v-if="childInfo.type==='question'"-->
    <!--         style="height: 6px;width: 100%;margin-bottom: 8px;margin-top: 16px"></div>-->
  </div>
</template>

<script>
export default {
  name: 'Questionnaire',
  props: ['info', 'showSubjectIdx'],
  data () {
    return {
      pickerShow: false,
      modelRadio: '',
      modelInput: '',
      modelCheckbox: []
    }
  },
  computed: {
    // 计算属性可以根据a变量延申出一个b变量，并且这个b变量可以根据a变量改变而改变
    childInfo () {
      console.log(this.info)
      const type = this.info.qType
      console.log(type)
      // alert(`${type}:${this.info.answer}`)
      if (type) {
        switch (type) {
          case 'radio':
            // alert(`${type}:${this.info.answer}`)
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.modelRadio = this.info.answer
            break
          case 'input':
            // alert(`${type}:${this.info.answer}`)
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.modelInput = this.info.answer
            break
          case 'checkbox':
            // alert(`${type}:${this.info.answer}`)
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            if (this.info.answer) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.modelCheckbox = this.info.answer
            }
            break
        }
      }

      //
      // const ans = this.info.answer
      // console.log(ans)
      // if (type === 'checkbox') {
      //   for (let i = 0; i < this.info.options.length; i++) {
      //     var opt = this.info.options[i]
      //     if (ans.join(',').indexOf(opt.id) > -1) {
      //       opt.isCheck = true
      //     }
      //   }
      // }
      return this.info
    }
  },
  methods: {
    onCheckboxChange (values) {
      this.info.answer = values
    },
    onInputChange (value) {
      this.info.answer = value

      this.$emit('onInputChange', this.info)
    },
    onRadioChange (name) {
      this.info.answer = name
    },
    onChangePicker (picker, value, index) {
      console.log(value)
      console.log(index)
      this.getPickerResult(value, index)
    },
    onConfirmPicker (value, index) {
      this.pickerShow = false
      this.getPickerResult(value, index)
    },
    getPickerResult (value, index) {
      console.log(value)
      console.log(index)
      let ans = ''
      value.forEach((val) => {
        ans += val
      })
      this.info.answer = ans
      // this.info.answer = value[0] + value[1]
      console.log('component', this.info.answer)
    }
  }
}
</script>

<style scoped>
.title0 {
  font-size: 24px;
  font-weight: bold;
  padding: 16px
}

.title1 {
  font-size: 24px;
  font-weight: bold;
  padding: 16px
}

.title2 {
  padding-left: 16px;
  padding-right: 16px;
  font-size: 20px;
  margin-bottom: 8px;
}

.question {
  padding-left: 16px;
  padding-right: 16px;
  font-size: 20px;
  margin-bottom: 8px;
  /*font-weight: bold;*/
}

.radio {
  margin-bottom: 16px;
  font-size: 20px;
}

.optionMargin {
  margin-right: 24px;
  margin-bottom: 10px;
}
</style>
