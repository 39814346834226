<template>
  <div>
    <base-title-bar-scroll-page ref="container" style="width: 100%" title="企业主页">
      <div class="homepage">
        <van-cell-group>
          <van-cell :value="companyName" title="当前企业"/>
          <van-cell :value="generatedCodesCount" title="已生成的兑换码数量"/>
          <van-cell :value="usedCodesCount" title="已使用的兑换码数量"/>
          <van-cell :value="unusedCodesCount" title="未使用的兑换码数量"/>
        </van-cell-group>

        <van-button block class="btn" type="info" @click="showGenerateCodePopup">生成兑换码</van-button>
        <van-popup v-model="showPopup" position="bottom" style="height: 50%">
          <div v-if="generatedCode">
            <div class="popup-content">
              兑换码：{{ generatedCode }}<br/>
              兑换链接：{{ exchangeLink }}<br/>
              <van-button icon="copy" style="width: 100%;margin-top: 16px" type="primary"
                          @click="copyExchangeInfo(generatedCode)">复制
              </van-button>
            </div>
          </div>
          <div v-else> 加载中</div>

        </van-popup>

        <van-popup v-model="showPopupGenPic" closeable position="bottom" style="height: 80%">
          <div>
            <div style="height: 48px;line-height: 48px;font-size: 18px;padding-left: 16px">
              {{ drawFinished ? '长按或右击图片保存到相册或转发' : '生成中' }}
            </div>
            <canvas v-if="!drawFinished" ref="canvas" height="500" width="500"></canvas>
            <img v-else :alt="imgName" :src="imgUrl" :style="{with:imgWith,height:imgHeight}"/>
            <!--            <button @click="downloadImage">下载图片</button>-->
          </div>
        </van-popup>

        <van-button block class="btn" type="primary" @click="goToExchangeOrders">查看兑换订单</van-button>
        <div style="text-align: center">未使用的兑换码，点击复制分享链接</div>
        <!--  未使用的兑换码      -->
        <div>
          <div v-for="(code) in unusedCodes" :key="code.id" style="position: relative">
            <van-cell
              :label="`状态: ${code.status ? '已使用' : '未使用'} | 生成: ${formatDate(code.createTime)}`"
              :title="`兑换码: ${code.code} ` "
            />
            <div style="position:absolute;right: 8px;z-index: 100;top: 0;border-bottom: 0">
              <div
                style="background-color: #0d3d3d;padding-right: 16px;padding-left: 16px;border-radius: 8px;font-size: 12px;color: white;margin-bottom: 8px"
                @click="copyExchangeInfo(code.code)">
                复制文案
              </div>
              <div
                style="background-color: #ff7229;padding-right: 16px;padding-left: 16px;border-radius: 8px;font-size: 12px;color: white;"
                @click="genPic(code.code)">
                生成图片
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-title-bar-scroll-page>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import { Toast } from 'vant'
import ExchangeDao from '@/h5/doucan/network/ExchangeDao'
import enterpriseFinder from '@/h5/doucan/giftcard/enterprise/EnterpriseFinder'
import MyImg from '@/h5/doucan/components/MyImg.vue'

export default {
  name: 'EnterpiseMainPage',
  components: {
    MyImg,
    BaseTitleBarScrollPage
  },
  data () {
    return {
      generatedCodesCount: 0,
      usedCodesCount: 0,
      unusedCodesCount: 0,
      showPopup: false,
      generatedCode: '',
      companyName: '无',
      unusedCodes: [],
      showPopupGenPic: false,
      imageSrc: 'https://cdn.keihong.tech/img/202406051651382.png', // 底图路径
      drawFinished: false,
      imgUrl: '',
      imgWith: '',
      imgHeight: '',
      imgName: ''

    }
  },
  mounted () {
    this.hideLoading()
    this.companyName = this.$route.query.name
    // 模拟获取已生成和已使用的兑换码数量

    this.loadStatistics()
    this.loadUnusedCode()
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    genPic (code) {
      this.drawFinished = false
      console.log('生成图片', code)
      this.showPopupGenPic = true
      this.$nextTick(() => {
        // loadImage
        // const ctx = this.$refs.canvas.getContext('2d')
        const img = new Image()
        img.crossOrigin = 'anonymous'
        img.onload = () => {
          const canvas = this.$refs.canvas
          if (!canvas) return
          const dpr = window.devicePixelRatio || 1 // 获取设备的像素比率

          // 设置canvas的宽度和高度为图片的宽度和高度
          console.log('img', img.width, img.height)
          const screenWidth = window.innerWidth // 获取屏幕宽度
          const scale = screenWidth / img.width // 计算缩放比例
          // 设置canvas的宽度为屏幕宽度，高度为图片高度乘以缩放比例
          canvas.width = screenWidth * dpr // 内部大小为屏幕宽度乘以设备像素比率
          canvas.height = img.height * scale * dpr // 同理
          console.log('canvas', canvas.width, canvas.height)
          canvas.style.width = screenWidth + 'px' // CSS大小为屏幕宽度
          canvas.style.height = (img.height * scale) + 'px' // CSS高度按比例
          this.imgHeight = canvas.style.height
          this.imgWith = canvas.style.width

          // 绘制图片
          const ctx = canvas.getContext('2d')
          ctx.scale(dpr, dpr) // 根据设备像素比例缩放画布
          ctx.drawImage(img, 0, 0, screenWidth, img.height * scale) // 重绘图片以适应新尺寸
          // drawText
          ctx.font = '20px Arial'
          ctx.fillStyle = 'red' // 文字颜色
          const x = Math.round(220 * screenWidth / 1080)
          const y = Math.round(1123 * img.height * scale / 1373)
          console.log('drawText-code', code)
          ctx.fillText(code, x, y) // 绘制文字的位置
          // ctx.fillText(code, 50, 455) // 绘制文字的位置
          this.imgUrl = canvas.toDataURL('image/png')
          this.imgName = '兑换码-' + code
          this.drawFinished = true
        }

        img.src = this.imageSrc
      })
    },
    showGenerateCodePopup () {
      ExchangeDao.generateCode(this.companyName).then(e => {
        console.log('生成兑换码', e.data.code)
        // 模拟生成兑换码
        this.generatedCode = e.data.code
        this.loadStatistics()
        this.loadUnusedCode()
      })
      this.showPopup = true
    },
    copyExchangeInfo (code) {
      const shareText = `${this.companyName} 给您分享了一张远程康复服务礼品卡，兑换码：${code}，兑换链接：${this.exchangeLink}`
      this.$copyText(shareText).then(e => {
        console.log('剪切板', e)
        Toast.success('复制成功')
      }, function (e) {
        alert('医院定位失败')
        console.log(e)
      })
    },
    formatDate (dateStr) {
      const date = new Date(dateStr)
      return date.toLocaleString()
    },
    goToExchangeOrders () {
      // 假设路由名为 'exchange-orders'
      this.$router.push({
        name: 'ExchangeRecordPage',
        query: {
          enterpriseId: enterpriseFinder.findIdByName(this.companyName)
        }
      })
    },
    loadStatistics () {
      ExchangeDao.codeStatistics(enterpriseFinder.findIdByName(this.companyName))
        .then(e => {
          console.log('codeStatistics', e)
          this.generatedCodesCount = e.totalCodes
          this.usedCodesCount = e.usedCodes
          this.unusedCodesCount = e.unusedCodes
        })
    },
    loadUnusedCode () {
      ExchangeDao.unusedCode(enterpriseFinder.findIdByName(this.companyName)).then(e => {
        console.log('unusedCode', e)
        this.unusedCodes = e
      })
    }
  },
  watch: {},
  computed: {
    exchangeLink () {
      return `https://doucan.jianerkanggz.cn/#/dh01?ep=${enterpriseFinder.findIdByName(this.companyName)}`
    }
  }

}
</script>
<style scoped>
.homepage {
  width: 100%;

}

.btn {
  width: calc(100% - 32px); /* 减去左右margin的总和 */
  margin: 16px;
  box-sizing: border-box;
}

.popup-content {
  padding: 16px;
}
</style>
