import { render, staticRenderFns } from "./ServiceImageButtonGroup.vue?vue&type=template&id=08f13160&scoped=true"
import script from "./ServiceImageButtonGroup.vue?vue&type=script&lang=js"
export * from "./ServiceImageButtonGroup.vue?vue&type=script&lang=js"
import style0 from "./ServiceImageButtonGroup.vue?vue&type=style&index=0&id=08f13160&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08f13160",
  null
  
)

export default component.exports