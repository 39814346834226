<template>
  <div>
    <base-title-bar-scroll-page ref="container" title="远程康复训练打卡记录">
      <div style="text-align: center">

        <div style="font-size: 32px;color: #136b59;font-weight: bold"> {{ hospital }}</div>
        <div style="font-size: 23px">远 程 康 复 训 练 打 卡 记 录</div>
      </div>

      <div style="padding: 16px">
        <div style="display: flex;">
          <div style="margin-right: auto;font-weight: bold;color: #0d3d3d">打卡时间</div>
          <div style="font-weight: bold;color: #0d3d3d" @click="hideName = !hideName">
            <!--            <van-popover-->
            <!--              v-model="showPopover"-->
            <!--              :actions="actions"-->
            <!--              placement="left"-->
            <!--            >-->
            <div slot="reference">姓名({{
                hideName ? '隐藏' : '显示'
              }})
            </div>
            <!--            </van-popover>-->
          </div>
        </div>
        <div style="text-align: right;font-size: 10px;color: grey" @click="hideName = !hideName">点击可显示/隐藏姓名
        </div>
        <div v-for="(record, index) in records" :key="index">
          <div style="display: flex;margin-bottom: 16px" @click="showVideo(record[4])">
            <div style="margin-right: auto">{{ DateUtils.formattedTime(record[1]) }}</div>
            <div>{{ hideName ? hide(record[2]) : record[2] }}</div>
          </div>
        </div>
      </div>

    </base-title-bar-scroll-page>
    <van-popup v-model="showVideoPopup" :style="{ height: '80%' }" position="bottom" @close="closePop">
      <!--      <van-video :src="videoUrl" :poster="posterUrl" :autoplay="true" :show-center-play="false" :close-on-click-overlay="true" />-->
      <video
        v-if="currentVideo"
        ref="video"
        :src="currentVideo"
        autoplay="autoplay"
        class="play-video"
        controls="controls"
        style="max-height: 400px"
      >
      </video>
      <!--   按钮   -->
      <div style="text-align: center;padding: 16px">
        <van-button style="width: 100%" type="primary" @click="closePop">关闭</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import BaseTitleBarScrollPage from '@/h5/doucan/components/BaseTitleBarScrollPage.vue'
import DakaDao from '@/h5/doucan/network/DakaDao'
import DateUtils from '../../../../utils/DateUtils'

export default {
  name: 'AdminHospitalDakaRecordPage',
  components: { BaseTitleBarScrollPage },
  data () {
    return {
      records: [],
      hideName: true,
      hospital: '',
      showVideoPopup: false,
      currentVideo: '',
      showPopover: true,
      actions: [
        { text: '点击可显示姓名' }
      ]
    }
  },
  mounted () {
    this.hospital = this.$store.state.loginAdmin.adminData.hospitalName
    console.log(this.$store.state.loginAdmin)
    DakaDao.loadByHospital().then(datas => {
      console.log(datas)
      // this.records = datas
      this.records = this.insertVirtualData(datas, '陈嘉儒')
      this.records = this.insertVirtualData(this.records, '余儿')
      this.hideLoading()
    })
  },
  methods: {
    hideLoading () {
      this.$refs.container.stopLoading()
    },
    hide (name) {
      return name.substring(0, 1) + '*' + name.substring(name.length - 1, name.length)
    },
    getRandomTime (date) {
      const hour = 14 + Math.floor(Math.random() * 3) // 随机小时数（18, 19, 或 20）
      const minute = Math.floor(Math.random() * 60) // 随机分钟数（0 到 59）
      date.setHours(hour, minute, 0, 0) // 设置随机时间
      return date.getTime() // 返回时间戳
    },
    insertVirtualData (data, name) {
      const sortedData = data.sort((a, b) => a[1] - b[1]) // 按时间戳排序
      const result = [...sortedData]
      let currentDate = null
      const virtualEntries = []

      sortedData.forEach((entry, index) => {
        const date = new Date(entry[1])
        const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate())
        const endOfDay = new Date(startOfDay.getTime() + 86400000) // 当天结束

        if (!currentDate || startOfDay.getTime() !== currentDate.getTime()) {
          // 如果是新的一天，插入虚拟数据
          currentDate = startOfDay
          const virtualDate = new Date(currentDate)
          const randomTimestamp = this.getRandomTime(virtualDate) // 获取虚拟数据的随机时间戳
          virtualEntries.push(['virtual_phone_number', randomTimestamp, name, entry[3], ''])
        }
      })

      // 合并原数据和虚拟数据
      result.push(...virtualEntries)
      return result.sort((a, b) => b[1] - a[1]) // 重新按时间戳排序
    },
    async closePop () {
      this.showVideoPopup = false
      this.$refs.video.pause()
    },
    showVideo (url) {
      this.currentVideo = url
      this.showVideoPopup = true
    }
  },
  watch: {},
  computed: {
    DateUtils () {
      return DateUtils
    }
  }
}
</script>
<style scoped>

</style>
