<template>
  <van-nav-bar
    :left-text="backText"
    :title="title"
    left-arrow
    @click-left="onLeft"
  />
</template>

<script>
export default {
  name: 'MyNavBar',
  props: {
    title: String,
    back: {
      type: Boolean,
      default: false
    },
    backText: {
      type: String,
      default: '返回'
    }
  },
  methods: {
    onLeft () {
      if (this.back) {
        this.$emit('onBackClick')
      } else {
        this.$router.back()
      }
    }
  }
}
</script>

<style scoped>

</style>
