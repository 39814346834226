import axios from 'axios'

const OrderDao = {

  async loadQuickPayOrder (outTradeNo) {
    const url = '/quick/pay/order/info?outTradeNo=' + outTradeNo
    const { data: resp } = await axios.get(url)
    const {
      code,
      data,
      message
    } = resp
    return data
    // if (code !== 200 || !data) {
    //   return {
    //     code: 2,
    //     msg: message
    //   }
    // }
    // return {
    //   code: 1,
    //   data: data
    // }
  }
}
export default OrderDao
