<template>
  <div>
    <van-nav-bar
      style="background-color: #00ad61"
      fixed
      z-index="5"
      @click-left="onClickLeft"
    >
      <template #title>
        <div style="color: white;font-size: 16px">{{ title }}</div>
      </template>
      <template v-if="leftArrow" #left>
        <van-icon name="arrow-left" size="14" color="white"/>
        <div style="font-size: 14px;color: white">{{ leftText }}</div>
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
    title: {
      type: String,
      required: true
    },
    leftArrow: {
      type: Boolean,
      default: false
    },
    leftText: {
      type: String,
      default: '返回'
    }
  },
  methods: {
    onClickLeft () {
      this.$router.back()
    }
  }
}
</script>

<style scoped>

</style>
