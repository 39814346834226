<!-- C:\Users\keiho\Documents\development\codes\doucan\vue_doucan_cms\src\h5\doucan\pages\admin/template.vue -->
<template>
  <div>
    <MyNavBar style="position:fixed;top: 0;width: 100%" title="本院患者列表"/>
    <div style="margin-top: 56px">
      <!--  医生列表 用vant组件 -->
      <!--  loading  -->
      <div v-show="patients.length === 0" style="text-align: center;margin-top: 16px">
        <van-loading/>
      </div>
      <div v-if="patients.length>0" style="padding: 16px">
        <div style="font-size: 20px">患者数量: {{ total }}</div>
      </div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-if="patients.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="loadMore"
        >
          <div v-for="(item, index) in patients"
               :key="index">
            <div>
              <van-cell-group v-show="patients">
                <van-cell

                  :label="`注册医生: ${item.registerDoctorName}`"
                  :title="`姓名: ${item.realname}`"
                  :value="`注册时间: ${DateUtils.formattedTime(item.createDate)}`"
                />
              </van-cell-group>
              <div style="padding: 16px">
                <div style="font-size: 12px">注册医生所在科室：{{ item.registerDoctorDepartment }}</div>
              </div>
            </div>
            <div style="height: 16px;background-color: #eeeeee"></div>
          </div>

        </van-list>
      </van-pull-refresh>

    </div>
  </div>
</template>
<script>
import MyNavBar from '../../components/MyNavBar'
import DateUtils from '@/utils/DateUtils'
import AdminHospitalDao from '@/h5/doucan/network/AdminHospitalDao'

export default {
  name: 'AdminHospitalPatientList',
  components: { MyNavBar },
  data () {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      patients: [],
      total: 0,
      pageNo: 1, // 当前页码
      pageSize: 20,
      totalPages: 0 // 总页数
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      if (this.finished) {
        return
      }
      this.loading = true
      const datas = await AdminHospitalDao.patientListPage(this.pageNo)
      console.log('datas', datas)
      this.patients.push(...datas.content)
      this.totalPages = datas.totalPages
      this.total = datas.totalElements
      this.refreshing = false
      // 加载状态结束
      this.loading = false
      console.log('已加载数量', this.patients.length)
      if (this.patients.length >= this.total) {
        this.finished = true
      }
    },
    onRefresh () {
      console.log('刷新')
      this.pageNo = 1 // 重置页码
      this.refreshing = true
      this.loadData() // 刷新数据
    },
    loadMore () {
      console.log('finished', this.finished)
      if (this.finished) {
        return
      }
      console.log('totalPages', this.totalPages)
      console.log('pageNo', this.pageNo)
      if (this.pageNo <= this.totalPages - 1) {
        this.pageNo++ // 增加页码
        this.loadData()
      }
    }
  },
  watch: {},
  computed: {
    DateUtils () {
      return DateUtils
    }
  }
}
</script>
<style scoped>

</style>
