// 用户模块
export default {
  namespaced: true,
  state () {
    return {
      // 用户信息
      register: {
        phone: '',
        name: '',
        sex: '',
        age: null,
        inviteCode: '',
        hospitalId: '',
        registHospitalId: '',
        doctor: '',
        sickKind: '',
        registerDevice: '',
        registerChannel: ''
      }
    }
  },
  mutations: {
    // 修改用户信息，payload就是用户信息对象
    setRegister (state, payload) {
      state.register = payload
    },
    setHospital (state, payload) {
      state.register.inviteCode = payload.inviteCode
      state.register.hospitalId = payload.hospitalId
      state.register.registHospitalId = payload.registHospitalId
    },
    setDoctor (state, payload) {
      state.register.doctor = payload
    },
    setSickKind (state, payload) {
      state.register.sickKind = payload
    },
    setUserBaseInfo (state, payload) {
      state.register.name = payload.name
      state.register.sex = payload.sex
      state.register.age = payload.age
    },
    setRegisterChannel (state, payload) {
      state.register.registerDevice = payload.device
      state.register.registerChannel = payload.channel
    }
  }
}
// ————————————————
// 版权声明：本文为CSDN博主「run-Ameng」的原创文章，遵循CC 4.0 BY-SA版权协议，转载请附上原文出处链接及本声明。
// 原文链接：https://blog.csdn.net/m0_58861443/article/details/120247013
